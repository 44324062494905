import React, { useState } from "react";
import P from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, Formik } from "formik";
import { loginUserAction } from "../../modules/auth/Actions";
import SubmitButton from "../Form/Misc/SubmitButton";
import { authIsLoadingSelector } from "../../modules/auth/Selectors";
import { Button } from "react-bootstrap";
const LoginForm = (props) => {
    const { loginUser, isLoading } = props;
    const [checked, setChecked] = useState(false);

    const validateUsername = (value) => {
        let error;
        if (!value) {
            error = "Required";
        }
        return error;
    };
    const validatePassword = (value) => {
        let error;
        if (!value) {
            error = "Required";
        } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/i.test(value)) {
            error =
                "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.";
        }
        return error;
    };
    return (
        <>
            <div className="title" style={{ marginTop: "10px" }}>
                <h1>Login</h1>
            </div>
            <Formik
                initialValues={{
                    username: "",
                    password: "",
                    remember: false,
                }}
                onSubmit={(values) => {
                    loginUser({
                        username: values.username?.toLowerCase(),
                        password: values.password,
                    });
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    dirty,
                    errors,
                    touched,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className=" d-flex flex-column">
                            <label htmlFor="username" className="form-label ">
                                Username
                            </label>
                            <Field
                                name="username"
                                style={{
                                    border: "0",
                                    padding: "8px",
                                    fontSize: "15px",
                                }}
                                validate={validateUsername}
                            />
                            {errors.username && touched.username && (
                                <div className="text-danger">
                                    {errors.username}
                                </div>
                            )}
                        </div>
                        <div className=" d-flex flex-column">
                            <label htmlFor="password" className="form-label ">
                                Password
                            </label>
                            <Field
                                name="password"
                                style={{
                                    border: "0",
                                    padding: "8px",
                                    fontSize: "15px",
                                }}
                                validate={validatePassword}
                                type="password"
                            />
                            {errors.password && touched.password && (
                                <div className="text-danger">
                                    {errors.password}
                                </div>
                            )}
                        </div>

                        <div className="my-3 form-check">
                            <input
                                className="form-check-input"
                                id="remember-me-input"
                                type="checkbox"
                                name="remember"
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="remember-me-input"
                                className="form-check-label"
                                style={{
                                    fontSize: " 1.6rem",
                                    fontWeight: "400",
                                }}
                            >
                                Remember me
                            </label>
                        </div>

                        <SubmitButton
                            className="btn btn-primary searchAction"
                            isLoading={isLoading}
                            disabled={!dirty}
                        >
                            Login
                        </SubmitButton>
                        {/* <Button
                            style={{ marginLeft: "10px" }}
                            as="a"
                            href="/register"
                        >
                            Create New Account
                        </Button> */}

                        {/* <Link
                  to='/forgot-password'
                  className ='forgetPassword'
                  style={{fontSize:" 1.6rem",fontWeight: "400"}}
                >
                  Forget Password ?
                </Link> */}
                    </form>
                )}
            </Formik>
        </>
    );
};

LoginForm.propTypes = {
    loginUser: P.func.isRequired,
    isLoading: P.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isLoading: authIsLoadingSelector(state),
});

const mapDispatchToProps = {
    loginUser: loginUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
