/* eslint-disable object-curly-newline */
import { call, put, select, takeLatest, takeEvery } from "redux-saga/effects";

import {
    newErrorNotificationAction,
    newSuccessNotificationAction,
} from "../notifications/Actions";

import {
    setSectorsIsLoadingAction,
    getSectorsAction,
    getSectorsSuccessAction,
    getSectorsCountAction,
    getSectorSuccessAction,
    resetSectorsAction,
} from "./Actions";
import * as api from "./Api";
import { actions } from "./Reducer";

// Plural
function* getSectorsSaga({ payload }) {
    const defaultBody = {
        offset: 0,
        size: 50,
        sort: "name",
        dir: "asc",
        searchTerm: "",
        filterBy: [],
    };

    const body = { ...defaultBody, ...payload };
    try {
        yield put(setSectorsIsLoadingAction(true));
        const response = yield call(api.getSectors, body);
        const { data } = response;
        const items = data.content;
        const count = data.count;
        yield put(getSectorsSuccessAction(items));
        yield put(getSectorsCountAction(count));
    } catch (error) {
        // yield put(errorNotificationFromError(error));
    } finally {
        yield put(setSectorsIsLoadingAction(false));
    }
}

function* getSectorSaga({ payload }) {
    const sectorId = payload;
    try {
        //  yield put(resetMembersAction())
        yield put(setSectorsIsLoadingAction(true));

        const response = yield call(api.getSector, sectorId);
        const { data } = response;
        yield put(getSectorSuccessAction(data));
    } catch (error) {
        yield put(newErrorNotificationAction("no sector found"));
    } finally {
        yield put(setSectorsIsLoadingAction(false));
    }
}

export default function* () {
    yield takeLatest(actions.GET_SECTORS, getSectorsSaga);
    yield takeLatest(actions.GET_SECTOR, getSectorSaga);
}
