import React from "react";
import SideHead from "../Heads/SideHead";
import SearchForm from "./SearchForm";
const SearchPage = (props) => {
    return (
        <>
            <div className="row">
                <div className="col">
                    <SideHead />
                </div>
                <div className="col-12 col-md-5 ">
                    <SearchForm filter={props.filter} />
                </div>
            </div>
        </>
    );
};

export default SearchPage;
