import SearchPage from "../../components/Search";
import Advertsment from "../../components/Advartsment/Advartsment";
import Header from "../../components/Header/Header";
import Layout from "../../components/Layout/Layout";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getMembersAction } from "../../modules/members/Actions";
import { membersSelector } from "../../modules/members/Selectors";
import MembersList from "../../components/Combine/Members/MembersList";
import { getAdsAction } from "../../modules/ads/Actions";

const Members = (props) => {
    const { members, getMembers, getAds } = props;
    useEffect(() => {
        getAds({ filterBy: [{ location: "search" }] });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 100,
            left: 100,
            behavior: "smooth",
        });
    }, []);
    return (
        <Layout>
            <Header>
                <SearchPage />
                <div
                    style={{
                        marginBlock: "75px",
                    }}
                >
                    <Advertsment location="SEARCH_BLOCK_1" />
                    <Advertsment location="SEARCH_BLOCK_2" />
                </div>
            </Header>
            <MembersList members={members} getMembers={getMembers} />

            <Advertsment location="SEARCH_BLOCK_3" />
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        members: membersSelector(state),
    };
};
const mapDispatchToProps = {
    getMembers: getMembersAction,
    getAds: getAdsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);
