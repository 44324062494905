import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormLabel, FormSelect, Row } from "react-bootstrap";
import Input from "../Input";

const Step3 = ({ currentStep }) => {
    const [tickets, setTickets] = useState([
        { type: "", name: "", value: "", paymentLink: "", seates: 0 },
    ]);
    // const [sponsorShipTickets, setSponsorShipTickets] = useState([
    //     { type: "", name: "", value: "", paymentLink: "" },
    // ]);

    const handleMemberTicketsChange = (index, property, value) => {
        const newTickets = [...tickets];
        newTickets[index][property] = value;
        setTickets(newTickets);
    };

    // const handleSponsorTicketsChange = (index, property, value) => {
    //     const newSponsorShipTickets = [...sponsorShipTickets];
    //     newSponsorShipTickets[index][property] = value;
    //     setSponsorShipTickets(newSponsorShipTickets);
    // };

    const types = ["Member", "Non Member", "Table", "Non Table", "Sponsor"];

    useEffect(() => {
        const currentEvent = JSON.parse(localStorage.getItem("eventStepTwo"));

        setTickets(
            currentEvent || [
                { type: "", name: "", value: "", paymentLink: "", seates: 0 },
            ]
        );
        // setSponsorShipTickets(
        //     currentEvent?.sponsorShipTickets || [
        //         { name: "", value: "", paymentLink: "" },
        //     ]
        // );
    }, []);

    useEffect(() => {
        localStorage.setItem("eventStepTwo", JSON.stringify(tickets));
    }, [tickets]);

    console.log({ tickets });

    return (
        <div className="row">
            <h4
                className="mb-5 d-flex align-items-center gap-3 pt-3 pb-4"
                style={{
                    borderBottom: "2px solid rgba(0, 0, 0, .06)",
                    color: "var(--primary-color)",
                }}
            >
                <span
                    style={{
                        width: "40px",
                        height: "40px",
                        lineHeight: "40px",
                        borderRadius: "50%",
                        border: "1px solid var(--primary-color)",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {currentStep + 1}
                </span>{" "}
                Prices
            </h4>
            <div className="d-flex flex-column gap-5">
                <div>
                    <div className="d-flex gap-3 align-items-center justify-content-between">
                        <h5>Tickets</h5>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                setTickets((prev) => [
                                    ...prev,
                                    {
                                        type: "",
                                        name: "",
                                        value: "",
                                        paymentLink: "",
                                        seates: 0,
                                    },
                                ])
                            }
                            style={{
                                color: "var(--primary-color)",
                                background: "rgba(10, 61, 128, 0.2)",
                                border: "none",
                                fontWeight: "900",
                                fontSize: "20px",
                            }}
                        >
                            +
                        </button>
                    </div>
                    {tickets.map((mt, i) => (
                        <Row
                            key={i}
                            style={{
                                marginTop: "30px",
                            }}
                        >
                            <Col>
                                {/* <FormLabel>Ticket Type</FormLabel> */}
                                <FormSelect
                                    style={{ height: "46px", fontSize: "17px" }}
                                    value={mt.type}
                                    onChange={(e) =>
                                        handleMemberTicketsChange(
                                            i,
                                            "type",
                                            e.target.value
                                        )
                                    }
                                >
                                    <option value="">Select Type</option>
                                    {types.map((t) => (
                                        <option key={t} value={t}>
                                            {t}
                                        </option>
                                    ))}
                                </FormSelect>
                            </Col>
                            <Col>
                                <Input
                                    label="Name"
                                    placeholder="Type member name"
                                    value={mt.name}
                                    onChange={(e) =>
                                        handleMemberTicketsChange(
                                            i,
                                            "name",
                                            e.target.value
                                        )
                                    }
                                />
                            </Col>
                            <Col>
                                <Input
                                    type="number"
                                    label="Price"
                                    placeholder="Type member price"
                                    value={mt.value}
                                    onChange={(e) =>
                                        handleMemberTicketsChange(
                                            i,
                                            "value",
                                            e.target.valueAsNumber
                                        )
                                    }
                                />
                            </Col>
                            <Col>
                                <Input
                                    label="Payment Link"
                                    placeholder="Type payment link"
                                    value={mt.paymentLink}
                                    onChange={(e) =>
                                        handleMemberTicketsChange(
                                            i,
                                            "paymentLink",
                                            e.target.value
                                        )
                                    }
                                />
                            </Col>
                            <Col>
                                <Input
                                    label="Seats"
                                    type="number"
                                    placeholder="Type number of seats"
                                    value={mt.seates}
                                    onChange={(e) =>
                                        handleMemberTicketsChange(
                                            i,
                                            "seates",
                                            e.target.valueAsNumber
                                        )
                                    }
                                />
                            </Col>
                            {i !== 0 ? (
                                <Col>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() =>
                                            setTickets(
                                                tickets.filter(
                                                    (_, index) => i !== index
                                                )
                                            )
                                        }
                                    >
                                        Delete
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                    ))}
                </div>
                {/* <div>
                    <div className="d-flex gap-3 align-items-center justify-content-between">
                        <h5>Sponsors</h5>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                setSponsorShipTickets((prev) => [
                                    ...prev,
                                    {
                                        name: "",
                                        value: "",
                                        paymentLink: "",
                                    },
                                ])
                            }
                            style={{
                                color: "var(--primary-color)",
                                background: "rgba(10, 61, 128, 0.2)",
                                border: "none",
                                fontWeight: "900",
                                fontSize: "20px",
                            }}
                        >
                            +
                        </button>
                    </div>
                    {sponsorShipTickets.map((mt, i) => (
                        <Row key={i} style={{ marginTop: "30px" }}>
                            <Col>
                                <FormSelect
                                    style={{ height: "46px", fontSize: "17px" }}
                                    value={mt.type}
                                    onChange={(e) =>
                                        handleSponsorTicketsChange(
                                            i,
                                            "type",
                                            e.target.value
                                        )
                                    }
                                >
                                    {types.map((t) => (
                                        <option key={t} value={t}>
                                            {t}
                                        </option>
                                    ))}
                                </FormSelect>
                            </Col>
                            <Col>
                                <Input
                                    label="Name"
                                    placeholder="Type sponsor name"
                                    value={mt.name}
                                    onChange={(e) =>
                                        handleSponsorTicketsChange(
                                            i,
                                            "name",
                                            e.target.value
                                        )
                                    }
                                />
                            </Col>
                            <Col>
                                <Input
                                    type="number"
                                    label="Price"
                                    placeholder="Type sponsor price"
                                    value={mt.value}
                                    onChange={(e) =>
                                        handleSponsorTicketsChange(
                                            i,
                                            "value",
                                            e.target.valueAsNumber
                                        )
                                    }
                                />
                            </Col>
                            <Col>
                                <Input
                                    label="Payment Link"
                                    placeholder="Type payment link"
                                    value={mt.paymentLink}
                                    onChange={(e) =>
                                        handleSponsorTicketsChange(
                                            i,
                                            "paymentLink",
                                            e.target.value
                                        )
                                    }
                                />
                            </Col>
                            {i !== 0 ? (
                                <Col>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() =>
                                            setSponsorShipTickets(
                                                sponsorShipTickets.filter(
                                                    (_, index) => i !== index
                                                )
                                            )
                                        }
                                    >
                                        Delete
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                    ))}
                </div> */}
            </div>
        </div>
    );
};

export default Step3;
