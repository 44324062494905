/**
 * Takes in old state of data with _id prop
 * and then merges via push or replace depending
 * if an item already exists in oldItems
 */
export default (oldItems, newItems) => {
  const merged = [...oldItems];
  newItems?.forEach((item) => {
    const indexOf = oldItems.findIndex(oldItem => oldItem._id === item._id);
    if (indexOf > -1) {
      merged[indexOf] = item;
    } else {
      merged.push(item);
    }
  });
  return merged;
};
