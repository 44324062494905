import { actions } from "./Reducer";

export const createUserAction = (formData) => ({
    type: actions.CREATE_USER,
    payload: formData,
});

export const createUserSuccessAction = (user) => ({
    type: actions.CREATE_USER_SUCCESS,
    payload: user,
});

export const getUserAction = (id) => ({
    type: actions.GET_USER,
    payload: id,
});

export const getUserSuccessAction = (user) => ({
    type: actions.GET_USER_SUCCESS,
    payload: user,
});

export const updateUserAction = (userId, userFormData) => ({
    type: actions.UPDATE_USER,
    payload: {
        userId,
        userFormData,
    },
});

export const updateUserSuccessAction = (user) => ({
    type: actions.UPDATE_USER_SUCCESS,
    payload: user,
});

export const getUsersAction = (payload) => ({
    type: actions.GET_USERS,
    payload,
});

export const getUsersSuccessAction = (users) => ({
    type: actions.GET_USERS_SUCCESS,
    payload: users,
});

export const getUsersNoMergeSuccessAction = (users) => ({
    type: actions.GET_USERS_SUCCESS_NO_MERGE,
    payload: users,
});

export const setSearchValueAction = (value) => ({
    type: actions.SET_USERS_SEARCH_VALUE,
    payload: value,
});

export const setUsersIsLoadingAction = (isLoading) => ({
    type: actions.SET_USERS_IS_LOADING,
    payload: isLoading,
});

export const getUsersCountAction = (count) => ({
    type: actions.GET_USERS_COUNT,
    payload: count,
});

export const resetUsersAction = () => ({
    type: actions.RESET_USERS,
});

export const deleteUserAction = (id) => ({
    type: actions.DELETE_USER,
    payload: id,
});

export const deleteUserSuccessAction = (userId) => ({
    type: actions.DELETE_USER_SUCCESS,
    payload: userId,
});
