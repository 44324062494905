import {actions} from "./Reducer";

export const getsubActivitesAction = (payload) => ({
  type: actions.GET_SUBASTIVITES,
  payload,
});

export const getsubActivitesSuccessAction = (subactivites) => ({
  type: actions.GET_SUBACTIVITES_SUCCESS,
  payload: subactivites,
});

export const getsubActivitesHeaderAction = (payload) => ({
  type: actions.GET_SUBACTIVITESHEADER,
  payload,
});
export const getsubActivitesHeaderSuccessAction = (subactivites) => ({
  type: actions.GET_SUBACTIVITESHEADER_SUCCESS,
  payload: subactivites,
});


export const getsubActivityAction = id => ({
  type: actions.GET_SUBACTIVITY,
  payload: id
});

export const gesubtActivitySuccessAction = subactivity => ({
  type: actions.GET_SUBACTIVITY_SUCCESS,
  payload: subactivity
});


export const setSearchValueAction = (value) => ({
  type: actions.SET_SUBACTIVITES_SEARCH_VALUE,
  payload: value,
});

export const setsubActivitesIsLoadingAction = (isLoading) => ({
  type: actions.SET_SUBASTIVITES_IS_LOADING,
  payload: isLoading,
});

export const getsubActivitesCountAction = (count) => ({
  type: actions.GET_SUBACTIVITES_COUNT,
  payload: count,
});

export const resetsubActivitesAction = () => ({
  type: actions.RESET_SUBACTIVITES,
});