import React from 'react';
import ContactInformation from '../../components/ContactInfo/ContactInfo';
import Card from '../../components/Card/Card';
import Header from '../../components/Header/Header';
import ShadowHead from '../../components/Heads/ShadowHead';
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo';
import Advertsment from '../../components/Advartsment/Advartsment';
import Layout from '../../components/Layout/Layout';
import {
  memberSelector,
  membersCountSelector,
  membersSelector,
  membersIsLoadingSelector,
} from '../../modules/members/Selectors';
import {
  deleteMemberAction,
  getMemberAction,
  getMembersAction,
} from '../../modules/members/Actions';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAdsAction } from '../../modules/ads/Actions';
import { adsSelector } from '../../modules/ads/Selectors';
import PrintProfile from './printProfile';
import Loading from '../../components/Misc/Loading';

const Profile = (props) => {
  const { member, getMember, isLoading,getAds } = props;

  const [active, setActive] = useState(false);
  const [stateData, setStateData] = useState();
  const profileId = props.match.params.id;

  useEffect(()=>{
  if(member)  getAds({ filterBy: [{ locationId: member?.memberId }] })   
   },[member])

  useEffect(() => {
    setStateData();
    const newData = {
      principle: [],
      affliate: [],
    };
    member?.affiliate?.map((data) => {
      if (data.associate === 'Affiliate Member' &&  data?.type == 'corporate' ) {
        newData.affliate.push(data);
      } else if (data.associate === 'Principal Member' &&  data?.type == 'corporate' ) {
        newData.principle.push(data);
      }
    });
    setStateData(newData);
  }, [member.affiliate]);



  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth',
    });
  }, []);

  
  useEffect(() => {
    if (profileId) getMember(profileId);
  }, [profileId]);

  if (!member) {
    return <Loading />;
  }

  return (
    <>
      <Layout>
        <Header>
          <div className='row'>
            <div className='memberInfo col-12 col-md-7 mb-4'>
              <div className='header member--item'>
                <Card data={member} withoutHover>
                  <PrintProfile active={active} data={member} />
                </Card>
              </div>
            </div>
            <Advertsment
                          
                          location='MEMBER_BLOCK_1'
                          memberId={member?.memberId }
                        />
          </div>
        </Header>
        <div className='main--body'>
          <div className='container'>
            <div className='row'>
              <div className='inner--body col-12 col-md-8 mb-5 me-auto'>
                <div className='section-block section contactInformation'>
                  <ContactInformation shadow data={member} />
                </div>
                <div className='section-block section workInformation'>
                  {/* <ShadowHead header="Work Information"/> */}
                  <div className='content'>
                    <CompanyInfo data={member} shadow />
                  </div>
                </div>
                <div className='section-block section companyBio'>
                  <ShadowHead header='Company Bio' />
                  <img src={member?.company?.logo} />
                  <div className='content' style={{ fontWeight: '400' }}>
                    <p>{member?.company?.bio}</p>
                  </div>
                </div>
              </div>

              <aside className='inner--sidebar col-12 col-md-4 mb-5'>
                <div className='section members--list'>
                  {stateData?.principle?.length > 0 && (
                    stateData?.principle?.length  == 1 ?             
                         <div className='title'> Principal Member</div>
                         :
                         <div className='title'> Principal Members </div>

                  )}
                  {member?.affiliate?.map((rMember, index) => {
                    if (
                      member?.type == 'corporate' &&
                      rMember.associate == 'Principal Member'
                    )
                      return (
                        <div className='content members--items'>
                          <div className='member--item mb-4' key={index}>
                            <Card data={rMember}>
                              {/* <Btn label="Industry" dataType="sector"  /> */}
                            </Card>
                          </div>
                        </div>
                      );
                  })}
                </div>
                <div className='section members--list'>
                  {stateData?.affliate?.length > 0 && (
                    stateData?.affliate?.length == 1 ?
                    <div className='title'> Affiliate Member </div>
                    :
                    <div className='title'> Affiliate Members </div>

                  )}
                  {member?.affiliate?.map((rMember, index) => {
                    if (
                      member?.type == 'corporate' &&
                      rMember.associate == 'Affiliate Member'
                    )
                      return (
                        <div className='content members--items'>
                          <div className='member--item mb-4' key={index}>
                            <Card data={rMember}>
                              {/* <Btn label="Industry" dataType="sector"  /> */}
                            </Card>
                          </div>
                        </div>
                      );
                  })}
                </div>
                <div className='section news--list'>
                  <div className='content'>
                    <div>
                      <div className='news--item' style={{ marginTop: '4rem' }}>
                     
                      </div>
                      <div className='news--item' style={{ marginTop: '4rem' }}>
                        <Advertsment
                          small
                          location='MEMBER_BLOCK_2'
                          memberId={member?.memberId }
                        />
                      </div>
                      <div className='news--item' style={{ marginTop: '4rem' }}>
                        <Advertsment
                          small
                          location='MEMBER_BLOCK_3'
                          memberId={member?.memberId }
                        />
                      </div>
                      {/* <div className='news--item' style={{ marginTop: '4rem' }}>
                        <Advertsment
                          small
                          location='MEMBER_BLOCK_4'
                          memberId={profileId}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <Advertsment location='MEMBER_BLOCK_4' memberId={member?.memberId } />
      </Layout>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const memberId = props?.location?.state;
  return {
    memberId,
    member: memberSelector(state),
    members: membersSelector(state),
    count: membersCountSelector(state),
    ads: adsSelector(state),
    isLoading: membersIsLoadingSelector(state),
  };
};
const mapDispatchToProps = {
  getMembers: getMembersAction,
  getMember: getMemberAction,
  deleteMember: deleteMemberAction,
  getAds: getAdsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
