import React from 'react';
import P from 'prop-types';
import styled from 'styled-components';

const TextError = (props) => {
  const { errorMessage } = props;
  return <Error className="invalid-feedback">{errorMessage && errorMessage}</Error>;
};

const Error = styled.div`
  display: block;
  margin-top: 0;
  position: absolute;
  bottom: 0;
  opacity: 1;
  transition: 0.2s !important;

  &:empty {
    opacity: 0;
  }
`;

TextError.propTypes = {
  errorMessage: P.string.isRequired,
};

export default TextError;
