import React, { useEffect, useState } from 'react';
import ShadowHead from '../../Heads/ShadowHead';
import Card from '../../Card/Card';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { membersCountSelector, membersPioneersSelector } from '../../../modules/members/Selectors';
import { getPioneersMembersAction } from '../../../modules/members/Actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Btn from '../../Buttons/Btn';
import Spinner from '../../Spinner/Spinner';
import { getmembers } from '../../../modules/members/Api';

const NewMembersSlider = (props) => {
  const { getPioneersMembers, pioneersMembers, loading, membersCount } = props;
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [size, setSize] = useState(6)
  useEffect(() => {
    getPioneersMembers({
      size: 10,
      offest: 0,
    });

  }, []);
  const fetchMoreData = (e) => {


    if (pioneersMembers?.length >= membersCount) {
      setHasMore(false);

      return false;
    }



    else if (pioneersMembers?.length <= membersCount) {
      setOffset(pioneersMembers.length);
      getPioneersMembers({

        offset: pioneersMembers.length,
        size: size,


      });

    }
  };
  return (
    <div className='section-block members--slider' style={{ marginBlock: '150px', }}>
      <div className='container'>
        <ShadowHead header={'EBA Pioneers'} />
        <Splide
          onMoved={(splide, prev, next) => {
            console.log(prev, next)
            if (offset == next) {
              fetchMoreData()

            }
          }}
          options={{
            type: 'loop',
            gap: '1rem',
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
            perPage: 5,
            rewind: true,
            arrows: 'slider',
            autoWidth: true,
            focus: 'center',
          }}
          hasSliderWrapper
        // hasAutoplayProgress
        >
          <>
            {loading && (
              <Spinner />
            )}
            {pioneersMembers?.map((member, index) => {
              return (
                <SplideSlide key={index} className='member--list'>
                  <a className='member--item'>
                    <Card data={member} slider></Card>
                  </a>
                </SplideSlide>
              );
            })}
          </>
        </Splide>

        <NavLink
          style={{ textDecoration: 'none', float: 'right', marginBlock: '20px', marginRight: '-35px' }}
          to={{
            pathname: `/pioneers`,
          }}
        >
          <Btn dataType='button' label='Load More' icon='spinner' animation />
        </NavLink>
      </div>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    pioneersMembers: membersPioneersSelector(state),
    loading: state.members.isLoading,
    membersCount: membersCountSelector(state)

  };
};

const mapDispatchToProps = {
  getPioneersMembers: getPioneersMembersAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewMembersSlider);
