import React, { useEffect } from 'react';
import P from 'prop-types';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  successNotificationsSelector,
  errorNotificationsSelector,
} from '../../modules/notifications/Selectors';
import {
  removeSuccessNotificationAction,
  removeErrorNotificationAction,
} from '../../modules/notifications/Actions';

const Notifications = (props) => {
  const {
    successNotifications,
    removeSuccessNotification,
    errorNotifications,
    removeErrorNotification,
  } = props;
  const { addToast, toastStack } = useToasts();

  useEffect(() => {
    successNotifications.forEach((successNotification) => {
      const { message, dateTimeAsId } = successNotification;
      const matchingToast = toastStack.find(toast => toast.id === dateTimeAsId);
      if (!matchingToast) {
        addToast(message, {
          id: dateTimeAsId,
          appearance: 'success',
          autoDismiss: true,
          onDismiss: (id) => {
            removeSuccessNotification(id);
          },
        });
      }
    });
  }, [successNotifications, addToast, toastStack, removeSuccessNotification]);

  useEffect(() => {
    errorNotifications.forEach((errorNotification) => {
      const { message, dateTimeAsId } = errorNotification;
      const matchingToast = toastStack.find(toast => toast.id === dateTimeAsId);
      if (!matchingToast) {
        addToast(message, {
          id: dateTimeAsId,
          appearance: 'error',
          autoDismiss: true,
          onDismiss: (id) => {
            removeErrorNotification(id);
          },
        });
      }
    });
  }, [errorNotifications, addToast, toastStack, removeErrorNotification]);

  return <></>;
};

Notifications.propTypes = {
  successNotifications: P.arrayOf(P.shape({ message: P.string, dateTimeAsId: P.number }))
    .isRequired,
  removeSuccessNotification: P.func.isRequired,
  errorNotifications: P.arrayOf(P.shape({ message: P.string, dateTimeAsId: P.number })).isRequired,
  removeErrorNotification: P.func.isRequired,
};

const mapStateToProps = state => ({
  successNotifications: successNotificationsSelector(state),
  errorNotifications: errorNotificationsSelector(state),
});

const mapDispatchToProps = {
  removeSuccessNotification: removeSuccessNotificationAction,
  removeErrorNotification: removeErrorNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
