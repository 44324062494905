import {createSelector} from "reselect";

const usersStateSelector = (state) => state.users;

const selectById = (state, userId) => userId;

export const usersSelector = createSelector(
  [usersStateSelector],
  (users) => users.users
);

export const usersLoadSelector = createSelector(
  [usersStateSelector],
  (users) => users.usersLoad
);

export const usersCountSelector = createSelector(
  [usersStateSelector],
  (users) => users.count
);

export const usersIsLoadingSelector = createSelector(
  [usersStateSelector],
  (users) => users.isLoading
);

export const searchValueSelector = createSelector(
  [usersStateSelector],
  (users) => users.searchValue
);

export const userByIdSelector = createSelector(
 
  [usersSelector, selectById],
  (users, _id) => {
    const user = users.find(user => _id === user._id);
    if (!user) {
      return null;
    }
    
    return user;
  }
);