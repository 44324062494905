import {combineReducers} from "redux";
import authReducer from "../modules/auth/Reducer";
import membersReducer from "../modules/members/Reducer"
import notificationsReducer from "../modules/notifications/Reducer"
import sectorsReducer from '../modules/sectors/Reducer'
import industriesReducer from '../modules/industry/Reducer'
import AdsReducer from '../modules/ads/Reducer'
import UserReducer from '../modules/user/Reducer'
import ActivitesReducer from '../modules/activites/Reducer'
import subActivitesReducer  from '../modules/subActivity/Reducer'
import feedbackReducer  from '../modules/help/Reducer'
import SearchReducer  from '../modules/search/Reducer'

// import {actions as authActions} from "../modules/auth/Reducer";



const appReducer = combineReducers({
  auth: authReducer,
  members : membersReducer,
  notifications : notificationsReducer,
  sectors : sectorsReducer,
  industries : industriesReducer,
  ads : AdsReducer,
  users : UserReducer,
  activites:ActivitesReducer,
  subactivites:subActivitesReducer,
  feedback:feedbackReducer,
  search:SearchReducer
});

const rootReducer = (state, action) => {
 
  return appReducer(state, action);
};

export default rootReducer;
