import { ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default () =>
  Yup.object().shape({
    locationId: Yup.string().nullable(),
    location: Yup.string().required('Required'),
    blockId: Yup.string().required('Required'),
    asset: Yup.string(),
    targetUrl: Yup.string().url('not valid url'),
    addLink: Yup.string().url('not valid url'),
    startDate: Yup.string().required('Required'),
    endDate: Yup.string().required('Required'),
  });
