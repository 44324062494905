import {createSelector} from "reselect";

const sectorsStateSelector = (state) => state.sectors;
const selectById = (state, sectorId) => sectorId;

export const sectorsSelector = createSelector(
  [sectorsStateSelector],
  (sectors) => sectors.sectors
);

export const sectorsLoadSelector = createSelector(
  [sectorsStateSelector],
  (sectors) => sectors.loadedSectors
);

export const sectorsCountSelector = createSelector(
  [sectorsStateSelector],
  (sectors) => sectors.count
);


export const sectorsIsLoadingSelector = createSelector(
  [sectorsStateSelector],
  (sectors) => sectors.isLoading
);

export const searchValueSelector = createSelector(
  [sectorsStateSelector],
  (sectors) => sectors.searchValue
);

export const sectorByIdSelector = createSelector(
 
  [sectorsSelector, selectById],
  (sectors, _id) => {
    const sector = sectors.find(sector => _id === sector._id);
    return sector;
  }
);