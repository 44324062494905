import api from "../../helpers/api";

export const getmembers = (options) =>
    api.post("members/search", { ...options });

export const getMember = (memberId) => api.get(`members/${memberId}`);

export const deleteMember = (memberId) => api.delete(`members/${memberId}`);

export const postMember = (formData) => api.post("members", { ...formData });

export const updateMember = (memberId, memberFormData) =>
    api.put(`members/${memberId}`, memberFormData);

export const getPioneersMembers = (options) =>
    api.post("members/pioneers", { ...options });
