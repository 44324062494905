import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  take,
} from "redux-saga/effects";
import membersForm from "../../pages/AddUsers/membersForm";

import {
  newErrorNotificationAction,
  newSuccessNotificationAction,
  errorNotificationFromError,
} from "../notifications/Actions";

import {
  setAdsIsLoadingAction,
  getAdsSuccessAction,
  getAdsCountAction,
  deleteAdSuccessAction,
  getAdSuccessAction,
  createAdSuccessAction,
  updateAdSuccessAction,
  addViewersSuccessAction,
} from "./Actions";
import * as api from "./Api";
import { actions } from "./Reducer";

// Plural
function* getAdsSaga({ payload }) {
  const defaultBody = {
    offset: 0,
    size: 1000,
    sort: "name",
    dir: "asc",
    searchTerm: "",
    filterBy: [],
  };
  const body = { ...defaultBody, ...payload };
  try {
    yield put(setAdsIsLoadingAction(true));
    const response = yield call(api.getAds, body);
    const { data } = response;
    const items = data.content;
    const count = data.count;
    yield put(getAdsSuccessAction(items));
    yield put(getAdsCountAction(count));
  } catch (error) {
    // yield put(errorNotificationFromError(error));
  } finally {
    yield put(setAdsIsLoadingAction(false));
  }
}

function* getAdSaga({ payload }) {
  const adId = payload;
  try {
    //  yield put(resetMembersAction())
    yield put(setAdsIsLoadingAction(true));

    const response = yield call(api.getAd, adId);
    const { data } = response;
    yield put(getAdSuccessAction(data));
  } catch (error) {
    // yield put(newErrorNotificationAction("no user found"));
  } finally {
    yield put(setAdsIsLoadingAction(false));
  }
}

function* deleteAdSaga({ payload }) {
  const adId = payload;
  try {
    yield put(setAdsIsLoadingAction(true));
    yield call(api.deleteAd, adId);
    yield put(deleteAdSuccessAction(adId));
    yield put(newSuccessNotificationAction("Ads deleted."));
    /*     removeFiles(itemId);
     */
  } catch (error) {
    yield put(errorNotificationFromError(error));
  } finally {
    yield put(setAdsIsLoadingAction(false));
  }
}

function* adViewersSaga({ payload }) {
  const adId = payload;
  try {
    yield put(setAdsIsLoadingAction(true));
    yield call(api.addViewers, adId);
    yield put(addViewersSuccessAction(adId));
  } catch (error) {
    yield put(errorNotificationFromError(error));
  } finally {
    yield put(setAdsIsLoadingAction(false));
  }
}

function* createAdSaga({ payload }) {
  const adFormData = payload;
  console.log("click");
  try {
    yield put(setAdsIsLoadingAction(true));

    const response = yield call(api.postAd, { ...adFormData });
    const { data } = response;
    const newAd = data;
    yield put(createAdSuccessAction(newAd));
    yield put(newSuccessNotificationAction("Created Ads"));
  } catch (error) {
    yield put(errorNotificationFromError(error));
  } finally {
    yield put(setAdsIsLoadingAction(false));
  }
}

function* updateAdSaga({ payload }) {
  const { adId, adFormData } = payload;

  try {
    yield put(setAdsIsLoadingAction(true));
    const response = yield call(api.updateAd, adId, adFormData);
    const { data } = response;
    const updatedAd = data;
    yield put(updateAdSuccessAction(updatedAd));
    yield put(newSuccessNotificationAction("Updated Ads"));
  } catch (error) {
    yield put(errorNotificationFromError(error));
  } finally {
    yield put(setAdsIsLoadingAction(false));
  }
}

export default function* () {
  yield takeLatest(actions.GET_ADS, getAdsSaga);
  yield takeLatest(actions.GET_AD, getAdSaga);
  yield takeLatest(actions.DELETE_AD, deleteAdSaga);
  yield takeLatest(actions.CREATE_AD, createAdSaga);
  yield takeLatest(actions.UPDATE_AD, updateAdSaga);
  yield takeLatest(actions.ADS_VIEWERS, adViewersSaga);
}
