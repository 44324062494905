import React, { useEffect, useState } from "react";
import Sidebar from "../../components/AdminSideBar/sidebar";
import { Button, Modal, Table } from "react-bootstrap";
import api from "../../helpers/api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

const Events = () => {
    const history = useHistory();
    const [isEventSessionsModalOpen, setIsEventSessionsModalOpen] = useState(
        false
    );
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [size, setSize] = useState(30);
    const [eventsList, setEventsList] = useState({ count: 0, content: [] });
    const [eventSessions, setEventSessions] = useState({
        count: 0,
        content: [],
    });
    const [selectedEventId, setSelectedEventId] = useState("");
    const [selectedSessionId, setSelectedSessionId] = useState("");
    const [isDeleteSessionModalOpen, setIsDeleteSessionModalOpen] = useState(
        false
    );

    const getEvents = async () => {
        try {
            const { data } = await api.post("/events/search", {
                offset,
                size,
                sort: "createdAt",
                dir: "desc",
            });

            setEventsList((prev) => ({
                content: [...prev.content, ...data?.content],
                count: prev.count,
            }));

            if (
                data?.content?.length === 0 ||
                data?.content?.length >= data?.count
            ) {
                setHasMore(false);
            }

            setOffset((prev) => prev + 10);
        } catch (error) {
            console.error(error);
        }
    };

    const getEventSessions = async (id) => {
        try {
            const { data } = await api.post("/event-sessions/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
                filterBy: [{ eventId: id, type: "SESSION" }],
            });

            setEventSessions(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    const handleDeleteSession = async (id) => {
        try {
            await api.delete(`/event-sessions/${id}`, {});

            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isEventSessionsModalOpen && selectedEventId) {
            getEventSessions(selectedEventId);
        }
    }, [isEventSessionsModalOpen, selectedEventId]);

    console.log({ eventsList });

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between">
                <h4>Events</h4>
                <Button
                    variant="primary"
                    onClick={() => {
                        localStorage.removeItem("eventStepOne");
                        localStorage.removeItem("eventStepTwo");
                        localStorage.setItem("currentStep", "0");
                        history.push("/dashboard/events-create");
                    }}
                >
                    Create Event
                </Button>
            </div>
            <InfiniteScroll
                dataLength={eventsList?.content?.length}
                next={getEvents}
                hasMore={hasMore}
            >
                <Table
                    responsive
                    className="mt-5"
                    striped
                    bordered
                    hover
                    size="lg"
                >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Place</th>
                            <th>Place Link</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Event Type</th>
                            <th>Number of Seats</th>
                            <th>Agenda</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventsList.content?.map((event, i) => (
                            <tr key={event?._id}>
                                <td>{i + 1}</td>
                                <td>{event?.title}</td>
                                <td>{event?.place}</td>
                                <td>
                                    <a
                                        href={event?.placeLink}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link
                                    </a>
                                </td>
                                <td>
                                    {moment(event?.startTime).format("lll")}
                                </td>
                                <td>{moment(event?.endTime).format("lll")}</td>
                                <td>{event?.eventType?.join(", ")}</td>
                                <td>{event?.numberOfSeats}</td>
                                <td>
                                    <div className="d-flex gap-3">
                                        <Button
                                            // variant="primary"
                                            size="sm"
                                            onClick={() => {
                                                setSelectedEventId(event?._id);
                                                setIsEventSessionsModalOpen(
                                                    true
                                                );
                                            }}
                                        >
                                            Sessions
                                        </Button>
                                        <Button
                                            // variant="primary"
                                            size="sm"
                                            onClick={() => {
                                                localStorage.setItem(
                                                    "eventStepOne",
                                                    JSON.stringify({
                                                        title: event?.title,
                                                        cover: event?.cover,
                                                        description:
                                                            event?.description,
                                                        place: event?.place,
                                                        startTime:
                                                            event?.startTime,
                                                        endTime: event?.endTime,
                                                        reminderDate:
                                                            event?.reminderDate,
                                                        placeLink:
                                                            event?.placeLink,
                                                        numberOfSeats:
                                                            event?.numberOfSeats,
                                                        country: event?.country,
                                                        eventType:
                                                            event?.eventType,
                                                        sectors: event?.sectors?.map(
                                                            (s) => s?._id
                                                        ),
                                                        sponsors: event?.sponsors?.map(
                                                            (s) => s?._id
                                                        ),
                                                        status: event?.status,
                                                    })
                                                );
                                                localStorage.setItem(
                                                    "eventStepTwo",
                                                    JSON.stringify(
                                                        event?.pricing
                                                    )
                                                );
                                                localStorage.setItem(
                                                    "currentStep",
                                                    "0"
                                                );
                                                history.push(
                                                    `/dashboard/events-create?edit=${event?._id}`
                                                );
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </InfiniteScroll>
            <Modal
                show={isEventSessionsModalOpen}
                onHide={() => setIsEventSessionsModalOpen(false)}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Event Sessions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {eventSessions?.content?.length ? (
                        <Table
                            responsive
                            className="mt-5"
                            striped
                            bordered
                            hover
                            size="lg"
                        >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>name</th>
                                    <th>place</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventSessions?.content?.map((session, i) => (
                                    <tr key={session?._id}>
                                        <td>{i + 1}</td>
                                        <td>{session?.name}</td>
                                        <td>{session?.place}</td>
                                        <td>
                                            {moment(session?.startTime).format(
                                                "ll"
                                            )}
                                        </td>
                                        <td>
                                            {moment(session?.endTime).format(
                                                "ll"
                                            )}
                                        </td>
                                        <td>
                                            <div className="d-flex gap-3">
                                                <Button
                                                    // variant="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        localStorage.setItem(
                                                            "session",
                                                            JSON.stringify(
                                                                session
                                                            )
                                                        );
                                                        history.push(
                                                            `/dashboard/sessions?edit=${session?._id}`
                                                        );
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    variant="danger"
                                                    onClick={() => {
                                                        setSelectedSessionId(
                                                            session?._id
                                                        );
                                                        setIsDeleteSessionModalOpen(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : null}
                </Modal.Body>
                <Modal
                    show={isDeleteSessionModalOpen}
                    onHide={() => setIsDeleteSessionModalOpen(false)}
                    // size="xl"
                    // centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this session?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setIsDeleteSessionModalOpen(false)}
                        >
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() =>
                                handleDeleteSession(selectedSessionId)
                            }
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsEventSessionsModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => setIsEventSessionsModalOpen(false)}
                        as="a"
                        href="/dashboard/sessions"
                    >
                        Create Session
                    </Button>
                </Modal.Footer>
            </Modal>
        </Sidebar>
    );
};

export default Events;
