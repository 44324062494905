import React, { useState, useEffect } from "react";
import { membersCountSelector, membersSelector } from "../../modules/members/Selectors";
import { connect } from 'react-redux';
import { getMembersAction } from '../../modules/members/Actions';
import queryString from 'query-string';


const Btn = (props) => {
  const { dataType, label, icon, animation, members, membersCount, getMembers, searchTerm, headerData } = props
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [size, setSize] = useState(6)
  const animate = animation ? "animation" : ""
  const [text, setText] = useState("")
  const [visible, setVisible] = useState("visible")



  const style = {
    visibility: visible
  }

  const [alpha, setAlpha] = useState('#');
  const [sector, setSector] = useState('');
  const [industry, setIndustry] = useState('');
  const [searchName, setSearchName] = useState('');
  const [activity, setActivity] = useState('')
  const [subActivity, setSubActivity] = useState('')
  const parsed = queryString.parse(window.location.search);


  useEffect(() => {
    setText("")
    setVisible("visible")
  }, [searchTerm, getMembers, headerData, alpha, parsed.letter])


  let body = {
    offset: 0,
    size: 6,
    sort: 'name',
    dir: 'asc',
    searchTerm: '',
    filterBy: [],
    letter: ''
  };
  let filterOptions;

  useEffect(() => {
    if (parsed?.alpha) {
      setAlpha(parsed?.alpha);
    }
    if (parsed?.sector) setSector(parsed?.sector);
    if (parsed?.industry) setIndustry(parsed?.industry);
    if (parsed?.activity) setActivity(parsed?.activity);
    if (parsed?.subActivity) setSubActivity(parsed?.subActivity)
    if (parsed?.searchTerm) {
      if (parsed?.searchTerm == "") setSearchName("")
      setSearchName(parsed?.searchTerm);
    }
  }, [parsed]);

  useEffect(() => {
    const searchAlpha = alpha === '#' ? '' : alpha;
    filterOptions = headerData?.memberType?.value != "" ? [{ type: headerData?.memberType?.value }] : []
    if (parsed?.sector && parsed?.sector != "null" && parsed?.sector != "undefined") filterOptions.push({ sectors: Number(parsed?.sector) });
    if (industry) filterOptions.push({ industry: Number(parsed?.industry) });
    if (parsed?.activity && parsed?.activity != "null" && parsed?.activity != "undefined") filterOptions.push({ activities: Number(parsed?.activity) });
    if (parsed?.subActivity && parsed?.subActivity != "null" && parsed?.subActivity != "undefined") filterOptions.push({ subActivity: parsed?.subActivity })

  }, [headerData, alpha, industry, sector, searchName, getMembers, activity, subActivity, parsed]);



  const fetchMoreData = (e) => {
    setText("")


    if (members?.length >= membersCount) {
      setHasMore(false);
      setText("no more data ...")
      setVisible("hidden")
      return false;
    }



    else if (members?.length <= membersCount) {
      setOffset(members.length);
      setText("")
      getMembers({
        ...body,
        offset: members.length,
        size: size,
        dir: headerData.dir ? 'asc' : "asc",
        filterBy: filterOptions,
        searchTerm: parsed.searchTerm == "" ? "" : searchName,
        letter: parsed.letter == "" ? "" : parsed.letter,

      });

    }
    setVisible("visible")
  };

  return (
    <>
      {dataType == "sector" ?
        // sector
        <div className="excerpt-1" data-type={dataType}>
          <a href="#">{label}</a>
        </div>
        :

        <a
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          className={`nav-link with-icon  me-5 `}
          data-type={dataType}
          onClick={(e) => fetchMoreData(e)}
          style={style}

        >

          {label}
        </a>



      }
    </>
  )
}


const mapStateToProps = state => {
  return ({
    // members : membersSelector(state),
    membersCount: membersCountSelector(state)
  })
}
const mapDispatchToProps = {
  // getMembers : getMembersAction
}


export default connect(mapStateToProps, mapDispatchToProps)(Btn)



