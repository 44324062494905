import { actions } from "./Reducer";

export const getSectorsAction = (payload) => ({
    type: actions.GET_SECTORS,
    payload,
});

export const getSectorsSuccessAction = (sectors) => ({
    type: actions.GET_SECTORS_SUCCESS,
    payload: sectors,
});

export const getSectorAction = (id) => ({
    type: actions.GET_SECTOR,
    payload: id,
});

export const getSectorSuccessAction = (sector) => ({
    type: actions.GET_SECTOR_SUCCESS,
    payload: sector,
});

export const setSearchValueAction = (value) => ({
    type: actions.SET_SECTORS_SEARCH_VALUE,
    payload: value,
});

export const setSectorsIsLoadingAction = (isLoading) => ({
    type: actions.SET_SECTORS_IS_LOADING,
    payload: isLoading,
});

export const getSectorsCountAction = (count) => ({
    type: actions.GET_SECTORS_COUNT,
    payload: count,
});

export const resetMembersAction = () => ({
    type: actions.RESET_SECTORS,
});
