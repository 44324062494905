import React from "react";
import P from "prop-types";
import styled from "styled-components";

const SubmitButton = props => {
  const { children, isLoading, disabled, className } = props;
  return (
    <Button
      type='submit'
      className={className}
      disabled={isLoading || disabled}
      style={{ backgroundColor: "rgba(10, 61, 128, 1)", color: "white" }}
      style={{
        width: '150px',
        height: '40px',
        letterSpacing: '5px',
        padding: '0'
      }}
    >
      {children}
    </Button>
  );
};

const Button = styled.button`
//   &.kt-spinner {
//     padding-right: 4rem !important;

//   }
`;

SubmitButton.propTypes = {
  children: P.string,
  isLoading: P.bool,
  disabled: P.bool,
  className: P.string
};

SubmitButton.defaultProps = {
  children: "Submit",
  isLoading: false,
  disabled: false,
  className: ""
};

export default SubmitButton;
