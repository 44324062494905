import React from 'react';
import ShadowHead from '../Heads/ShadowHead';



const CompanyInfo = (props) => {
  const { data } = props


  return (
    <div className="section-block section workInformation">
      {props.shadow ? <ShadowHead header="Work Information" /> : <div className="title">Work Information</div>}
      <div className="content" style={{
        marginTop: '15px'
      }}>
        <div className="item">
          <div className="data">
            <div className="title">Company Name</div>
            <div className="value">
              {data?.company?.name}
            </div>
          </div>
        </div>
        {data?.sectors?.length > 0 && <div className="item">
          <div className="data">
            <div className="title">Sectors</div>
            {data?.sectors?.map(sector => {
              return <div className="value">
                {sector.secENm}
              </div>
            })
            }
          </div>
        </div>}
      </div>
    </div>
  )
}


export default CompanyInfo;