import React from "react";
import P from "prop-types";

// TO DO :
// Use Render Props Instead of Component
const Subheader = ({ title, children, Component }) => (
    <div
        className="d-flex mb-5 justify-content-between align-items-center"
        id="kt_subheader"
        style={{ color: "var(--primary-color)" }}
    >
        <div className="">
            <h3 className="title">{title}</h3>
            {Component ? <Component /> : null}
        </div>
        <div className="">{children}</div>
    </div>
);

Subheader.propTypes = {
    title: P.string.isRequired,
    children: P.oneOfType([P.arrayOf(P.node), P.node]),
};

Subheader.defaultProps = {
    children: null,
};

export default Subheader;
