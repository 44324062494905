import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Image from "../../assets/images/logo-new.png";
import { logoutUserAction } from "../../modules/auth/Actions";
import MobileNaBar from "./mobileSidebar";
import api from "../../helpers/api";
import { toast } from "react-toastify";
import {
    MdOutlineEmojiEvents,
    MdOutlineEvent,
    MdOutlineVerifiedUser,
} from "react-icons/md";
import { TbCalendarEvent } from "react-icons/tb";
import { FaRegNewspaper, FaUser } from "react-icons/fa6";
import { GrAnnounce } from "react-icons/gr";
import { FaUsers } from "react-icons/fa6";
import { IoLogOut } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
import { GoSponsorTiers } from "react-icons/go";

const Sidebar = ({ children, logoutUser }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [active, setActive] = useState(false);
    const handelSyncData = async () => {
        setActive(false);
        setIsLoading(true);
        try {
            const data_sectors = await api.post("/sectors/sync");
            const data_activity = await api.post("/activities/sync");
            const data_subactivity = await api.post("/sub-activities/sync");
            const data_members = await api.post("/members/sync");
            const data_accounts = await api.post("/members/createAccounts");
            setActive(true);
            setIsLoading(false);
            toast.success("Sync data done");
        } catch (error) {
            setError(error.message);
        }
    };

    const dashboardLinks =
        localStorage.getItem("role") === "member"
            ? [
                  {
                      id: 1,
                      name: "Schedules",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/schedules",
                      isActive: window.location.pathname.includes("/schedules"),
                  },
                  {
                      id: 2,
                      name: "Pending Requests",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/pending-requests",
                      isActive: window.location.pathname.includes(
                          "/pending-requests"
                      ),
                  },
                  {
                      id: 3,
                      name: "Booked Tickets",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/booked-tickets",
                      isActive: window.location.pathname.includes(
                          "/booked-tickets"
                      ),
                  },
              ]
            : localStorage.getItem("role") === "non_member" ||
              localStorage.getItem("role") === "Delegation"
            ? [
                  {
                      id: 1,
                      name: "Schedules",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/schedules",
                      isActive: window.location.pathname.includes("/schedules"),
                  },
                  {
                      id: 2,
                      name: "Pending Requests",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/pending-requests",
                      isActive: window.location.pathname.includes(
                          "/pending-requests"
                      ),
                  },
                  {
                      id: 3,
                      name: "Booked Tickets",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/booked-tickets",
                      isActive: window.location.pathname.includes(
                          "/booked-tickets"
                      ),
                  },
                  {
                      id: 4,
                      name: "Update Profile",
                      icon: <MdOutlineVerifiedUser fontSize={22} />,
                      to: "/dashboard/update-profile",
                      isActive: window.location.pathname.includes(
                          "/update-profile"
                      ),
                  },
              ]
            : [
                  {
                      id: 1,
                      name: "Events",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/events",
                      isActive: window.location.pathname.includes("/events"),
                  },
                  {
                      id: 2,
                      name: "Event Sessions",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/sessions",
                      isActive: window.location.pathname.includes("/sessions"),
                  },
                  {
                      id: 100,
                      name: "Event B2B",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/event-b2b",
                      isActive: window.location.pathname.includes("event-b2b"),
                  },
                  {
                      id: 102,
                      name: "Event Breaktimes",
                      icon: <MdOutlineEvent fontSize={22} />,
                      to: "/dashboard/event-breaktimes",
                      isActive: window.location.pathname.includes(
                          "event-breaktimes"
                      ),
                  },
                  {
                      id: 3,
                      name: "Advertisement",
                      icon: <GrAnnounce fontSize={22} />,
                      to: "/dashboard/advertisments",
                      isActive: window.location.pathname.includes(
                          "/advertisments"
                      ),
                  },
                  {
                      id: 4,
                      name: "Sponsors",
                      icon: <GoSponsorTiers fontSize={22} />,
                      to: "/dashboard/sponsors",
                      isActive: window.location.pathname.includes("/sponsors"),
                  },
                  {
                      id: 5,
                      name: "Users",
                      icon: <FaUsers fontSize={22} />,
                      to: "/dashboard/users",
                      isActive: window.location.pathname.includes("/users"),
                  },
                  {
                      id: 6,
                      name: "Delegations",
                      icon: <FaUsers fontSize={22} />,
                      to: "/dashboard/delegations",
                      isActive: window.location.pathname.includes(
                          "/delegations"
                      ),
                  },
                  {
                      id: 7,
                      name: "Event Booking Requests",
                      icon: <MdOutlineEmojiEvents fontSize={22} />,
                      to: "/dashboard/event-booking-requests",
                      isActive: window.location.pathname.includes(
                          "/event-booking-requests"
                      ),
                  },
                  //   {
                  //       id: 7,
                  //       name: "Logout",
                  //       icon: <IoLogOut fontSize={22} />,
                  //       to: "/",
                  //       isActive: false,
                  //   },
              ];

    return (
        <div className="row">
            <div
                className="col-md-2 position-fixed p-4"
                style={{ background: "var(--primary-color)", height: "100vh" }}
            >
                <div className="d-flex align-items-center flex-column gap-1 mt-4">
                    <div
                        className="align-items-center mb-3 pb-3"
                        style={{
                            borderBottom: "2px solid rgba(255, 255, 255, .1)",
                        }}
                    >
                        <Link to="/">
                            <div className="logo d-flex">
                                <img
                                    alt="logo"
                                    src={Image}
                                    style={{
                                        width: "240px",
                                        height: "fit-content",
                                    }}
                                />
                            </div>
                        </Link>
                    </div>
                    {dashboardLinks.map((link) => (
                        <Link
                            key={link.id}
                            to={link.to}
                            style={{
                                textDecoration: "none",
                                color: link.isActive ? "#fff" : "#bbb",
                                width: "100%",
                                padding: "15px 20px",
                                borderRadius: "3px",
                            }}
                        >
                            <div className="logo d-flex align-items-center gap-4">
                                {link.icon}
                                <span>{link.name}</span>
                            </div>
                        </Link>
                    ))}

                    <div
                        className="logo d-flex align-items-center gap-4"
                        style={{
                            color: "#fff",
                            width: "100%",
                            padding: "15px 20px",
                            // textAlign: "left",
                        }}
                    >
                        <IoLogOut fontSize={22} />
                        <NavLink
                            onClick={logoutUser}
                            className="nav-link text-light"
                            to="/login"
                        >
                            Logout
                        </NavLink>
                        {/* <span>Logout</span> */}
                    </div>
                    {/* <NavLink className="nav-link text-light" to="/login">
                            Logout
                        </NavLink> */}
                </div>
            </div>
            <div className="col-md-10" style={{ marginLeft: "16%" }}>
                {/* <div className="bg-white p-4 d-flex align-items-right justify-content-end">
                    <Dropdown>
                        <Dropdown.Toggle
                            style={{
                                background: "#fff",
                                color: "#000",
                                border: "none",
                            }}
                            id="dropdown-basic"
                        >
                            <FaUser /> Charles O'Reilly
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Action 1</Dropdown.Item>
                            <Dropdown.Item>Another action 2</Dropdown.Item>
                            <Dropdown.Item>Something else 3</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
                <div className="p-5">{children}</div>
            </div>
        </div>
    );

    return (
        <div className="custom--scrollbar">
            <div className="row hero--header home">
                <div
                    className=" main--navbar col-lg-3 col-12 d-md-block d-flex p-0"
                    style={{ position: "fixed" }}
                >
                    <div
                        className="d-flex flex-column"
                        style={{ background: "#0A3D80", height: "100%" }}
                    >
                        <div
                            style={{
                                height: "20vh",
                                padding: "40px",
                                // background: "white",
                            }}
                        >
                            <Link to="/">
                                <div className="logo d-flex">
                                    <img
                                        src={Image}
                                        style={{
                                            width: "240px",
                                            height: "fit-content",
                                        }}
                                    />
                                </div>
                            </Link>
                        </div>

                        <div
                            className="nav--list m-0 d-md-flex d-none"
                            style={{
                                height: "80vh",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                            }}
                        >
                            <ul
                                className="nav desktop flex-column justify-content-start align-items-start mt-5"
                                style={{
                                    paddingInline: "25px",
                                    fontSize: "18px",
                                }}
                            >
                                <li className="nav-item my-3">
                                    <NavLink
                                        className="nav-link text-light"
                                        to="/dashboard/events"
                                    >
                                        Events
                                    </NavLink>
                                </li>
                                <li className="nav-item my-3 text-light">
                                    <NavLink
                                        className="nav-link text-light"
                                        to="/dashboard/users"
                                    >
                                        Users
                                    </NavLink>
                                </li>

                                <li className="nav-item my-3">
                                    <NavLink
                                        className="nav-link text-light"
                                        to="/dashboard/advertisments"
                                    >
                                        Advertisment
                                    </NavLink>
                                </li>
                            </ul>
                            <ul
                                className="nav desktop flex-column justify-content-start align-items-start "
                                style={{ paddingInline: "25px" }}
                            >
                                <li
                                    className="nav-item "
                                    onClick={handelSyncData}
                                >
                                    <span
                                        className="nav-link text-light"
                                        style={{ fontSize: "15px" }}
                                    >
                                        {isLoading ? (
                                            <>
                                                <div
                                                    class="spinner-border"
                                                    role="status"
                                                >
                                                    <span class="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </>
                                        ) : (
                                            "Sync data"
                                        )}
                                    </span>
                                </li>
                                <li
                                    className="nav-item mt-3"
                                    style={{ fontSize: "15px" }}
                                >
                                    <NavLink
                                        className="nav-link text-light"
                                        to="/login"
                                        onClick={logoutUser}
                                    >
                                        Logout
                                    </NavLink>
                                </li>
                            </ul>
                            <MobileNaBar />
                        </div>
                    </div>
                </div>
                <div className=" main--navbar col-lg-3 col-12 d-md-block d-flex p-0"></div>
                <div
                    className="col-12 col-lg-9 col-md-9 col-sm-12  w-lg-100"
                    style={{ background: "#fbfbfb", minHeight: "100vh" }}
                >
                    {children}
                </div>
            </div>

            {active && (
                <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="modal-body text-center">
                                <i
                                    class="fas fa-check-circle"
                                    style={{ fontSize: "50px", color: "green" }}
                                ></i>
                                <p
                                    style={{
                                        fontSize: "25px",
                                        fontWeight: "400",
                                        marginTop: "10px",
                                    }}
                                >
                                    thanks
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    logoutUser: logoutUserAction,
};

export default connect(null, mapDispatchToProps)(Sidebar);
