import React, { useEffect } from "react";
import UserLayout from "./components/UserLayout/UserLayout";
import { Provider } from "react-redux";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import Notifications from "./components/Notifications/index";
import { store } from "./services/configurationStore";
import Login from "./pages/auth/index";
import Register from "./pages/register";
const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <ToastProvider>
                    <Notifications />
                    <Switch>
                        <Route path="/register" component={Register} />
                        <Route path="/login" component={Login} />
                        <Route path="/forgot-password" component={Login} />
                        <UserLayout />
                    </Switch>
                </ToastProvider>
            </Router>
        </Provider>
    );
};

export default App;
