import { Formik, Field } from "formik";
import P from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import api from "../../helpers/api";

import TextInput from "../../components/Form/inputs/TextInput";
import { createUserAction } from "../../modules/user/Actions";
import Select from "../../components/Form/inputs/selsect";

import { DatePickerField } from "../../components/Form/inputs/DatePicker";
import {
    userByIdSelector,
    usersIsLoadingSelector,
} from "../../modules/user/Selectors";

import FormGroup from "../../components/Form/FormGroup";
import usersSchema from "./membersSchema";
import { Redirect } from "react-router";
import { useState } from "react";
import beforeSubmit from "../../helpers/beforeSubmit";
import {
    getUserAction,
    getUsersAction,
    updateUserAction,
} from "../../modules/user/Actions";
import moment from "moment";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const UsersForm = (props) => {
    const { addToast } = useToasts();
    // items
    const {
        createUser,
        getUser,
        updateUser,
        userId,
        user,
        usersIsLoading,
        getUsers,
        mappedUsers,
    } = props;

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (userId) {
            getUser(userId);
        }
    }, [userId, getUser]);
    const Roles = [
        { value: "superAdmin", label: "Admin" },
        { value: "client", label: "Client" },
        { value: "member", label: "Member" },
        { value: "non_member", label: "Non Member" },
        { value: "Delegation", label: "Delegation" },
    ];

    if (redirect) return <Redirect to="/dashboard/users" />;

    return (
        <div className="d-flex justify-content-center align-items-center">
            <Formik
                enableReinitialize
                initialValues={user ? { ...user } : usersSchema().cast()}
                validationSchema={usersSchema()}
                onSubmit={async (values) => {
                    // Edit the values before submit

                    const payload = {
                        ...values,
                        username: values.username.toLowerCase(),
                        expireAt: moment(values.expireAt).toISOString(),
                    };

                    const changedValues = beforeSubmit(payload, ["_id"]);

                    if (userId) {
                        await updateUser(userId, changedValues);
                        // handleUserAction(changedValues);
                        // setRedirect(true);
                    } else {
                        await createUser(changedValues);
                        // setRedirect(true);
                    }
                }}
            >
                {({ handleSubmit, values }) => {
                    console.log({ values });
                    return (
                        <div
                            style={{
                                background: "white",
                                width: "500px",
                                padding: "45px",
                                marginTop: "60px",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.08)",
                                borderRadius: "10px",
                            }}
                        >
                            <FormGroup
                                heading={
                                    userId && user
                                        ? `Update User [ ${user.name} ]`
                                        : "Create New"
                                }
                                handleSubmit={handleSubmit}
                                isLoading={usersIsLoading}
                            >
                                <TextInput name="username" label="Username:" />
                                <TextInput
                                    name="password"
                                    label="Password:"
                                    type="password"
                                />

                                <div className="row justify-content-between">
                                    <div className=" col-6 my-4">
                                        <label className="form-label">
                                            Role:{" "}
                                        </label>
                                        <Field
                                            name="role"
                                            as="select"
                                            style={{
                                                width: "100%",
                                                // padding: "5px",
                                                border: "1px solid #C4C4C4",
                                                borderRadius: "5px",
                                                height: "40px",
                                            }}
                                        >
                                            <option value={""}>
                                                Select...
                                            </option>
                                            {Roles.map((role, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={role.value}
                                                    >
                                                        {role.label}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    </div>

                                    <div className=" col-6 my-4">
                                        <TextInput
                                            name="expireAt"
                                            label="End Date"
                                            type="date"
                                        />
                                        {/* <label className="form-label">
                                            End Date:{" "}
                                        </label>{" "} */}
                                        {/* <input
                                            type="date"
                                            name="expireAt"
                                            className="w-100"
                                            style={{ padding: "5px" }}
                                            placeholderText="Enter End Date"
                                        /> */}
                                        {/* <DatePickerField
                                            name="expireAt"
                                            className="w-100"
                                            placeholderText="Enter End Date"
                                        /> */}
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

UsersForm.propTypes = {
    createUsers: P.func.isRequired,
    getUser: P.func.isRequired,
    updateUser: P.func.isRequired,
    userId: P.string,
    user: P.shape({ name: P.string }),
    usersIsLoading: P.bool.isRequired,
    getUsers: P.func.isRequired,
};

UsersForm.defaultProps = {
    userId: null,
    user: null,
};

const mapStateToProps = (state, props) => {
    const userId =
        props.match && props.match.params ? props.match.params.id : "";
    return {
        userId,
        user: userByIdSelector(state, userId),
        usersIsLoading: usersIsLoadingSelector(state),
    };
};

const mapDispatchToProps = {
    createUser: createUserAction,
    getUser: getUserAction,
    updateUser: updateUserAction,
    getUsers: getUsersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersForm);
