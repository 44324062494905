import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import { Button, Col, Form, Image, Modal, Row, Table } from "react-bootstrap";
import api from "../../../helpers/api";
import FileInput from "../../../components/events/FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import Input from "../../../components/events/Input";
import InputSelect from "../../../components/events/InputSelect";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const SponsorsPage = () => {
    const history = useHistory();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [sponsorsList, setSponsorsList] = useState({ content: [], count: 0 });
    const [selectedSponsor, setSelectedSponsor] = useState();
    const [sponsor, setSponsor] = useState({
        ticket: "",
        name: "",
        description: "",
        image: "",
        link: "",
    });
    const [selectedEventId, setSelectedEventId] = useState("");
    const [eventsList, setEventsList] = useState({ count: 0, content: [] });

    const getSponsors = async () => {
        try {
            const { data } = await api.post("/sponsors/search", {
                offset: 0,
                size: 20,
                sort: "createdAt",
                dir: "desc",
            });

            setSponsorsList(data);
        } catch (error) {
            console.error(error);
        }
    };

    // const getEvents = async () => {
    //     try {
    //         const { data } = await api.post("/events/search", {
    //             offset: 0,
    //             size: 50,
    //             sort: "createdAt",
    //             dir: "desc",
    //         });

    //         setEventsList(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // useEffect(() => {
    //     getEvents();
    // }, []);

    useEffect(() => {
        getSponsors();
    }, []);

    // useEffect(() => {
    //     if (selectedSponsor) {
    //         setSponsor(selectedSponsor);
    //     }
    // }, [selectedSponsor]);

    // const handleSponsorAction = async () => {
    //     try {
    //         if (selectedSponsor) {
    //             await api.put(`/sponsors/${selectedSponsor?._id}`, {
    //                 ...sponsor,
    //                 events: [
    //                     {
    //                         eventId: selectedEventId,
    //                         ticket: getSelectedEvent?.pricing?.find(
    //                             (p) => p?.type === sponsor?.ticket
    //                         ),
    //                     },
    //                 ],
    //             });
    //         } else {
    //             await api.post(`/sponsors`, {
    //                 ...sponsor,
    //                 events: [
    //                     {
    //                         eventId: selectedEventId,
    //                         ticket: getSelectedEvent?.pricing?.find(
    //                             (p) => p?.type === sponsor?.ticket
    //                         ),
    //                     },
    //                 ],
    //             });
    //         }

    //         window.location.reload();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    const handleDelete = async (id) => {
        try {
            await api.delete(`/sponsors/${id}`, {});

            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     setSponsor({
    //         ...sponsor,
    //         [name]: value,
    //     });
    // };

    // const handleUpload = async (formData) => {
    //     try {
    //         const res = await api.post("/uploads", formData);

    //         console.log({ res });
    //         setSponsor((prev) => ({ ...prev, image: res?.data?.url }));
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // const handleCoverChange = async (event) => {
    //     const file = event.target.files[0];

    //     const formData = new FormData();
    //     formData.append("file", file);

    //     await handleUpload(formData);
    // };

    const getSelectedEvent = useMemo(() => {
        const e = eventsList?.content?.find((e) => e?._id === selectedEventId);

        return e;
    }, [eventsList?.content, selectedEventId]);

    console.log({ getSelectedEvent, sponsor });

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <h3>Sponsors</h3>
                <Button
                    onClick={() => {
                        // setSelectedSponsor(undefined);
                        localStorage.removeItem("sponsor");
                        // setSponsor({
                        //     name: "",
                        //     description: "",
                        //     link: "",
                        //     image: "",
                        // });
                        history.push("/dashboard/create-sponsor");
                        // setIsCreateModalOpen(true);
                    }}
                >
                    Add
                </Button>
            </div>
            <Table responsive className="mt-5" striped bordered hover size="lg">
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Link</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sponsorsList.content?.map((sponsor, i) => (
                        <tr key={sponsor?._id}>
                            <td>
                                <Image
                                    src={sponsor?.image}
                                    alt={sponsor?.name}
                                    width={100}
                                    height={100}
                                />
                            </td>
                            <td>{sponsor?.name}</td>
                            <td>{sponsor?.description}</td>
                            <td>
                                <a
                                    href={sponsor?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Link
                                </a>
                            </td>
                            <td>
                                <div className="d-flex gap-3">
                                    <Button
                                        // variant="primary"
                                        size="sm"
                                        onClick={() => {
                                            setSelectedSponsor(sponsor);
                                            // setIsCreateModalOpen(true);
                                            localStorage.setItem(
                                                "sponsor",
                                                JSON.stringify(sponsor)
                                            );
                                            history.push(
                                                `/dashboard/create-sponsor?edit=${sponsor?._id}`
                                            );
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        // variant="primary"
                                        size="sm"
                                        onClick={() =>
                                            handleDelete(sponsor?._id)
                                        }
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {/* <Modal
                show={isCreateModalOpen}
                onHide={() => setIsCreateModalOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedSponsor ? "Edit" : "Create"} Sponsor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    <div
                        className="d-flex flex-column gap-5"
                        style={{ width: "100%" }}
                    >
                        <div className="d-flex flex-column gap-1">
                            <Form.Label style={{ fontSize: "18px" }}>
                                Select Event
                            </Form.Label>
                            <Form.Select
                                aria-label=""
                                size="lg"
                                style={{
                                    height: "50px",
                                    fontSize: "18px",
                                }}
                                name="country"
                                value={selectedEventId}
                                onChange={(e) =>
                                    setSelectedEventId(e.target.value)
                                }
                            >
                                <option>Open this select event</option>
                                {eventsList?.content?.map((event) => (
                                    <option value={event?._id} key={event?._id}>
                                        {event?.title}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div>
                            <Form.Label>Event Tickets</Form.Label>
                            <InputSelect
                                options={getSelectedEvent?.pricing?.map((p) => {
                                    return {
                                        label: `${p?.name} | Price: ${p?.value}`,
                                        value: p?.type,
                                    };
                                })}
                                // isMulti
                                defaultValue={selectedSponsor?.ticket?.type}
                                onChange={(e) =>
                                    setSponsor({
                                        ...sponsor,
                                        ticket: e.value,
                                    })
                                }
                            />
                        </div>
                        <div className="d-flex gap-3" style={{ width: "100%" }}>
                            <Input
                                type="text"
                                id="name"
                                label="Sponsor Name"
                                placeholder="Sponsor Name"
                                name="name"
                                value={sponsor?.name}
                                onChange={handleInputChange}
                            />
                            <Input
                                type="text"
                                id="link"
                                label="Link"
                                placeholder="Link"
                                name="link"
                                value={sponsor?.link}
                                onChange={handleInputChange}
                            />
                        </div>
                        <Input
                            type="textarea"
                            id="description"
                            label="Description"
                            placeholder="Description"
                            name="description"
                            value={sponsor?.description}
                            onChange={handleInputChange}
                        />
                        <FileInput
                            handleChange={handleCoverChange}
                            title="Sponsor Logo"
                            icon={
                                <IoMdCloudUpload
                                    color="rgba(156, 163, 175, 1)"
                                    fontSize="40px"
                                />
                            }
                            description1="Click to upload or drag and drop"
                            description2="Max. File Size: 30MB"
                            imageSrc={sponsor?.image || ""}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsCreateModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSponsorAction}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </Sidebar>
    );
};

export default SponsorsPage;
