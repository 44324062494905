import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { membersCountSelector } from "../../modules/members/Selectors";
import { connect } from "react-redux";

const AlphaList = ({ alpha, setAlpha, headerData, membersCount }) => {
    const parsed = queryString.parse(window.location.search);

    const alphabetic = [
        "#",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
    ];
    let name =
        parsed.letter == "" || parsed.letter == undefined ? "#" : parsed.letter;

    let sector =
        parsed?.sector != "undefined" && parsed?.sector != "null"
            ? parsed.sector
            : "";
    let activity =
        parsed?.activity != "undefined" && parsed?.activity != "null"
            ? parsed.activity
            : "";
    let subActivity =
        parsed?.subActivity != "undefined" && parsed?.subActivity != "null"
            ? parsed.subActivity
            : "";

    return (
        <div className="header--forList">
            <ul
                className="alphabetic-list"
                alphabetic-list
                style={{ cursor: "pointer" }}
            >
                {alphabetic.map((data, index) => {
                    return (
                        <li key={index} onClick={() => setAlpha(data)}>
                            <Link
                                className={name === data ? "active" : ""}
                                href
                                to={{
                                    pathname: "",
                                    search: `?letter=${
                                        data == "#" ? "" : data
                                    }&sector=${sector}&activity=${activity}&subActivity=${subActivity}`,
                                }}
                            >
                                {data}
                            </Link>
                        </li>
                    );
                })}
            </ul>
            <div></div>

            <h6 className="bg-light p-2" style={{ float: "right" }}>
                {" "}
                Members Count : {membersCount}{" "}
            </h6>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        membersCount: membersCountSelector(state),
    };
};

export default connect(mapStateToProps, null)(AlphaList);
