import React, { useRef, useState } from "react";
import Input from "../Input";
import { Button, Col, Form, Row } from "react-bootstrap";
import FileInput from "../FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import { TbCookieMan } from "react-icons/tb";
import moment from "moment";

const Step2 = ({ currentStep }) => {
    const [breakTime, setBreakTime] = useState([
        { name: "", startBreakTime: "", endBreakTime: "" },
    ]);
    const [b2bMeetings, setB2bMeetings] = useState([
        { name: "", startEventTime: "", endEventTime: "" },
    ]);
    const [sessions, setSessions] = useState([
        {
            name: "",
            startTime: "",
            endTime: "",
            duration: "",
            sessionPlace: "",
            description: "",
            speakerName: "",
            speakerTitle: "",
            speakerPosition: "",
            speakerLinkedin: "",
            speakerCompany: "",
            speakerPhoto: "",
            speakerPresentation: "",
            speakerBio: "",
            speakerConfirmation: "",
        },
    ]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleSessionsChange = (index, property, value) => {
        const newSessions = [...sessions];
        newSessions[index][property] = value;
        setSessions(newSessions);
    };

    const handleBreakTimeChange = (index, property, value) => {
        const newBreakTimes = [...breakTime];
        newBreakTimes[index][property] = value;
        setBreakTime(newBreakTimes);
    };

    const handleB2BMeetingsChange = (index, property, value) => {
        const newMeetings = [...b2bMeetings];
        newMeetings[index][property] = value;
        setB2bMeetings(newMeetings);
    };

    const handleFileChange = (inputName, event) => {
        const files = Array.from(event.target.files);

        // Update the state with the selected files for the specific input
        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [inputName]: files[0],
        }));
    };

    console.log({ sessions, selectedFiles, breakTime, b2bMeetings });

    return (
        <div class="row">
            <h4
                className="mb-5 d-flex align-items-center gap-3 pt-3 pb-4"
                style={{
                    borderBottom: "2px solid rgba(0, 0, 0, .06)",
                    color: "var(--primary-color)",
                }}
            >
                <span
                    style={{
                        width: "40px",
                        height: "40px",
                        lineHeight: "40px",
                        borderRadius: "50%",
                        border: "1px solid var(--primary-color)",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {currentStep + 1}
                </span>{" "}
                Event Agenda
            </h4>
            <div className="col-md-12">
                <div className="d-flex flex-column gap-4">
                    <div
                        style={{ background: "rgba(235, 235, 235, 1)" }}
                        className="p-4 rounded-3 d-flex gap-2 align-items-center justify-content-between mb-4"
                    >
                        <h5>Event Sessions</h5>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                setSessions((prev) => [
                                    ...prev,
                                    { name: "", from: "", to: "" },
                                ])
                            }
                            style={{
                                color: "var(--primary-color)",
                                background: "rgba(10, 61, 128, 0.2)",
                                border: "none",
                                fontWeight: "900",
                                fontSize: "20px",
                            }}
                        >
                            +
                        </button>
                    </div>
                    {sessions.map((session, i) => (
                        <div key={i} className={i === 0 ? "" : "mt-5"}>
                            {sessions.length > 1 ? (
                                <h3 className="mb-5">Session #{i + 1}</h3>
                            ) : null}
                            <Row>
                                <Col>
                                    <Input
                                        id="sessionName"
                                        label="Session Name"
                                        placeholder="Type Session Name"
                                        value={session.name}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "name",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        id="startTime"
                                        label="Start Time"
                                        placeholder="Type Start Time"
                                        value={session.startTime}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "startTime",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        id="endTime"
                                        label="End Time"
                                        placeholder="Type End Time"
                                        value={session.endTime}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "endTime",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <Input
                                        id="duration"
                                        label="Duration"
                                        placeholder="Type Session Duration"
                                        value={session.duration}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "duration",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        id="sessionPlace"
                                        label="Session Place"
                                        placeholder="Type Session Place"
                                        value={session.sessionPlace}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "sessionPlace",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <Input
                                        type="textarea"
                                        id="description"
                                        label="Description"
                                        placeholder="Type Session Description"
                                        value={session.description}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "description",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <Input
                                        id="speakerName"
                                        label="Speaker Name"
                                        placeholder="Type Speaker Name"
                                        value={session.speakerName}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerName",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        id="speakerTitle"
                                        label="Speaker Title"
                                        placeholder="Type Speaker Title"
                                        value={session.speakerTitle}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerTitle",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <Input
                                        id="speakerPosition"
                                        label="Speaker Position"
                                        placeholder="Type Speaker Position"
                                        value={session.speakerPosition}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerPosition",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        id="speakerLinkedin"
                                        label="Speaker Linkedin"
                                        placeholder="Type Speaker Linkedin"
                                        value={session.speakerLinkedin}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerLinkedin",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <Input
                                        id="speakerCompany"
                                        label="Speaker Company"
                                        placeholder="Type Speaker Company"
                                        value={session.speakerCompany}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerCompany",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        id="speakerBio"
                                        label="Speaker Bio"
                                        placeholder="Type Speaker Bio"
                                        value={session.speakerBio}
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerBio",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <FileInput
                                        handleChange={(event) =>
                                            handleFileChange(
                                                "speakerPhoto",
                                                event
                                            )
                                        }
                                        title="Speaker Photo"
                                        icon={
                                            <IoMdCloudUpload
                                                color="rgba(156, 163, 175, 1)"
                                                fontSize="40px"
                                            />
                                        }
                                        description1="Click to upload or drag and drop"
                                        description2="Max. File Size: 30MB"
                                    />
                                </Col>
                                <Col>
                                    <FileInput
                                        handleChange={(event) =>
                                            handleFileChange(
                                                "speakerPresentation",
                                                event
                                            )
                                        }
                                        title="Speaker Presentation"
                                        icon={
                                            <IoMdCloudUpload
                                                color="rgba(156, 163, 175, 1)"
                                                fontSize="40px"
                                            />
                                        }
                                        description1="Click to upload or drag and drop"
                                        description2="Max. File Size: 30MB"
                                    />
                                </Col>
                            </Row>
                            <Form.Group
                                style={{ marginTop: "30px" }}
                                as={Row}
                                controlId="formRadio"
                            >
                                <h6 style={{ marginBottom: "20px" }}>
                                    Speaker Confirmation S
                                </h6>
                                <Col>
                                    <Form.Check
                                        type="radio"
                                        label="Confirmed"
                                        name="confirmed"
                                        value="confirmed"
                                        checked={
                                            session.speakerConfirmation ===
                                            "confirmed"
                                        }
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerConfirmation",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        type="radio"
                                        label="Not Confirmed"
                                        name="notconfirmed"
                                        value="notconfirmed"
                                        checked={
                                            session.speakerConfirmation ===
                                            "notconfirmed"
                                        }
                                        onChange={(e) =>
                                            handleSessionsChange(
                                                i,
                                                "speakerConfirmation",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Form.Group>
                            <div
                                className="mt-4 p-5 rounded-3"
                                style={{ background: "rgba(235, 235, 235, 1)" }}
                            >
                                <div className="d-flex gap-3 align-items-center justify-content-between">
                                    <h5>Break Time</h5>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                            setBreakTime((prev) => [
                                                ...prev,
                                                {
                                                    name: "",
                                                    startBreakTime: "",
                                                    endBreakTime: "",
                                                },
                                            ])
                                        }
                                        style={{
                                            color: "var(--primary-color)",
                                            background:
                                                "rgba(10, 61, 128, 0.2)",
                                            border: "none",
                                            fontWeight: "900",
                                            fontSize: "20px",
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            {breakTime.map((time, i) => (
                                <Row key={i} style={{ marginTop: "30px" }}>
                                    <Col>
                                        <Input
                                            id="breakTimeName"
                                            label="Set Break Time Name"
                                            placeholder="Type Break Time Name"
                                            value={time.name}
                                            onChange={(e) =>
                                                handleBreakTimeChange(
                                                    i,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="startBreakTime"
                                            label="Start Break Time"
                                            placeholder="Type Start Break Time"
                                            value={time.startBreakTime}
                                            onChange={(e) =>
                                                handleBreakTimeChange(
                                                    i,
                                                    "startBreakTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="endBreakTime"
                                            label="End Break Time"
                                            placeholder="Type End Break Time"
                                            value={time.endBreakTime}
                                            onChange={(e) =>
                                                handleBreakTimeChange(
                                                    i,
                                                    "endBreakTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    {i !== 0 ? (
                                        <Col>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() =>
                                                    setBreakTime(
                                                        breakTime.filter(
                                                            (_, index) =>
                                                                i !== index
                                                        )
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </Col>
                                    ) : null}
                                </Row>
                            ))}
                            <div
                                className="mt-4 p-5 rounded-3"
                                style={{ background: "rgba(235, 235, 235, 1)" }}
                            >
                                <div className="d-flex gap-3 align-items-center justify-content-between">
                                    <h5>B2B Meetings</h5>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                            setB2bMeetings((prev) => [
                                                ...prev,
                                                {
                                                    name: "",
                                                    startEventTime: "",
                                                    endEventTime: "",
                                                },
                                            ])
                                        }
                                        style={{
                                            color: "var(--primary-color)",
                                            background:
                                                "rgba(10, 61, 128, 0.2)",
                                            border: "none",
                                            fontWeight: "900",
                                            fontSize: "20px",
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            {b2bMeetings.map((m, i) => (
                                <Row key={i} style={{ marginTop: "30px" }}>
                                    <Col>
                                        <Input
                                            id="name"
                                            label="Meeting Name"
                                            placeholder="Type Meeting Name"
                                            value={m.name}
                                            onChange={(e) =>
                                                handleB2BMeetingsChange(
                                                    i,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="startEventTime"
                                            label="Start Event Time"
                                            placeholder="Type Start Event Time"
                                            value={m.startEventTime}
                                            onChange={(e) =>
                                                handleB2BMeetingsChange(
                                                    i,
                                                    "startEventTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="endEventTime"
                                            label="End Event Time"
                                            placeholder="Type End Event Time"
                                            value={m.endEventTime}
                                            onChange={(e) =>
                                                handleB2BMeetingsChange(
                                                    i,
                                                    "endEventTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    {i !== 0 ? (
                                        <Col>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() =>
                                                    setB2bMeetings(
                                                        b2bMeetings.filter(
                                                            (_, index) =>
                                                                i !== index
                                                        )
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </Col>
                                    ) : null}
                                </Row>
                            ))}
                            {i !== 0 ? (
                                <div className="mt-5">
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() =>
                                            setSessions(
                                                sessions.filter(
                                                    (_, index) => i !== index
                                                )
                                            )
                                        }
                                    >
                                        Delete Session | #{i + 1}
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Step2;
