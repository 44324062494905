import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Btn from "../../Buttons/Btn";
import Card from "../../Card/Card";
import MembersHeader from "../../Header/MembersHeader";
import AlphaList from "../../Pagination/AlphaList";
import queryString from "query-string";
import {
    membersIsLoadingSelector,
    membersSelector,
} from "../../../modules/search/Selectors";

import { connect } from "react-redux";
const memberTypes = [
    { value: "", label: "Select" },
    { value: "individual", label: "Individual" },
    { value: "corporate", label: "Corporate" },
];

const MembersList = (props) => {
    const { members, getMembers, sectors, searchMembers, isLoading } = props;
    const [alpha, setAlpha] = useState("#");
    const [sector, setSector] = useState("");
    const [industry, setIndustry] = useState("");
    const [searchName, setSearchName] = useState("");
    const [letter, setLetter] = useState("");
    const [activity, setActivity] = useState("");
    const [subActivity, setSubActivity] = useState("");
    /*
    Dir
    true => asc
    false => desc
    **/
    const [headerData, setHeaderData] = useState({
        dir: false,
        memberType: memberTypes[0],
    });

    const parsed = queryString.parse(window.location.search);

    useEffect(() => {
        if (parsed?.letter) {
            setAlpha(parsed?.letter);
        }
        if (parsed?.sector) setSector(parsed?.sector);
        if (parsed?.industry && parsed?.industry !== undefined)
            setIndustry(parsed?.industry);
        if (parsed?.activity && parsed?.activity !== undefined)
            setActivity(parsed?.activity);
        if (parsed?.subActivity && parsed?.subActivity !== undefined)
            setSubActivity(parsed?.subActivity);
        if (parsed?.searchTerm && parsed?.searchTerm !== undefined) {
            if (parsed?.searchTerm == "") setSearchName("");
            setSearchName(parsed?.searchTerm);
        }
        if (parsed?.searchTerm && parsed?.searchTerm !== undefined) {
            if (parsed?.searchTerm == "") setSearchName("");
            setSearchName(parsed?.searchTerm);
        }
    }, [parsed]);

    useEffect(() => {
        let body = {
            offset: 0,
            size: 6,
            sort: "name",
            dir: "asc",
            searchTerm: "",
            filterBy: [],
        };
        let searchAlpha, alpha;
        let filterOptions =
            headerData.memberType.value != ""
                ? [{ type: headerData.memberType.value }]
                : [];
        if (
            parsed?.sector &&
            parsed?.sector != "undefined" &&
            parsed?.sector != "null" &&
            parsed?.sector != "Select..."
        )
            filterOptions.push({ sectors: Number(parsed?.sector) });
        if (industry)
            filterOptions.push({ industry: Number(parsed?.industry) });
        if (
            parsed?.activity &&
            parsed?.activity != "undefined" &&
            parsed?.activity != "null" &&
            parsed?.activity != "Select..."
        )
            filterOptions.push({ activities: Number(parsed?.activity) });
        if (
            parsed?.subActivity &&
            parsed?.subActivity != "undefined" &&
            parsed?.subActivity != "null" &&
            parsed?.sector != "Select..."
        )
            filterOptions.push({ subActivity: parsed?.subActivity });
        if (
            parsed?.searchTerm &&
            parsed?.searchTerm != "undefined" &&
            parsed?.searchTerm != "null" &&
            parsed?.searchTerm != "Select..."
        )
            searchAlpha = parsed.searchTerm;
        if (
            parsed?.letter &&
            parsed?.letter != "undefined" &&
            parsed?.letter != "null" &&
            parsed?.letter != "Select..."
        )
            alpha = parsed.letter;

        let timeId = setTimeout(() => {
            getMembers({
                ...body,
                dir: headerData.dir ? "desc" : "asc",
                filterBy: filterOptions,
                searchTerm: searchAlpha,
                letter: alpha,
            });
        }, 100);
        return () => clearTimeout(timeId);
    }, [
        headerData,
        alpha,
        industry,
        sector,
        searchName,
        getMembers,
        activity,
        subActivity,
    ]);

    console.log({ members });

    return (
        <div
            className="section-block members--list"
            style={{ transition: "2s ease-in-out", color: "#082244" }}
        >
            <div className="container">
                <MembersHeader
                    headerData={headerData}
                    setHeaderData={setHeaderData}
                    memberTypes={memberTypes}
                    sectors={sectors}
                    alpha={alpha}
                />
                <div className="header--forList">
                    <AlphaList
                        alpha={alpha}
                        setAlpha={setAlpha}
                        headerData={headerData}
                    />
                </div>
                <div className="members--items row">
                    {members?.map((member, index) => {
                        return (
                            <div
                                className="col-12 col-md-6 col-lg-4 mb-5"
                                key={index}
                            >
                                <div className="member--item">
                                    {/* {member?.name} */}
                                    <Card data={member} key={index}></Card>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div
                    className="d-flex justify-content-between"
                    style={{
                        float:
                            window.location.pathname !== "/members"
                                ? ""
                                : "right",
                        cursor: "pointer",
                    }}
                >
                    {window.location.pathname !== "/members" ? (
                        <NavLink
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname: `/members`,
                                search: `?sector=${sector}&activity=${activity}&searchTerm=${searchName}&subActivity=${subActivity}&letter=${alpha}`,
                            }}
                        >
                            <Btn
                                dataType="button"
                                label="Load More"
                                icon="spinner"
                                animation
                                searchTerm={searchName}
                                alpha={alpha}
                                headerData={headerData}
                                getMembers={getMembers}
                                members={members}
                            />
                        </NavLink>
                    ) : (
                        <Btn
                            dataType="button"
                            label="Load More"
                            icon="spinner"
                            animation
                            searchTerm={searchName}
                            alpha={alpha}
                            headerData={headerData}
                            getMembers={getMembers}
                            members={members}
                        />
                    )}

                    {window.location.pathname == "/members" ? (
                        ""
                    ) : (
                        <NavLink
                            to="/members"
                            style={{ textDecoration: "none", color: "black" }}
                        >
                            <Btn
                                dataType="button"
                                label="View All Members"
                                icon="ball-pile"
                            />
                        </NavLink>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        searchMembers: membersSelector(state),
        isLoading: membersIsLoadingSelector(state),
    };
};

export default connect(mapStateToProps, null)(MembersList);
