import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Form, Row } from "react-bootstrap";
import Input from "../Input";
import { sectorsSelector } from "../../../modules/sectors/Selector";
import { getSectorsAction } from "../../../modules/sectors/Actions";
import { connect } from "react-redux";
import FileInput from "../FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import api from "../../../helpers/api";
import moment from "moment";
import { useLocation } from "react-router-dom";

const CreateEventForm = (props) => {
    const {
        eventStepOne,
        setEventStepOne,
        getSectors,
        sectors,
        handleInputChange,
    } = props;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const eventId = query.get("edit");

    const handleUpload = async (formData) => {
        try {
            const res = await api.post("/uploads", formData);

            console.log({ res });
            setEventStepOne((prev) => ({ ...prev, cover: res?.data?.url }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleCoverChange = async (event) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("file", file);

        await handleUpload(formData);
    };

    const handleSectorsChange = (secId) => {
        const isSelected = eventStepOne?.sectors?.includes(secId);

        if (isSelected) {
            setEventStepOne((prevSelected) => ({
                ...prevSelected,
                sectors: eventStepOne?.sectors?.filter((id) => id !== secId),
            }));
        } else {
            setEventStepOne((prevSelected) => ({
                ...prevSelected,
                sectors: [...eventStepOne?.sectors, secId],
            }));
        }
    };

    useEffect(() => {
        getSectors();
    }, []);

    console.log({ eventStepOne, props });

    return (
        <div className="d-flex flex-column gap-5 step">
            <FileInput
                handleChange={handleCoverChange}
                title="Event Cover"
                icon={
                    <IoMdCloudUpload
                        color="rgba(156, 163, 175, 1)"
                        fontSize="50px"
                    />
                }
                description1="Click to upload or drag and drop"
                description2="Max. File Size: 30MB"
                description3="Picture Dimesions 1800 x 500"
                imageSrc={eventStepOne?.cover || ""}
            />
            <Input
                type="text"
                id="eventTitle"
                label="Event Title"
                placeholder="Event Title"
                name="title"
                value={eventStepOne?.title}
                onChange={handleInputChange}
            />
            <Input
                type="textarea"
                id="description"
                label="Description"
                placeholder="Write description details..."
                name="description"
                value={eventStepOne?.description}
                onChange={handleInputChange}
            />
            <div className="row">
                <div className="col-md-4">
                    <Input
                        type="datetime-local"
                        id="reminderDate"
                        label="Reminder Date"
                        name="reminderDate"
                        value={
                            eventStepOne?.reminderDate
                                ? moment(eventStepOne?.reminderDate).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                  )
                                : ""
                        }
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-4">
                    <Input
                        type="datetime-local"
                        id="startEventTime"
                        label="Event Start Date"
                        name="startTime"
                        value={
                            eventStepOne?.startTime
                                ? moment(eventStepOne?.startTime).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                  )
                                : ""
                        }
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-4">
                    <Input
                        type="datetime-local"
                        id="endEventTime"
                        label="Event End Date"
                        name="endTime"
                        value={
                            eventStepOne?.endTime
                                ? moment(eventStepOne?.endTime).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                  )
                                : ""
                        }
                        onChange={handleInputChange}
                        min={eventStepOne?.startTime}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Input
                        type="text"
                        id="placeEvent"
                        label="Event Venue"
                        name="place"
                        value={eventStepOne?.place}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <Input
                        type="text"
                        id="googleLink"
                        label="Event Venue Map Link"
                        name="placeLink"
                        value={eventStepOne?.placeLink}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <Row>
                <h5 className="mb-4">Event Activity</h5>
                <Col>
                    <Form.Check
                        type="radio"
                        label="Pre Event"
                        name="status"
                        value="pre_started"
                        disabled={eventId ? true : false}
                        checked={eventStepOne?.status === "pre_started"}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="Started"
                        name="status"
                        value="started"
                        disabled={eventId ? true : false}
                        checked={eventStepOne?.status === "started"}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="Finished"
                        name="status"
                        value="finished"
                        disabled={eventId ? true : false}
                        checked={eventStepOne?.status === "finished"}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <div>
                <h4>Event Sectors</h4>
                <p>
                    Choose one or more categories you will talk about on the
                    Event
                </p>
                <div className="mt-5">
                    <div
                        className="btn-group d-flex gap-3 flex-wrap"
                        role="group"
                        aria-label="Basic checkbox toggle button group"
                    >
                        {sectors?.map((sector, i) => (
                            <div key={sector?._id}>
                                <input
                                    type="checkbox"
                                    className="btn-check"
                                    id={`${sector?._id}`}
                                    autocomplete="off"
                                    checked={eventStepOne?.sectors?.includes(
                                        sector?._id
                                    )}
                                    onChange={() =>
                                        handleSectorsChange(sector?._id)
                                    }
                                />
                                <label
                                    className="btn btn-outline-primary rounded"
                                    for={`${sector?._id}`}
                                >
                                    {sector?.secENm}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateTprops = (state) => ({
    sectors: sectorsSelector(state),
});

const mapDispatchToProps = {
    getSectors: getSectorsAction,
};

export default connect(mapStateTprops, mapDispatchToProps)(CreateEventForm);
