import {fork, put} from "redux-saga/effects";
import {appReadyAction} from "./globalActions";
import authSaga from "../modules/auth/Saga";
import membersSaga from "../modules/members/Saga"
import sectorsSaga from '../modules/sectors/Saga'
import industriesSaga from '../modules/industry/Saga'
import adsSaga from '../modules/ads/Saga'
import userSaga from '../modules/user/Saga'
import activitesSaga from '../modules/activites/Saga'
import subActivitesSaga from '../modules/subActivity/Saga'
import feedbackSaga from '../modules/help/Saga'
import searchSaga from '../modules/search/Saga'

export default function* () {
    yield fork(authSaga);
    yield fork(membersSaga)
    yield fork(sectorsSaga)
    yield fork(industriesSaga)
    yield fork(adsSaga)
    yield fork(userSaga)
    yield fork(activitesSaga)
    yield fork(subActivitesSaga)
    yield fork (feedbackSaga)
    yield fork (searchSaga)
    yield put(appReadyAction());

  }