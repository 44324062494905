import React, { useEffect, useRef, useState } from "react";
import Input from "../Input";
import { Button, Col, Form, Row } from "react-bootstrap";
import FileInput from "../FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import { TbCookieMan } from "react-icons/tb";
import api from "../../../helpers/api";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useToasts } from "react-toast-notifications";

const CEventSessions = () => {
    const location = useLocation();
    const { addToast } = useToasts();
    const [eventsList, setEventsList] = useState({ count: 0, content: [] });
    const [selectedEventId, setSelectedEventId] = useState("");
    // const [breakTime, setBreakTime] = useState([
    //     { name: "", startBreakTime: "", endBreakTime: "" },
    // ]);
    // const [b2bMeetings, setB2bMeetings] = useState([
    //     { name: "", startMeetingTime: "", endMeetingTime: "" },
    // ]);
    const [session, setSession] = useState({
        name: "",
        startTime: "",
        endTime: "",
        duration: "",
        place: "",
        description: "",
        speakerName: "",
        speakerTitle: "",
        speakerPosition: "",
        speakerLinkedIn: "",
        speakerCompany: "",
        speakerPhoto: "",
        speakerPresentation: "",
        speakerBio: "",
        speakerConfirmation: "confirmed",
    });
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("edit");

    const handleSessionChange = (e) => {
        const { name, value } = e.target;

        // Update the state with the new value
        setSession({
            ...session,
            [name]: value,
        });
    };

    // const handleBreakTimeChange = (index, property, value) => {
    //     const newBreakTimes = [...breakTime];
    //     newBreakTimes[index][property] = value;
    //     setBreakTime(newBreakTimes);
    // };

    // const handleB2BMeetingsChange = (index, property, value) => {
    //     const newMeetings = [...b2bMeetings];
    //     newMeetings[index][property] = value;
    //     setB2bMeetings(newMeetings);
    // };

    const handleUpload = async (formData, property) => {
        try {
            const res = await api.post("/uploads", formData);

            console.log({ res });
            setSession((prev) => ({ ...prev, [property]: res?.data?.url }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileChange = async (event, property) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("file", file);

        await handleUpload(formData, property);
    };

    const getEvents = async () => {
        try {
            const { data } = await api.post("/events/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
            });

            setEventsList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const currentSession = JSON.parse(localStorage.getItem("session"));

        if (sessionId) {
            setSession(currentSession);
            setSelectedEventId(currentSession?.eventId);
            // setBreakTime(
            //     currentSession?.breaktime || [
            //         { name: "", startBreakTime: "", endBreakTime: "" },
            //     ]
            // );
            // setB2bMeetings(
            //     currentSession?.b2bMeetings || [
            //         { name: "", startMeetingTime: "", endMeetingTime: "" },
            //     ]
            // );
        }
    }, [sessionId]);

    useEffect(() => {
        getEvents();
    }, []);

    const handleSessionAction = async () => {
        try {
            if (sessionId) {
                await api.put(`/event-sessions/${sessionId}`, {
                    eventId: selectedEventId,
                    ...session,
                    speakerPresentation: [session?.speakerPresentation],
                    // b2bMeetings,
                    // breaktime: breakTime,
                    type: "SESSION",
                });
            } else {
                await api.post("/event-sessions", {
                    eventId: selectedEventId,
                    ...session,
                    speakerPresentation: [session?.speakerPresentation],
                    // b2bMeetings,
                    // breaktime: breakTime,
                    type: "SESSION",
                });
            }

            window.location.pathname = "/dashboard/events";
            // setEventsList(data);
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    console.log({ session, sessionId });

    return (
        <>
            <Form.Label style={{ fontSize: "18px" }}>
                Select Sessions Event
            </Form.Label>
            <Form.Select
                aria-label=""
                size="lg"
                style={{
                    height: "50px",
                    marginBottom: "30px",
                    fontSize: "18px",
                }}
                name="country"
                value={selectedEventId}
                onChange={(e) => setSelectedEventId(e.target.value)}
            >
                <option>Open this select event</option>
                {eventsList?.content?.map((event) => (
                    <option value={event?._id} key={event?._id}>
                        {event?.title}
                    </option>
                ))}
            </Form.Select>
            <div className="mt-5">
                <Row>
                    <Col>
                        <Input
                            id="sessionName"
                            label="Session Name"
                            placeholder="Type Session Name"
                            name="name"
                            value={session?.name}
                            onChange={handleSessionChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            id="startTime"
                            label="Start Time"
                            placeholder="Type Start Time"
                            name="startTime"
                            value={
                                session?.startTime
                                    ? moment(session?.startTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""
                            }
                            onChange={handleSessionChange}
                            type="datetime-local"
                        />
                    </Col>
                    <Col>
                        <Input
                            id="endTime"
                            label="End Time"
                            placeholder="Type End Time"
                            name="endTime"
                            value={
                                session?.endTime
                                    ? moment(session?.endTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""
                            }
                            onChange={handleSessionChange}
                            type="datetime-local"
                            min={session.startTime}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col>
                        <Input
                            id="duration"
                            label="Duration"
                            placeholder="Type Session Duration"
                            name="duration"
                            value={session?.duration}
                            onChange={handleSessionChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            id="place"
                            label="Session Place"
                            placeholder="Type Session Place"
                            name="place"
                            value={session?.place}
                            onChange={handleSessionChange}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col>
                        <Input
                            type="textarea"
                            id="description"
                            label="Description"
                            placeholder="Type Session Description"
                            name="description"
                            value={session?.description}
                            onChange={handleSessionChange}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col>
                        <Input
                            id="speakerName"
                            label="Speaker Name"
                            placeholder="Type Speaker Name"
                            name="speakerName"
                            value={session?.speakerName}
                            onChange={handleSessionChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            id="speakerTitle"
                            label="Speaker Title"
                            placeholder="Type Speaker Title"
                            name="speakerTitle"
                            value={session?.speakerTitle}
                            onChange={handleSessionChange}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col>
                        <Input
                            id="speakerPosition"
                            label="Speaker Position"
                            placeholder="Type Speaker Position"
                            name="speakerPosition"
                            value={session?.speakerPosition}
                            onChange={handleSessionChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            id="speakerLinkedIn"
                            label="Speaker Linkedin"
                            placeholder="Type Speaker Linkedin"
                            name="speakerLinkedIn"
                            value={session?.speakerLinkedIn}
                            onChange={handleSessionChange}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col xs={12}>
                        <Input
                            id="speakerCompany"
                            label="Speaker Company"
                            placeholder="Type Speaker Company"
                            name="speakerCompany"
                            value={session?.speakerCompany}
                            onChange={handleSessionChange}
                        />
                    </Col>
                    <Col style={{ marginTop: "30px" }}>
                        <Input
                            id="speakerBio"
                            label="Speaker Bio"
                            placeholder="Type Speaker Bio"
                            name="speakerBio"
                            value={session?.speakerBio}
                            onChange={handleSessionChange}
                            type="textarea"
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col>
                        <FileInput
                            handleChange={(event) =>
                                handleFileChange(event, "speakerPhoto")
                            }
                            title="Speaker Photo"
                            icon={
                                <IoMdCloudUpload
                                    color="rgba(156, 163, 175, 1)"
                                    fontSize="40px"
                                />
                            }
                            description1="Click to upload or drag and drop"
                            description2="Max. File Size: 30MB"
                            imageSrc={session?.speakerPhoto || ""}
                        />
                    </Col>
                    <Col>
                        <FileInput
                            handleChange={(event) =>
                                handleFileChange(event, "speakerPresentation")
                            }
                            title="Speaker Presentation"
                            icon={
                                <IoMdCloudUpload
                                    color="rgba(156, 163, 175, 1)"
                                    fontSize="40px"
                                />
                            }
                            description1="Click to upload or drag and drop"
                            description2="Max. File Size: 30MB"
                            fileSrc={session?.speakerPresentation || ""}
                        />
                    </Col>
                </Row>
                <Form.Group
                    style={{ marginTop: "30px" }}
                    as={Row}
                    controlId="formRadio"
                >
                    <h6 style={{ marginBottom: "20px" }}>
                        Speaker Confirmation S
                    </h6>
                    <Col>
                        <Form.Check
                            type="radio"
                            label="Confirmed"
                            value="confirmed"
                            name="speakerConfirmation"
                            checked={
                                session?.speakerConfirmation === "confirmed"
                            }
                            onChange={(e) => handleSessionChange(e)}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            type="radio"
                            label="Not Confirmed"
                            name="speakerConfirmation"
                            value="notconfirmed"
                            checked={
                                session?.speakerConfirmation === "notconfirmed"
                            }
                            onChange={(e) => handleSessionChange(e)}
                        />
                    </Col>
                </Form.Group>
                {/* <div
                    className="mt-4 p-5 rounded-3"
                    style={{
                        background: "rgba(235, 235, 235, 1)",
                    }}
                >
                    <div className="d-flex gap-3 align-items-center justify-content-between">
                        <h5>Break Time</h5>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                setBreakTime((prev) => [
                                    ...prev,
                                    {
                                        name: "",
                                        startBreakTime: "",
                                        endBreakTime: "",
                                    },
                                ])
                            }
                            style={{
                                color: "var(--primary-color)",
                                background: "rgba(10, 61, 128, 0.2)",
                                border: "none",
                                fontWeight: "900",
                                fontSize: "20px",
                            }}
                        >
                            +
                        </button>
                    </div>
                </div>
                {breakTime.map((time, i) => (
                    <Row key={i} style={{ marginTop: "30px" }}>
                        <Col>
                            <Input
                                id="breakTimeName"
                                label="Set Break Time Name"
                                placeholder="Type Break Time Name"
                                value={time.name}
                                onChange={(e) =>
                                    handleBreakTimeChange(
                                        i,
                                        "name",
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col>
                            <Input
                                type="datetime-local"
                                id="startBreakTime"
                                label="Start Break Time"
                                placeholder="Type Start Break Time"
                                value={
                                    time?.startBreakTime
                                        ? moment(time?.startBreakTime).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : ""
                                }
                                onChange={(e) =>
                                    handleBreakTimeChange(
                                        i,
                                        "startBreakTime",
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col>
                            <Input
                                type="datetime-local"
                                id="endBreakTime"
                                label="End Break Time"
                                placeholder="Type End Break Time"
                                value={
                                    time?.endBreakTime
                                        ? moment(time?.endBreakTime).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : ""
                                }
                                onChange={(e) =>
                                    handleBreakTimeChange(
                                        i,
                                        "endBreakTime",
                                        e.target.value
                                    )
                                }
                                min={time?.startBreakTime}
                            />
                        </Col>
                        {i !== 0 ? (
                            <Col>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() =>
                                        setBreakTime(
                                            breakTime.filter(
                                                (_, index) => i !== index
                                            )
                                        )
                                    }
                                >
                                    Delete
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                ))} */}
                {/* <div
                    className="mt-4 p-5 rounded-3"
                    style={{
                        background: "rgba(235, 235, 235, 1)",
                    }}
                >
                    <div className="d-flex gap-3 align-items-center justify-content-between">
                        <h5>B2B Meetings</h5>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                setB2bMeetings((prev) => [
                                    ...prev,
                                    {
                                        name: "",
                                        startMeetingTime: "",
                                        endMeetingTime: "",
                                    },
                                ])
                            }
                            style={{
                                color: "var(--primary-color)",
                                background: "rgba(10, 61, 128, 0.2)",
                                border: "none",
                                fontWeight: "900",
                                fontSize: "20px",
                            }}
                        >
                            +
                        </button>
                    </div>
                </div>
                {b2bMeetings.map((m, i) => (
                    <Row key={i} style={{ marginTop: "30px" }}>
                        <Col>
                            <Input
                                id="name"
                                label="Meeting Name"
                                placeholder="Type Meeting Name"
                                value={m.name}
                                onChange={(e) =>
                                    handleB2BMeetingsChange(
                                        i,
                                        "name",
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col>
                            <Input
                                type="datetime-local"
                                id="startMeetingTime"
                                label="Start Meeting Time"
                                placeholder="Type Start Meeting Time"
                                value={
                                    m?.startMeetingTime
                                        ? moment(m?.startMeetingTime).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : ""
                                }
                                onChange={(e) =>
                                    handleB2BMeetingsChange(
                                        i,
                                        "startMeetingTime",
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                        <Col>
                            <Input
                                type="datetime-local"
                                id="endMeetingTime"
                                label="End Meeting Time"
                                placeholder="Type End Meeting Time"
                                value={
                                    m?.endMeetingTime
                                        ? moment(m?.endMeetingTime).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : ""
                                }
                                onChange={(e) =>
                                    handleB2BMeetingsChange(
                                        i,
                                        "endMeetingTime",
                                        e.target.value
                                    )
                                }
                                min={m?.startMeetingTime}
                            />
                        </Col>
                        {i !== 0 ? (
                            <Col>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() =>
                                        setB2bMeetings(
                                            b2bMeetings.filter(
                                                (_, index) => i !== index
                                            )
                                        )
                                    }
                                >
                                    Delete
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                ))} */}
            </div>
            <div className="d-flex align-items-center justify-content-start gap-3 mt-5 pb-4">
                <Button variant="primary" onClick={handleSessionAction}>
                    {sessionId ? "Edit Session" : "Create Session"}
                </Button>
            </div>

            {/* <div class="row">
                <div className="col-md-12">
                    <div className="d-flex flex-column gap-4">
                        <div
                            style={{ background: "rgba(235, 235, 235, 1)" }}
                            className="p-4 rounded-3 d-flex gap-2 align-items-center justify-content-between mb-4"
                        >
                            <h5>Event Sessions</h5>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                    setSessions((prev) => [
                                        ...prev,
                                        { name: "", from: "", to: "" },
                                    ])
                                }
                                style={{
                                    color: "var(--primary-color)",
                                    background: "rgba(10, 61, 128, 0.2)",
                                    border: "none",
                                    fontWeight: "900",
                                    fontSize: "20px",
                                }}
                            >
                                +
                            </button>
                        </div>
                        {sessions.map((session, i) => (
                            <div key={i} className={i === 0 ? "" : "mt-5"}>
                                {sessions.length > 1 ? (
                                    <h3 className="mb-5">Session #{i + 1}</h3>
                                ) : null}
                                <Row>
                                    <Col>
                                        <Input
                                            id="sessionName"
                                            label="Session Name"
                                            placeholder="Type Session Name"
                                            value={session.name}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="startTime"
                                            label="Start Time"
                                            placeholder="Type Start Time"
                                            value={session.startTime}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "startTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="endTime"
                                            label="End Time"
                                            placeholder="Type End Time"
                                            value={session.endTime}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "endTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "30px" }}>
                                    <Col>
                                        <Input
                                            id="duration"
                                            label="Duration"
                                            placeholder="Type Session Duration"
                                            value={session.duration}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "duration",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="place"
                                            label="Session Place"
                                            placeholder="Type Session Place"
                                            value={session.place}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "place",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "30px" }}>
                                    <Col>
                                        <Input
                                            type="textarea"
                                            id="description"
                                            label="Description"
                                            placeholder="Type Session Description"
                                            value={session.description}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "description",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "30px" }}>
                                    <Col>
                                        <Input
                                            id="speakerName"
                                            label="Speaker Name"
                                            placeholder="Type Speaker Name"
                                            value={session.speakerName}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerName",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="speakerTitle"
                                            label="Speaker Title"
                                            placeholder="Type Speaker Title"
                                            value={session.speakerTitle}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerTitle",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "30px" }}>
                                    <Col>
                                        <Input
                                            id="speakerPosition"
                                            label="Speaker Position"
                                            placeholder="Type Speaker Position"
                                            value={session.speakerPosition}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerPosition",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="speakerLinkedIn"
                                            label="Speaker Linkedin"
                                            placeholder="Type Speaker Linkedin"
                                            value={session.speakerLinkedIn}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerLinkedIn",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "30px" }}>
                                    <Col>
                                        <Input
                                            id="speakerCompany"
                                            label="Speaker Company"
                                            placeholder="Type Speaker Company"
                                            value={session.speakerCompany}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerCompany",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            id="speakerBio"
                                            label="Speaker Bio"
                                            placeholder="Type Speaker Bio"
                                            value={session.speakerBio}
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerBio",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "30px" }}>
                                    <Col>
                                        <FileInput
                                            handleChange={(event) =>
                                                handleFileChange(
                                                    "speakerPhoto",
                                                    event
                                                )
                                            }
                                            title="Speaker Photo"
                                            icon={
                                                <IoMdCloudUpload
                                                    color="rgba(156, 163, 175, 1)"
                                                    fontSize="40px"
                                                />
                                            }
                                            description1="Click to upload or drag and drop"
                                            description2="Max. File Size: 30MB"
                                        />
                                    </Col>
                                    <Col>
                                        <FileInput
                                            handleChange={(event) =>
                                                handleFileChange(
                                                    "speakerPresentation",
                                                    event
                                                )
                                            }
                                            title="Speaker Presentation"
                                            icon={
                                                <IoMdCloudUpload
                                                    color="rgba(156, 163, 175, 1)"
                                                    fontSize="40px"
                                                />
                                            }
                                            description1="Click to upload or drag and drop"
                                            description2="Max. File Size: 30MB"
                                        />
                                    </Col>
                                </Row>
                                <Form.Group
                                    style={{ marginTop: "30px" }}
                                    as={Row}
                                    controlId="formRadio"
                                >
                                    <h6 style={{ marginBottom: "20px" }}>
                                        Speaker Confirmation S
                                    </h6>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label="Confirmed"
                                            name="confirmed"
                                            value="confirmed"
                                            checked={
                                                session.speakerConfirmation ===
                                                "confirmed"
                                            }
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerConfirmation",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label="Not Confirmed"
                                            name="notconfirmed"
                                            value="notconfirmed"
                                            checked={
                                                session.speakerConfirmation ===
                                                "notconfirmed"
                                            }
                                            onChange={(e) =>
                                                handleSessionsChange(
                                                    i,
                                                    "speakerConfirmation",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                                <div
                                    className="mt-4 p-5 rounded-3"
                                    style={{
                                        background: "rgba(235, 235, 235, 1)",
                                    }}
                                >
                                    <div className="d-flex gap-3 align-items-center justify-content-between">
                                        <h5>Break Time</h5>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                setBreakTime((prev) => [
                                                    ...prev,
                                                    {
                                                        name: "",
                                                        startBreakTime: "",
                                                        endBreakTime: "",
                                                    },
                                                ])
                                            }
                                            style={{
                                                color: "var(--primary-color)",
                                                background:
                                                    "rgba(10, 61, 128, 0.2)",
                                                border: "none",
                                                fontWeight: "900",
                                                fontSize: "20px",
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                {breakTime.map((time, i) => (
                                    <Row key={i} style={{ marginTop: "30px" }}>
                                        <Col>
                                            <Input
                                                id="breakTimeName"
                                                label="Set Break Time Name"
                                                placeholder="Type Break Time Name"
                                                value={time.name}
                                                onChange={(e) =>
                                                    handleBreakTimeChange(
                                                        i,
                                                        "name",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Input
                                                id="startBreakTime"
                                                label="Start Break Time"
                                                placeholder="Type Start Break Time"
                                                value={time.startBreakTime}
                                                onChange={(e) =>
                                                    handleBreakTimeChange(
                                                        i,
                                                        "startBreakTime",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Input
                                                id="endBreakTime"
                                                label="End Break Time"
                                                placeholder="Type End Break Time"
                                                value={time.endBreakTime}
                                                onChange={(e) =>
                                                    handleBreakTimeChange(
                                                        i,
                                                        "endBreakTime",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                        {i !== 0 ? (
                                            <Col>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() =>
                                                        setBreakTime(
                                                            breakTime.filter(
                                                                (_, index) =>
                                                                    i !== index
                                                            )
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </Col>
                                        ) : null}
                                    </Row>
                                ))}
                                <div
                                    className="mt-4 p-5 rounded-3"
                                    style={{
                                        background: "rgba(235, 235, 235, 1)",
                                    }}
                                >
                                    <div className="d-flex gap-3 align-items-center justify-content-between">
                                        <h5>B2B Meetings</h5>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                setB2bMeetings((prev) => [
                                                    ...prev,
                                                    {
                                                        name: "",
                                                        startMeetingTime: "",
                                                        endMeetingTime: "",
                                                    },
                                                ])
                                            }
                                            style={{
                                                color: "var(--primary-color)",
                                                background:
                                                    "rgba(10, 61, 128, 0.2)",
                                                border: "none",
                                                fontWeight: "900",
                                                fontSize: "20px",
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                {b2bMeetings.map((m, i) => (
                                    <Row key={i} style={{ marginTop: "30px" }}>
                                        <Col>
                                            <Input
                                                id="name"
                                                label="Meeting Name"
                                                placeholder="Type Meeting Name"
                                                value={m.name}
                                                onChange={(e) =>
                                                    handleB2BMeetingsChange(
                                                        i,
                                                        "name",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Input
                                                id="startMeetingTime"
                                                label="Start Event Time"
                                                placeholder="Type Start Event Time"
                                                value={m.startMeetingTime}
                                                onChange={(e) =>
                                                    handleB2BMeetingsChange(
                                                        i,
                                                        "startMeetingTime",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Input
                                                id="endMeetingTime"
                                                label="End Event Time"
                                                placeholder="Type End Event Time"
                                                value={m.endMeetingTime}
                                                onChange={(e) =>
                                                    handleB2BMeetingsChange(
                                                        i,
                                                        "endMeetingTime",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Col>
                                        {i !== 0 ? (
                                            <Col>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() =>
                                                        setB2bMeetings(
                                                            b2bMeetings.filter(
                                                                (_, index) =>
                                                                    i !== index
                                                            )
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </Col>
                                        ) : null}
                                    </Row>
                                ))}
                                {i !== 0 ? (
                                    <div className="mt-5">
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() =>
                                                setSessions(
                                                    sessions.filter(
                                                        (_, index) =>
                                                            i !== index
                                                    )
                                                )
                                            }
                                        >
                                            Delete Session | #{i + 1}
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default CEventSessions;
