import React, { useState } from "react";
import Image from "../../assets/images/pic.png";
import { Button, Form } from "react-bootstrap";
import Input from "../../components/events/Input";
import api from "../../helpers/api";
import { useToasts } from "react-toast-notifications";
import Cookies from "js-cookie";

const Register = () => {
    const { addToast } = useToasts();
    const [values, setValues] = useState({ username: "", password: "" });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleAction = async () => {
        try {
            await api.post(`/auth/signup`, {
                ...values,
                role: "non_member",
                expireAt: "2025-04-02T00:44:46.934Z",
            });

            // localStorage.setItem("role", "non_member");
            // localStorage.setItem("username", values.username);
            window.location.pathname = "/login";
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    return (
        <>
            <div
                className="login custom--scrollbar"
                style={{ color: "#051538" }}
            >
                <div className="login--inner">
                    <div className="row">
                        <div
                            className="col-12 col-md-4 mb-5 mb-md-0"
                            style={{
                                backgroundColor: "#f0f8ff3d",
                                backdropFilter: " blur(10px)",
                            }}
                        >
                            <div className="left">
                                {/* background */}
                                <div className="background-container"></div>
                                {/* site logo */}
                                <a href="/" className="logo">
                                    <div className="logo--icon">
                                        <img src={Image} alt="" />
                                    </div>
                                </a>
                                <div className="big text-justify mt-5">
                                    EBA Members’ Directory includes complete
                                    data about its members and their companies
                                    filtered through different search criteria
                                    (member name, company name, sector…){" "}
                                </div>
                            </div>
                        </div>
                        <div className="right col-12 col-md-7">
                            <div className="container">
                                <div className="row justify-content-center login--form">
                                    <div className="col-6 mb-5">
                                        <div className="d-flex flex-column gap-3">
                                            <h6>Create new account</h6>
                                            <div className="d-flex flex-column gap-4">
                                                <Input
                                                    value={values.username}
                                                    type="text"
                                                    label="Username"
                                                    name="username"
                                                    onChange={handleInputChange}
                                                />
                                                <Input
                                                    value={values.password}
                                                    type="password"
                                                    label="Password"
                                                    name="password"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="d-flex gap-3">
                                                <Button
                                                    disabled={
                                                        !values.username ||
                                                        !values.password
                                                    }
                                                    onClick={handleAction}
                                                >
                                                    Register
                                                </Button>
                                                <Button as="a" href="/login">
                                                    Login
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer>
                                    {/* <!-- Content --> */}
                                    <div className="container">
                                        {/* <!-- Copyrights --> */}
                                        <div className="copyrights">
                                            <div>
                                                © COPYRIGHT{" "}
                                                {new Date().getFullYear()}
                                            </div>
                                            <div>
                                                Egyptian Businessmen’s
                                                Association - EBA. All Rights
                                                Reserved.
                                            </div>
                                            <div
                                                className="mt-3 text-dark"
                                                style={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Technology Partner -
                                                <a
                                                    href="https://www.pharaohsoft.com"
                                                    className="text-dark"
                                                    target="_blanck"
                                                    rel="noreferrer"
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Pharaoh Soft LTD
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Back to Top --> */}
                                    <button
                                        id="backToTop"
                                        className="btn with-icon"
                                        type="button"
                                    >
                                        <div className="icon">
                                            <em className="fad fa-long-arrow-alt-up"></em>
                                        </div>
                                        Back to Top
                                    </button>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
