import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { logoutUserAction } from '../../modules/auth/Actions';
import { connect } from 'react-redux'
import Image from '../../assets/images/pic.png';



const MobileNavBar = ({ logoutUser }) => {
    const [active, setActive] = useState(false)
    const [isOpend, setIsOpend] = useState("")

    const handelActive = () => {
        if (isOpend == "active") setIsOpend("")
        else setIsOpend("active")
    }
    const handelOpen = () => {
        setActive(!active)

    }
    return (
        <ul className="nav mobile"
            style={{
                float: "right",

            }}>
            <li className="nav-item">
                <a
                    className="nav-link"
                    href="javascript:;"
                    data-sidebar_trigger="menuMobile"
                    onClick={handelActive}
                >
                    <em className="fad fa-bars text-light"></em>
                </a>

                {/* <!-- Menu Mobile Sidebar --> */}
                <div className="sidebar_container">
                    <div
                        id="menuMobile"
                        data-sidebar_container="menuMobile"
                        className={`fixed--sidebar menuMobile left ${isOpend}`}
                    >
                        {/* <!-- Close Button --> */}
                        <button className="btn with-icon close" type="button" onClick={handelActive}>
                            <div className="icon"><em className="fad fa-times"></em></div>
                            Close
                        </button>

                        {/* <!-- Content -->  */}
                        <div className="fixed--sidebar_container custom--scrollbar">
                            <div className="header">
                                <div className="logo">
                                    <img
                                        src={Image}
                                        style={{
                                            width: '240px',
                                        }}
                                    />
                                </div>
                                <h2>
                                    <strong>EBA</strong> Members <br />
                                    <strong>Directory</strong>
                                </h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit.
                                </p>
                            </div>

                            <ul className="nav inner">
                                <li className="nav-item ">
                                    <NavLink className="nav-link" to="/home">
                                        Home
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/dashboard/advertisments">
                                        Advertisment
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/dashboard/users">
                                        Users
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="btn btn-primary action mt-4"
                                        href="/login"
                                        role="button"
                                        onClick={logoutUser}

                                    >
                                        <em className="fad fa-sign-out"></em>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    )
}

const mapDispatchToProps = {
    logoutUser: logoutUserAction
};

export default connect(null, mapDispatchToProps)(MobileNavBar);
