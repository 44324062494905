import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import Input from "../../../components/events/Input";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import api from "../../../helpers/api";
import { Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { IoMdCloudUpload } from "react-icons/io";
import FileInput from "../../../components/events/FileInput";

const CreateDelegation = () => {
    const { addToast } = useToasts();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const delId = query.get("edit");
    // const [delegation, setDelegation] = useState({
    //     username: "",
    //     password: "",
    // });
    const [userInfo, setUserInfo] = useState({
        title: "",
        name: "",
        username: "",
        password: "",
        businessPhone_1: "",
        businessPhone_2: "",
        businessFax_1: "",
        businessFax_2: "",
        mobile: "",
        email: "",
        address: "",
        photo: "",
        position: "",
        type: "individual",
        company: {
            name: "",
            bio: "",
            email: "",
            website: "",
            address: "",
            phone_1: "",
            phone_2: "",
            phone_3: "",
            fax_1: "",
            fax_2: "",
            fax_3: "",
            contact_person: "",
            sector: "",
            brief_intro: "",
        },
        video: "",
        councils: "",
        committees: "",
    });

    const handleUserChange = (event) => {
        const { name, value } = event.target;
        // If the changed field is within the 'company' object
        if (name.startsWith("company.")) {
            // Extract the field name from 'name'
            const companyFieldName = name.split(".")[1];

            // Update the 'company' object within the state
            setUserInfo((prevState) => ({
                ...prevState,
                company: {
                    ...prevState.company,
                    [companyFieldName]: value,
                },
            }));
        } else {
            // If it's not within the 'company' object, update normally
            setUserInfo((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleUpload = async (formData, property) => {
        try {
            const res = await api.post("/uploads", formData);

            console.log({ res });
            setUserInfo((prev) => ({ ...prev, photo: res?.data?.url }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileChange = async (event, property) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("file", file);

        await handleUpload(formData, property);
    };

    useEffect(() => {
        const userInfo = localStorage.getItem("user_info");

        if (userInfo) {
            const parsedUserInfo = JSON.parse(userInfo);
            if (parsedUserInfo) {
                setUserInfo(parsedUserInfo.user || parsedUserInfo);
            }
        }
    }, []);

    // useEffect(() => {
    //     const currentDel = JSON.parse(localStorage.getItem("delegation"));

    //     if (delId) {
    //         setDelegation(currentDel);
    //     }
    // }, [delId]);

    const handleDelegationAction = async () => {
        try {
            if (delId) {
                const { data } = await api.put(`/users/${delId}`, {
                    ...userInfo,
                    role: "Delegation",
                    expireAt: new Date("2025"),
                });

                localStorage.setItem("user_info", JSON.stringify(data));
            } else {
                await api.post(`/users`, {
                    ...userInfo,
                    role: "Delegation",
                    expireAt: new Date("2025"),
                });
            }

            window.location.pathname = "/dashboard/delegations";
        } catch (error) {
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     setDelegation({
    //         ...delegation,
    //         [name]: value,
    //     });
    // };

    return (
        <Sidebar>
            <h3 className="mb-5">{delId ? "Edit" : "Create"} Delegation</h3>
            {/* <div className="p-2">
                <div className="d-flex gap-3" style={{ width: "100%" }}>
                    <Input
                        type="text"
                        id="username"
                        label="Username"
                        placeholder="Username"
                        name="username"
                        value={delegation?.username}
                        onChange={handleInputChange}
                    />
                    <Input
                        type="password"
                        id="password"
                        label="Password"
                        placeholder="Password"
                        name="password"
                        value={delegation?.password}
                        onChange={handleInputChange}
                    />
                </div>
            </div> */}
            <div className="d-flex flex-column gap-4 mt-5">
                <Input
                    value={userInfo?.title}
                    type="text"
                    label="Title"
                    name="title"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.name}
                    type="text"
                    label="Name"
                    name="name"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.position}
                    type="text"
                    label="Position"
                    name="position"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.username}
                    type="text"
                    label="Username"
                    name="username"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.password}
                    type="password"
                    label="Password"
                    name="password"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessPhone_1}
                    type="text"
                    label="Bussiness Phone 1"
                    name="businessPhone_1"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessPhone_2}
                    type="text"
                    label="Bussiness Phone 2"
                    name="businessPhone_2"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessFax_1}
                    type="text"
                    label="Bussiness Fax 1"
                    name="businessFax_1"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessFax_2}
                    type="text"
                    label="Bussiness Fax 2"
                    name="businessFax_2"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.mobile}
                    type="text"
                    label="Mobile"
                    name="mobile"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.email}
                    type="email"
                    label="Email"
                    name="email"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.address}
                    type="text"
                    label="Address"
                    name="address"
                    onChange={handleUserChange}
                />
                <FileInput
                    handleChange={handleFileChange}
                    title="User Photo"
                    icon={
                        <IoMdCloudUpload
                            color="rgba(156, 163, 175, 1)"
                            fontSize="40px"
                        />
                    }
                    description1="Click to upload or drag and drop"
                    description2="Max. File Size: 30MB"
                    imageSrc={userInfo?.photo || ""}
                />
                <div className="d-flex flex-column gap-2">
                    <label>Type</label>
                    <select
                        value={userInfo?.type}
                        onChange={(e) =>
                            setUserInfo((prev) => ({
                                ...prev,
                                type: e.target.value,
                            }))
                        }
                        defaultValue="individual"
                    >
                        <option value="individual" selected>
                            Individual
                        </option>
                        <option value="corporate">Corporate</option>
                    </select>
                </div>
                <h6>Company Info</h6>
                <Input
                    value={userInfo?.company?.name}
                    type="text"
                    label="Company Name"
                    name="company.name"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.bio}
                    type="text"
                    label="Company Bio"
                    name="company.bio"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.email}
                    type="text"
                    label="Company Email"
                    name="company.email"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.website}
                    type="text"
                    label="Company Website"
                    name="company.website"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.address}
                    type="text"
                    label="Company Address"
                    name="company.address"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.phone_1}
                    type="text"
                    label="Company Phone"
                    name="company.phone_1"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.contact_person}
                    type="text"
                    label="Company Contact Person"
                    name="company.contact_person"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.sector}
                    type="text"
                    label="Company Sector"
                    name="company.sector"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.brief_intro}
                    type="textarea"
                    label="Company Brief Information"
                    name="company.brief_intro"
                    onChange={handleUserChange}
                />
            </div>
            <Button
                className="mt-5"
                variant="primary"
                onClick={handleDelegationAction}
            >
                {delId ? "Edit" : "Create"} Delegation
            </Button>
        </Sidebar>
    );
};

export default CreateDelegation;
