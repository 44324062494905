import React, { useEffect, useState } from "react";
import api from "../../helpers/api";
import { FaCheckToSlot } from "react-icons/fa6";
import { Button } from "react-bootstrap";

const ConfirmSchedule = ({ match }) => {
    const { scheduleId } = match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleMeetConfirmAction = async (id) => {
        setIsLoading(true);

        try {
            await api.patch(
                `/member-schedules/${id}/confirm-meeting-request`,
                {}
            );
            setIsLoading(false);
            setIsConfirmed(true);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setIsConfirmed(false);
        }
    };

    useEffect(() => {
        if (scheduleId) {
            handleMeetConfirmAction(scheduleId);
        }
    }, [scheduleId]);

    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {isLoading ? (
                <p>Loading...</p>
            ) : isConfirmed ? (
                <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                    <FaCheckToSlot
                        style={{ fontSize: "100px", color: "green" }}
                    />
                    <h1>Meeting has been confirmed</h1>

                    <Button as="a" href="/" style={{ marginTop: "30px" }}>
                        Go to Home
                    </Button>
                </div>
            ) : (
                <Button as="a" href="/" style={{ marginTop: "30px" }}>
                    Go to Home
                </Button>
            )}
        </div>
    );
};

export default ConfirmSchedule;
