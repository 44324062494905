import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Input from "../../components/events/Input";
import {
    useHistory,
    useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useToasts } from "react-toast-notifications";
import api from "../../helpers/api";
import moment from "moment";
import Sidebar from "../../components/AdminSideBar/sidebar";

const CreateB2B = () => {
    const history = useHistory();
    const { addToast } = useToasts();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const b2bId = query.get("edit");
    const [b2bMeetings, setB2bMeetings] = useState({
        name: "",
        startTime: "",
        endTime: "",
    });
    const [selectedEventId, setSelectedEventId] = useState("");
    const [eventsList, setEventsList] = useState({ count: 0, content: [] });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update the state with the new value
        setB2bMeetings({
            ...b2bMeetings,
            [name]: value,
        });
    };

    const getEvents = async () => {
        try {
            const { data } = await api.post("/events/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
            });

            setEventsList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        const currentB2B = JSON.parse(localStorage.getItem("b2b"));

        if (b2bId) {
            setB2bMeetings(currentB2B);
            setSelectedEventId(currentB2B?.eventId);
        }
    }, [b2bId]);

    const handleB2BAction = async () => {
        try {
            if (b2bId) {
                await api.put(`/event-sessions/${b2bId}`, {
                    eventId: selectedEventId,
                    ...b2bMeetings,
                    type: "B2B",
                });
            } else {
                await api.post("/event-sessions", {
                    eventId: selectedEventId,
                    ...b2bMeetings,
                    type: "B2B",
                });
            }

            window.location.pathname = "/dashboard/event-b2b";
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    return (
        <Sidebar>
            <h3 className="mb-5">{b2bId ? "Edit" : "Create"} B2B</h3>
            <div className="p-2">
                <Form.Label style={{ fontSize: "18px" }}>
                    Select B2B Event
                </Form.Label>
                <Form.Select
                    aria-label=""
                    size="lg"
                    style={{
                        height: "50px",
                        marginBottom: "30px",
                        fontSize: "18px",
                    }}
                    name="country"
                    value={selectedEventId}
                    onChange={(e) => setSelectedEventId(e.target.value)}
                >
                    <option>Open this select event</option>
                    {eventsList?.content
                        ?.filter(
                            (event) => !event?.guests[0]?.schedules?.length
                        )
                        ?.map((event) => (
                            <option value={event?._id} key={event?._id}>
                                {event?.title}
                            </option>
                        ))}
                </Form.Select>
                <Row style={{ marginTop: "30px" }}>
                    <Col>
                        <Input
                            id="name"
                            label="B2B Name"
                            placeholder="Type B2B Name"
                            value={b2bMeetings.name}
                            name="name"
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="datetime-local"
                            id="startMeetingTime"
                            label="Start Meeting Time"
                            placeholder="Type Start Meeting Time"
                            value={
                                b2bMeetings.startTime
                                    ? moment(b2bMeetings?.startTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""
                            }
                            name="startTime"
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="datetime-local"
                            id="endMeetingTime"
                            label="End Meeting Time"
                            placeholder="Type End Meeting Time"
                            value={
                                b2bMeetings.endTime
                                    ? moment(b2bMeetings?.endTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""
                            }
                            name="endTime"
                            onChange={handleChange}
                            min={b2bMeetings?.startTime}
                        />
                    </Col>
                </Row>
                <Button
                    className="mt-5"
                    variant="primary"
                    onClick={handleB2BAction}
                >
                    {b2bId ? "Edit B2B" : "Create B2B"}
                </Button>
            </div>
        </Sidebar>
    );
};

export default CreateB2B;
