import produce from "immer";

export const actions = {
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",

    LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
    LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
    FORGET_PASSWORD: "FORGET_PASSWORD",

    UPDATE_PROFILE: "UPDATE_PROFILE",
    UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",

    SET_AUTH_IS_LOADING: "SET_AUTH_IS_LOADING",
};

const initialState = {
    user: null,
    token: null,
    role: null,
    isLoading: false,
};

export default produce((draft, action) => {
    const { type, payload } = action;
    if (!draft) {
        return initialState;
    }

    switch (type) {
        case actions.LOGIN_USER_SUCCESS: {
            const { user, token, sl } = payload;
            draft.user = user;
            draft.token = token;
            draft.role = sl;
            break;
        }

        case actions.LOGOUT_USER_SUCCESS: {
            draft.user = null;
            draft.token = null;

            break;
        }

        case actions.UPDATE_PROFILE_SUCCESS: {
            draft.user = payload;
            break;
        }

        // Misc
        case actions.SET_AUTH_IS_LOADING: {
            const isLoading = payload;
            draft.isLoading = isLoading;
            break;
        }

        default:
            break;
    }
});
