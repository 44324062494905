/* eslint-disable object-curly-newline */
import {call, put, select, takeLatest, takeEvery} from "redux-saga/effects";

import {
  newErrorNotificationAction,
  newSuccessNotificationAction,
} from "../notifications/Actions";

import { setIndustriesIsLoadingAction  , getIndustriesSuccessAction, getIndustrySuccessAction, getIndustriesCountAction} from "./Actions";
import * as api from "./Api";
import {actions} from "./Reducer";

// Plural
function* getIndustriesSaga({payload}) {
  const defaultBody ={
    offset: 0,
    size: 50,
    sort: "name",
    dir: "asc",
    searchTerm: "",
    filterBy: []
}

  const body = {...defaultBody, ...payload};
  try {
    yield put(setIndustriesIsLoadingAction(true));
    const response = yield call(api.getIndustries, body);
    const {data} = response;
    const items = data.content;
    const count = data.count;

    yield put(getIndustriesSuccessAction(items));
    yield put(getIndustriesCountAction(count));
  } catch (error) {
      console.log(error)
    //yield put(errorNotificationFromError(error));
  } finally {
    yield put(setIndustriesIsLoadingAction(false));
  }
}

function* getIndustrySaga({payload}) {
  const industryId = payload;
  try {
  //  yield put(resetMembersAction())
    yield put(setIndustriesIsLoadingAction(true));

    const response = yield call(api.getIndustry, industryId);
    const {data} = response;
    yield put(getIndustrySuccessAction(data));
  } catch (error) {
    yield put(newErrorNotificationAction("no industry found"));
  } finally {
    yield put(setIndustriesIsLoadingAction(false));
  }
}


export default function* () {
 
  yield takeLatest(actions.GET_INDUSTRIES, getIndustriesSaga);
 yield takeLatest(actions.GET_INDUSTRY,getIndustrySaga)

 
}
