import {createSelector} from "reselect";

const subactivitesStateSelector = (state) => state.subactivites;
const selectById = (state, industryId) => industryId;

export const subactivitesSelector = createSelector(
  [subactivitesStateSelector],
  (subactivites) => subactivites.subactivites
);

export const subactivitesHeaderSelector = createSelector(
  [subactivitesStateSelector],
  (subactivites) => subactivites.subactivitesHeader
);


export const activitesLoadSelector = createSelector(
  [subactivitesStateSelector],
  (subactivites) => subactivites.loadedactivites
);

export const activitesCountSelector = createSelector(
  [subactivitesStateSelector],
  (subactivites) => subactivites.count
);


export const activitesIsLoadingSelector = createSelector(
  [subactivitesStateSelector],
  (subactivites) => subactivites.isLoading
);

export const searchValueSelector = createSelector(
  [subactivitesStateSelector],
  (subactivites) => subactivites.searchValue
);

export const subActivityByIdSelector = createSelector(
 
  [subactivitesSelector, selectById],
  (subactivites, _id) => {
    const industry = subactivites.find(industry => _id === industry._id);
    return industry;
  }
);