
import React, { useRef, useState } from "react";


const SideBar = (props) => {
    const { children, withHead } = props
    const [active, setActive] = useState(props.active)
    const style = {
        "position": "fixed",
        "width": "100%",
        "height": "100%",
        "top": "0",
        "left": "0",
        "zIndex": "3000",
        "backgroundColor": " rgba(0,0,0,0.5)",
        'color': '#082244'
    }

    document.body.style.overflow = ""

    //    window.onclick = () =>{
    //     if(props.active == "active") setActive("")
    // }

    // document.onclick = (e) =>{
    // if(e.target.id !== "sidebar") setActive("")
    // }

    const sidebar = useRef()
        ;


    const handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (e.target.id != "sidebar") {


            return;
        }
        setActive("")


    }


    if (active) {
        // attach/remove event handler
        document.addEventListener('click', handleOutsideClick, false);
    } else {
        document.removeEventListener('click', handleOutsideClick, false);
    }







    const handelActive = () => {
        if (props.active == "active") setActive("")
    }

    return (
        <div className="sidebar_container" style={active ? style : {}} ref={sidebar} id="sidebar">

            <div
                id="searchSidebarnav1"
                data-sidebar_container="searchSidebarnav1"
                className={`fixed--sidebar advanced_search ${active}`}
            >
                {/* <!-- Close Button --> */}
                <button className="btn with-icon close" type="button" onClick={handelActive}>
                    <div className="icon"><em className="fad fa-times"></em></div>
                    Close
                </button>

                {/* <!-- Content --> */}
                <div className="fixed--sidebar_container custom--scrollbar">
                    {withHead && <div className="header mb-0" >
                        <h2>
                            <strong>EBA</strong> Members <br />
                            <strong>Directory</strong>
                        </h2>
                        <p>
                            EBA Members’ Directory includes complete
                            data about its members and their companies filtered through
                            different search criteria (member name, company name, sector…)
                        </p>
                    </div>}


                    {children}


                </div>
            </div>

        </div>
    )

}

export default SideBar;