export const actions = {
  NEW_SUCCESS_NOTIFICATION: 'NEW_SUCCESS_NOTIFICATION',
  REMOVE_SUCCESS_NOTIFICATION: 'REMOVE_SUCCESS_NOTIFICATION',
  NEW_ERROR_NOTIFICATION: 'NEW_ERROR_NOTIFICATION',
  REMOVE_ERROR_NOTIFICATION: 'REMOVE_ERROR_NOTIFICATION',
};

const initialState = {
  successNotifications: [],
  errorNotifications: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.NEW_SUCCESS_NOTIFICATION: {
      const newNotification = payload;
      return {
        ...state,
        successNotifications: [...state.successNotifications, newNotification],
      };
    }

    case actions.REMOVE_SUCCESS_NOTIFICATION: {
      const dateTimeAsId = payload;
      const updatedNotifs = state.successNotifications.filter(
        notif => notif.dateTimeAsId !== dateTimeAsId,
      );
      return {
        ...state,
        successNotifications: updatedNotifs,
      };
    }

    case actions.NEW_ERROR_NOTIFICATION: {
      const newNotification = payload;
      return {
        ...state,
        errorNotifications: [...state.errorNotifications, newNotification],
      };
    }

    case actions.REMOVE_ERROR_NOTIFICATION: {
      const dateTimeAsId = payload;
      const updatedNotifs = state.errorNotifications.filter(
        notif => notif.dateTimeAsId !== dateTimeAsId,
      );
      return {
        ...state,
        errorNotifications: updatedNotifs,
      };
    }

    default:
      return state;
  }
};
