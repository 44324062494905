export const actions = {
  APP_READY: 'APP_READY',
 
};

export const appReadyAction = () => ({
  type: actions.APP_READY,
});


