import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import { Button, Col, Form, Image, Modal, Row, Table } from "react-bootstrap";
import api from "../../../helpers/api";
import FileInput from "../../../components/events/FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import Input from "../../../components/events/Input";
import InputSelect from "../../../components/events/InputSelect";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const BookedTickets = () => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tickets, setTickets] = useState({
        content: [],
        count: 0,
    });
    const [selectedTicket, setSelectedTicket] = useState();
    const [bookedSeats, setBookedSeats] = useState([
        { name: "", email: "", position: "", company: "" },
    ]);

    const handleBookedSeatsChange = (index, property, value) => {
        const newSeats = [...bookedSeats];
        newSeats[index][property] = value;
        setBookedSeats(newSeats);
    };

    const getTickets = async () => {
        try {
            const { data } = await api.post("/book-request", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
            });

            setTickets(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getTickets();
    }, []);

    const addEmailsToAvailableSeats = async () => {
        try {
            await api.patch(
                `/book-request/${selectedTicket?._id}/addAttendees`,
                {
                    attendeesEmail: bookedSeats?.map((b) => {
                        return {
                            ...b,
                            name: b?.name || "NA",
                        };
                    }),
                }
            );

            window.location.reload();
        } catch (error) {
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    useEffect(() => {
        if (selectedTicket) {
            setBookedSeats(
                selectedTicket?.attendees || [
                    { name: "NA", email: "", company: "", position: "" },
                ]
            );
        }
    }, [selectedTicket]);

    console.log({ bookedSeats });

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <h3>Booked Tickets</h3>
            </div>
            <Table responsive className="mt-5" striped bordered hover size="lg">
                <thead>
                    <tr>
                        <th>Ticket Name</th>
                        <th>Ticket Price</th>
                        <th>Ticket Type</th>
                        <th>Ticket Seats</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.content?.map((ticket, i) => (
                        <tr key={ticket?._id}>
                            <td>{ticket?.ticket?.name}</td>
                            <td>{ticket?.ticket?.value}</td>
                            <td>{ticket?.ticket?.type}</td>
                            <td>{ticket?.ticket?.seates}</td>
                            <td>
                                <div className="d-flex gap-3">
                                    <Button
                                        // variant="primary"
                                        size="sm"
                                        onClick={() => {
                                            setSelectedTicket(ticket);
                                            setIsModalOpen(true);
                                        }}
                                        disabled={ticket?.ticket?.seates <= 1}
                                    >
                                        Seats
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Emails to Ticket Seats</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    <div className="d-flex gap-3 align-items-center justify-content-between">
                        <h6>Book Seat</h6>
                        {selectedTicket?.ticket?.seates <=
                        bookedSeats?.length ? null : (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                    setBookedSeats((prev) => [
                                        ...prev,
                                        {
                                            name: "",
                                            email: "",
                                            company: "",
                                            position: "",
                                        },
                                    ])
                                }
                                style={{
                                    color: "var(--primary-color)",
                                    background: "rgba(10, 61, 128, 0.2)",
                                    border: "none",
                                    fontWeight: "900",
                                    fontSize: "20px",
                                }}
                            >
                                +
                            </button>
                        )}
                    </div>
                    {bookedSeats.map((mt, i) => {
                        const isAttendeeAdded = mt?.name || mt?.email;

                        return (
                            <Row
                                key={i}
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Col>
                                    <div className="d-flex gap-2">
                                        <Input
                                            label="Name"
                                            placeholder="Type member name"
                                            value={mt.name}
                                            onChange={(e) =>
                                                handleBookedSeatsChange(
                                                    i,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Input
                                            label="Member Company"
                                            placeholder="Type member company"
                                            value={mt.company}
                                            onChange={(e) =>
                                                handleBookedSeatsChange(
                                                    i,
                                                    "company",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Input
                                            label="Member Position"
                                            placeholder="Type member position"
                                            value={mt.position}
                                            onChange={(e) =>
                                                handleBookedSeatsChange(
                                                    i,
                                                    "position",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Input
                                            label="Email"
                                            placeholder="Type member email"
                                            value={mt.email}
                                            onChange={(e) =>
                                                handleBookedSeatsChange(
                                                    i,
                                                    "email",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {!isAttendeeAdded && (
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() =>
                                                    setBookedSeats(
                                                        bookedSeats.filter(
                                                            (_, index) =>
                                                                i !== index
                                                        )
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => addEmailsToAvailableSeats()}
                    >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </Sidebar>
    );
};

export default BookedTickets;
