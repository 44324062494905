import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    take,
} from "redux-saga/effects";
import membersForm from "../../pages/AddUsers/membersForm";

import {
    newErrorNotificationAction,
    newSuccessNotificationAction,
    errorNotificationFromError,
} from "../notifications/Actions";

import {
    setMembersIsLoadingAction,
    clearMemberDetails,
    getMembersSuccessAction,
    getMembersCountAction,
    getMemberSuccessAction,
    resetMembersAction,
    deleteMemberAction,
    deleteMemberSuccessAction,
    createMemberAction,
    createMemberSuccessAction,
    updateMemberAction,
    updateMemberSuccessAction,
    getPioneersMembersSuccessAction,
} from "./Actions";
import * as api from "./Api";
import { actions } from "./Reducer";

// Plural
function* getMembersSaga({ payload }) {
    const defaultBody = {
        offset: 0,
        size: 6,
        sort: "name",
        dir: "asc",
        searchTerm: "",
        filterBy: [],
    };

    const body = { ...defaultBody, ...payload };

    try {
        yield put(setMembersIsLoadingAction(true));

        const response = yield call(api.getmembers, body);
        const { data } = response;
        const items = data.content;
        const count = data.count;
        yield put(getMembersSuccessAction(items));
        yield put(getMembersCountAction(count));
    } catch (error) {
        // yield put(errorNotificationFromError(error));
    } finally {
        yield put(setMembersIsLoadingAction(false));
    }
}

function* getPioneersMembersSaga({ payload }) {
    const defaultBody = {
        offset: 0,
        size: 6,
        sort: "name",
        dir: "asc",
        searchTerm: "",
        filterBy: [],
    };

    const body = { ...defaultBody, ...payload };

    try {
        yield put(setMembersIsLoadingAction(true));

        const response = yield call(api.getPioneersMembers, body);
        const { data } = response;
        const items = data.content;
        const count = data.count;
        yield put(getPioneersMembersSuccessAction(items));
        yield put(getMembersCountAction(count));
    } catch (error) {
        // yield put(errorNotificationFromError(error));
    } finally {
        yield put(setMembersIsLoadingAction(false));
    }
}

function* getMemberSaga({ payload }) {
    const memberId = payload;
    try {
        //  yield put(resetMembersAction())
        yield put(setMembersIsLoadingAction(true));
        yield put(clearMemberDetails());
        const response = yield call(api.getMember, memberId);
        const { data } = response;

        yield put(getMemberSuccessAction(data));
    } catch (error) {
        // yield put(newErrorNotificationAction("no user found"));
    } finally {
        yield put(setMembersIsLoadingAction(false));
    }
}

function* deleteMemberSaga({ payload }) {
    const memberId = payload;
    try {
        yield put(setMembersIsLoadingAction(true));
        yield call(api.deleteMember, memberId);
        yield put(deleteMemberSuccessAction(memberId));
        yield put(newSuccessNotificationAction("Item deleted."));
        /*     removeFiles(itemId);
         */
    } catch (error) {
        yield put(errorNotificationFromError(error));
    } finally {
        yield put(setMembersIsLoadingAction(false));
    }
}

function* createMemberSaga({ payload }) {
    const memberFormData = payload;
    try {
        yield put(setMembersIsLoadingAction(true));
        const response = yield call(api.postMember, memberFormData);
        const { data } = response;
        const newMember = data;
        yield put(createMemberSuccessAction(newMember));

        /*     const { files } = itemFormData;

    if (files.length > 0) {
      uploadFiles({ id: newItem._id, files });
    } */

        yield put(newSuccessNotificationAction("Created Member"));
        //yield put(push("/members"));
    } catch (error) {
        yield put(errorNotificationFromError(error));
    } finally {
        yield put(setMembersIsLoadingAction(false));
    }
}

function* updateMemberSaga({ payload }) {
    const { memberId, memberFormData } = payload;

    try {
        yield put(setMembersIsLoadingAction(true));
        const response = yield call(api.updateMember, memberId, memberFormData);
        const { data } = response;
        const updatedMember = data;
        yield put(updateMemberSuccessAction(updatedMember));
        yield put(newSuccessNotificationAction("Updated Member"));
        // yield put(push("/items"));
        /* 
    const {files} = itemFormData;
    if (files.length > 0) {
      uploadFiles({id: itemId, files});
    } */
    } catch (error) {
        yield put(errorNotificationFromError(error));
    } finally {
        yield put(setMembersIsLoadingAction(false));
    }
}

export default function* () {
    yield takeLatest(actions.GET_MEMBERS, getMembersSaga);
    yield takeLatest(actions.GET_MEMBER, getMemberSaga);
    yield takeLatest(actions.DELETE_MEMBER, deleteMemberSaga);
    yield takeLatest(actions.CREATE_MEMBER, createMemberSaga);
    yield takeLatest(actions.UPDATE_MEMBER, updateMemberSaga);
    yield takeLatest(actions.GET_PIONEERS_MEMBERS, getPioneersMembersSaga);
}
