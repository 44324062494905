import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    take,
} from "redux-saga/effects";
import membersForm from "../../pages/AddUsers/membersForm";

import {
    newErrorNotificationAction,
    newSuccessNotificationAction,
    errorNotificationFromError,
} from "../notifications/Actions";

import {
    deleteUserSuccessAction,
    createUserSuccessAction,
    getUsersCountAction,
    getUsersSuccessAction,
    getUserSuccessAction,
    setUsersIsLoadingAction,
    updateUserSuccessAction,
    getUsersNoMergeSuccessAction,
} from "./Actions";
import * as api from "./Api";
import { actions } from "./Reducer";

// Plural
function* getUsersSaga({ payload }) {
    const defaultBody = {
        offset: 0,
        size: 6,
        sort: "name",
        dir: "asc",
        searchTerm: "",
        filterBy: [],
    };

    const body = { ...defaultBody, ...payload };

    try {
        yield put(setUsersIsLoadingAction(true));

        const response = yield call(api.getusers, body);
        const { data } = response;
        const items = data.content;
        const count = data.count;
        if (body.searchTerm) {
            yield put(getUsersNoMergeSuccessAction(items));
        } else yield put(getUsersSuccessAction(items));
        yield put(getUsersCountAction(count));
    } catch (error) {
        // yield put(errorNotificationFromError(error));
    } finally {
        yield put(setUsersIsLoadingAction(false));
    }
}

function* getUserSaga({ payload }) {
    const userId = payload;
    try {
        yield put(setUsersIsLoadingAction(true));
        const response = yield call(api.getUser, userId);
        const { data } = response;
        yield put(getUserSuccessAction(data));
    } catch (error) {
        // yield put(newErrorNotificationAction("no user found"));
    } finally {
        yield put(setUsersIsLoadingAction(false));
    }
}

function* deleteUserSaga({ payload }) {
    const userId = payload;
    try {
        yield put(setUsersIsLoadingAction(true));
        yield call(api.deleteUser, userId);
        yield put(deleteUserSuccessAction(userId));
        yield put(newSuccessNotificationAction("user deleted."));
        /*     removeFiles(itemId);
         */
    } catch (error) {
        yield put(errorNotificationFromError(error));
    } finally {
        yield put(setUsersIsLoadingAction(false));
    }
}

function* createUserSaga({ payload }) {
    const userFormData = payload;
    try {
        yield put(setUsersIsLoadingAction(true));
        const response = yield call(api.postUser, userFormData);

        const { data } = response;
        const newUser = data;
        yield put(createUserSuccessAction(newUser));
        yield put(newSuccessNotificationAction("Created User"));
        window.location.pathname = "/dashboard/users";
    } catch (error) {
        console.log({ error });
        yield put(errorNotificationFromError(error));
    } finally {
        yield put(setUsersIsLoadingAction(false));
    }
}

function* updateUserSaga({ payload }) {
    const { userId, userFormData } = payload;

    try {
        yield put(setUsersIsLoadingAction(true));
        const response = yield call(api.updateUser, userId, userFormData);
        const { data } = response;
        const updatedUser = data;
        yield put(updateUserSuccessAction(updatedUser));
        yield put(newSuccessNotificationAction("Updated User"));
        window.location.pathname = "/dashboard/users";
        // yield put(push("/items"));
        /* 
    const {files} = itemFormData;
    if (files.length > 0) {
      uploadFiles({id: itemId, files});
    } */
    } catch (error) {
        yield put(errorNotificationFromError(error));
    } finally {
        yield put(setUsersIsLoadingAction(false));
    }
}

export default function* () {
    yield takeLatest(actions.GET_USERS, getUsersSaga);
    yield takeLatest(actions.GET_USER, getUserSaga);
    yield takeLatest(actions.DELETE_USER, deleteUserSaga);
    yield takeLatest(actions.CREATE_USER, createUserSaga);
    yield takeLatest(actions.UPDATE_USER, updateUserSaga);
}
