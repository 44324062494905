import React from "react";
import {Formik} from "formik";
import P from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import styled from "styled-components";

import {forgetPasswordAction} from "../../modules/auth/Actions";

const Feedback = styled.div`
  display: block;
  margin-top: 0;
  position: absolute;
  top: 100%;
  opacity: 1;
  transition: 0.2s !important;
color : white;
  &:empty {
    opacity: 0;
  }
`;

const LoginForm = props => {
  const {forgetPassword} = props;

  return (
    <div>
         <div className='title'>
           <h1>Forgot Password ?</h1>
        </div>
        <div className='kt-login__desc'>
          Enter your email to reset your password:
        </div>
     
      <Formik
        initialValues={{
          email: ""
        }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid Email Address";
          }
          return errors;
        }}
        onSubmit={async ({email}) => {
          forgetPassword(email);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched
        }) => (
          <form  onSubmit={handleSubmit}>
            <div className='input-group mb-5'>
              <input
                className='form-control'
                placeholder='Email'
                name='email'
                id='kt_email'
                autoComplete='off'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && (
                <Feedback className='invalid-feedback'>{errors.email}</Feedback>
              )}
            </div>
            <div className="row d-flex justify-content-center">
            
              <button
                id=''
                className='btn btn-primary searchAction col-sm-12 col-md-7 w-auto'
               
                type='submit'
              >
                Request
              </button>
              &nbsp;&nbsp;
              <Link
                to='/login'
               
                className='btn btn-secondary searchAction col-sm-12 col-md-3 mt-3 mt-md-0 w-auto'
              >
                Cancel
              </Link>
            </div>
        
           
          </form>
        )}
      </Formik>
    </div>
  );
};

LoginForm.propTypes = {
  forgetPassword: P.func.isRequired
};

const mapDispatchToProps = {
  forgetPassword: forgetPasswordAction
};

export default connect(null, mapDispatchToProps)(LoginForm);
