import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { getMemberAction, getMembersAction, getMembersSearchAction } from '../../modules/members/Actions'
import { memberSelector, membersSelector } from '../../modules/members/Selectors'
import Sidebar from "../../components/AdminSideBar/sidebar";
import AdsList from './adsList'
import { adsIsLoadingSelector, adsSelector } from "../../modules/ads/Selectors";
import { deleteAdAction, getAdsAction } from "../../modules/ads/Actions";
import { Formik } from "formik";
import SelectInput from "../../components/Form/inputs/selsect";
import { membersSearchAsNameAndIdSelector } from "../../modules/search/Selectors";
import AdsForm from "./adsForm";
import { Link } from "react-router-dom";
import queryString from 'query-string';

const MemberAds = (props) => {
  const MemberId = props.match.params.id;
  console.log(MemberId, 'MemberId')
  const { ads, getAds, isLoading, mappedMembers, member, deleteAd, getMember } = props
  const [blockId, setBlockId] = useState('')
  const [locationId, setLcationId] = useState('')
  const [selectedBlock, setSelectedBlock] = useState(null)

  const handelChangeLocation = (e) => {
    setBlockId(e)
    setSelectedBlock(e)

  }


  useEffect(() => {
    if (member?.memberId && blockId) getAds({ filterBy: [{ blockId: blockId }, { locationId: member?.memberId }] })

  }, [member?.memberId, blockId])

  useEffect(() => {
    getMember(MemberId)
  }, [MemberId])

  const handelBack = () => {
    window.location.replace('/dashboard/advertisments')
  }



  const Ads = [
    { value: 'MEMBER_BLOCK_1', label: 'Top ad' },
    { value: 'MEMBER_BLOCK_2', label: 'First Right Side ad' },
    { value: 'MEMBER_BLOCK_3', label: 'Second Right Side  ad' },
    { value: 'MEMBER_BLOCK_4', label: 'Bottom  ad' }

  ]
  const parsed = queryString.parse(window.location.search);

  console.log(parsed)

  return (
    <Sidebar>
      <div className="container mt-5">
        <div onClick={handelBack} style={{ fontSize: '20px', fontWeight: '500', marginBottom: '30px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <em className="fad fa-arrow-left"></em>
          <p style={{ marginLeft: '5px', marginBottom: '0' }}>Back</p>
        </div>
        <div className="row"
          style={{
            flexDirection: 'column',
            float: 'right',
            marginTop: '195px'
          }}>
          {Ads.map((adBlock) => {
            return <Link to={{
              pathname: window.location.pathname,
              search: `?block=${adBlock.label}`
            }}
              style={{
                textDecoration: 'none'
              }}>

              <div
                className="col-4 mb-5 mx-5"
                onClick={() => handelChangeLocation(adBlock.value)}
                style={{
                  border: '1px solid #80808026',
                  background: parsed.block == adBlock.label ? '#0A3D80' : 'white',
                  width: '175px',
                  height: '55px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',
                  fontSize: '15px',
                  fontWeight: '500',
                  textDecoration: 'none',
                  color: parsed.block == adBlock.label ? 'white' : '#0A3D80',
                  cursor: 'pointer',




                }}>{adBlock.label}</div>
            </Link>

          })}

        </div>




        <div
          className=' lazyload d-flex  align-items-center'
          data-bg='assets/images/avatar.png'
        >
          <img
            className='avatar rounded-circle'
            src={
              `http://www.eba.org.eg/mem_photo/${member?.photo?.split('\\')[2].split('.')[0]}.jpg`
            }
            style={{ width: '160px', height: "160px", marginBottom: "30px", marginInline: '40px' }}
          />
          <div>
            <h5>{member?.title} : {member?.name}</h5>
            <h5>{member?.position} - {member?.company?.name}</h5>
          </div>
        </div>


        {selectedBlock ?
          ads?.length == 0 ?
            <AdsForm data={{ location: 'member', locationId: member?.memberId, blockId: blockId, addLink: '', targetUrl: '', asset: '' }} />
            :
            <AdsForm
              data={ads[0]}
              deleteAd={deleteAd}
              adId={ads[0]?._id}
            />
          : null
        }


      </div>
    </Sidebar>
  )
}

const mapStateToProps = (state, props) => {
  return ({
    ads: adsSelector(state),
    members: membersSelector(state),
    isLoading: adsIsLoadingSelector(state),
    mappedMembers: membersSearchAsNameAndIdSelector(state),
    member: memberSelector(state)

  })
}
const mapDispatchToProps = {
  getAds: getAdsAction,
  getMembers: getMembersAction,
  getMemberssearch: getMembersSearchAction,
  getMember: getMemberAction,
  deleteAd: deleteAdAction,

}

export default connect(mapStateToProps, mapDispatchToProps)(MemberAds);
