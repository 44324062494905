import produce from "immer";
import mergeById from "../../helpers/mergeById";



export const actions = {
  GET_INDUSTRIES : " GET_INDUSTRIES",
  SET_INDUSTRIES_SEARCH_VALUE : "SET_INDUSTRIES_SEARCH_VALUE",
  SET_INDUSTRIES_IS_LOADING : "SET_INDUSTRIES_IS_LOADING",
  GET_INDUSTRIES_SUCCESS : "GET_INDUSTRIES_SUCCESS",
  GET_INDUSTRIES_COUNT : "GET_INDUSTRIES_COUNT",
  GET_INDUSTRY : "GET_INDUSTRY",
  GET_INDUSTRY_SUCCESS : "GET_INDUSTRY_SUCCESS",
  RESET_INDUSTRIES : "RESET_INDUSTRIES"
};

const initialState = {
  industries: [],
  loadedIndustries : [] ,
  isLoading: false,
  count: 0,
  searchValue: "",
 
};


export default produce((draft, action) => {
  const {type, payload} = action;
  if (!draft) {
    return initialState;
  }

  switch (type) {
    case actions.SET_INDUSTRIES_SEARCH_VALUE: {
      draft.industries = [];
      draft.searchValue = payload;
      break;
    }
    case actions.GET_INDUSTRIES_SUCCESS: {
      const currentIndustry = draft.industries;
      const industries = payload;
     // draft.sectors = sectors
      draft.industries = mergeById(currentIndustry, industries);
      break;
    }

    case actions.GET_INDUSTRY_SUCCESS: {
      const currentIndustry = draft.industries;
      const industries = payload;
      draft.sector = mergeById(currentIndustry, industries);
      break;
    }

   
    case actions.SET_INDUSTRIES_IS_LOADING: {
      draft.isLoading = payload;
      break;
    }
    case actions.GET_INDUSTRIES_COUNT: {
      draft.count = payload;
      break;
    }
    case actions.RESET_INDUSTRIES: {
      draft.industries = [];
      break;
    }
    default:
      break;
  }
});
