import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import api from "../../helpers/api";
import Input from "../../components/events/Input";
import { useToasts } from "react-toast-notifications";
import Cookies from "js-cookie";

const LoginModal = ({
    isShow,
    setIsShow,
    setIsCreateAccount,
    selectedTicket,
}) => {
    const { addToast } = useToasts();
    const [values, setValues] = useState({ username: "", password: "" });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleLoginAccount = async () => {
        try {
            const { data } = await api.post(`/auth/signin`, {
                ...values,
            });

            localStorage.setItem("role", data.user.role);
            localStorage.setItem("username", data.user.username);
            Cookies.set("userID", data?._id, { expires: 1 });
            Cookies.set("TOKEN", data?.token, { expires: 1 });
            window.location.reload();
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    return (
        <>
            <Modal
                show={isShow}
                onHide={() => setIsShow(false)}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Login to your account firstly</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-4">
                        <Input
                            value={values.username}
                            type="text"
                            label="Username"
                            name="username"
                            onChange={handleInputChange}
                        />
                        <Input
                            value={values.password}
                            type="password"
                            label="Password"
                            name="password"
                            onChange={handleInputChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsShow(false)}
                    >
                        Close
                    </Button>
                    {selectedTicket?.type === "Member" ? null : (
                        <Button
                            onClick={() => {
                                setIsCreateAccount(true);
                                setIsShow(false);
                            }}
                        >
                            Create New Account
                        </Button>
                    )}
                    <Button
                        disabled={!values.username || !values.password}
                        onClick={handleLoginAccount}
                    >
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LoginModal;
