import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    getActivitesAction,
    getActivitesHeaderAction,
} from "../../modules/activites/Actions";
import {
    activitesHeaderSelector,
    activitesSelector,
} from "../../modules/activites/Selector";
import { getSectorsAction } from "../../modules/sectors/Actions";
import { sectorsSelector } from "../../modules/sectors/Selector";
import {
    getsubActivitesAction,
    getsubActivitesHeaderAction,
} from "../../modules/subActivity/Actions";
import {
    subactivitesHeaderSelector,
    subactivitesSelector,
} from "../../modules/subActivity/Selector";
import ShadowHead from "../Heads/ShadowHead";
import queryString from "query-string";
import { Link } from "react-router-dom";

const MembersHeader = (props) => {
    const parsed = queryString.parse(window.location.search);

    const {
        headerData,
        setHeaderData,
        memberTypes,
        getSectors,
        getMemberActivites,
        activites,
        sectors,
        getMemberSubActivites,
        subActivites,
        alpha,
    } = props;
    const [subactivitylabel, setSubActivityLabel] = useState("Select");
    const [activitylabel, setActivityLabel] = useState("Select");
    const [sectorlabel, setSectorLabel] = useState("Select");
    const [sectorActivity, setSectorActivity] = useState(Number(parsed.sector));
    // const [sectorSubActivity , setSectorSubActivity] = useState(0)

    useEffect(() => {
        if (parsed?.sector)
            getMemberActivites({
                filterBy: [{ secId: Number(parsed.sector) }],
            });
        if (parsed?.activity)
            getMemberSubActivites({
                filterBy: [
                    { actId: Number(parsed.activity) },
                    { secId: Number(parsed.sector) },
                ],
            });
    }, []);

    useEffect(() => {
        if (sectorActivity)
            getMemberActivites({ filterBy: [{ secId: sectorActivity }] });
        setHeaderData({ ...headerData, sector: sectorActivity });
    }, [sectorActivity]);

    useEffect(() => {
        if (headerData?.memberActivity)
            getMemberSubActivites({
                filterBy: [
                    { actId: headerData?.memberActivity?.actId },
                    { secId: sectorActivity },
                ],
            });
    }, [headerData]);

    let secValue = sectors.filter((sec) => sec.secId == parsed.sector);
    let actValue = activites.filter((sec) => sec.actId == parsed.activity);
    let subValue = subActivites.filter((sec) => sec._id == parsed.subActivity);

    useEffect(() => {
        if (secValue.length != 0) {
            setSectorLabel(secValue[0]?.secENm);
        }
        if (actValue.length != 0) {
            setActivityLabel(actValue[0]?.actENm);
        }
        if (subValue.length != 0) {
            setSubActivityLabel(subValue[0]?.subacT_E_NM);
        }
    }, [secValue, actValue, subValue]);

    return (
        <div className="header--forList">
            <div className="row">
                <div className="col">
                    <ShadowHead header="EBA Members" />
                </div>

                <div
                    className="col-12 col-md-2 mt-4 mt-md-0 d-flex mx-2"
                    style={{ width: "auto" }}
                >
                    <div className="options ms-auto my-auto">
                        <div className="option">
                            <div className="header">Member Type</div>
                            <div className="dropdown with-toggler bg-light">
                                <a
                                    href
                                    onClick={() => {
                                        setHeaderData({
                                            ...headerData,
                                            dir: !headerData.dir,
                                        });
                                    }}
                                    className="toggler"
                                    style={{ cursor: "pointer" }}
                                >
                                    <em
                                        className={`fad fa-long-arrow-alt-${
                                            headerData.dir ? "down" : "up"
                                        }`}
                                    ></em>
                                </a>
                                <button
                                    className="btn dropdown-toggle"
                                    type="button"
                                    id="sortBy"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {headerData.memberType.label}
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="sortBy"
                                >
                                    {memberTypes.map((member, index) => (
                                        <li
                                            style={{ cursor: "pointer" }}
                                            key={index}
                                            onClick={() =>
                                                setHeaderData({
                                                    ...headerData,
                                                    memberType: member,
                                                })
                                            }
                                        >
                                            <a className="dropdown-item" href>
                                                {member.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="col-12 col-md-2 mt-4 mt-md-0 d-flex mx-2"
                    style={{ width: "auto" }}
                >
                    <div className="options ms-auto my-auto">
                        <div className="option">
                            <div className="header">Member Sector</div>
                            <div className="dropdown with-toggler  bg-light">
                                <button
                                    className="btn dropdown-toggle"
                                    type="button"
                                    id="sortBy"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => getSectors()}
                                >
                                    {sectorlabel}
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="sortBy"
                                >
                                    <li
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setHeaderData({
                                                ...headerData,
                                                memberSectors: "",
                                                memberActivity: "",
                                                memberSubActivity: "",
                                            });
                                            setSectorLabel("Select");
                                        }}
                                    >
                                        <Link
                                            className="dropdown-item"
                                            to={{ search: "" }}
                                        >
                                            select
                                        </Link>
                                    </li>
                                    {sectors?.map((sector, index) => (
                                        <li
                                            style={{ cursor: "pointer" }}
                                            key={index}
                                            onClick={() => {
                                                setSectorLabel(sector.secENm);
                                                setSectorActivity(sector.secId);
                                                setHeaderData({
                                                    ...headerData,
                                                    memberActivity: "",
                                                    memberSubActivity: "",
                                                });
                                                setActivityLabel("Select");
                                                setSubActivityLabel("Select");
                                            }}
                                        >
                                            <Link
                                                className="dropdown-item"
                                                to={{
                                                    search: `?sector=${sector.secId}&searchTerm=&activity=&subActivity=&letter=${alpha}`,
                                                }}
                                            >
                                                {sector.secENm}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {headerData.memberSectors ||
                    (sectorlabel !== "Select" && (
                        <div
                            style={{ width: "auto" }}
                            className="col-12 col-md-2 mt-4 mt-md-0 d-flex mx-2"
                        >
                            <div className="options ms-auto my-auto">
                                <div className="option">
                                    <div className="header">
                                        Member Activity
                                    </div>
                                    <div className="dropdown with-toggler  bg-light">
                                        <button
                                            className="btn dropdown-toggle"
                                            type="button"
                                            id="sortBy"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {activitylabel}
                                        </button>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="sortBy"
                                        >
                                            <li
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setHeaderData({
                                                        ...headerData,
                                                        memberActivity: "",
                                                    });
                                                    setActivityLabel("Select");
                                                }}
                                            >
                                                <a
                                                    className="dropdown-item"
                                                    href
                                                >
                                                    Select
                                                </a>
                                            </li>
                                            {activites.map(
                                                (activity, index) => (
                                                    <li
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        key={index}
                                                        onClick={() => {
                                                            setHeaderData({
                                                                ...headerData,
                                                                memberActivity: activity,
                                                            });
                                                            setActivityLabel(
                                                                activity.actENm
                                                            );
                                                            setSubActivityLabel(
                                                                "Select"
                                                            );
                                                        }}
                                                    >
                                                        <Link
                                                            className="dropdown-item"
                                                            to={{
                                                                search: `?sector=${parsed.sector}&activity=${activity.actId}&searchTerm=&subactivity=&letter=${alpha}`,
                                                            }}
                                                        >
                                                            {activity.actENm}
                                                        </Link>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                {headerData.memberActivity ||
                subactivitylabel !== "Select" ||
                parsed.activity ? (
                    <div
                        style={{ width: "auto" }}
                        className="col-12 col-md-2 mt-4 mt-md-0 d-flex mx-2"
                    >
                        <div className="options ms-auto my-auto">
                            <div className="option">
                                <div className="header">
                                    Member Sub Activity
                                </div>
                                <div className="dropdown with-toggler  bg-light">
                                    <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        id="sortBy"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {subactivitylabel}
                                    </button>
                                    <ul
                                        className="dropdown-menu"
                                        aria-labelledby="sortBy"
                                    >
                                        <li
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setHeaderData({
                                                    ...headerData,
                                                    memberSubActivity: "",
                                                });
                                                setSubActivityLabel("Select");
                                            }}
                                        >
                                            <a className="dropdown-item" href>
                                                Select
                                            </a>
                                        </li>
                                        {subActivites.map(
                                            (subactivity, index) => (
                                                <li
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    key={index}
                                                    onClick={() => {
                                                        setHeaderData({
                                                            ...headerData,
                                                            memberSubActivity: subactivity,
                                                        });
                                                        setSubActivityLabel(
                                                            subactivity.subacT_E_NM
                                                        );
                                                    }}
                                                >
                                                    <Link
                                                        className="dropdown-item"
                                                        to={{
                                                            search: `?sector=${parsed.sector}&activity=${parsed.activity}&subActivity=${subactivity?._id}&searchTerm=&letter=${alpha}`,
                                                        }}
                                                    >
                                                        {
                                                            subactivity.subacT_E_NM
                                                        }
                                                    </Link>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

const mapStateTprops = (state) => ({
    activites: activitesHeaderSelector(state),
    subActivites: subactivitesHeaderSelector(state),
    sectors: sectorsSelector(state),
});

const mapDispatchToProps = {
    getMemberActivites: getActivitesHeaderAction,
    getMemberSubActivites: getsubActivitesHeaderAction,

    getSectors: getSectorsAction,
};
export default connect(mapStateTprops, mapDispatchToProps)(MembersHeader);
