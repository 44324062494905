import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import api from "../../helpers/api";
import Input from "../../components/events/Input";
import { useToasts } from "react-toast-notifications";
import FileInput from "../../components/events/FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import Cookies from "js-cookie";

const CreateNewAccount = ({ isShow, setIsShow, setIsLoginAccount }) => {
    const { addToast } = useToasts();
    const [values, setValues] = useState({ username: "", password: "" });
    const [userInfo, setUserInfo] = useState({
        title: "",
        name: "",
        businessPhone_1: "",
        businessPhone_2: "",
        businessFax_1: "",
        businessFax_2: "",
        mobile: "",
        email: "",
        address: "",
        photo: "",
        position: "",
        type: "",
        company: {
            name: "",
            bio: "",
            email: "",
            website: "",
            address: "",
            phone_1: "",
            phone_2: "",
            phone_3: "",
            fax_1: "",
            fax_2: "",
            fax_3: "",
        },
        video: "",
        councils: "",
        committees: "",
    });
    const [isUpdateMe, setIsUpdateMe] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleUserChange = (event) => {
        const { name, value } = event.target;
        // If the changed field is within the 'company' object
        if (name.startsWith("company.")) {
            // Extract the field name from 'name'
            const companyFieldName = name.split(".")[1];

            // Update the 'company' object within the state
            setUserInfo((prevState) => ({
                ...prevState,
                company: {
                    ...prevState.company,
                    [companyFieldName]: value,
                },
            }));
        } else {
            // If it's not within the 'company' object, update normally
            setUserInfo((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleCreateAccount = async () => {
        try {
            const { data } = await api.post(`/auth/signup`, {
                ...values,
                role: "non_member",
                expireAt: "2025-04-02T00:44:46.934Z",
            });

            localStorage.setItem("role", "non_member");
            localStorage.setItem("username", values.username);
            Cookies.set("userID", data?._id, { expires: 1 });
            Cookies.set("TOKEN", data?.token, { expires: 1 });
            // window.location.reload();
            setIsShow(false);
            setIsUpdateMe(true);
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const updateUser = async () => {
        try {
            await api.put(`/users/non-member/me`, {
                ...userInfo,
            });

            window.location.reload();
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const handleUpload = async (formData, property) => {
        try {
            const res = await api.post("/uploads", formData);

            console.log({ res });
            setUserInfo((prev) => ({ ...prev, photo: res?.data?.url }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileChange = async (event, property) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("file", file);

        await handleUpload(formData, property);
    };

    console.log({ userInfo });

    return (
        <>
            <Modal
                show={isShow}
                onHide={() => setIsShow(false)}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-4">
                        <Input
                            value={values.username}
                            type="text"
                            label="Username"
                            name="username"
                            onChange={handleInputChange}
                        />
                        <Input
                            value={values.password}
                            type="password"
                            label="Password"
                            name="password"
                            onChange={handleInputChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsShow(false)}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            setIsLoginAccount(true);
                            setIsShow(false);
                        }}
                    >
                        Login
                    </Button>
                    <Button
                        disabled={!values.username || !values.password}
                        onClick={handleCreateAccount}
                    >
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={isUpdateMe}
                onHide={() => setIsUpdateMe(false)}
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update your info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-4">
                        <Input
                            value={userInfo.title}
                            type="text"
                            label="Title"
                            name="title"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.name}
                            type="text"
                            label="Name"
                            name="name"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.businessPhone_1}
                            type="text"
                            label="Bussiness Phone 1"
                            name="businessPhone_1"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.businessPhone_2}
                            type="text"
                            label="Bussiness Phone 2"
                            name="businessPhone_2"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.businessFax_1}
                            type="text"
                            label="Bussiness Fax 1"
                            name="businessFax_1"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.businessFax_2}
                            type="text"
                            label="Bussiness Fax 2"
                            name="businessFax_2"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.mobile}
                            type="text"
                            label="Mobile"
                            name="mobile"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.email}
                            type="email"
                            label="Email"
                            name="email"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.address}
                            type="text"
                            label="Address"
                            name="address"
                            onChange={handleUserChange}
                        />
                        <FileInput
                            handleChange={handleFileChange}
                            title="User Photo"
                            icon={
                                <IoMdCloudUpload
                                    color="rgba(156, 163, 175, 1)"
                                    fontSize="40px"
                                />
                            }
                            description1="Click to upload or drag and drop"
                            description2="Max. File Size: 30MB"
                            imageSrc={userInfo.photo || ""}
                        />
                        <Input
                            value={userInfo.position}
                            type="text"
                            label="Position"
                            name="position"
                            onChange={handleUserChange}
                        />
                        <div className="d-flex flex-column gap-2">
                            <label>Type</label>
                            <select
                                value={userInfo.type}
                                onChange={(e) =>
                                    setUserInfo((prev) => ({
                                        ...prev,
                                        type: e.target.value,
                                    }))
                                }
                                defaultValue="individual"
                            >
                                <option value="individual">Individual</option>
                                <option value="corporate">Corporate</option>
                            </select>
                        </div>
                        <h6>Company Info</h6>
                        <Input
                            value={userInfo.company.name}
                            type="text"
                            label="Company Name"
                            name="company.name"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.company.bio}
                            type="text"
                            label="Company Bio"
                            name="company.bio"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.company.email}
                            type="text"
                            label="Company Email"
                            name="company.email"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.company.website}
                            type="text"
                            label="Company Website"
                            name="company.website"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.company.address}
                            type="text"
                            label="Company Address"
                            name="company.address"
                            onChange={handleUserChange}
                        />
                        <Input
                            value={userInfo.company.phone_1}
                            type="text"
                            label="Company Phone"
                            name="company.phone_1"
                            onChange={handleUserChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsShow(false)}
                    >
                        Close
                    </Button>
                    <Button onClick={updateUser}>Update</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateNewAccount;
