import React, { useRef } from "react";
import { Button } from "react-bootstrap";

const FileInput = ({
    handleChange,
    title,
    icon,
    description1,
    description2,
    description3,
    imageSrc,
    fileSrc,
}) => {
    const ref = useRef(null);

    return (
        <div>
            <input
                type="file"
                ref={ref}
                onChange={handleChange}
                style={{ display: "none" }}
            />
            <div
                onClick={() => ref?.current?.click()}
                className="position-relative"
                style={{
                    borderRadius: "20px",
                    border: "1px solid rgba(104, 104, 104, 1)",
                    color: "rgba(104, 104, 104, 1)",
                    height: "240px",
                }}
            >
                <h6
                    className="position-absolute"
                    style={{
                        marginTop: "-12px",
                        marginLeft: "30px",
                        backgroundColor: "#f5f5f5",
                        padding: "0 10px",
                        fontWeight: "normal",
                        color: "#000",
                    }}
                >
                    {title}
                </h6>
                {imageSrc ? (
                    <div>
                        <img
                            alt=""
                            src={imageSrc}
                            width="100%"
                            height="238px"
                            style={{
                                objectFit: "contain",
                                borderRadius: "20px",
                                backgroundPosition: "center",
                            }}
                        />
                    </div>
                ) : fileSrc ? (
                    <div
                        className="d-flex flex-column gap-2 align-items-center justify-content-center w-full"
                        style={{ height: "100%" }}
                    >
                        <Button onClick={() => window.open(fileSrc)}>
                            Go To File
                        </Button>
                    </div>
                ) : (
                    <div
                        className="d-flex flex-column gap-2 align-items-center justify-content-center w-full"
                        style={{ height: "100%" }}
                    >
                        {icon}
                        <p className="m-0">{description1}</p>
                        <p className="m-0">{description2}</p>
                        <p className="m-0">{description3}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileInput;
