import React from "react";
import Image from "../../assets/images/pic.png";

const Footer = (props) => {
    return (
        <footer
            style={{
                color: "#071C3F",
            }}
        >
            {/* <!-- Background --> */}
            <div className="background-container"></div>
            {/* <!-- Content --> */}
            <div className="container text-center">
                <div className="siteInfo d-flex justify-content-between">
                    {/* <!-- Site Logo --> */}
                    <div className="logo ">
                        <img
                            src={Image}
                            alt="logo"
                            style={{ width: "25rem" }}
                        />
                    </div>
                    <div>
                        <div
                            className="big"
                            style={{ color: "#082244", fontWeight: "500" }}
                        >
                            EBA Members’ Directory
                        </div>
                        <p>
                            EBA Members’ directory includes complete
                            <br /> data about its members and their companies
                            filtered through <br />
                            different search criteria (member name, company
                            name, sector…)
                        </p>

                        <div className="onSocialMedia my-5">
                            <ul>
                                <li>
                                    <a
                                        href="https://web.facebook.com/EBA.Page?_rdc=1&_rdr"
                                        className="facebook"
                                        target="_blank"
                                    >
                                        <em className="fab fa-facebook-f text-light"></em>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://twitter.com/EBA_Egypt"
                                        className="twitter"
                                        target="_blank"
                                    >
                                        <em className="fab fa-twitter text-light"></em>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.linkedin.com/groups/3790127/"
                                        className="linkedin"
                                        target="_blank"
                                    >
                                        <em className="fab fa-linkedin-in text-light"></em>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/egyptianbusinessmen/"
                                        className="instagram"
                                        target="_blank"
                                    >
                                        <em className="fab fa-instagram text-light"></em>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/channel/UCkcf3Ylsss8AtP7HlUzDDMw"
                                        className="youtube"
                                        target="_blank"
                                    >
                                        <em className="fab fa-youtube text-light"></em>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=systems.excel.eba&hl=en_US&gl=EG"
                                        className="android"
                                        target="_blank"
                                    >
                                        <em className="fab fa-android text-light"></em>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://apps.apple.com/eg/app/egyptian-business-association/id1545107745"
                                        className="apple"
                                        target="_blank"
                                    >
                                        <em className="fab fa-apple text-light"></em>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <!-- On Social Media --> */}

                {/* <!-- Copyrights --> */}
                <div className="copyrights fw-bolder">
                    <div>© COPYRIGHT {new Date().getFullYear()}</div>
                    <div>
                        Egyptian Businessmen’s Association - EBA. All Rights
                        Reserved.
                    </div>
                    <div className="mt-3 " style={{ fontWeight: "bold" }}>
                        Technology Partner -
                        <a
                            target="_blank"
                            href="https://www.pharaohsoft.com"
                            style={{ fontWeight: "bold" }}
                            rel="noreferrer"
                        >
                            Pharaoh Soft LTD
                        </a>
                    </div>
                </div>
            </div>

            {/* <!-- Back to Top --> */}
            <button id="backToTop" className="btn with-icon" type="button">
                <div className="icon">
                    <em className="fad fa-long-arrow-alt-up"></em>
                </div>
                Back to Top
            </button>
        </footer>
    );
};

export default Footer;
