import {actions} from "./Reducer";



export const loginUserAction = formData => ({
  type: actions.LOGIN_USER,
  payload: formData
});

export const logoutUserAction = () => ({
  type: actions.LOGOUT_USER
});

export const loginUserSuccessAction = (user, token, sl) => ({
  type: actions.LOGIN_USER_SUCCESS,
  payload: {user, token, sl}
});

export const logoutUserSuccessAction = () => ({
  type: actions.LOGOUT_USER_SUCCESS
});



export const updateMyProfileAction = formData => ({
  type: actions.UPDATE_PROFILE,
  payload: formData
});

export const updateMyProfileSuccessAction = userData => ({
  type: actions.UPDATE_PROFILE_SUCCESS,
  payload: userData
});

export const forgetPasswordAction = email => ({
  type: actions.FORGET_PASSWORD,
  payload: {
    email
  }
});
/**
 * Misc
 */

export const setAuthIsLoadingAction = isLoading => ({
  type: actions.SET_AUTH_IS_LOADING,
  payload: isLoading
});
