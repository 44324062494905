import { call, put, takeLatest } from "redux-saga/effects";
import { TOKEN } from "../../constants";
import { actions as globalActions } from "../../services/globalActions";
import {
    errorNotificationFromError,
    newSuccessNotificationAction,
    newErrorNotificationAction,
} from "../notifications/Actions";
import {
    loginUserSuccessAction,
    logoutUserAction,
    logoutUserSuccessAction,
    setAuthIsLoadingAction,
    updateMyProfileSuccessAction,
} from "./Actions";
import * as api from "./Api";
import { actions } from "./Reducer";
import {
    getCookie,
    deleteCookie,
    allCookieList,
    setCookie,
} from "../../helpers/cookies";

import Cookie from "js-cookie";

function* loginUser({ payload }) {
    try {
        const formData = payload;
        yield put(setAuthIsLoadingAction(true));
        const response = yield call(api.Login, formData);
        const { data } = response;

        const { user, token: accessToken } = data;

        yield localStorage.setItem("role", data.user.role);
        yield localStorage.setItem("username", data.user.username);

        if (payload.remember == true) {
            Cookie.set("TOKEN", accessToken, { expires: 7 });
            Cookie.set("userRole", data.user.role, { expires: 7 });
            Cookie.set("userID", data.user._id, { expires: 7 });
        } else {
            Cookie.set("TOKEN", accessToken, { expires: 1 });
            Cookie.set("userRole", data.user.role, { expires: 1 });
            Cookie.set("userID", data.user._id, { expires: 1 });
        }
        Cookie.set("user_info", data);

        yield put(loginUserSuccessAction(user, accessToken));
    } catch (error) {
        yield put(errorNotificationFromError(error));
    } finally {
        yield put(setAuthIsLoadingAction(false));
    }
}

function* logoutUser() {
    try {
        Cookie.remove("TOKEN");
        Cookie.remove("userRole");
        Cookie.remove("userID");
        Cookie.remove("user_info");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("username");

        yield call(api.logout());
        yield put(logoutUserSuccessAction());
    } catch (error) {
        //  yield put(errorNotificationFromError(error));
    } finally {
        // yield put(newSuccessNotificationAction("Goodbye!"));
    }
}

function* forgetPassword({ payload }) {
    try {
        const { email } = payload;
        yield put(setAuthIsLoadingAction(true));

        yield call(api.forgetPassword, { email });

        yield put(
            newSuccessNotificationAction(
                "An email has been sent to your account to reset the password"
            )
        );
    } catch (error) {
        yield put(newErrorNotificationAction("this Email not found"));
    } finally {
        yield put(setAuthIsLoadingAction(false));
    }
}

function* updateMyProfile({ payload }) {
    try {
        yield put(setAuthIsLoadingAction(true));
        const response = yield call(api.updateMyProfile, payload);
        const { data } = response;
        yield put(updateMyProfileSuccessAction(data));
    } catch (error) {
        // yield put(errorNotificationFromError(error));
    } finally {
        yield put(setAuthIsLoadingAction(false));
    }
}

function* loadTokenFromStorage() {
    const token = Cookie.get("TOKEN");

    const cachedToken = token;
    try {
        yield put(cachedToken);
        yield put(setAuthIsLoadingAction(true));
        const response = yield call(api.getCurrentUser);
        const { data } = response;
        yield put(loginUserSuccessAction(data, token));
    } catch (error) {
        if (
            error.response &&
            error.response.status &&
            error.response.status === 401 &&
            cachedToken
        ) {
            // yield put(errorNotificationFromError(error));
            yield put(logoutUserAction());
        }
    } finally {
        yield put(setAuthIsLoadingAction(false));
    }
}

export default function* () {
    yield takeLatest(actions.LOGIN_USER, loginUser);
    yield takeLatest(actions.LOGOUT_USER, logoutUser);
    yield takeLatest(actions.FORGET_PASSWORD, forgetPassword);
    yield takeLatest(actions.UPDATE_PROFILE, updateMyProfile);
    yield takeLatest(globalActions.APP_READY, loadTokenFromStorage);
}
