import {actions} from "./Reducer";


export const createMemberAction = (formData) =>  ({
  type: actions.CREATE_MEMBER,
  payload: formData,
});

export const createMemberSuccessAction = (Member) => ({
  type: actions.CREATE_MEMBER_SUCCESS,
  payload: Member,
});


export const getMemberAction = (id) => ({
  type: actions.GET_MEMBER,
  payload: id,
});

export const getMemberSuccessAction = (member) => ({
  type: actions.GET_MEMBER_SUCCESS,
  payload: member,
});

export const updateMemberAction = (memberId, memberFormData) => ({
  type: actions.UPDATE_MEMBER,
  payload: {
    memberId,
    memberFormData,
  },
});

export const updateMemberSuccessAction = (member) => ({
  type: actions.UPDATE_MEMBER_SUCCESS,
  payload: member,
});

export const getMembersAction = (payload) => ({
  type: actions.GET_MEMBERS,
  payload,
});

export const getMembersSuccessAction = (members) => ({
  type: actions.GET_MEMBERS_SUCCESS,
  payload: members,
});
export const getPioneersMembersAction = (payload) => ({
  type: actions.GET_PIONEERS_MEMBERS,
  payload,
});

export const getPioneersMembersSuccessAction = (members) => ({
  type: actions.GET_PIONEERS_MEMBERS_SUCCESS,
  payload: members,
});



export const setSearchValueAction = (value) => ({
  type: actions.SET_MEMBERS_SEARCH_VALUE,
  payload: value,
});

export const setMembersIsLoadingAction = (isLoading) => ({
  type: actions.SET_MEMBERS_IS_LOADING,
  payload: isLoading,
});

export const getMembersCountAction = (count) => ({
  type: actions.GET_MEMBERS_COUNT,
  payload: count,
});

export const resetMembersAction = () => ({
  type: actions.RESET_MEMBERS,
});

export const deleteMemberAction = (id) =>({
  type : actions.DELETE_MEMBER,
  payload : id
})

export const deleteMemberSuccessAction = (memberId) => ({
  type: actions.DELETE_MEMBER_SUCCESS,
  payload: memberId,
});


export const clearMemberDetails = () =>({
  type : actions.CLEAR_MEMBER_DETAILS
})