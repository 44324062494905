import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    Image,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import api from "../../../helpers/api";
import FileInput from "../../../components/events/FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import Input from "../../../components/events/Input";
import InputSelect from "../../../components/events/InputSelect";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const DelegationsPage = () => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [delegationList, setDelegationList] = useState({
        content: [],
        count: 0,
    });
    const [selectedDelegation, setSelectedDelegation] = useState();
    const [delegation, setDelegation] = useState({
        username: "",
        password: "",
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [offset, setOffset] = useState(0);
    const [size, setSize] = useState(30);
    const [hasMore, setHasMore] = useState(true);
    const [eventsList, setEventsList] = useState({ count: 0, content: [] });
    const [selectedEventId, setSelectedEventId] = useState("");
    const [selectedSearchEvent, setSelectedSearchEvent] = useState("");

    const getDelegations = async () => {
        try {
            const { data } = await api.post("users/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
                searchTerm,
                filterBy: selectedSearchEvent
                    ? [
                          { "events._id": selectedSearchEvent },
                          { role: "Delegation" },
                      ]
                    : [{ role: "Delegation" }],
            });

            // setDelegationList(data);

            if (searchTerm || selectedSearchEvent) {
                setDelegationList((prev) => ({
                    content: data?.content,
                    count: prev.count,
                }));
            } else {
                setDelegationList((prev) => ({
                    content: [...prev.content, ...data?.content],
                    count: prev.count,
                }));
            }

            if (
                data?.content?.length === 0 ||
                data?.content?.length >= data?.count
            ) {
                setHasMore(false);
            }

            setOffset((prev) => prev + 10);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getDelegations();
    }, [searchTerm, selectedSearchEvent]);

    const getEvents = async () => {
        try {
            const { data } = await api.post("/events/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
            });

            setEventsList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        if (selectedDelegation) {
            setDelegation(selectedDelegation);
        }
    }, [selectedDelegation]);

    // const handleDelegationAction = async () => {
    //     try {
    //         if (selectedDelegation) {
    //             await api.put(`/users/${selectedDelegation?._id}`, {
    //                 ...delegation,
    //                 role: "Delegation",
    //                 expireAt: new Date("2025"),
    //             });
    //         } else {
    //             await api.post(`/users`, {
    //                 ...delegation,
    //                 role: "Delegation",
    //                 expireAt: new Date("2025"),
    //             });
    //         }

    //         window.location.reload();
    //     } catch (error) {
    //         addToast("Something went wrong", {
    //             id: "error",
    //             appearance: "error",
    //             autoDismiss: true,
    //         });
    //     }
    // };
    const handleDelete = async (id) => {
        try {
            await api.delete(`/users/${id}`, {});

            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    const assignDelegation = async (eventId, delegationId) => {
        try {
            await api.put(
                `/events/${eventId}/${delegationId}/add-delegation`,
                {}
            );

            window.location.reload();
        } catch (error) {
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const getDelegationEvents = useMemo(() => {
        const delEvents = eventsList?.content?.filter((event) =>
            event?.guests?.find(
                (guest) => guest?._id === selectedDelegation?._id
            )
        );

        return delEvents;
    }, [eventsList?.content, selectedDelegation?._id]);

    console.log({ eventsList, getDelegationEvents });

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     setDelegation({
    //         ...delegation,
    //         [name]: value,
    //     });
    // };

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <h3>Delegations</h3>
                <Button
                    onClick={() => {
                        // setSelectedDelegation(undefined);
                        setDelegation({
                            username: "",
                            password: "",
                        });
                        localStorage.removeItem("delegation");
                        localStorage.removeItem("user_info");
                        history.push("/dashboard/create-delegation");
                        // setIsCreateModalOpen(true);
                    }}
                >
                    Add
                </Button>
            </div>
            <div className="mt-5 d-flex gap-3">
                <Input
                    value={searchTerm}
                    type="text"
                    label="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Form.Select
                    aria-label=""
                    size="lg"
                    style={{
                        height: "50px",
                        marginBottom: "30px",
                        fontSize: "18px",
                    }}
                    name="selectedSearchEvent"
                    value={selectedSearchEvent}
                    onChange={(e) => setSelectedSearchEvent(e.target.value)}
                >
                    <option value="">Open this select event</option>
                    {eventsList?.content?.map((event) => (
                        <option value={event?._id} key={event?._id}>
                            {event?.title}
                        </option>
                    ))}
                </Form.Select>
            </div>
            <InfiniteScroll
                dataLength={delegationList?.content?.length}
                next={getDelegations}
                hasMore={hasMore}
            >
                <Table
                    responsive
                    className="mt-3"
                    striped
                    bordered
                    hover
                    size="lg"
                >
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Position</th>
                            <th>Type</th>
                            <th>Company Name</th>
                            <th>Company Website</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {delegationList.content?.map((delegation, i) => (
                            <tr key={delegation?._id}>
                                <td>{delegation?.title}</td>
                                <td>{delegation?.name}</td>
                                <td>{delegation?.username}</td>
                                <td>{delegation?.position}</td>
                                <td>{delegation?.type}</td>
                                <td>{delegation?.company?.name}</td>
                                <td>{delegation?.company?.website}</td>
                                <td>
                                    <div className="d-flex gap-3">
                                        <Button
                                            // variant="primary"
                                            size="sm"
                                            onClick={() => {
                                                setSelectedDelegation(
                                                    delegation
                                                );
                                                // setIsCreateModalOpen(true);
                                                localStorage.setItem(
                                                    "delegation",
                                                    JSON.stringify(delegation)
                                                );
                                                localStorage.setItem(
                                                    "user_info",
                                                    JSON.stringify(delegation)
                                                );
                                                history.push(
                                                    `/dashboard/create-delegation?edit=${delegation?._id}`
                                                );
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            // variant="primary"
                                            size="sm"
                                            onClick={() => {
                                                setSelectedDelegation(
                                                    delegation
                                                );
                                                setIsAssignModalOpen(true);
                                            }}
                                        >
                                            Add to Event
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() =>
                                                handleDelete(delegation?._id)
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </InfiniteScroll>
            <Modal
                show={isAssignModalOpen}
                onHide={() => setIsAssignModalOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Delegation to Event</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    <div className="d-flex gap-3" style={{ width: "100%" }}>
                        <h6>Delegation: {selectedDelegation?.email}</h6>
                    </div>
                    <div
                        className="d-flex flex-column gap-2"
                        style={{ width: "100%" }}
                    >
                        <h6 className="mt-4">Delegation Events</h6>
                        <div className="d-flex gap-3 flex-wrap">
                            {getDelegationEvents?.map((event) => (
                                <Badge key={event?._id} bg="primary">
                                    {event?.title}
                                </Badge>
                            ))}
                        </div>
                    </div>

                    <Form.Label style={{ fontSize: "18px", marginTop: "10px" }}>
                        Select Event
                    </Form.Label>
                    <Form.Select
                        aria-label=""
                        size="lg"
                        style={{
                            height: "50px",
                            fontSize: "18px",
                        }}
                        name="country"
                        value={selectedEventId}
                        onChange={(e) => setSelectedEventId(e.target.value)}
                    >
                        <option>Open this select event</option>
                        {eventsList?.content?.map((event) => (
                            <option value={event?._id} key={event?._id}>
                                {event?.title}
                            </option>
                        ))}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsAssignModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() =>
                            assignDelegation(
                                selectedEventId,
                                selectedDelegation?._id
                            )
                        }
                    >
                        Assign
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <Modal
                show={isCreateModalOpen}
                onHide={() => setIsCreateModalOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedDelegation ? "Edit" : "Create"} Delegation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    <div className="d-flex gap-3" style={{ width: "100%" }}>
                        <Input
                            type="text"
                            id="username"
                            label="Username"
                            placeholder="Username"
                            name="username"
                            value={delegation?.username}
                            onChange={handleInputChange}
                        />
                        <Input
                            type="password"
                            id="password"
                            label="Password"
                            placeholder="Password"
                            name="password"
                            value={delegation?.password}
                            onChange={handleInputChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsCreateModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDelegationAction}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </Sidebar>
    );
};

export default DelegationsPage;
