import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Input from "../../components/events/Input";
import {
    useHistory,
    useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useToasts } from "react-toast-notifications";
import api from "../../helpers/api";
import moment from "moment";
import Sidebar from "../../components/AdminSideBar/sidebar";

const CreateBreaktime = () => {
    const history = useHistory();
    const { addToast } = useToasts();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const breakId = query.get("edit");
    const [breakMeetings, setBreakMeetings] = useState({
        name: "",
        startTime: "",
        endTime: "",
    });
    const [selectedEventId, setSelectedEventId] = useState("");
    const [eventsList, setEventsList] = useState({ count: 0, content: [] });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update the state with the new value
        setBreakMeetings({
            ...breakMeetings,
            [name]: value,
        });
    };

    const getEvents = async () => {
        try {
            const { data } = await api.post("/events/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
            });

            setEventsList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        const currentBreak = JSON.parse(localStorage.getItem("break"));

        if (breakId) {
            setBreakMeetings(currentBreak);
            setSelectedEventId(currentBreak?.eventId);
        }
    }, [breakId]);

    const handleBreakAction = async () => {
        try {
            if (breakId) {
                await api.put(`/event-sessions/${breakId}`, {
                    eventId: selectedEventId,
                    ...breakMeetings,
                    type: "BREAK",
                });
            } else {
                await api.post("/event-sessions", {
                    eventId: selectedEventId,
                    ...breakMeetings,
                    type: "BREAK",
                });
            }

            window.location.pathname = "/dashboard/event-breaktimes";
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    return (
        <Sidebar>
            <h3 className="mb-5">{breakId ? "Edit" : "Create"} Breaktime</h3>
            <div className="p-2">
                <Form.Label style={{ fontSize: "18px" }}>
                    Select B2B Event
                </Form.Label>
                <Form.Select
                    aria-label=""
                    size="lg"
                    style={{
                        height: "50px",
                        marginBottom: "30px",
                        fontSize: "18px",
                    }}
                    name="country"
                    value={selectedEventId}
                    onChange={(e) => setSelectedEventId(e.target.value)}
                >
                    <option>Open this select event</option>
                    {eventsList?.content
                        ?.filter(
                            (event) => !event?.guests[0]?.schedules?.length
                        )
                        ?.map((event) => (
                            <option value={event?._id} key={event?._id}>
                                {event?.title}
                            </option>
                        ))}
                </Form.Select>
                <Row style={{ marginTop: "30px" }}>
                    <Col>
                        <Input
                            id="name"
                            label="Breaktime Name"
                            placeholder="Type Breaktime Name"
                            value={breakMeetings.name}
                            name="name"
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="datetime-local"
                            id="startMeetingTime"
                            label="Start Meeting Time"
                            placeholder="Type Start Meeting Time"
                            value={
                                breakMeetings.startTime
                                    ? moment(breakMeetings?.startTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""
                            }
                            name="startTime"
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="datetime-local"
                            id="endMeetingTime"
                            label="End Meeting Time"
                            placeholder="Type End Meeting Time"
                            value={
                                breakMeetings.endTime
                                    ? moment(breakMeetings?.endTime).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""
                            }
                            name="endTime"
                            onChange={handleChange}
                            min={breakMeetings?.startTime}
                        />
                    </Col>
                </Row>
                <Button
                    className="mt-5"
                    variant="primary"
                    onClick={handleBreakAction}
                >
                    {breakId ? "Edit Breaktime" : "Create Breaktime"}
                </Button>
            </div>
        </Sidebar>
    );
};

export default CreateBreaktime;
