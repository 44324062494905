import produce from "immer";
import mergeById from "../../helpers/mergeById";



export const actions = {
  GET_SECTORS : "GET_SECTORS",
  SET_SECTORS_SEARCH_VALUE : "SET_SECTORS_SEARCH_VALUE",
  SET_SECTORS_IS_LOADING : "SET_SECTORS_IS_LOADING",
  GET_SECTORS_SUCCESS : "GET_SECTORS_SUCCESS",
  GET_SECTORS_COUNT : "GET_SECTORS_COUNT",
  GET_SECTOR : "GET_SECTOR",
  GET_SECTOR_SUCCESS : "GET_SECTOR_SUCCESS",
  RESET_SECTORS : "RESET_SECTORS"

};

const initialState = {
  sectors: [],
  loadedSectors : [] ,
  isLoading: false,
  count: 0,
  searchValue: "",
 
};


export default produce((draft, action) => {
  const {type, payload} = action;
  if (!draft) {
    return initialState;
  }

  switch (type) {
    case actions.SET_SECTORS_SEARCH_VALUE: {
      draft.sectors = [];
      draft.searchValue = payload;
      break;
    }
    case actions.GET_SECTORS_SUCCESS: {
      const currentSector = draft.sectors;
      const sectors = payload;
     // draft.sectors = sectors
      draft.sectors = mergeById(currentSector, sectors);
      break;
    }

    case actions.GET_SECTOR_SUCCESS: {
      const currentSector = draft.sectors;
      const sectors = payload;
      draft.sector = mergeById(currentSector, sectors);
      break;
    }

   
    case actions.SET_SECTORS_IS_LOADING: {
      draft.isLoading = payload;
      break;
    }
    case actions.GET_SECTORS_COUNT: {
      draft.count = payload;
      break;
    }
    case actions.RESET_SECTORS: {
      draft.sectors = [];
      break;
    }
    default:
      break;
  }
});
