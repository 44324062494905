import api from "../../helpers/api";

export const Login = (formData) => api.post("/auth/signin", formData);

export const forgetPassword = (email) =>
    api.post("/auth/forget-password", email);

export const getCurrentUser = () => api.get("/users/me");

export const logout = () => api.get("/auth/logout", true);

export const updateMyProfile = (formData) => api.put("/users/me", formData);
