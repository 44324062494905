import {actions} from "./Reducer";

export const getActivitesAction = (payload) => ({
  type: actions.GET_ASTIVITES,
  payload,
});

export const getActivitesSuccessAction = (activites) => ({
  type: actions.GET_ACTIVITES_SUCCESS,
  payload: activites,
});
export const getActivitesHeaderAction = (payload) => ({
  type: actions.GET_ACTIVITESHEADER,
  payload,
});
export const getActivitesHeaderSuccessAction = (activites) => ({
  type: actions.GET_ACTIVITESHEADER_SUCCESS,
  payload: activites,
});

export const getActivityAction = id => ({
  type: actions.GET_ACTIVITY,
  payload: id
});

export const getActivitySuccessAction = activity => ({
  type: actions.GET_ACTIVITY_SUCCESS,
  payload: activity
});


export const setSearchValueAction = (value) => ({
  type: actions.SET_ACTIVITES_SEARCH_VALUE,
  payload: value,
});

export const setActivitesIsLoadingAction = (isLoading) => ({
  type: actions.SET_ASTIVITES_IS_LOADING,
  payload: isLoading,
});

export const getActivitesCountAction = (count) => ({
  type: actions.GET_ACTIVITES_COUNT,
  payload: count,
});

export const resetActivitesAction = () => ({
  type: actions.RESET_ACTIVITES,
});