export function allCookieList(){
    let assoiativeCookie = [];
    let cookieSplit = document.cookie.split(";");
    for(let i=0 ; i<cookieSplit.length;i++){
        assoiativeCookie[cookieSplit[i].split('=')[0].trim()] = cookieSplit[i].split('=')[1] 
    }
    return assoiativeCookie;
  }
  
  export function getCookie(cookieName){
    if(arguments.length!=1)
    throw  "this is not exsit cooki";
    
    let cookie = allCookieList();
    for(let i in cookie)
    if( i == cookieName)
    return cookie[i];
  }
  export function deleteCookie(cookiname){
    
        document.cookie = cookiname + "=;expire=20-2-2019";
  
   
  }

  export function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 100000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires ;
  }