import { ErrorMessage } from "formik";
import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    name:Yup.string().default("").required("Enter your name please"),
    email: Yup.string().email("enter valid email").required("please enter member email"),
    phone: Yup.string(),
    comment: Yup.string(),
  })
