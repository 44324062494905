import {actions} from "./Reducer";


export const createFeedBackAction = (formData) =>  ({
  type: actions.CREATE_FEEDBACK,
  payload: formData,
});

export const createFeedBackSuccessAction = (feedback) => ({
  type: actions.CREATE_FEEDBACK_SUCCESS,
  payload: feedback,
});


