import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Header from "../../components/Header/Header";
import api from "../../helpers/api";
import moment from "moment";
import {
    Accordion,
    Badge,
    Button,
    Card,
    Form,
    Modal,
    Table,
} from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import Input from "../../components/events/Input";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import FileInput from "../../components/events/FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import CreateNewAccount from "./create-new-account";
import { getCookie } from "../../helpers/cookies";
import LoginModal from "./login-modal";
import { QrReader } from "react-qr-reader";

const SingleEvent = ({ match }) => {
    const { eventId } = match.params;
    const { addToast } = useToasts();
    const [event, setEvent] = useState();
    const [qrCodeData, setQRCodeData] = useState();
    const [scanData, setScanData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [verifyPaymentAttach, setVerifyPaymentAttach] = useState("");
    const [isJoin, setIsJoin] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState();
    const [isSchedule, setIsSchedule] = useState(false);
    const [isLoginAccount, setIsLoginAccount] = useState(false);
    const [isCreateAccount, setIsCreateAccount] = useState(false);
    const [isCompanyDetails, setICompanyDetails] = useState(false);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState();
    const [scheduleDate, setScheduleDate] = useState({
        startTime: "",
        endTime: "",
    });
    const isAuth = getCookie("TOKEN");

    const getEvent = async () => {
        const endpoint = isAuth
            ? `/events/${eventId}`
            : `/events/${eventId}/public`;
        setIsLoading(true);
        try {
            const { data } = await api.get(endpoint);

            setEvent(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log({ error });
        }
    };

    const scheduleAMeeting = async () => {
        try {
            const { data } = await api.post(`/member-schedules`, {
                eventId,
                startTime: scheduleDate?.startTime,
                endTime: scheduleDate?.endTime,
            });

            addToast(
                "Your request has been sent. wait for confirmation email",
                {
                    id: "success",
                    appearance: "success",
                    autoDismiss: true,
                }
            );
            setIsSchedule(false);
        } catch (error) {
            console.log({ error });
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const isUserJoined = () => {
        let isJoined = false;

        const user = event?.guests?.find(
            (g) => g?._id === Cookies.get("userID")
        );

        if (user) {
            isJoined = true;
        } else {
            isJoined = false;
        }

        return isJoined;
    };

    const joinEvent = async (eventId) => {
        try {
            const { data } = await api.post(`/book-request/${eventId}`, {
                verifyPaymentAttach: verifyPaymentAttach || undefined,
                ticket: selectedTicket,
            });

            addToast("Your request has been sent. wait for confirmation", {
                id: "success",
                appearance: "success",
                autoDismiss: true,
            });
            window.location.reload();
        } catch (error) {
            console.log({ error });
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const handleUpload = async (formData, property) => {
        try {
            const res = await api.post("/uploads", formData);

            console.log({ res });
            setVerifyPaymentAttach(res?.data?.url);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileChange = async (event, property) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("file", file);

        await handleUpload(formData, property);
    };

    const handleMeetRequestAction = async (id) => {
        try {
            await api.patch(`/member-schedules/${id}/meet-request`, {});

            window.location.reload();
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const getSeasts = useMemo(() => {
        return event?.pricing?.reduce(
            (acc, event) => acc + event?.seates || 0,
            0
        );
    }, [event]);

    console.log({
        isUserJoined: isUserJoined(),
        userId: Cookies.get("userID"),
        guests: event?.guests,
        event,
        getSeasts,
    });

    useEffect(() => {
        if (eventId) {
            getEvent();
        }
    }, [eventId]);

    const handleScanQRCode = async () => {
        try {
            const { data } = await api.get(`/ticket/${qrCodeData?.id}/qrcode`);

            setScanData(data);
        } catch (error) {
            console.error(error);
        }
    };

    const markAsAttendee = async () => {
        try {
            const { data } = await api.get(
                `api/ticket/${scanData?._id}/${eventId}/mark-ticket-attend`
            );

            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (qrCodeData) {
            handleScanQRCode();
        }
    }, [qrCodeData]);

    return (
        <Layout>
            {Cookies.get("userID") ? (
                <Header>
                    <div className="d-flex flex-column gap-3">
                        <div className="d-flex flex-column">
                            <h1 style={{ fontWeight: "700" }}>
                                EBA{" "}
                                <span style={{ fontWeight: "300" }}>
                                    Events
                                </span>{" "}
                            </h1>
                            <h1 style={{ fontWeight: "700" }}>Directory </h1>
                        </div>
                        <p style={{ color: "#333", lineHeight: "1.8" }}>
                            Plan your next adventure with our curated selection
                            of events.
                        </p>
                    </div>
                </Header>
            ) : null}
            {isLoading ? (
                <div style={{ textAlign: "center", padding: "50px 0" }}>
                    <p>Loading...</p>
                </div>
            ) : (
                <>
                    <div className="w-100">
                        <img
                            src={event?.cover}
                            alt={event?.title}
                            style={{
                                height: "500px",
                                width: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </div>
                    <div
                        className="container"
                        style={{ padding: "0 0 50px 0" }}
                    >
                        <div className="container">
                            <div className="mt-5 d-flex gap-5">
                                <div class="row">
                                    <div class="col-md-8 col-12">
                                        <div>
                                            <div>
                                                <h1>{event?.title}</h1>
                                                <p>{event?.description}</p>
                                            </div>
                                            <div
                                                className="d-flex gap-2"
                                                style={{ width: "100%" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center justify-content-between p-4 mt-4"
                                                    style={{
                                                        background: "#fff",
                                                        borderRadius: "4px",
                                                        flex: 1,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Start Event Time
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                "var(--primary-color)",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {moment(
                                                            event?.startTime
                                                        ).format("llll")}
                                                    </span>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between p-4 mt-4"
                                                    style={{
                                                        background: "#fff",
                                                        borderRadius: "4px",
                                                        flex: 1,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        End Event Time
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                "var(--primary-color)",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {moment(
                                                            event?.endTime
                                                        ).format("llll")}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex gap-2"
                                                style={{ width: "100%" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center justify-content-between p-4 mt-4"
                                                    style={{
                                                        background: "#fff",
                                                        borderRadius: "4px",
                                                        flex: 1,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Event Place
                                                    </span>
                                                    <a
                                                        style={{
                                                            color:
                                                                "var(--primary-color)",
                                                            fontWeight: "600",
                                                        }}
                                                        href={event?.placeLink}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {event?.place}
                                                    </a>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between p-4 mt-4"
                                                    style={{
                                                        background: "#fff",
                                                        borderRadius: "4px",
                                                        flex: 1,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Event Type
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                "var(--primary-color)",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {event?.eventType?.join(
                                                            ", "
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column gap-3 mt-4">
                                                {event?.eventSessions?.map(
                                                    (session, i) => (
                                                        <div
                                                            key={session?._id}
                                                            className="p-4"
                                                            style={{
                                                                background:
                                                                    "#fff",
                                                                borderRadius:
                                                                    "4px",
                                                            }}
                                                        >
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <Badge bg="primary">
                                                                        {i + 1}
                                                                    </Badge>
                                                                    <h5>
                                                                        {
                                                                            session?.name
                                                                        }
                                                                    </h5>
                                                                </div>
                                                                <Button
                                                                    variant="outline-primary"
                                                                    onClick={() =>
                                                                        window.open(
                                                                            session?.speakerPresentation
                                                                        )
                                                                    }
                                                                >
                                                                    Presentation
                                                                </Button>
                                                            </div>
                                                            <div className="mt-4 d-flex align-items-center gap-2">
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "var(--primary-color)",
                                                                        fontWeight:
                                                                            "500",
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        session?.startTime
                                                                    ).format(
                                                                        "HH:mm a"
                                                                    )}
                                                                </span>
                                                                <span>to</span>
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "var(--primary-color)",
                                                                        fontWeight:
                                                                            "500",
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        session?.endTime
                                                                    ).format(
                                                                        "HH:mm a"
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="mt-4 d-flex flex-column gap-1">
                                                                <h6>
                                                                    Session
                                                                    Details
                                                                </h6>
                                                                <p>
                                                                    {
                                                                        session?.description
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="mt-4 d-flex flex-column gap-3">
                                                                <h6>
                                                                    The
                                                                    Presenter
                                                                </h6>
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src={
                                                                            session?.speakerPhoto
                                                                        }
                                                                        alt={
                                                                            session?.speakerName
                                                                        }
                                                                        style={{
                                                                            width:
                                                                                "50px",
                                                                            height:
                                                                                "50px",
                                                                            borderRadius:
                                                                                "50%",
                                                                        }}
                                                                    />
                                                                    <h6>
                                                                        {
                                                                            session?.speakerName
                                                                        }
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div className="mt-4">
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <h3>Break Times</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <Table
                                                                striped
                                                                bordered
                                                                hover
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Name
                                                                        </th>
                                                                        <th>
                                                                            Start
                                                                        </th>
                                                                        <th>
                                                                            End
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {event?.breaktime?.map(
                                                                        (
                                                                            breaktime
                                                                        ) => (
                                                                            <tr
                                                                                key={
                                                                                    breaktime?._id
                                                                                }
                                                                            >
                                                                                <td>
                                                                                    {
                                                                                        breaktime?.name
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {moment(
                                                                                        breaktime?.startTime
                                                                                    ).format(
                                                                                        "lll"
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {moment(
                                                                                        breaktime?.endTime
                                                                                    ).format(
                                                                                        "lll"
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>
                                                            <h3>
                                                                B2B Meetings
                                                            </h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <Table
                                                                striped
                                                                bordered
                                                                hover
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Name
                                                                        </th>
                                                                        <th>
                                                                            Start
                                                                        </th>
                                                                        <th>
                                                                            End
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {event?.b2bMeetings?.map(
                                                                        (
                                                                            meeting
                                                                        ) => (
                                                                            <tr
                                                                                key={
                                                                                    meeting?._id
                                                                                }
                                                                            >
                                                                                <td>
                                                                                    {
                                                                                        meeting?.name
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {moment(
                                                                                        meeting?.startTime
                                                                                    ).format(
                                                                                        "lll"
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {moment(
                                                                                        meeting?.endTime
                                                                                    ).format(
                                                                                        "lll"
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-12">
                                        {" "}
                                        <div>
                                            {/* {isUserJoined() ? (
                                        <Button
                                            onClick={() => setIsSchedule(true)}
                                        >
                                            Schedule a Meeting
                                        </Button>
                                    ) : null} */}
                                            <Modal
                                                show={isSchedule}
                                                onHide={() =>
                                                    setIsSchedule(false)
                                                }
                                                centered
                                                backdrop="static"
                                                keyboard={false}
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        Schedule a Meeting
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body
                                                    style={{
                                                        padding: "20px",
                                                        backgroundColor:
                                                            "#f5f5f5",
                                                    }}
                                                >
                                                    <div className="d-flex flex-column gap-5">
                                                        <Input
                                                            type="datetime-local"
                                                            id="startTime"
                                                            label="Start Time"
                                                            name="startTime"
                                                            value={
                                                                scheduleDate.startTime
                                                            }
                                                            onChange={(e) =>
                                                                setScheduleDate(
                                                                    {
                                                                        ...scheduleDate,
                                                                        startTime:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                        <Input
                                                            type="datetime-local"
                                                            id="endTime"
                                                            label="End Time"
                                                            name="endTime"
                                                            value={
                                                                scheduleDate.endTime
                                                            }
                                                            onChange={(e) =>
                                                                setScheduleDate(
                                                                    {
                                                                        ...scheduleDate,
                                                                        endTime:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() =>
                                                            setIsSchedule(false)
                                                        }
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button
                                                        variant="primary"
                                                        onClick={
                                                            scheduleAMeeting
                                                        }
                                                    >
                                                        Create
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                            {isAuth &&
                                            localStorage.getItem("role") ===
                                                "superAdmin" ? (
                                                <Card
                                                    style={{
                                                        padding: "10px",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    <Card.Body>
                                                        <Card.Title
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        >
                                                            Seats:{" "}
                                                            {
                                                                event?.numberOfSeats
                                                            }
                                                        </Card.Title>
                                                        <Card.Title
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        >
                                                            Available:{" "}
                                                            {
                                                                event?.availableSeates
                                                            }
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            ) : null}
                                            <Card
                                                style={{
                                                    padding: "10px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Card.Body>
                                                    <Card.Title
                                                        style={{
                                                            fontSize: "20px",
                                                        }}
                                                    >
                                                        Tickets
                                                    </Card.Title>
                                                    <div
                                                        className="mt-4 d-flex flex-column gap-3"
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {event?.pricing?.map(
                                                            (p, i) => {
                                                                return (
                                                                    <Button
                                                                        key={i}
                                                                        onClick={() => {
                                                                            if (
                                                                                !Cookies.get(
                                                                                    "userID"
                                                                                )
                                                                            ) {
                                                                                setSelectedTicket(
                                                                                    p
                                                                                );
                                                                                setIsLoginAccount(
                                                                                    true
                                                                                );
                                                                            } else {
                                                                                setSelectedTicket(
                                                                                    p
                                                                                );
                                                                                setIsJoin(
                                                                                    true
                                                                                );
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            textAlign:
                                                                                "left",
                                                                        }}
                                                                        disabled={
                                                                            event?.status !==
                                                                            "pre_started"
                                                                        }
                                                                    >
                                                                        <div className="d-flex flex-column gap-2">
                                                                            <h6>
                                                                                Name:{" "}
                                                                                {
                                                                                    p?.name
                                                                                }
                                                                            </h6>
                                                                            <h6>
                                                                                Price:{" "}
                                                                                {
                                                                                    p?.value
                                                                                }
                                                                            </h6>
                                                                            <h6>
                                                                                Type:{" "}
                                                                                {
                                                                                    p?.type
                                                                                }
                                                                            </h6>
                                                                            <h6>
                                                                                Seats:{" "}
                                                                                {
                                                                                    p?.seates
                                                                                }
                                                                            </h6>
                                                                        </div>
                                                                    </Button>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            {event?.guests?.length ? (
                                                <Card
                                                    style={{
                                                        padding: "10px",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    <Card.Body
                                                        style={{
                                                            maxHeight: "300px",
                                                            overflowY: "auto",
                                                        }}
                                                    >
                                                        <Card.Title
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        >
                                                            Guests
                                                        </Card.Title>
                                                        <div className="d-flex flex-column gap-2">
                                                            {event?.guests?.map(
                                                                (
                                                                    g,
                                                                    index,
                                                                    array
                                                                ) => (
                                                                    <>
                                                                        {index ===
                                                                            0 ||
                                                                        g?.role !==
                                                                            array[
                                                                                index -
                                                                                    1
                                                                            ]
                                                                                ?.role ? (
                                                                            <h6>
                                                                                {g?.role ===
                                                                                "member"
                                                                                    ? "Members"
                                                                                    : g?.role ===
                                                                                      "non_member"
                                                                                    ? "Non Members"
                                                                                    : "Delegations"}
                                                                            </h6>
                                                                        ) : null}
                                                                        <Button
                                                                            key={
                                                                                g?._id
                                                                            }
                                                                            onClick={() => {
                                                                                setSelectedCompany(
                                                                                    g
                                                                                );
                                                                                setICompanyDetails(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                !Cookies.get(
                                                                                    "userID"
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        >
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                {g?.photo ? (
                                                                                    <img
                                                                                        src={
                                                                                            g?.photo
                                                                                        }
                                                                                        alt={
                                                                                            g
                                                                                                ?.company
                                                                                                ?.name
                                                                                        }
                                                                                        style={{
                                                                                            width:
                                                                                                "50px",
                                                                                            height:
                                                                                                "50px",
                                                                                            borderRadius:
                                                                                                "50%",
                                                                                        }}
                                                                                    />
                                                                                ) : null}
                                                                                <h6>
                                                                                    {g
                                                                                        ?.company
                                                                                        ?.name ||
                                                                                        g?.username}
                                                                                </h6>
                                                                            </div>
                                                                        </Button>
                                                                    </>
                                                                )
                                                            )}
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            ) : null}
                                            <Card
                                                style={{
                                                    padding: "10px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Card.Body>
                                                    <Card.Title
                                                        style={{
                                                            fontSize: "20px",
                                                        }}
                                                    >
                                                        We will talk about
                                                    </Card.Title>
                                                    <div className="mt-4 d-flex flex-wrap gap-3">
                                                        {event?.sectors?.map(
                                                            (sec) => (
                                                                <Badge
                                                                    key={
                                                                        sec?._id
                                                                    }
                                                                    bg="primary"
                                                                >
                                                                    {
                                                                        sec?.secENm
                                                                    }
                                                                </Badge>
                                                            )
                                                        )}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            <Card
                                                style={{
                                                    padding: "10px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Card.Body>
                                                    <Card.Title
                                                        style={{
                                                            fontSize: "20px",
                                                        }}
                                                    >
                                                        Country
                                                    </Card.Title>
                                                    <div className="d-flex gap-2 flex-wrap">
                                                        {event?.country?.map(
                                                            (c) => (
                                                                <Badge bg="primary">
                                                                    {c}
                                                                </Badge>
                                                            )
                                                        )}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            {isAuth &&
                                                localStorage.getItem("role") ===
                                                    "superAdmin" && (
                                                    <div className="mt-3">
                                                        <Button
                                                            variant="primary"
                                                            className="mt-3"
                                                            onClick={() =>
                                                                setIsQRCodeModalOpen(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            Scan QR Code
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Modal show={isJoin} onHide={() => setIsJoin(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Join The Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-3">
                        <h6>Ticket Name: {selectedTicket?.name}</h6>
                        <h6>Ticket Price: {selectedTicket?.value}</h6>
                        <h6 style={{ marginBottom: "20px" }}>
                            Ticket Payment Link:{" "}
                            <a
                                href={selectedTicket?.paymentLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {selectedTicket?.paymentLink}
                            </a>
                        </h6>
                        <FileInput
                            handleChange={handleFileChange}
                            title="Payment Transaction Image"
                            icon={
                                <IoMdCloudUpload
                                    color="rgba(156, 163, 175, 1)"
                                    fontSize="40px"
                                />
                            }
                            description1="Click to upload or drag and drop"
                            description2="Max. File Size: 30MB"
                            imageSrc={verifyPaymentAttach || ""}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsJoin(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => joinEvent(eventId)}
                        // disabled={!verifyPaymentAttach}
                    >
                        Join
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={isCompanyDetails}
                onHide={() => setICompanyDetails(false)}
                centered
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Company Details</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        backgroundColor: "#f5f5f5",
                    }}
                >
                    <div className="d-flex flex-column">
                        {selectedCompany?.company ? (
                            <>
                                <Table
                                    responsive
                                    className="mt-5"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                >
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Position</th>
                                            <th>Type</th>
                                            <th>Company name</th>
                                            <th>Company website</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{selectedCompany?.title}</td>
                                            <td>{selectedCompany?.name}</td>
                                            <td>{selectedCompany?.email}</td>
                                            <td>{selectedCompany?.position}</td>
                                            <td>{selectedCompany?.type}</td>
                                            <td>
                                                {selectedCompany?.company?.name}
                                            </td>
                                            <td>
                                                {
                                                    selectedCompany?.company
                                                        ?.website
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table
                                    responsive
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                >
                                    <thead>
                                        <tr>
                                            <th>Company Contact Person</th>
                                            <th>Company Sector</th>
                                            <th>Company Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    selectedCompany?.company
                                                        ?.contact_person
                                                }
                                            </td>
                                            <td>
                                                {
                                                    selectedCompany?.company
                                                        ?.sector
                                                }
                                            </td>
                                            <td>
                                                {
                                                    selectedCompany?.company
                                                        ?.brief_intro
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <div className="d-flex flex-column gap-2">
                                <h4>Name: {selectedCompany?.username}</h4>
                                <a href={`/member/${selectedCompany?._id}`}>
                                    Member Details
                                </a>
                            </div>
                        )}
                        <div className="d-flex flex-column gap-1">
                            <h6>Company Schedules</h6>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedCompany?.schedules?.map(
                                        (meeting) => (
                                            <tr key={meeting?._id}>
                                                <td>{meeting?.status}</td>
                                                <td>
                                                    {moment(
                                                        meeting?.startTime
                                                    ).format("lll")}
                                                </td>
                                                <td>
                                                    {moment(
                                                        meeting?.endTime
                                                    ).format("lll")}
                                                </td>
                                                <td>
                                                    <Button
                                                        // variant="primary"
                                                        size="sm"
                                                        onClick={() => {
                                                            // setSelectedSchedule(schedule);
                                                            // setIsCreateModalOpen(true);

                                                            handleMeetRequestAction(
                                                                meeting?._id
                                                            );
                                                        }}
                                                        disabled={
                                                            meeting?.status ===
                                                                "FREE" &&
                                                            event?.status ===
                                                                "pre_started" &&
                                                            selectedCompany?._id !==
                                                                Cookies.get(
                                                                    "userID"
                                                                )
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        Meet Request
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setICompanyDetails(false)}
                    >
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={() => {}}>
                        Create
                    </Button> */}
                </Modal.Footer>
            </Modal>
            <Modal
                show={isQRCodeModalOpen}
                onHide={() => setIsQRCodeModalOpen(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Join The Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                setQRCodeData(result);
                            }

                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        style={{ width: "100%" }}
                    />
                    {scanData && <>Attendee: {scanData?.attendeeEmail}</>}
                    <div>
                        {scanData && (
                            <Button
                                variant="primary"
                                onClick={() => markAsAttendee()}
                            >
                                Mark As Attendee
                            </Button>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsQRCodeModalOpen(false)}
                    >
                        Close
                    </Button>
                    {/* <Button
                        variant="primary"
                        onClick={() => joinEvent(eventId)}
                    >
                        Join
                    </Button> */}
                </Modal.Footer>
            </Modal>
            <CreateNewAccount
                isShow={isCreateAccount}
                setIsShow={setIsCreateAccount}
                setIsLoginAccount={setIsLoginAccount}
            />
            <LoginModal
                isShow={isLoginAccount}
                setIsShow={setIsLoginAccount}
                setIsCreateAccount={setIsCreateAccount}
                selectedTicket={selectedTicket}
            />
        </Layout>
    );
};

export default SingleEvent;
