import {createSelector} from "reselect";

const activitesStateSelector = (state) => state.activites;
const selectById = (state, industryId) => industryId;

export const activitesSelector = createSelector(
  [activitesStateSelector],
  (activites) => activites.activites
);

export const activitesHeaderSelector = createSelector(
  [activitesStateSelector],
  (activites) => activites.activitesHeader
);

export const activitesLoadSelector = createSelector(
  [activitesStateSelector],
  (activites) => activites.loadedactivites
);

export const activitesCountSelector = createSelector(
  [activitesStateSelector],
  (activites) => activites.count
);


export const activitesIsLoadingSelector = createSelector(
  [activitesStateSelector],
  (activites) => activites.isLoading
);

export const searchValueSelector = createSelector(
  [activitesStateSelector],
  (activites) => activites.searchValue
);

export const activityByIdSelector = createSelector(
 
  [activitesSelector, selectById],
  (activites, _id) => {
    const industry = activites.find(industry => _id === industry._id);
    return industry;
  }
);