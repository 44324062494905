import React, { useState } from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteAdAction } from "../../modules/ads/Actions";
import { deleteUserAction } from "../../modules/user/Actions";

const ListItem = (props) => {
    const [visible, setVisible] = useState(false);
    const {
        item,
        editLink,
        onDelete,
        url,
        deleteUser,
        deleteAd,
        label,
    } = props;
    const deleteItem = (itemId) => {
        url === "users" ? deleteUser(itemId) : deleteAd(itemId);
        setVisible(false);
    };
    return (
        <>
            <div className="d-flex align-items-center gap-3 justify-content-between">
                <p
                    className="category--item c"
                    style={{
                        // textTransform: "capitalize",
                        fontSize: "1.8rem",
                        margin: 0,
                    }}
                >
                    {label}
                </p>
                <div className="d-flex gap-3">
                    {editLink ? (
                        <Link
                            to={`/dashboard/${url}/${item._id}`}
                            className="btn btn-primary rounded"
                        >
                            Edit
                        </Link>
                    ) : (
                        ""
                    )}
                    {onDelete && (
                        <>
                            <Tooltip
                                placement="topRight"
                                trigger={["click"]}
                                animation="zoom"
                                onVisibleChange={(visible) =>
                                    setVisible(visible)
                                }
                                visible={visible}
                                // className={"opaque"}
                                overlay={
                                    <div>
                                        <p>
                                            Are you sure you want to delete this
                                            record?
                                        </p>
                                        <p>This process can't be undone.</p>
                                        <div className="d-flex gap-4">
                                            <button
                                                onClick={() =>
                                                    setVisible(false)
                                                }
                                                className="btn btn-secondary w-100"
                                            >
                                                No
                                            </button>
                                            <button
                                                onClick={() =>
                                                    deleteItem(item._id)
                                                }
                                                className="btn btn-danger ml-1 w-100"
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </div>
                                }
                                destroyTooltipOnHide={true}
                                // overlayClassName={"opaque"}
                            >
                                <button
                                    type="button"
                                    className="btn rounded"
                                    style={{
                                        background: "rgba(224, 224, 224, 1)",
                                        color: "var(--primary-color)",
                                    }}
                                >
                                    Delete
                                </button>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
            <hr style={{ width: "100%", color: "#C4C4C4", margin: 0 }} />
        </>
    );
};

const mapDispatchToProps = {
    deleteAd: deleteAdAction,
    deleteUser: deleteUserAction,
};
export default connect(null, mapDispatchToProps)(ListItem);
