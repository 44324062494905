import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addViewersAction, getAdsAction } from "../../modules/ads/Actions";
import { adsSelector } from "../../modules/ads/Selectors";
import YouTube from "react-youtube";
import moment from "moment";
import { findAllByTestId } from "@testing-library/dom";
const Advertsment = (props) => {
    const videoIdA = "XxVg_s8xAms";
    const videoIdB = "-DX3vJiqxm4";
    const {
        small,
        ads,
        getAds,
        location,
        memberId,
        login,
        addViewers,
        logo,
    } = props;
    const [videoUrl, setVideoUrl] = useState();
    const [adsData, setAdsData] = useState([]);
    const [visit, setVisit] = useState(false);
    const opts = {
        height:
            location == "MEMBER_BLOCK_1" ||
            location == "MEMBER_BLOCK_2" ||
            location == "MEMBER_BLOCK_3"
                ? "220"
                : "500",
        width: "100%",
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    const onReady = (event) => {
        event.target.pauseVideo();
    };

    // useEffect(() => {

    // if(!memberId) {

    //     getAds({filterBy: [{ location: location.split('_')[0].toLowerCase() }] })
    // }

    // }, [memberId]);

    // useEffect(()=>{
    //   if (memberId) {
    //     getAds({ filterBy: [{ locationId: memberId }] })

    //    }
    // },[memberId])

    function youtube_parser(url) {
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    }
    const includesAds = (arr, adValue) => {
        let found = false;
        for (let i = 0; i < arr.length; i++) {
            if (
                ads[i].blockId == adValue
                // || ads[i].enabled == findAllByTestId
            ) {
                found = true;
                break;
            }
        }
        return found;
    };

    const AdBlock = [
        { value: "HOME_BLOCK_1", label: "HOME_BLOCK_1", name: "home" },
        { value: "HOME_BLOCK_2", label: "HOME_BLOCK_2", name: "home" },
        { value: "HOME_BLOCK_3", label: "HOME_BLOCK_3", name: "home" },
        { value: "HOME_BLOCK_4", label: "HOME_BLOCK_4", name: "home" },
        { value: "SEARCH_BLOCK_1", label: "SEARCH_BLOCK_1", name: "search" },
        { value: "SEARCH_BLOCK_2", label: "SEARCH_BLOCK_2", name: "search" },
        { value: "SEARCH_BLOCK_3", label: "SEARCH_BLOCK_3", name: "search" },
        { value: "LOGIN_BLOCK_1", label: "LOGIN_BLOCK_1", name: "login" },
        { value: "LOGIN_BLOCK_2", label: "LOGIN_BLOCK_2", name: "login" },
        { value: "LOGIN_BLOCK_3", label: "LOGIN_BLOCK_3", name: "login" },
        { value: "LOGIN_BLOCK_4", label: "LOGIN_BLOCK_4", name: "login" },
        {
            value: "INDUSTRY_BLOCK_1",
            label: "INDUSTRY_BLOCK_1",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_2",
            label: "INDUSTRY_BLOCK_2",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_3",
            label: "INDUSTRY_BLOCK_3",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_4",
            label: "INDUSTRY_BLOCK_4",
            name: "industry",
        },
        { value: "MEMBER_BLOCK_1", label: "MEMBER_BLOCK_1", name: "member" },
        { value: "MEMBER_BLOCK_2", label: "MEMBER_BLOCK_2", name: "member" },
        { value: "MEMBER_BLOCK_3", label: "MEMBER_BLOCK_3", name: "member" },
        { value: "MEMBER_BLOCK_4", label: "MEMBER_BLOCK_4", name: "member" },
        {
            value: "PIONEERS_BLOCK_1",
            label: "PIONEERS_BLOCK_1",
            name: "pioneers",
        },
        {
            value: "PIONEERS_BLOCK_2",
            label: "PIONEERS_BLOCK_2",
            name: "pioneers",
        },
        {
            value: "PIONEERS_BLOCK_3",
            label: "PIONEERS_BLOCK_3",
            name: "pioneers",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_1",
            label: "ADVANCED_SEARCH_BLOCK_1",
            name: "advanced search",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_2",
            label: "ADVANCED_SEARCH_BLOCK_2",
            name: "advanced search",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_3",
            label: "ADVANCED_SEARCH_BLOCK_3",
            name: "advanced search",
        },
    ];

    let difference = AdBlock.filter((x) => !includesAds(ads, x.label));

    return (
        <>
            {ads.map((ad, index) => {
                if (ad.blockId == location) {
                    const isOpen = moment(new Date()).isBetween(
                        ad?.startDate,
                        ad?.endDate
                    );

                    if (
                        ad?.blockId?.split("_")[0] == "MEMBER" &&
                        ad.locationId == memberId
                        // && ad.enabled
                    ) {
                        return (
                            <div
                                className={`ad-container ${
                                    login ? "" : "my-5"
                                }`}
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    addViewers(ad?._id);

                                    if (ad?.addLink)
                                        window.open(ad?.addLink, "_blank");
                                }}
                            >
                                <div className="container">
                                    <div
                                        className=" d-flex flex-column position-relative"
                                        style={{
                                            boxShadow: logo
                                                ? ""
                                                : "3px 3px 45px #0000008f",
                                        }}
                                    >
                                        <span
                                            className="badge position-absolute border-bottom-0"
                                            style={{
                                                width: "15%",
                                                top: "-9px",
                                                left: "-0.2px",
                                                backgroundColor: "#0a3d80",
                                            }}
                                        >
                                            Ads
                                        </span>
                                        {ad.asset && (
                                            <img
                                                src={ad?.asset}
                                                alt="ad here"
                                                className={`${
                                                    small ? " " : ""
                                                } m-auto `}
                                                style={{
                                                    height: "120px",
                                                    minWidth: "100%",
                                                }}
                                            />
                                        )}

                                        {ad?.targetUrl && (
                                            <YouTube
                                                videoId={youtube_parser(
                                                    ad?.targetUrl
                                                )}
                                                opts={opts}
                                                onReady={onReady}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    } else if (
                        ad?.blockId?.split("_")[0] !== "MEMBER"
                        // &&ad.enabled
                    ) {
                        return (
                            <div
                                className={`ad-container ${
                                    login ? "" : "my-5"
                                }`}
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    addViewers(ad?._id);

                                    if (ad?.addLink)
                                        window.open(ad?.addLink, "_blank");
                                }}
                            >
                                <div className="container">
                                    <div
                                        className=" d-flex flex-column position-relative"
                                        style={{
                                            boxShadow: logo
                                                ? ""
                                                : "3px 3px 45px #0000008f",
                                        }}
                                    >
                                        {!logo && (
                                            <span
                                                className="badge position-absolute border-bottom-0"
                                                style={{
                                                    width: "35px",
                                                    top: "-9px",
                                                    left: "-0.2px",
                                                    backgroundColor: "#0a3d80",
                                                    borderBottomLeftRadius: "0",
                                                    borderBottomRightRadius:
                                                        "0",
                                                }}
                                            >
                                                Ads
                                            </span>
                                        )}
                                        {ad.asset && (
                                            <img
                                                src={ad?.asset}
                                                alt="ad here"
                                                className={`${
                                                    small ? " " : ""
                                                } m-auto `}
                                                style={{
                                                    maxHeight: "100%",
                                                    minWidth: "100%",
                                                }}
                                            />
                                        )}
                                        {ad?.targetUrl && (
                                            <YouTube
                                                videoId={youtube_parser(
                                                    ad?.targetUrl
                                                )}
                                                opts={opts}
                                                onReady={onReady}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
            })}

            {difference.map((val) => {
                if (val.label == location) {
                    if (
                        val?.label?.split("_")[0] == "MEMBER" &&
                        val?.label != "MEMBER_BLOCK_4" &&
                        val?.label != "MEMBER_BLOCK_1"
                    ) {
                        return (
                            <div className="container my-3">
                                <img
                                    src={"assets/images/add-2.png"}
                                    alt="ad here"
                                    className={`${small ? " " : ""} m-auto `}
                                    style={{
                                        maxHeight: "100%",
                                        minWidth: "100%",
                                        marginBlock: "5px",
                                    }}
                                />
                            </div>
                        );
                    } else if (
                        val?.label == "LOGIN_BLOCK_1" ||
                        val?.label == "LOGIN_BLOCK_2" ||
                        val?.label == "LOGIN_BLOCK_3" ||
                        val?.label == "ADVANCED_SEARCH_BLOCK_1" ||
                        val?.label == "ADVANCED_SEARCH_BLOCK_2"
                    ) {
                        return (
                            <div className="container my-3">
                                <img
                                    src={"assets/images/add-2.png"}
                                    alt="ad here"
                                    className={`${small ? " " : ""} m-auto `}
                                    style={{
                                        maxHeight: "100%",
                                        minWidth: "100%",
                                        marginBlock: "5px",
                                    }}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div className="container my-3">
                                <img
                                    src={"assets/images/add-1.png"}
                                    alt="ad here"
                                    className={`${small ? " " : ""} m-auto `}
                                    style={{
                                        maxHeight: "100%",
                                        minWidth: "100%",
                                        marginBlock: "5px",
                                    }}
                                />
                            </div>
                        );
                    }
                }
            })}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ads: adsSelector(state),
    };
};

const mapDispatchToProps = {
    getAds: getAdsAction,
    addViewers: addViewersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Advertsment);
