import {actions} from "./Reducer";

export const getIndustriesAction = (payload) => ({
  type: actions.GET_INDUSTRIES,
  payload,
});

export const getIndustriesSuccessAction = (industries) => ({
  type: actions.GET_INDUSTRIES_SUCCESS,
  payload: industries,
});

export const getIndustryAction = id => ({
  type: actions.GET_INDUSTRY,
  payload: id
});

export const getIndustrySuccessAction = industry => ({
  type: actions.GET_INDUSTRY_SUCCESS,
  payload: industry
});


export const setSearchValueAction = (value) => ({
  type: actions.SET_INDUSTRIES_SEARCH_VALUE,
  payload: value,
});

export const setIndustriesIsLoadingAction = (isLoading) => ({
  type: actions.SET_INDUSTRIES_IS_LOADING,
  payload: isLoading,
});

export const getIndustriesCountAction = (count) => ({
  type: actions.GET_INDUSTRIES_COUNT,
  payload: count,
});

export const resetIndystriesAction = () => ({
  type: actions.RESET_INDUSTRIES,
});