import { Field, Formik } from "formik";
import React, { useEffect, useState, useMemo } from "react";
import FancySearch from "../Logos/FancySearch";
import SideBar from "../SideBar/SideBar";
import { connect } from "react-redux";
import { getSectorsAction } from "../../modules/sectors/Actions";
import { sectorsSelector } from "../../modules/sectors/Selector";
import {
    membersCountSelector,
    membersSelector,
    membersIsLoadingSelector,
} from "../../modules/members/Selectors";
import { getMembersSearchAction } from "../../modules/members/Actions";
import { industriesSelector } from "../../modules/industry/Selector";
import { getActivitesAction } from "../../modules/activites/Actions";
import { activitesSelector } from "../../modules/activites/Selector";
import { getsubActivitesAction } from "../../modules/subActivity/Actions";
import { subactivitesSelector } from "../../modules/subActivity/Selector";
import SearchableSelect from "../Form/inputs/SearchableSelect";

import {
    membersSearchAsNameAndIdSelector,
    searchValueSelector,
} from "../../modules/search/Selectors";
import Advartsment from "../Advartsment/Advartsment";

const SearchSidebar = (props) => {
    const {
        getMembers,
        getSectors,
        sectors,
        getSubActivities,
        subActivities,
        getActivites,
        activites,
        isLoading,
        mappedMembers,
    } = props;
    const [active, setActive] = useState(props.active);
    const [sector, setSector] = useState("");
    const [activity, setActivity] = useState();
    useEffect(() => {
        getSectors();
    }, []);
    const MembersSectors = useMemo(() => [
        sectors.map((sector) => {
            return {
                value: sector._id,
                label: sector.secENm,
                secID: sector.secId,
            };
        }),
    ]);
    useEffect(() => {
        if (activity)
            getSubActivities({
                filterBy: [
                    { actId: Number(activity) },
                    { secId: Number(sector) },
                ],
            });
    }, [sector, activity]);

    useEffect(() => {
        if (sector) getActivites({ filterBy: [{ secId: Number(sector) }] });
    }, [sector]);

    return (
        <SideBar active={active ? "active" : ""} withHead>
            <Formik
                enableReinitialize
                initialValues={{
                    searchTerm: "",
                    sector: "",
                    activity: "",
                    subActivity: "",
                }}
                onSubmit={(values) => {
                    window.location.replace(
                        `/members?letter=${values?.searchTerm}&sector=${values?.sector}&subActivity=${values?.subActivity}&activity=${values.activity}`
                    );
                }}
            >
                {({ values, handleSubmit }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            position: "relative",
                            zIndex: "100",
                            color: "#082244",
                        }}
                    >
                        <div className="title">Advanced Search</div>

                        <SearchableSelect
                            name="searchTerm"
                            options={mappedMembers}
                            isLoading={false}
                            getItems={getMembers}
                        />

                        <label
                            htmlFor="search-sector"
                            className="form-label mt-4 mb-1"
                        >
                            Sector
                        </label>
                        <Field
                            as="select"
                            name="sector"
                            className="form-select"
                            style={{
                                border: "0",
                                padding: "8px",
                                color: "#808080",
                                fontSize: "15px",
                            }}
                        >
                            <option value="">Select...</option>
                            {MembersSectors[0].map((sector, index) => {
                                return (
                                    <option value={sector.secID} key={index}>
                                        {sector.label}
                                    </option>
                                );
                            })}
                        </Field>
                        {values.sector ? (
                            <>
                                {setSector(values.sector)}
                                <label
                                    htmlFor="search-activity"
                                    className="form-label mt-5"
                                >
                                    Activity
                                </label>
                                <Field
                                    as="select"
                                    name="activity"
                                    className="form-select"
                                    style={{
                                        border: "0",
                                        padding: "8px",
                                        color: "#808080",
                                        fontSize: "15px",
                                    }}
                                >
                                    <option>Select...</option>
                                    {activites.map((activity, index) => {
                                        return (
                                            <option
                                                value={activity.actId}
                                                key={index}
                                            >
                                                {activity.actENm}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </>
                        ) : (
                            ""
                        )}

                        {values.activity && values.sector ? (
                            <>
                                {setActivity(values.activity)}

                                <label
                                    htmlFor="search-activity"
                                    className="form-label mt-5"
                                >
                                    Sub Activity
                                </label>
                                <Field
                                    as="select"
                                    name="subActivity"
                                    className="form-select"
                                    style={{
                                        border: "0",
                                        padding: "8px",
                                        color: "#808080",
                                        fontSize: "15px",
                                    }}
                                >
                                    <option>Select...</option>
                                    {subActivities.map((subActivity, index) => {
                                        return (
                                            <option
                                                value={subActivity._id}
                                                key={index}
                                            >
                                                {subActivity.subacT_E_NM}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </>
                        ) : (
                            ""
                        )}
                        <button
                            type="submit"
                            style={{
                                width: "150px",
                                height: "40px",
                                letterSpacing: "5px",
                            }}
                            className="btn btn-primary searchAction my-4 "
                        >
                            <em className="fad fa-search"></em> Submit
                        </button>
                    </form>
                )}
            </Formik>
            <div className="search_fancy">
                <FancySearch />
            </div>
            <ul
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    listStyle: "none",
                    paddingLeft: "0rem",
                    position: "relative",
                    zIndex: "10",
                }}
            >
                <li>
                    <Advartsment
                        small
                        login
                        location="ADVANCED_SEARCH_BLOCK_1"
                    />
                </li>
                <li>
                    <Advartsment
                        small
                        login
                        location="ADVANCED_SEARCH_BLOCK_2"
                    />
                </li>
            </ul>
            <Advartsment location="ADVANCED_SEARCH_BLOCK_3" />
        </SideBar>
    );
};

const mapStateToProps = (state) => ({
    membersCount: membersCountSelector(state),
    members: membersSelector(state),
    sectors: sectorsSelector(state),
    industries: industriesSelector(state),
    activites: activitesSelector(state),
    subActivities: subactivitesSelector(state),
    searchValue: searchValueSelector(state),
    isLoading: membersIsLoadingSelector(state),
    mappedMembers: membersSearchAsNameAndIdSelector(state),
});

const mapDispatchToProps = {
    getMembers: getMembersSearchAction,
    getSectors: getSectorsAction,
    getSubActivities: getsubActivitesAction,
    getActivites: getActivitesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSidebar);
