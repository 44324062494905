import { useEffect, useState } from "react";
import Sidebar from "../../components/AdminSideBar/sidebar";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Input from "../../components/events/Input";
import moment from "moment";
import api from "../../helpers/api";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const EventB2B = () => {
    const history = useHistory();
    // const { addToast } = useToasts();
    // const location = useLocation();
    // const query = new URLSearchParams(location.search);
    // const b2bId = query.get("edit");
    // const [b2bMeetings, setB2bMeetings] = useState({
    //     name: "",
    //     startTime: "",
    //     endTime: "",
    // });
    // const [selectedEventId, setSelectedEventId] = useState("");
    // const [eventsList, setEventsList] = useState({ count: 0, content: [] });
    const [eventB2BList, setEventB2BList] = useState({
        count: 0,
        content: [],
    });
    // const [isCreateModal, setIsCreateModal] = useState(false);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     // Update the state with the new value
    //     setB2bMeetings({
    //         ...b2bMeetings,
    //         [name]: value,
    //     });
    // };

    // const getEvents = async () => {
    //     try {
    //         const { data } = await api.post("/events/search", {
    //             offset: 0,
    //             size: 50,
    //             sort: "createdAt",
    //             dir: "desc",
    //         });

    //         setEventsList(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // useEffect(() => {
    //     getEvents();
    // }, []);

    const getEventB2B = async (id) => {
        try {
            const { data } = await api.post("/event-sessions/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
                filterBy: [{ type: "B2B" }],
            });

            setEventB2BList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getEventB2B();
    }, []);

    const handleDeleteB2B = async (id) => {
        try {
            await api.delete(`/event-sessions/${id}`, {});

            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    // useEffect(() => {
    //     const currentB2B = JSON.parse(localStorage.getItem("b2b"));

    //     if (b2bId) {
    //         setB2bMeetings(currentB2B);
    //         setSelectedEventId(currentB2B?.eventId);
    //     }
    // }, [b2bId]);

    // const handleB2BAction = async () => {
    //     try {
    //         if (b2bId) {
    //             await api.put(`/event-sessions/${b2bId}`, {
    //                 eventId: selectedEventId,
    //                 ...b2bMeetings,
    //                 type: "B2B",
    //             });
    //         } else {
    //             await api.post("/event-sessions", {
    //                 eventId: selectedEventId,
    //                 ...b2bMeetings,
    //                 type: "B2B",
    //             });
    //         }

    //         window.location.reload();
    //     } catch (error) {
    //         console.error(error);
    //         addToast("Something went wrong", {
    //             id: "error",
    //             appearance: "error",
    //             autoDismiss: true,
    //         });
    //     }
    // };

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between">
                <h4>Event B2B</h4>
                <Button
                    variant="primary"
                    onClick={() => {
                        localStorage.removeItem("b2b");
                        // setB2bMeetings({
                        //     name: "",
                        //     startTime: "",
                        //     endTime: "",
                        // });
                        history.push(`/dashboard/create-b2b?edit=`);
                        // setSelectedEventId("");
                        // setIsCreateModal(true);
                    }}
                >
                    Create B2B
                </Button>
            </div>
            <div>
                {eventB2BList?.content?.length ? (
                    <Table
                        responsive
                        className="mt-5"
                        striped
                        bordered
                        hover
                        size="lg"
                    >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>name</th>
                                <th>event</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventB2BList?.content?.map((session, i) => (
                                <tr key={session?._id}>
                                    <td>{i + 1}</td>
                                    <td>{session?.name}</td>
                                    <td>{session?.event?.title}</td>
                                    <td>
                                        {moment(session?.startTime).format(
                                            "lll"
                                        )}
                                    </td>
                                    <td>
                                        {moment(session?.endTime).format("lll")}
                                    </td>
                                    <td>
                                        <div className="d-flex gap-3">
                                            <Button
                                                // variant="primary"
                                                size="sm"
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        "b2b",
                                                        JSON.stringify(session)
                                                    );
                                                    history.push(
                                                        `/dashboard/create-b2b?edit=${session?._id}`
                                                    );
                                                    // setIsCreateModal(true);
                                                }}
                                                disabled={
                                                    session?.event?.guests
                                                        ?.length
                                                        ? true
                                                        : false
                                                }
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="danger"
                                                onClick={() => {
                                                    handleDeleteB2B(
                                                        session?._id
                                                    );
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : null}
            </div>
            {/* <Modal
                show={isCreateModal}
                onHide={() => setIsCreateModal(false)}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Event B2B</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "30px" }}>
                    <Form.Label style={{ fontSize: "18px" }}>
                        Select B2B Event
                    </Form.Label>
                    <Form.Select
                        aria-label=""
                        size="lg"
                        style={{
                            height: "50px",
                            marginBottom: "30px",
                            fontSize: "18px",
                        }}
                        name="country"
                        value={selectedEventId}
                        onChange={(e) => setSelectedEventId(e.target.value)}
                    >
                        <option>Open this select event</option>
                        {eventsList?.content
                            ?.filter(
                                (event) => !event?.guests[0]?.schedules?.length
                            )
                            ?.map((event) => (
                                <option value={event?._id} key={event?._id}>
                                    {event?.title}
                                </option>
                            ))}
                    </Form.Select>
                    <Row style={{ marginTop: "30px" }}>
                        <Col>
                            <Input
                                id="name"
                                label="Meeting Name"
                                placeholder="Type Meeting Name"
                                value={b2bMeetings.name}
                                name="name"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col>
                            <Input
                                type="datetime-local"
                                id="startMeetingTime"
                                label="Start Meeting Time"
                                placeholder="Type Start Meeting Time"
                                value={
                                    b2bMeetings.startTime
                                        ? moment(b2bMeetings?.startTime).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : ""
                                }
                                name="startTime"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col>
                            <Input
                                type="datetime-local"
                                id="endMeetingTime"
                                label="End Meeting Time"
                                placeholder="Type End Meeting Time"
                                value={
                                    b2bMeetings.endTime
                                        ? moment(b2bMeetings?.endTime).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : ""
                                }
                                name="endTime"
                                onChange={handleChange}
                                min={b2bMeetings?.startTime}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsCreateModal(false)}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleB2BAction}>
                        {b2bId ? "Edit B2B" : "Create B2B"}
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </Sidebar>
    );
};

export default EventB2B;
