import React, { useState, useEffect } from "react";
import MobileNavBar from "./MobileNaBar";
import { Link, NavLink } from "react-router-dom";
import SearchSidebar from "../Search/SearchSidebar";
import { connect, useSelector } from "react-redux";
import { logoutUserAction } from "../../modules/auth/Actions";
import Image from "../../assets/images/logo-new.png";
import Members from "../../pages/AddUsers/index";
import Ads from "../../pages/AddAds/index";
import { getCookie } from "../../helpers/cookies";
import { Dropdown } from "react-bootstrap";

const NavBar = (props) => {
    const [active, setActive] = useState(false);

    const handelActive = () => {
        setActive(!active);
    };
    useEffect(() => {
        return () => setActive(true);
    }, [active]);

    const isAuth = getCookie("TOKEN");
    const userName = localStorage.getItem("username");
    return (
        <div
            className="d-flex main--navbar align-items-center"
            //position-fixed start-0 w-100  p-3 justify-content-center align-items-center"
            //style={{zIndex:"10" , backgroundColor:"#f9f7f2c4" , borderRadius:"1px 1px 45px 45px"}}
        >
            <div
                className="logo d-flex"
                style={{
                    width: "250px",
                    borderRadius: "5px",
                    // paddingLeft:"10px"
                }}
            >
                <Link to="/">
                    <img src={Image} style={{ width: "250px" }} />
                </Link>
            </div>
            <div className="nav--list">
                <ul className="nav desktop">
                    {isAuth &&
                        localStorage.getItem("role") !== "non_member" &&
                        localStorage.getItem("role") !== "Delegation" && (
                            <>
                                <li className="nav-item">
                                    <NavLink
                                        to="/"
                                        className="nav-link"
                                        // style={{color:"#02224c" , fontWeight:"400"}}
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item" id="sidebar">
                                    <a
                                        className="nav-link"
                                        onClick={handelActive}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Search
                                    </a>
                                    {/* <!-- Advanced Sidebar --> */}
                                    {active && (
                                        <SearchSidebar
                                            active={active ? "active" : ""}
                                        />
                                    )}
                                </li>
                            </>
                        )}
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/events"
                            //style={{color:"#02224c" , fontWeight:"400"}}
                        >
                            Events
                        </NavLink>
                    </li>
                    {!isAuth && (
                        <li className="nav-item">
                            <NavLink
                                to="/login"
                                className="nav-link"
                                // style={{color:"#02224c" , fontWeight:"400"}}
                            >
                                Login
                            </NavLink>
                        </li>
                    )}
                    {isAuth && (
                        <li className="nav-item">
                            <NavLink
                                className="nav-link"
                                to="/contactus"
                                //style={{color:"#02224c" , fontWeight:"400"}}
                            >
                                Contact Us
                            </NavLink>
                        </li>
                    )}
                    {isAuth && (
                        <Dropdown>
                            <Dropdown.Toggle
                                style={{ background: "var(--primary-color)" }}
                                id="dropdown-basic"
                            >
                                {userName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {localStorage.getItem("role") ===
                                "superAdmin" ? (
                                    <Dropdown.Item href="/dashboard/users">
                                        Dashboard
                                    </Dropdown.Item>
                                ) : null}
                                {localStorage.getItem("role") === "member" ? (
                                    <Dropdown.Item href="/dashboard/schedules">
                                        Dashboard
                                    </Dropdown.Item>
                                ) : null}
                                {localStorage.getItem("role") ===
                                "non_member" ? (
                                    <Dropdown.Item href="/dashboard/schedules">
                                        Dashboard
                                    </Dropdown.Item>
                                ) : null}
                                {localStorage.getItem("role") ===
                                "Delegation" ? (
                                    <Dropdown.Item href="/dashboard/schedules">
                                        Dashboard
                                    </Dropdown.Item>
                                ) : null}
                                <Dropdown.Item
                                    onClick={() => props.logoutUser()}
                                    href="/login"
                                >
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {/* <div class="dropdown">
                        <button
                            class="btn btn-primary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {userName}
                        </button>
                        <ul
                            class="dropdown-menu w-100  text-dark"
                            aria-labelledby="dropdownMenuButton1"
                        >
                            {localStorage.getItem("role") == "superAdmin" ? (
                                <li className="dropdown-item">
                                    <Link
                                        to="/dashboard/users"
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        Dashboard
                                    </Link>
                                </li>
                            ) : null}
                            <li
                                className="dropdown-item"
                                onClick={() => props.logoutUser()}
                            >
                                <a
                                    href="/login"
                                    style={{
                                        textDecoration: "none",
                                    }}
                                    onClick={props.logoutUser}
                                >
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div> */}
                </ul>
                <MobileNavBar />
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    logoutUser: logoutUserAction,
};

export default connect(null, mapDispatchToProps)(NavBar);
