import React, { useState, useEffect } from "react";
import TextInput from "../Form/inputs/TextInput";
import SearchSidebar from "../Search/SearchSidebar";
import { Formik } from "formik";
import { connect } from "react-redux";
import { getMembersSearchAction } from "../../modules/members/Actions";
import {
    membersCountSelector,
    membersSelector,
    membersIsLoadingSelector,
    membersSearchSelector,
    membersSearchCountSelector,
} from "../../modules/members/Selectors";
import {
    membersAsNameAndIdSelector,
    membersSearchAsNameAndIdSelector,
    searchValueSelector,
} from "../../modules/search/Selectors";
import { NavLink } from "react-router-dom";
import SearchableSelect from "../Form/inputs/SearchableSelect";
import SideHead from "../Heads/SideHead";
import ShadowHead from "../Heads/ShadowHead";

const SearchForm = (props) => {
    const { children, getMembers, isLoading, mappedMembers } = props;
    const [active, setActive] = useState(false);

    const handelActive = () => {
        setActive(!active);
    };

    return (
        <div className="search" style={{ color: "#082244" }}>
            {/* <!-- Header --> */}
            {!props.hide && (
                <div className="header mb-2">
                    <div className="title me-auto my-auto">Search</div>
                    <a className="btn my-auto" onClick={handelActive}>
                        <em className="fad fa-search me-3"></em>Advanced Search
                    </a>

                    {active && (
                        <SearchSidebar active={active ? "active" : ""} />
                    )}
                </div>
            )}
            {/* <!-- Form --> */}
            <Formik
                enableReinitialize
                initialValues={{
                    offset: 0,
                    size: 6,
                    sort: "name",
                    dir: "asc",
                    searchTerm: "",
                    filterBy: [],
                }}
                onSubmit={(values) => {
                    getMembers(values);
                    window.scrollBy(100, 700);
                }}
            >
                {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            {/* <TextInput name='searchTerm' placeholder='Name / Company' label="Name / Company" /> */}
                            <SearchableSelect
                                name="searchTerm"
                                options={mappedMembers}
                                isLoading={isLoading}
                                getItems={getMembers}
                            />
                        </div>
                        {children}
                        {/* {window.location.pathname == '/members' ? (
              <NavLink
                to={{
                  search: `searchTerm=${values?.searchTerm}`,
                }}
                type='submit'
                className='btn btn-primary searchAction '
              >
                <em className='fad fa-search'></em> Submit
              </NavLink>
            ) : (
              <NavLink
                to={{
                  pathname: `/members`,
                  search: `searchTerm=${values?.searchTerm}`,
                }}
                type='submit'
                className='btn btn-primary searchAction '
              >
                <em className='fad fa-search'></em> Submit
              </NavLink>
            )} */}
                        <a
                            href={`/members?searchTerm=${values?.searchTerm}`}
                            type="submit"
                            className="btn btn-primary d-flex align-items-center gap-3 justify-content-center"
                            style={
                                {
                                    // width: "150px",
                                    // height: "40px",
                                    // letterSpacing: "5px",
                                }
                            }
                        >
                            <em
                                className="fad fa-search"
                                style={{ fontSize: "20px" }}
                            ></em>{" "}
                            <h5 style={{ margin: 0 }}>Submit</h5>
                        </a>
                    </form>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = (state) => ({
    memberssCount: membersSearchCountSelector(state),
    members: membersSearchSelector(state),
    searchValue: searchValueSelector(state),
    isLoading: membersIsLoadingSelector(state),
    mappedMembers: membersSearchAsNameAndIdSelector(state),
});

const mapDispatchToProps = {
    getMembers: getMembersSearchAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
