import axios from "axios";

import { API_URL, LIVE_API_URL, TOKEN } from "../constants";
import Cookie from "js-cookie";

import { getCookie } from "./cookies";

const axiosInstance = axios.create({
    baseURL:
        process.env.REACT_APP_NODE_ENV === "development"
            ? `${API_URL}/api`
            : `${LIVE_API_URL}/api`,
    responseType: "json",
});

axiosInstance.interceptors.request.use((config) => {
    const token = getCookie("TOKEN");
    const authorizedConfig = { ...config };
    if (token) {
        authorizedConfig.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            // axios.get('/api/auth/refresh').then
            Cookie.remove("TOKEN");
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);
export default axiosInstance;
