import SearchPage from '../../components/Search';
import Advertsment from '../../components/Advartsment/Advartsment';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import { useEffect } from 'react';
import Members from '../../components/Combine/Members/Members';
import ShadowHead from '../../components/Heads/ShadowHead'
import {getAdsAction} from '../../modules/ads/Actions'
import { connect } from 'react-redux';
const Pioneers = (props) => {
  useEffect(()=>{
    props.getAds({filterBy: [{ location:'pioneers' }] })
  
   },[])
  useEffect(()=>{
      window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth'
        })
      
  },[])
  return (
    <Layout>
      <Header>
        <SearchPage/>
        <Advertsment location="PIONEERS_BLOCK_1" />
        <Advertsment location="PIONEERS_BLOCK_2" />
      </Header>
      <div className="section-block members--slider"> 
        <div className="container">
        <ShadowHead header={"EBA Pioneers"} />
        </div>
      </div>
      <Members   />
    
      <Advertsment location="PIONEERS_BLOCK_3" />

    </Layout>
  );
};

const mapDispatchToProps = {
  getAds: getAdsAction,
};

export default connect(null, mapDispatchToProps)(Pioneers);

