/* eslint-disable object-curly-newline */
import {call, put, select, takeLatest, takeEvery} from "redux-saga/effects";

import {
  newErrorNotificationAction,
  newSuccessNotificationAction,
} from "../notifications/Actions";

import { getActivitesSuccessAction, getActivitySuccessAction, setActivitesIsLoadingAction , getActivitesCountAction, getActivitesHeaderSuccessAction } from "./Actions";
import * as api from "./Api";
import {actions} from "./Reducer";

// Plural
function* getActivitesSaga({payload}) {
  const defaultBody ={
    offset: 0,
    size: 50,
    sort: "name",
    dir: "asc",
    searchTerm: "",
    filterBy: []
}

  const body = {...defaultBody, ...payload};
  try {
    yield put(setActivitesIsLoadingAction(true));
    const response = yield call(api.getActivites, body);
    const {data} = response;
    const items = data.content;
    const count = data.count;

    yield put(getActivitesSuccessAction(items));
    yield put(getActivitesCountAction(count));
  } catch (error) {
      console.log(error)
    //yield put(errorNotificationFromError(error));
  } finally {
    yield put(setActivitesIsLoadingAction(false));
  }
}

function* getActivitesHeaderSaga({payload}) {
  const defaultBody ={
    offset: 0,
    size: 50,
    sort: "name",
    dir: "asc",
    searchTerm: "",
    filterBy: []
}

  const body = {...defaultBody, ...payload};
  try {
    yield put(setActivitesIsLoadingAction(true));
    const response = yield call(api.getActivites, body);
    const {data} = response;
    const items = data.content;
    const count = data.count;

    yield put(getActivitesHeaderSuccessAction(items));
  } catch (error) {
      console.log(error)
    //yield put(errorNotificationFromError(error));
  } finally {
    yield put(setActivitesIsLoadingAction(false));
  }
}


function* getActivitySaga({payload}) {
  const activityId = payload;
  try {
  //  yield put(resetMembersAction())
    yield put(setActivitesIsLoadingAction(true));

    const response = yield call(api.getActivity, activityId);
    const {data} = response;
    yield put(getActivitySuccessAction(data));
  } catch (error) {
    yield put(newErrorNotificationAction("no activities found"));
  } finally {
    yield put(setActivitesIsLoadingAction(false));
  }
}


export default function* () {
 
  yield takeLatest(actions.GET_ASTIVITES, getActivitesSaga);
 yield takeLatest(actions.GET_ACTIVITY,getActivitySaga)
 yield takeLatest(actions.GET_ACTIVITESHEADER,getActivitesHeaderSaga)

 
}
