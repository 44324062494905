import {createSelector} from "reselect";

const authStateSelector = (state) => state.auth;


export const authTokenSelector = createSelector(
  [authStateSelector],
  (auth) => auth.token
);

export const authCurrentUserSelector = createSelector(
  [authStateSelector],
  (auth) => auth.user
);

export const currentUserIdSelector = createSelector(
  [authCurrentUserSelector],
  (user) => user._id
);








export const authCurrentUserTypeSelector = createSelector(
  [authCurrentUserSelector],
  (user) => {
    if (user) {
      return user.userType;
    }
    return [];
  }
);



// Misc
export const authIsLoadingSelector = createSelector(
  [authStateSelector],
  (auth) => auth.isLoading
);
