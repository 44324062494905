import React from "react";
import Sidebar from "../../components/AdminSideBar/sidebar";
import Subheader from "../../components/Subheader/Subheader";

const Dashboard = () => {
    return (
        <Sidebar>
            <Subheader title="Welcome"></Subheader>
        </Sidebar>
    );
};

export default Dashboard;
