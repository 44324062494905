import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import {Field, Formik} from "formik";
import beforeSubmit from "../../helpers/beforeSubmit";
import FormGroup from '../../components/Form/FormGroup';
import TextInput from "../../components/Form/inputs/TextInput";
import { connect } from 'react-redux';
import feedbackSchema from './feedbackSchema';
import { createFeedBackAction } from '../../modules/help/Actions';

const  Help = (props)=> {

   const {createFeedback} = props
        return ( 
            <Layout>
            <Header>
               <div className="container" >
                   <h3>Contact us</h3>
                   <div className="row">
                   <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                     <iframe 
                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1727.2645153931962!2d31.215445658068134!3d30.021676995472653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145846df5e90233b%3A0x444d7bd4b618ac50!2sEgyptian+Businessmen+Association+-+EBA!5e0!3m2!1sen!2sus!4v1558861316382!5m2!1sen!2sus" 
                     style={{border:"0" }}
                     allowfullscreen="" 
                     width="100%" 
                     height="450" f
                     rameborder="0"></iframe>
                    </div>
                   </div>
               </div>
            </Header>
             <div className="container mt-5">
                <div className="row">
                <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                    <div class="contactForm px-5">
                        <h1>Your Feedback</h1>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                name:"",
                                email:"",
                                phone:"",
                                comment:""
                            }}
                            validationSchema={feedbackSchema}
                            onSubmit={(values , { resetForm }) => {
                              
                               
                                createFeedback(values)
                                resetForm()
                            }}
                            
                            >
                        {({handleSubmit, values,resetForm}) => {
                            return (
                            <FormGroup
                                heading=""
                                handleSubmit={handleSubmit}
                            
                            >
                                <TextInput name='name' placeholder='Full Name' />
                                <TextInput name='email' placeholder='Email' type="text"/>
                                <TextInput name='phone' placeholder='Phone' type="text"/>
                                <Field as="textarea" name="comment" placeholder="comment" className="form-control my-5"></Field>
                            </FormGroup>
                            );
                        }}
                        </Formik>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="address px-3">
                        <h1>Contacts</h1>
                        <p><strong>Address:</strong> 21 Charles de Gaulle st. – Nile Tower- Giza District, Giza Governorate – Egypt</p>
                        <p><strong>Tel:</strong> + (202) 3572 3020 / + (202) 3573 6030</p>
                        <p><strong>Mob:</strong> 010 0538 4604 / 010 0538 4605</p>
                        <p><strong>Fax:</strong> + (202) 3572 3020 / + (202) 3573 6030</p>
                        <p><strong>E-mail:</strong> <a href="mailto:eba@eba.org.eg">eba@eba.org.eg</a></p>
                        <p><strong>Website:</strong> <a href="http://www.eba.org.eg">www.eba.org.eg</a></p>
                    </div>
                </div>
            </div>
            </div>
          </Layout>
         );
 
}
 
const mapDispatchToProps = {
    createFeedback : createFeedBackAction
  }

export default connect(null, mapDispatchToProps)(Help);
