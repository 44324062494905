import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Header from "../../components/Header/Header";
import SearchPage from "../../components/Search";
import Categories from "../../components/Combine/Categories";
import CreateEventForm from "../../components/events/step1/CreateEventForm";
import EventInfo from "../../components/events/step1/EventInfo";
import Sidebar from "../../components/AdminSideBar/sidebar";
import Step1 from "../../components/events/step1";
import Step2 from "../../components/events/step2";
import Step3 from "../../components/events/step3";
import { Button } from "react-bootstrap";
import api from "../../helpers/api";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router-dom";

const EventCreate = () => {
    const location = useLocation();
    const { addToast } = useToasts();
    const initialStep = parseInt(localStorage.getItem("currentStep")) || 0;
    const [currentStep, setCurrentStep] = useState(initialStep);
    const steps = [
        <Step1 currentStep={currentStep} />,
        // <Step2 currentStep={currentStep} />,
        <Step3 currentStep={currentStep} />,
    ];
    const query = new URLSearchParams(location.search);
    const eventId = query.get("edit");

    useEffect(() => {
        localStorage.setItem("currentStep", currentStep.toString());
    }, [currentStep]);

    const handleNextStep = () => {
        setCurrentStep((prevIndex) =>
            Math.min(prevIndex + 1, steps.length - 1)
        );
    };

    const handlePreviousStep = () => {
        setCurrentStep((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleEventAction = async () => {
        try {
            const pricing = JSON.parse(localStorage.getItem("eventStepTwo"));
            const body = {
                ...JSON.parse(localStorage.getItem("eventStepOne")),
                pricing,
            };

            if (eventId) {
                // handle edit
                await api.put(`/events/${eventId}`, {
                    ...body,
                    numberOfSeats: Number(body.numberOfSeats),
                });
            } else {
                await api.post("/events", {
                    ...body,
                    numberOfSeats: Number(body.numberOfSeats),
                });
            }

            localStorage.removeItem("eventStepOne");
            localStorage.removeItem("eventStepTwo");
            window.location.pathname = "/dashboard/events";
            addToast("Event Created Successfully", {
                id: "success",
                appearance: "success",
                autoDismiss: true,
            });
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    console.log({ location, eventId });

    return (
        <Sidebar>
            <div>{steps[currentStep]}</div>
            <div className="d-flex align-items-center justify-content-start gap-3 mt-5 pb-4">
                {currentStep === steps.length - 1 ? (
                    <Button variant="primary" onClick={handleEventAction}>
                        {eventId ? "Edit Event" : "Create Event"}
                    </Button>
                ) : null}
                {currentStep === 0 ? null : (
                    <button
                        type="button"
                        class="btn btn-primary"
                        disabled={currentStep === 0}
                        onClick={handlePreviousStep}
                    >
                        Prev
                    </button>
                )}
                {currentStep === steps.length - 1 ? null : (
                    <button
                        type="button"
                        class="btn btn-primary"
                        disabled={currentStep === steps.length - 1}
                        onClick={handleNextStep}
                    >
                        Next
                    </button>
                )}
            </div>
        </Sidebar>
    );
};

export default EventCreate;
