import React, { useRef } from "react";
import styled from "styled-components";
import ReactToPrint from 'react-to-print';
import MemberData from "./memberData";

const PrintProfile = (props) => {

  const componentRef = useRef();
  return (
    <div >
      <a class="nav-link p-0" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ cursor: 'pointer', textDecoration: 'underline', fontSize: '20px', letterSpacing: '1.5px' }}>
        Print
      </a>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Member Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div ref={componentRef}>
                <MemberData data={props?.data} />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <ReactToPrint
                trigger={() => <button className="btn btn-primary mx-3 mt-2">Print this out!</button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const ContentContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ListItemDetails = styled.div`
  padding: 0 !important;
  margin-right: 10px;
  flex: 1;
  flex-shrink: 0;
`;

export default PrintProfile;