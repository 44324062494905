import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import SearchSidebar from "../Search/SearchSidebar";
import { logoutUserAction } from "../../modules/auth/Actions";
import { connect } from "react-redux";
import Image from "../../assets/images/pic.png";
import Members from "../../pages/AddUsers/index";
import Ads from "../../pages/AddAds/index";

const MobileNavBar = (props) => {
    const [active, setActive] = useState(false);
    const [isOpend, setIsOpend] = useState("");

    const handelActive = () => {
        if (isOpend == "active") setIsOpend("");
        else setIsOpend("active");
    };
    const handelOpen = () => {
        setActive(!active);
    };
    return (
        <ul className="nav mobile">
            <li className="nav-item">
                <a
                    className="nav-link"
                    href="javascript:;"
                    data-sidebar_trigger="searchSidebarnav2"
                    onClick={handelOpen}
                >
                    <em className="fad fa-search"></em>
                </a>

                {/* <!-- Advanced Sidebar --> */}
                {active && <SearchSidebar active={active ? "active" : ""} />}
            </li>
            <li className="nav-item">
                <a
                    className="nav-link"
                    href="javascript:;"
                    data-sidebar_trigger="menuMobile"
                    onClick={handelActive}
                >
                    <em className="fad fa-bars"></em>
                </a>

                {/* <!-- Menu Mobile Sidebar --> */}
                <div className="sidebar_container">
                    <div
                        id="menuMobile"
                        data-sidebar_container="menuMobile"
                        className={`fixed--sidebar menuMobile left ${isOpend}`}
                        style={{
                            width: "285px",
                        }}
                    >
                        {/* <!-- Close Button --> */}
                        <button
                            className="btn with-icon close"
                            type="button"
                            onClick={handelActive}
                        >
                            <div className="icon">
                                <em className="fad fa-times"></em>
                            </div>
                            Close
                        </button>

                        {/* <!-- Content -->  */}
                        <div className="fixed--sidebar_container custom--scrollbar">
                            <div className="header">
                                <div className="">
                                    <div
                                        className="logo d-flex"
                                        style={{
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <Link to="/">
                                            <img src={Image} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between w-50 flex-column">
                                <ul className="nav inner">
                                    <li
                                        className="nav-item"
                                        // style={{ paddingBlock: "25px" }}
                                    >
                                        <NavLink
                                            className="nav-link"
                                            // aria-current="page"
                                            to="/home"
                                        >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li
                                        className="nav-item"
                                        style={{ paddingBlock: "25px" }}
                                    >
                                        <NavLink
                                            className="nav-link"
                                            // aria-current="page"
                                            to="/events"
                                        >
                                            Events
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link"
                                            to="/contactus"
                                        >
                                            Contact us
                                        </NavLink>
                                    </li>
                                </ul>

                                <ul
                                    className="nav inner"
                                    style={{ marginTop: "30rem" }}
                                >
                                    {localStorage.getItem("role") ===
                                    "superAdmin" ? (
                                        <li
                                            className="nav-item"
                                            style={{ paddingBlock: "25px" }}
                                        >
                                            <Link
                                                className="action mt-4"
                                                to="/dashboard/users"
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                Dashboard
                                            </Link>
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                    {localStorage.getItem("role") ===
                                    "member" ? (
                                        <li
                                            className="nav-item"
                                            style={{ paddingBlock: "25px" }}
                                        >
                                            <Link
                                                className="action mt-4"
                                                to="/dashboard/schedules"
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                Dashboard
                                            </Link>
                                        </li>
                                    ) : null}
                                    <li className="nav-item">
                                        <a
                                            className=" action mt-4"
                                            href="/login"
                                            role="button"
                                            onClick={props.logoutUser}
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <em className="fad fa-sign-out"></em>
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
};

const mapDispatchToProps = {
    logoutUser: logoutUserAction,
};

export default connect(null, mapDispatchToProps)(MobileNavBar);
