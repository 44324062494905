const FancySearch = () =>{
    return(
        <svg viewBox="0 0 520 137">
        <g opacity="0.04">
            <path
                d="M45.8361 39.0499C54.7608 39.0499 62.6184 40.4423 69.4089 43.2271C76.2965 45.916 82.2624 49.9012 87.3068 55.1828L75.2294 71.1717C71.0581 66.8504 66.5472 63.6334 61.6968 61.5208C56.9435 59.3121 51.511 58.2078 45.3995 58.2078C41.8103 58.2078 38.9 58.5919 36.6689 59.3601C34.4377 60.1283 32.8371 61.1847 31.867 62.5291C30.8969 63.8735 30.4119 65.41 30.4119 67.1385C30.4119 69.1551 31.3334 70.8837 33.1766 72.3241C35.1167 73.6685 38.221 74.8209 42.4893 75.7812L58.6411 79.3823C68.2448 81.591 75.2779 84.8079 79.7402 89.0332C84.2026 93.2585 86.4337 98.8763 86.4337 105.886C86.4337 112.608 84.6876 118.322 81.1953 123.028C77.7031 127.637 72.9012 131.142 66.7897 133.543C60.6782 135.848 53.6937 137 45.8361 137C39.4336 137 33.3221 136.328 27.5016 134.983C21.6812 133.543 16.4428 131.526 11.7864 128.934C7.13006 126.341 3.20125 123.364 0 120.003L12.0774 103.438C14.5026 106.223 17.4614 108.719 20.9536 110.928C24.5429 113.041 28.4232 114.721 32.5945 115.97C36.8629 117.218 41.1797 117.842 45.5451 117.842C48.9403 117.842 51.7535 117.506 53.9847 116.834C56.3129 116.162 58.0105 115.153 59.0776 113.809C60.1447 112.464 60.6782 110.88 60.6782 109.055C60.6782 107.039 59.9507 105.358 58.4956 104.014C57.0405 102.573 54.2757 101.373 50.2014 100.413L32.449 96.5235C27.0166 95.2752 22.1662 93.6427 17.8979 91.626C13.7266 89.5134 10.4283 86.7285 8.00313 83.2715C5.57794 79.7184 4.36534 75.301 4.36534 70.0194C4.36534 64.0656 6.01447 58.7839 9.31273 54.1745C12.611 49.4691 17.3644 45.7719 23.5728 43.0831C29.7813 40.3943 37.2024 39.0499 45.8361 39.0499Z"
                fill="white"
            />
            <path
                d="M135.839 137C127.788 137 120.803 135.512 114.886 132.535C109.065 129.558 104.554 125.284 101.353 119.715C98.152 114.145 96.5513 107.423 96.5513 99.5485C96.5513 91.6741 98.152 84.952 101.353 79.3823C104.554 73.8126 109.017 69.5392 114.74 66.5623C120.561 63.5854 127.206 62.0969 134.675 62.0969C142.339 62.0969 148.838 63.5854 154.174 66.5623C159.606 69.4432 163.729 73.4765 166.542 78.662C169.356 83.7516 170.762 89.6094 170.762 96.2355C170.762 97.964 170.714 99.6925 170.617 101.421C170.52 103.15 170.374 104.59 170.18 105.742H112.558V92.6344H159.267L147.48 97.0997C147.48 91.338 146.413 86.9206 144.279 83.8476C142.242 80.7747 139.041 79.2382 134.675 79.2382C131.571 79.2382 128.903 79.9584 126.672 81.3989C124.538 82.8393 122.889 85.096 121.725 88.169C120.658 91.2419 120.124 95.1791 120.124 99.9806C120.124 104.686 120.755 108.527 122.016 111.504C123.277 114.385 125.072 116.498 127.4 117.842C129.728 119.09 132.541 119.715 135.839 119.715C139.526 119.715 142.436 118.994 144.57 117.554C146.801 116.114 148.45 114.097 149.517 111.504L169.307 117.986C167.949 122.115 165.572 125.621 162.177 128.501C158.879 131.286 154.901 133.399 150.245 134.839C145.686 136.28 140.884 137 135.839 137Z"
                fill="white"
            />
            <path
                d="M225.198 89.8975C225.198 86.5365 224.228 83.8957 222.288 81.9751C220.445 79.9585 217.923 78.9501 214.721 78.9501C211.52 78.9501 208.755 79.7664 206.427 81.3989C204.196 82.9354 202.644 85.7202 201.771 89.7535L180.817 84.856C182.175 77.9418 185.765 72.4201 191.585 68.2909C197.405 64.1616 205.603 62.0969 216.176 62.0969C223.258 62.0969 229.272 63.1533 234.22 65.2659C239.264 67.2825 243.096 70.3555 245.715 74.4848C248.431 78.518 249.79 83.5115 249.79 89.4654V115.825C249.79 119.571 251.487 121.443 254.882 121.443C256.629 121.443 258.035 121.251 259.102 120.867L257.647 134.839C254.737 136.184 251.196 136.856 247.025 136.856C243.242 136.856 239.895 136.28 236.985 135.127C234.074 133.879 231.795 132.054 230.146 129.654C228.496 127.157 227.672 124.036 227.672 120.291V119.427L230.437 118.418C230.437 122.548 229.175 126.005 226.653 128.789C224.228 131.574 221.027 133.639 217.05 134.983C213.169 136.328 209.095 137 204.827 137C199.879 137 195.562 136.232 191.876 134.695C188.19 133.159 185.328 130.95 183.291 128.069C181.351 125.092 180.381 121.443 180.381 117.122C180.381 111.936 181.933 107.663 185.037 104.302C188.238 100.845 193.234 98.4441 200.025 97.0997L227.526 91.482L227.381 104.446L213.412 107.327C210.502 107.903 208.319 108.767 206.864 109.92C205.506 110.976 204.827 112.608 204.827 114.817C204.827 116.93 205.603 118.514 207.155 119.571C208.707 120.627 210.55 121.155 212.684 121.155C214.333 121.155 215.885 120.915 217.341 120.435C218.893 119.955 220.251 119.235 221.415 118.274C222.579 117.218 223.501 115.922 224.18 114.385C224.859 112.849 225.198 111.024 225.198 108.911V89.8975Z"
                fill="white"
            />
            <path
                d="M268.866 135.56V63.5374H290.693L291.712 79.0942C293.555 73.7165 296.271 69.5392 299.86 66.5623C303.547 63.5854 308.203 62.0969 313.829 62.0969C315.672 62.0969 317.225 62.241 318.486 62.5291C319.844 62.8172 320.911 63.2013 321.687 63.6814L319.213 83.8476C318.243 83.4635 316.982 83.1754 315.43 82.9834C313.975 82.6953 312.18 82.5512 310.046 82.5512C307.136 82.5512 304.42 83.1754 301.897 84.4238C299.375 85.6722 297.338 87.5448 295.786 90.0416C294.331 92.4423 293.603 95.5152 293.603 99.2604V135.56H268.866Z"
                fill="white"
            />
            <path
                d="M365.024 62.0969C372.397 62.0969 378.508 63.1533 383.359 65.2659C388.306 67.2825 392.138 70.0194 394.854 73.4765C397.667 76.8375 399.414 80.4866 400.093 84.4238L377.247 91.9141C376.568 87.7849 375.307 84.7119 373.464 82.6953C371.718 80.5826 369.147 79.5263 365.752 79.5263C362.648 79.5263 360.028 80.2465 357.894 81.687C355.857 83.0314 354.305 85.2401 353.238 88.313C352.171 91.2899 351.637 95.1311 351.637 99.8366C351.637 104.542 352.219 108.383 353.383 111.36C354.548 114.241 356.197 116.354 358.331 117.698C360.465 118.946 362.987 119.571 365.897 119.571C368.323 119.571 370.311 119.139 371.863 118.274C373.513 117.314 374.871 115.97 375.938 114.241C377.005 112.512 377.684 110.4 377.975 107.903L400.238 114.961C399.559 119.187 397.716 122.98 394.709 126.341C391.798 129.606 387.918 132.199 383.068 134.119C378.217 136.04 372.542 137 366.043 137C358.088 137 351.152 135.56 345.235 132.679C339.317 129.702 334.758 125.428 331.557 119.859C328.355 114.193 326.755 107.423 326.755 99.5485C326.755 91.6741 328.355 84.952 331.557 79.3823C334.758 73.7165 339.22 69.4432 344.944 66.5623C350.764 63.5854 357.458 62.0969 365.024 62.0969Z"
                fill="white"
            />
            <path
                d="M413.099 135.56V33H437.836V76.6454C439.97 71.8439 443.026 68.2428 447.003 65.8421C450.98 63.3453 455.588 62.0969 460.827 62.0969C465.871 62.0969 470.236 63.0572 473.923 64.9778C477.706 66.8984 480.665 69.7313 482.799 73.4765C484.933 77.2216 486 81.9271 486 87.5928V135.56H461.263V94.3629C461.263 89.1773 460.293 85.6242 458.353 83.7036C456.51 81.687 454.036 80.6787 450.932 80.6787C448.604 80.6787 446.421 81.2548 444.384 82.4072C442.347 83.5596 440.746 85.3841 439.582 87.8809C438.418 90.3777 437.836 93.6427 437.836 97.6759V135.56H413.099Z"
                fill="white"
            />
            <path
                d="M481.041 55.4965L481.041 57.4829L483.027 57.4972L489.807 57.5458L491.821 57.5603L491.822 55.5466L491.828 38.828L508.547 38.8217L510.56 38.8209L510.546 36.8074L510.497 30.0271L510.483 28.0407L508.497 28.0414L491.834 28.0472L491.814 11.4687L491.811 9.48535L489.828 9.47112L483.048 9.42248L481.034 9.40803L481.033 11.4218L481.028 28.0282L464.422 28.0335L462.408 28.0341L462.422 30.0478L462.471 36.8281L462.485 38.8114L464.469 38.8138L481.047 38.8337L481.041 55.4965Z"
                fill="white"
                stroke="white"
                stroke-width="4"
            />
        </g>
    </svg>
    )
}

export default FancySearch;