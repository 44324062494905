
import {call, put, select, takeLatest, takeEvery, take } from "redux-saga/effects";
import membersForm from "../../pages/AddUsers/membersForm";

import {
  newErrorNotificationAction,
  newSuccessNotificationAction,
  errorNotificationFromError
} from "../notifications/Actions";
import { createFeedBackSuccessAction } from "./Actions";


import * as api from "./Api";
import {actions} from "./Reducer";



function* createFeedbackSaga({payload}) {
  const adFormData = payload;
  try {
    const response = yield call(api.postFeedback, {...adFormData});
    const {data} = response;
    const feedback = data;
    yield put(createFeedBackSuccessAction(feedback));
    yield put(newSuccessNotificationAction("thank you"));
  } catch (error) {
    yield put(newSuccessNotificationAction("thank you"));
  } finally {
  }

  
}





export default function* () {
 

  yield takeLatest(actions.CREATE_FEEDBACK , createFeedbackSaga)
 
}
