import React, { useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import MembersList from "./membersList";
import { Formik } from "formik";
import { getMembersAction } from "../../modules/members/Actions";
import { membersSelector } from "../../modules/members/Selectors";
import Subheader from "../../components/Subheader/Subheader";
import Sidebar from "../../components/AdminSideBar/sidebar";
import TextInput from "../../components/Form/inputs/TextInput";
import MembersForm from "./membersForm";
import { IoPulseOutline } from "react-icons/io5";
import { LuPlusCircle } from "react-icons/lu";
import api from "../../helpers/api";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const Members = ({ getMembers }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handelSyncData = async () => {
        setIsLoading(true);
        try {
            await api.post("/sectors/sync");
            await api.post("/activities/sync");
            await api.post("/sub-activities/sync");
            await api.post("/members/sync");
            await api.post("/members/createAccounts");
            setIsLoading(false);
            window.location.reload();
        } catch (error) {
            toast.error(error.message);
            setIsLoading(false);
        }
    };

    return (
        <Sidebar>
            <div className="" style={{ color: "#0B2A4B" }}>
                <Subheader
                    title="All Users"
                    Component={() => (
                        <Switch>
                            <Route
                                exact
                                path="/dashboard/users"
                                component={() => (
                                    <>
                                        <Formik
                                            enableReinitialize
                                            initialValues={{
                                                offset: 0,
                                                size: 6,
                                                sort: "",
                                                dir: "asc",
                                                searchTerm: "",
                                                filterBy: [],
                                            }}
                                            onChange={(values) => {
                                                getMembers(values);
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                values,
                                                handleChange,
                                            }) => (
                                                <form
                                                    onChange={handleChange}
                                                    className="my-3 "
                                                >
                                                    {/* <div class="col-12 ">
                                  <TextInput name='searchTerm' placeholder='Name' label="" />
                              </div> */}
                                                </form>
                                            )}
                                        </Formik>
                                    </>
                                )}
                            />
                        </Switch>
                    )}
                >
                    <div className="d-flex gap-4">
                        <Button onClick={() => setIsModalOpen(true)}>
                            Sync Data
                        </Button>
                        <Modal
                            show={isModalOpen}
                            onHide={() => setIsModalOpen(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Sync Data</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to sync data?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={handelSyncData}
                                >
                                    {isLoading ? "Loading..." : "Sync"}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Switch>
                            <Route
                                exact
                                path="/dashboard/users"
                                component={() => (
                                    <Link
                                        to="/dashboard/users/create"
                                        style={{
                                            textDecoration: "none",
                                            color: "#0B2A4B",
                                            fontSize: "15px",
                                            display: "flex",
                                            alignItems: "center",
                                            border:
                                                "1px solid var(--primary-color)",
                                            padding: "10px",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        <LuPlusCircle />
                                        <p className="p-0 m-0 mx-3">Add User</p>
                                    </Link>
                                )}
                            />
                            <Route
                                exact
                                path={[
                                    "/dashboard/users",
                                    "/dashboard/users/:id",
                                ]}
                                component={() => (
                                    <Link
                                        to="/dashboard/users"
                                        className="btn btn-primary btn-bold btn-icon-h"
                                    >
                                        Back to list
                                        <i className="flaticon2-plus" />
                                    </Link>
                                )}
                            />
                        </Switch>
                    </div>
                </Subheader>
                <div className="" id="">
                    <div className="row">
                        <div className="col-lg-12">
                            <Switch>
                                <Route
                                    exact
                                    path="/dashboard/users"
                                    component={MembersList}
                                />
                                <Route
                                    exact
                                    path="/dashboard/users/create"
                                    component={MembersForm}
                                />
                                <Route
                                    exact
                                    path="/dashboard/users/:id"
                                    component={MembersForm}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};

const mapStateToProps = (state) => {
    return {
        members: membersSelector(state),
    };
};
const mapDispatchToProps = {
    getMembers: getMembersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);
