import {createSelector} from "reselect";

const industriesStateSelector = (state) => state.industries;
const selectById = (state, industryId) => industryId;

export const industriesSelector = createSelector(
  [industriesStateSelector],
  (industries) => industries.industries
);

export const industriesLoadSelector = createSelector(
  [industriesStateSelector],
  (industries) => industries.loadedindustries
);

export const industriesCountSelector = createSelector(
  [industriesStateSelector],
  (industries) => industries.count
);


export const industriesIsLoadingSelector = createSelector(
  [industriesStateSelector],
  (industries) => industries.isLoading
);

export const searchValueSelector = createSelector(
  [industriesStateSelector],
  (industries) => industries.searchValue
);

export const industryByIdSelector = createSelector(
 
  [industriesSelector, selectById],
  (industries, _id) => {
    const industry = industries.find(industry => _id === industry._id);
    return industry;
  }
);