/* eslint-disable object-curly-newline */
import {call, put, select, takeLatest, takeEvery} from "redux-saga/effects";

import {
  newErrorNotificationAction,
  newSuccessNotificationAction,
} from "../notifications/Actions";

import { getsubActivitesSuccessAction,  setsubActivitesIsLoadingAction , getsubActivitesCountAction, getsubActivitesHeaderSuccessAction  } from "./Actions";
import * as api from "./Api";
import {actions} from "./Reducer";

// Plural
function* getsubActivitesSaga({payload}) {
  const defaultBody ={
    offset: 0,
    size: 50,
    sort: "name",
    dir: "asc",
    searchTerm: "",
    filterBy: []
}

  const body = {...defaultBody, ...payload};
  try {
    yield put(setsubActivitesIsLoadingAction(true));
    const response = yield call(api.getsubActivites, body);
    const {data} = response;
    const items = data.content;
    const count = data.count;

    yield put(getsubActivitesSuccessAction(items));
    yield put(getsubActivitesCountAction(count));
  } catch (error) {
      console.log(error)
    //yield put(errorNotificationFromError(error));
  } finally {
    yield put(setsubActivitesIsLoadingAction(false));
  }
}

function* getsubActivitesHeaderSaga({payload}) {
  const defaultBody ={
    offset: 0,
    size: 50,
    sort: "name",
    dir: "asc",
    searchTerm: "",
    filterBy: []
}

  const body = {...defaultBody, ...payload};
  try {
    yield put(setsubActivitesIsLoadingAction(true));
    const response = yield call(api.getsubActivites, body);
    const {data} = response;
    const items = data.content;

    yield put(getsubActivitesHeaderSuccessAction(items));
  } catch (error) {
      console.log(error)
    //yield put(errorNotificationFromError(error));
  } finally {
    yield put(setsubActivitesIsLoadingAction(false));
  }
}

function* getsubActivitySaga({payload}) {
  const activityId = payload;
  try {
  //  yield put(resetMembersAction())
    yield put(setsubActivitesIsLoadingAction(true));

    const response = yield call(api.getsubActivity, activityId);
    const {data} = response;
    yield put(getsubActivitesSuccessAction(data));
  } catch (error) {
    yield put(newErrorNotificationAction("no activities found"));
  } finally {
    yield put(setsubActivitesIsLoadingAction(false));
  }
}


export default function* () {
 
  yield takeLatest(actions.GET_SUBASTIVITES, getsubActivitesSaga);
 yield takeLatest(actions.GET_SUBACTIVITY,getsubActivitySaga);
 yield takeLatest(actions.GET_SUBACTIVITESHEADER,getsubActivitesHeaderSaga)

 
}
