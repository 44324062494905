import React, { useEffect, useState } from "react";
import CreateEventForm from "./CreateEventForm";
import EventInfo from "./EventInfo";

const Step1 = ({ currentStep }) => {
    const [eventStepOne, setEventStepOne] = useState({
        title: "",
        cover: "",
        description: "",
        place: "",
        startTime: "",
        endTime: "",
        reminderDate: "",
        placeLink: "",
        numberOfSeats: 1,
        country: [],
        eventType: [],
        sectors: [],
        sponsors: [],
        status: "pre_started",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Update the state with the new value
        setEventStepOne({
            ...eventStepOne,
            [name]: value,
        });
    };

    useEffect(() => {
        setEventStepOne((prev) => ({
            ...prev,
            ...JSON.parse(localStorage.getItem("eventStepOne")),
        }));
    }, []);

    useEffect(() => {
        localStorage.setItem("eventStepOne", JSON.stringify(eventStepOne));
    }, [eventStepOne]);

    return (
        <div class="row">
            <h4
                className="mb-5 d-flex align-items-center gap-3 pt-3 pb-4"
                style={{
                    borderBottom: "2px solid rgba(0, 0, 0, .06)",
                    color: "var(--primary-color)",
                }}
            >
                <span
                    style={{
                        width: "40px",
                        height: "40px",
                        lineHeight: "40px",
                        borderRadius: "50%",
                        border: "1px solid var(--primary-color)",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {currentStep + 1}
                </span>{" "}
                General Information
            </h4>
            <div class="col-md-9">
                <div>
                    <CreateEventForm
                        eventStepOne={eventStepOne}
                        setEventStepOne={setEventStepOne}
                        handleInputChange={handleInputChange}
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div>
                    <EventInfo
                        eventStepOne={eventStepOne}
                        setEventStepOne={setEventStepOne}
                        handleInputChange={handleInputChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step1;
