import api from "../../helpers/api";

export const getAds = (options) =>
  api.post("advertisements/search", { ...options });

export const getAd = (adId) => api.get(`/advertisements/${adId}`);

export const deleteAd = (adId) => api.delete(`advertisements/${adId}`);

export const postAd = (formData) => api.post("advertisements", { ...formData });

export const addViewers = (adId) => api.put(`advertisements/inc-views/${adId}`);

export const updateAd = (adId, adFormData) =>
  api.put(`advertisements/${adId}`, adFormData);
