import React from 'react';
import styled from 'styled-components';

const Loading = () => (
  <FixedContainer>
    <h3>Loading</h3>
  </FixedContainer>
);

const FixedContainer = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #181e2c;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  
`;

export default Loading;
