import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import { Button, Col, Image, Modal, Row, Table } from "react-bootstrap";
import api from "../../../helpers/api";
import FileInput from "../../../components/events/FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import Input from "../../../components/events/Input";
import moment from "moment";
import { useToasts } from "react-toast-notifications";

const SchedulesPage = () => {
    const { addToast } = useToasts();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [schedulesList, setSchedulesList] = useState({
        content: [],
        count: 0,
    });
    const [selectedSchedule, setSelectedSchedule] = useState();

    const getSchedules = async () => {
        try {
            const { data } = await api.post("/member-schedules/search", {
                offset: 0,
                size: 30,
                sort: "createdAt",
                dir: "desc",
                filterBy: [{ status: "FREE" }],
            });

            setSchedulesList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getSchedules();
    }, []);

    useEffect(() => {
        if (selectedSchedule) {
            setSelectedSchedule(selectedSchedule);
        }
    }, [selectedSchedule]);

    const handleMeetRequestAction = async (id) => {
        try {
            await api.patch(`/member-schedules/${id}/meet-request`, {});

            window.location.reload();
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <h3>Schedules</h3>
            </div>
            <Table responsive className="mt-5" striped bordered hover size="lg">
                <thead>
                    <tr>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Event</th>
                        <th>User</th>
                        <th>Schedule Status</th>
                        <th>Event Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {schedulesList.content?.map((schedule, i) => (
                        <tr key={schedule?._id}>
                            <td>
                                {moment(schedule?.startTime).format(
                                    "YYYY-MM-DD HH:mm"
                                )}
                            </td>
                            <td>
                                {moment(schedule?.endTime).format(
                                    "YYYY-MM-DD HH:mm"
                                )}
                            </td>
                            <td>{schedule?.event?.title}</td>
                            <td>{schedule?.user?.username}</td>
                            <td>{schedule?.status}</td>
                            <td>{schedule?.event?.status}</td>
                            <td>
                                <div className="d-flex gap-3">
                                    <Button
                                        // variant="primary"
                                        size="sm"
                                        onClick={() => {
                                            // setSelectedSchedule(schedule);
                                            // setIsCreateModalOpen(true);

                                            handleMeetRequestAction(
                                                schedule?._id
                                            );
                                        }}
                                        disabled={
                                            schedule?.status === "FREE" &&
                                            schedule?.event?.status ===
                                                "pre_started"
                                                ? false
                                                : true
                                        }
                                    >
                                        Meet Request
                                    </Button>
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            window.location.pathname = `/event-details/${schedule?.event?._id}`;
                                        }}
                                    >
                                        Event
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {/* <Modal
                show={isCreateModalOpen}
                onHide={() => setIsCreateModalOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedSchedule ? "Edit" : "Create"} Sponsor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    scs
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsCreateModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSponsorAction}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </Sidebar>
    );
};

export default SchedulesPage;
