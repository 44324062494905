import produce from 'immer';
import mergeById from '../../helpers/mergeById';

export const actions = {
  CREATE_MEMBER: 'CREATE_MEMBER',
  CREATE_MEMBER_SUCCESS: 'CREATE_MEMBER_SUCCESS',
  UPDATE_MEMBER_SUCCESS: ' UPDATE_MEMBER_SUCCESS',
  UPDATE_MEMBER: ' UPDATE_MEMBER',
  GET_MEMBERS: 'GET_MEMBERS',
  GET_MEMBERS_SEARCH: 'GET_MEMBERS_SEARCH',
  SET_MEMBERS_SEARCH_VALUE: 'SET_MEMBERS_SEARCH_VALUE',
  SET_MEMBERS_IS_LOADING: 'SET_MEMBERS_IS_LOADING',
  GET_MEMBERS_SUCCESS: 'GET_MEMBERS_SUCCESS',
  GET_MEMBERS_COUNT: 'GET_MEMBERS_COUNT',
  GET_MEMBERS_SEARCH_SUCCESS: 'GET_MEMBERS_SEARCH_SUCCESS',
  GET_MEMBERS_SEARCH_COUNT: 'GET_MEMBERS_COUNT',
  GET_MEMBER: 'GET_MEMBER',
  GET_MEMBER_SUCCESS: 'GET_MEMBER_SUCCESS',
  RESET_MEMBERS: 'RESET_MEMBERS',
  DELETE_MEMBER: 'DELETE_MEMBER',
  DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
  GET_PIONEERS_MEMBERS: 'GET_PIONEERS_MEMBERS',
  GET_PIONEERS_MEMBERS_SUCCESS: 'GET_PIONEERS_MEMBERS_SUCCESS',
  CLEAR_MEMBER_DETAILS: 'CLEAR_MEMBER_DETAILS',
};

const initialState = {
  members: [],
  membersSearch: [],
  pioneers: [],
  isLoading: false,
  count: 0,
  countSearch: 0,
  searchValue: '',
  member: {},
};

export default produce((draft, action) => {
  const { type, payload } = action;
  if (!draft) {
    return initialState;
  }

  switch (type) {
    case actions.CLEAR_MEMBER_DETAILS: {
      draft.member = {};
      break;
    }
    case actions.CREATE_MEMBER_SUCCESS: {
      const currentMember = draft.Members;
      const member = payload;
      draft.members = mergeById(currentMember, [member]);
      break;
    }
    case actions.GET_MEMBER_SUCCESS: {
      const currentMember = draft.Members;
      const member = payload;
      draft.member = member;
      break;
    }

    case actions.UPDATE_MEMBER_SUCCESS: {
      const updatedMember = payload;
      const targetIndex = draft.members.findIndex(
        (member) => member._id === updatedMember._id
      );
      draft.members[targetIndex] = updatedMember;
      break;
    }

    case actions.SET_MEMBERS_SEARCH_VALUE: {
      draft.members = [];
      draft.searchValue = payload;
      break;
    }

    case actions.GET_MEMBERS_SUCCESS: {
      const currentMembers = draft.members;
      const members = payload.members;
      console.log(members, 'reduser')
      let req = payload.body
      if (req.offset == 0) {
        draft.members = members
      } else {
        draft.members = mergeById(currentMembers, members);

      }

      break;
    }

    case actions.GET_MEMBERS_SEARCH_SUCCESS: {
      const currentMembers = draft.members;
      const members = payload;
      draft.membersSearch = members;
      break;
    }

    case actions.GET_PIONEERS_MEMBERS_SUCCESS: {
      const currentMembers = draft.pioneers;
      const members = payload.members;
      let req = payload.body
      if (req.offset == 0) {
        draft.pioneers = members
      } else {
        draft.pioneers = mergeById(currentMembers, members);

      }
      break;
    }

    case actions.SET_MEMBERS_IS_LOADING: {
      draft.isLoading = payload;
      break;
    }
    case actions.GET_MEMBERS_COUNT: {
      draft.count = payload;
      break;
    }
    case actions.GET_MEMBERS_SEARCH_COUNT: {
      draft.countSearch = payload;
      break;
    }
    case actions.RESET_MEMBERS: {
      draft.members = [];
      break;
    }
    case actions.DELETE_MEMBER_SUCCESS: {
      const memberId = payload;
      const updatedmembers = draft.members.filter(
        (member) => member._id !== memberId
      );
      draft.members = updatedmembers;
      break;
    }
    default:
      break;
  }
});
