import React, { useEffect, useState } from "react";
import api from "../../helpers/api";
import { Badge, Button } from "react-bootstrap";
import moment from "moment";

const TicketInvitation = ({ match }) => {
    const { id } = match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState();

    const getTicket = async () => {
        setIsLoading(true);
        try {
            const { data } = await api.get(`/ticket/${id}`);

            setTicket(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log({ error });
        }
    };

    useEffect(() => {
        if (id) {
            getTicket();
        }
    }, [id]);

    console.log({ ticket });

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {isLoading ? (
                <div style={{ textAlign: "center", padding: "50px 0" }}>
                    <p>Loading...</p>
                </div>
            ) : (
                <>
                    <h5 className="mb-4">Ticket</h5>
                    <div
                        className="d-flex flex-column gap-3 shadow"
                        style={{
                            background: "#fff",
                            padding: "40px 30px",
                            borderRadius: "10px",
                            position: "relative",
                            width: "400px",
                        }}
                    >
                        <div className="d-flex justify-content-between gap-1">
                            <div className="d-flex gap-1 flex-column">
                                <h6>Event Name</h6>
                                <p style={{ margin: 0 }}>
                                    {ticket?.event?.title}
                                </p>
                            </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-between align-items-center gap-2">
                            <div className="d-flex gap-1 flex-column">
                                <h6>Date</h6>
                                <p style={{ margin: 0 }}>
                                    {moment(ticket?.event?.startTime).format(
                                        "ll"
                                    )}{" "}
                                    {moment(ticket?.event?.startTime).format(
                                        "HH:mm A"
                                    )}
                                </p>
                            </div>
                            <div className="d-flex gap-1 flex-column">
                                <Button
                                    as="a"
                                    href={`/event-details/${ticket?.event?._id}`}
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    View Event
                                </Button>
                            </div>
                        </div>
                        <h6 className="mt-3">QR Code</h6>
                        <div className="shadow-sm">
                            <img
                                src={ticket?.qrCode}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                        <p className="text-center">Please scan for details.</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default TicketInvitation;
