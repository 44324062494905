import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import {
    getMemberAction,
    getMembersAction,
    getMembersSearchAction,
} from "../../modules/members/Actions";
import {
    memberSelector,
    membersSelector,
} from "../../modules/members/Selectors";
import Sidebar from "../../components/AdminSideBar/sidebar";
import AdsList from "./adsList";
import { adsIsLoadingSelector, adsSelector } from "../../modules/ads/Selectors";
import { getAdsAction } from "../../modules/ads/Actions";
import { Formik } from "formik";
import SelectInput from "../../components/Form/inputs/selsect";
import { membersSearchAsNameAndIdSelector } from "../../modules/search/Selectors";
import { Link } from "react-router-dom";
import queryString from "query-string";
const Ads = (props) => {
    const parsed = queryString.parse(window.location.search);

    const {
        ads,
        getAds,
        isLoading,
        mappedMembers,
        member,
        getMemberssearch,
        getMember,
    } = props;
    const [blockId, setBlockId] = useState("");
    const [locationId, setLcationId] = useState("");
    const [show, setShow] = useState(true);

    const handelChangeLocation = (e) => {
        setBlockId(e);
        setShow(false);
        if (e != "member") getAds({ filterBy: [{ location: e }] });
    };

    const handelBack = () => {
        setBlockId();
        setShow(true);
    };
    const AdBlock = [
        { value: "Select..", label: "Select..." },
        { value: "HOME_BLOCK_1", label: "HOME_BLOCK_1", name: "home" },
        { value: "HOME_BLOCK_2", label: "HOME_BLOCK_2", name: "home" },
        { value: "HOME_BLOCK_3", label: "HOME_BLOCK_3", name: "home" },
        { value: "HOME_BLOCK_4", label: "HOME_BLOCK_4", name: "home" },
        { value: "SEARCH_BLOCK_1", label: "SEARCH_BLOCK_1", name: "search" },
        { value: "SEARCH_BLOCK_2", label: "SEARCH_BLOCK_2", name: "search" },
        { value: "SEARCH_BLOCK_3", label: "SEARCH_BLOCK_3", name: "search" },
        { value: "LOGIN_BLOCK_1", label: "LOGIN_BLOCK_1", name: "login" },
        { value: "LOGIN_BLOCK_2", label: "LOGIN_BLOCK_2", name: "login" },
        { value: "LOGIN_BLOCK_3", label: "LOGIN_BLOCK_3", name: "login" },
        { value: "LOGIN_BLOCK_4", label: "LOGIN_BLOCK_4", name: "login" },
        {
            value: "INDUSTRY_BLOCK_1",
            label: "INDUSTRY_BLOCK_1",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_2",
            label: "INDUSTRY_BLOCK_2",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_3",
            label: "INDUSTRY_BLOCK_3",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_4",
            label: "INDUSTRY_BLOCK_4",
            name: "industry",
        },
        { value: "MEMBER_BLOCK_1", label: "MEMBER_BLOCK_1", name: "member" },
        { value: "MEMBER_BLOCK_2", label: "MEMBER_BLOCK_2", name: "member" },
        { value: "MEMBER_BLOCK_3", label: "MEMBER_BLOCK_3", name: "member" },
        { value: "MEMBER_BLOCK_4", label: "MEMBER_BLOCK_4", name: "member" },
        {
            value: "PIONEERS_BLOCK_1",
            label: "PIONEERS_BLOCK_1",
            name: "pioneers",
        },
        {
            value: "PIONEERS_BLOCK_2",
            label: "PIONEERS_BLOCK_2",
            name: "pioneers",
        },
        {
            value: "PIONEERS_BLOCK_3",
            label: "PIONEERS_BLOCK_3",
            name: "pioneers",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_1",
            label: "ADVANCED_SEARCH_BLOCK_1",
            name: "advanced search",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_2",
            label: "ADVANCED_SEARCH_BLOCK_2",
            name: "advanced search",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_3",
            label: "ADVANCED_SEARCH_BLOCK_3",
            name: "advanced search",
        },
    ];

    useEffect(() => {
        if (locationId?.searchTerm?._id) getMember(locationId?.searchTerm?._id);
    }, [locationId?.searchTerm]);

    useEffect(() => {
        if (locationId?.searchTerm?._id && blockId == "member") {
            window.location.pathname = `/dashboard/advertisments/member/${locationId?.searchTerm?._id}`;
        }
    }, [locationId, blockId]);

    const selectedBlock = AdBlock.filter((block) => {
        return block.name == blockId;
    });

    const AdsBlocks = [
        { label: "Home", value: "home" },
        { label: "Search", value: "search" },
        { label: "Login", value: "login" },
        { label: "Member", value: "member" },
        { label: "Pioneers", value: "pioneers" },
        { label: "Advanced Search", value: "advanced search" },
    ];

    return (
        <Sidebar>
            <div style={{ height: "100vh" }}>
                <div className="d-flex gap-3 flex-wrap mb-5">
                    {AdsBlocks.map((adBlock) => {
                        return (
                            <Link
                                to={{
                                    pathname: window.location.pathname,
                                    search: `?block=${parsed?.block}&location=${adBlock?.label}`,
                                }}
                                // className="col-2 my-5 ml-5"
                                style={{
                                    textDecoration: "none",
                                    flex: 1,
                                }}
                            >
                                <div
                                    onClick={() =>
                                        handelChangeLocation(adBlock.value)
                                    }
                                    style={{
                                        border: "1px solid #80808026",
                                        background:
                                            parsed.location === adBlock.label
                                                ? "#0A3D80"
                                                : "white",
                                        width: "100%",
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "10px",
                                        fontSize: "20px",
                                        fontWeight: "500",
                                        cursor: "pointer",
                                        padding: "10px",
                                        color:
                                            parsed.location === adBlock.label
                                                ? "white"
                                                : "#0A3D80",
                                    }}
                                >
                                    {adBlock.label}
                                </div>
                            </Link>
                        );
                    })}
                </div>

                <div>
                    {blockId === "member" && (
                        <Formik
                            enableReinitialize
                            initialValues={{
                                location: "",
                            }}
                            onSubmit={(values) => {
                                console.log(values);
                            }}
                        >
                            {({
                                handleSubmit,
                                values,
                                handleBlur,
                                handleChange,
                                errors,
                            }) => {
                                setLcationId(values);

                                return (
                                    <SelectInput
                                        name="searchTerm"
                                        options={mappedMembers}
                                        isLoading={isLoading}
                                        getItems={getMemberssearch}
                                    />
                                );
                            }}
                        </Formik>
                    )}
                </div>

                {blockId !== "member" && !show && (
                    <AdsList
                        ads={ads}
                        selectedBlock={selectedBlock}
                        isLoading={isLoading}
                        location={blockId}
                        locationId={locationId?.searchTerm}
                    />
                )}
            </div>
        </Sidebar>
    );
};

const mapStateToProps = (state, props) => {
    return {
        ads: adsSelector(state),
        members: membersSelector(state),
        isLoading: adsIsLoadingSelector(state),
        mappedMembers: membersSearchAsNameAndIdSelector(state),
        member: memberSelector(state),
    };
};
const mapDispatchToProps = {
    getAds: getAdsAction,
    getMembers: getMembersAction,
    getMemberssearch: getMembersSearchAction,
    getMember: getMemberAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ads);
