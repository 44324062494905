import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import { Button, Col, Image, Modal, Row, Table } from "react-bootstrap";
import api from "../../../helpers/api";
import FileInput from "../../../components/events/FileInput";
import { IoMdCloudUpload } from "react-icons/io";
import Input from "../../../components/events/Input";
import moment from "moment";
import { useToasts } from "react-toast-notifications";

const PendingRequests = () => {
    const { addToast } = useToasts();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [actionType, setActionType] = useState("");
    const [schedulesList, setSchedulesList] = useState({
        content: [],
        count: 0,
    });
    const [selectedSchedule, setSelectedSchedule] = useState();

    const getSchedules = async () => {
        try {
            const { data } = await api.post(
                "/member-schedules-requests/search",
                {
                    offset: 0,
                    size: 30,
                    sort: "createdAt",
                    dir: "desc",
                    // filterBy: [{ status: "CONFIRMED" }],
                }
            );

            setSchedulesList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getSchedules();
    }, []);

    useEffect(() => {
        if (selectedSchedule) {
            setSelectedSchedule(selectedSchedule);
        }
    }, [selectedSchedule]);

    const handleMeetRequestAction = async (id, status) => {
        try {
            await api.post(`/member-schedules-requests/${id}/review`, {
                status,
            });

            window.location.reload();
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <h3>Pending Requests</h3>
            </div>
            <Table responsive className="mt-5" striped bordered hover size="lg">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Schedule Start Time</th>
                        <th>Schedule End Time</th>
                        <th>User</th>
                        <th>Request Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {schedulesList.content?.map((schedule, i) => (
                        <tr key={schedule?._id}>
                            <td>{schedule?.event?.title}</td>
                            <td>
                                {moment(schedule?.schedule?.startTime).format(
                                    "YYYY-MM-DD HH:mm"
                                )}
                            </td>
                            <td>
                                {moment(schedule?.schedule?.endTime).format(
                                    "YYYY-MM-DD HH:mm"
                                )}
                            </td>
                            <td>{schedule?.user?.username}</td>
                            <td>{schedule?.status}</td>
                            <td>
                                <div className="d-flex gap-3">
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            setActionType("CONFIRMED");
                                            setSelectedSchedule(schedule);
                                            setIsConfirmModalOpen(true);
                                            // handleMeetRequestAction(
                                            //     schedule?._id,
                                            //     "CONFIRMED"
                                            // );
                                        }}
                                        disabled={
                                            schedule?.status === "REFUSED"
                                        }
                                    >
                                        Confirm
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => {
                                            setActionType("REFUSED");
                                            setSelectedSchedule(schedule);
                                            setIsConfirmModalOpen(true);
                                            // handleMeetRequestAction(
                                            //     schedule?._id,
                                            //     "REFUSED"
                                            // );
                                        }}
                                        disabled={
                                            schedule?.status === "REFUSED"
                                        }
                                    >
                                        Refuse
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => {
                                            setActionType("CANCELED");
                                            setSelectedSchedule(schedule);
                                            setIsConfirmModalOpen(true);
                                            // handleMeetRequestAction(
                                            //     schedule?._id,
                                            //     "CANCELED"
                                            // );
                                        }}
                                        disabled={
                                            schedule?.status === "REFUSED"
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                show={isConfirmModalOpen}
                onHide={() => setIsConfirmModalOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Request Action</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    Are you sure you want to{" "}
                    {actionType === "CONFIRMED"
                        ? "confirm"
                        : actionType === "REFUSED"
                        ? "refuse"
                        : "cancel"}{" "}
                    this request?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsConfirmModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant={
                            actionType === "CONFIRMED" ? "primary" : "danger"
                        }
                        onClick={() =>
                            handleMeetRequestAction(
                                selectedSchedule?._id,
                                actionType
                            )
                        }
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <Modal
                show={isCreateModalOpen}
                onHide={() => setIsCreateModalOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedSchedule ? "Edit" : "Create"} Sponsor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    scs
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsCreateModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSponsorAction}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </Sidebar>
    );
};

export default PendingRequests;
