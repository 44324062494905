import produce from "immer";
import mergeById from "../../helpers/mergeById";



export const actions = {
  GET_ASTIVITES : "GET_ASTIVITES",
  GET_ACTIVITES_SUCCESS : "GET_ACTIVITES_SUCCESS",
  GET_ACTIVITY: "GET_ACTIVITY",
  GET_ACTIVITY_SUCCESS : "GET_ACTIVITY_SUCCESS",
  SET_ACTIVITES_SEARCH_VALUE : "SET_ACTIVITES_SEARCH_VALUE",
  SET_ASTIVITES_IS_LOADING : "SET_ASTIVITES_IS_LOADING",
  GET_ACTIVITES_COUNT : "GET_ACTIVITES_COUNT",
  RESET_ACTIVITES : "RESET_ACTIVITES",
  GET_ACTIVITESHEADER_SUCCESS:"GET_ACTIVITESHEADER_SUCCESS",
  GET_ACTIVITESHEADER:"GET_ACTIVITESHEADER"
};

const initialState = {
  activites: [],
  isLoading: false,
  count: 0,
  activitesHeader:[],
  searchValue: "",
 
};


export default produce((draft, action) => {
  const {type, payload} = action;
  if (!draft) {
    return initialState;
  }

  switch (type) {
    case actions.SET_ACTIVITES_SEARCH_VALUE: {
      draft.activites = [];
      draft.searchValue = payload;
      break;
    }
    case actions.GET_ACTIVITES_SUCCESS: {
      const activites = payload;
      draft.activites =  activites;
      break;
    }
    case actions.GET_ACTIVITESHEADER_SUCCESS: {
      const activites = payload;
      draft.activitesHeader =  activites;
      break;
    }

    case actions.GET_ACTIVITY_SUCCESS: {
      const currentActivity = draft.activites;
      const activites = payload;
      draft.activites = mergeById(currentActivity, activites);
      break;
    }

   
    case actions.SET_ACTIVITES_IS_LOADING: {
      draft.isLoading = payload;
      break;
    }
    case actions.GET_ACTIVITES_COUNT: {
      draft.count = payload;
      break;
    }
    case actions.RESET_ACTIVITES: {
      draft.activites = [];
      break;
    }
    default:
      break;
  }
});
