import { createSelector } from 'reselect';

const notificationsStateSelector = state => state.notifications;

export const successNotificationsSelector = createSelector(
  [notificationsStateSelector],
  notifications => notifications.successNotifications,
);

export const errorNotificationsSelector = createSelector(
  [notificationsStateSelector],
  notifications => notifications.errorNotifications,
);
