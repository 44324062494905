import P from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Advertsment from "../../components/Advartsment/Advartsment";

import ForgotPasswordForm from "../../components/Auth/ForgetPasswordForm";
import LoginForm from "../../components/Auth/LoginForm";
import Loading from "../../components/Misc/Loading";
import Image from "../../assets/images/pic.png";

import {
    authIsLoadingSelector,
    authTokenSelector,
} from "../../modules/auth/Selectors";
import "./style.css";
import { getAdsAction } from "../../modules/ads/Actions";
const Login = (props) => {
    useEffect(() => {
        props.getAds({ filterBy: [{ location: "login" }] });
    }, []);
    const { token, isLoading } = props;

    const redirectTo = "/";

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {token ? (
                <Redirect to={redirectTo} />
            ) : (
                <div
                    className="login custom--scrollbar"
                    style={{ color: "#051538" }}
                >
                    <div className="login--inner">
                        <div className="row">
                            <div
                                className="col-12 col-md-4 mb-5 mb-md-0"
                                style={{
                                    backgroundColor: "#f0f8ff3d",
                                    backdropFilter: " blur(10px)",
                                }}
                            >
                                <div className="left">
                                    {/* background */}
                                    <div className="background-container"></div>
                                    {/* site logo */}
                                    <a href="/" className="logo">
                                        <div className="logo--icon">
                                            <img src={Image} alt="" />
                                        </div>
                                    </a>
                                    <div className="big text-justify mt-5">
                                        EBA Members’ Directory includes complete
                                        data about its members and their
                                        companies filtered through different
                                        search criteria (member name, company
                                        name, sector…){" "}
                                    </div>
                                </div>
                            </div>
                            <div className="right col-12 col-md-7">
                                <div className="container">
                                    <div className="row justify-content-center login--form">
                                        <div className="col-6 mb-5">
                                            <Switch>
                                                <Route
                                                    path="/login"
                                                    component={LoginForm}
                                                />
                                                <Route
                                                    path="/forgot-password"
                                                    component={
                                                        ForgotPasswordForm
                                                    }
                                                />
                                            </Switch>

                                            <div className="">
                                                <Switch>
                                                    <Route
                                                        path="/register"
                                                        component={() => (
                                                            <>
                                                                <span className="">
                                                                    Already have
                                                                    an account?
                                                                </span>{" "}
                                                                <Link
                                                                    to="/login"
                                                                    className=""
                                                                >
                                                                    Login!
                                                                </Link>
                                                            </>
                                                        )}
                                                    />
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                    <footer>
                                        {/* <!-- Content --> */}
                                        <div className="container">
                                            {/* <!-- Logos --> */}
                                            <ul className="logos">
                                                <li className="col-12 col-md-3">
                                                    <Advertsment
                                                        login
                                                        small
                                                        location="LOGIN_BLOCK_1"
                                                        logo
                                                    />
                                                </li>
                                                <li className="col-12 col-md-3">
                                                    <Advertsment
                                                        login
                                                        small
                                                        location="LOGIN_BLOCK_2"
                                                        logo
                                                    />
                                                </li>
                                                <li className="col-12 col-md-3">
                                                    <Advertsment
                                                        login
                                                        small
                                                        location="LOGIN_BLOCK_3"
                                                        logo
                                                    />
                                                </li>
                                            </ul>
                                            <Advertsment
                                                login
                                                location="LOGIN_BLOCK_4"
                                            />

                                            {/* <!-- Copyrights --> */}
                                            <div className="copyrights">
                                                <div>
                                                    © COPYRIGHT{" "}
                                                    {new Date().getFullYear()}
                                                </div>
                                                <div>
                                                    Egyptian Businessmen’s
                                                    Association - EBA. All
                                                    Rights Reserved.
                                                </div>
                                                <div
                                                    className="mt-3 text-dark"
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Technology Partner -
                                                    <a
                                                        href="https://www.pharaohsoft.com"
                                                        className="text-dark"
                                                        target="_blanck"
                                                        rel="noreferrer"
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Pharaoh Soft LTD
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!-- Back to Top --> */}
                                        <button
                                            id="backToTop"
                                            className="btn with-icon"
                                            type="button"
                                        >
                                            <div className="icon">
                                                <em className="fad fa-long-arrow-alt-up"></em>
                                            </div>
                                            Back to Top
                                        </button>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Login.propTypes = {
    isLoading: P.bool.isRequired,
    token: P.string,
};

Login.defaultProps = {
    token: null,
};

const mapStateToProps = (state) => ({
    token: authTokenSelector(state),
    isLoading: authIsLoadingSelector(state),
});

const mapDispatchToProps = {
    getAds: getAdsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
