import Sidebar from "../../components/AdminSideBar/sidebar";
import CEventSessions from "../../components/events/sessions";

const EventSessions = () => {
    return (
        <Sidebar>
            <CEventSessions />
        </Sidebar>
    );
};

export default EventSessions;
