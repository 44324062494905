import React from 'react';
import SocialInfo from '../SocaialInfo/SocailInfo';
import ShadowHead from '../Heads/ShadowHead'
import { adsCountSelector } from '../../modules/ads/Selectors';

const ContactInformation = (props) =>{
    const classes = props.horizontal ? " d-flex item" : "col-12 col-md-6  item"
    const informations =[
        {icon:"phone", title:"Phone" ,value:props?.data?.businessPhone_1},
        {icon:"fax", title:"Fax" ,value:props?.data?.businessFax_1},
        {icon:"envelope", title:"E-mail" ,value:props?.data?.email?.split(",")},
        {icon:"link", title:"WebSite" ,value:props?.data?.company?.website}
        
    ]
    return(
        <div className="section-block section contactInformation">
       {props.shadow ? <ShadowHead header="Contact Information"/> : <div className="title">Contact Information</div>}
       <img src="" alt="" />
        <div className="content row">
            {informations.map(( info  , index) =>{
                return (
                    <div className={classes} key={index} style={{display:info.value!==null ? "flex" : "none"}}>
                        <div className="icon">
                            <em className={`fad fa-${info.value != null && info.icon}`}></em>
                        </div>
                        <div className="data">
                            <div className="title">{info.value != null && info.title}</div>
                            <div className="value">
                               {info.value != null && info.title == "Phone" ? <a href={`tel:${info.value}`}>{info.value}</a> : "" }
                               {info.value != null && info.title == "E-mail" ? <a href={`mailto:${info.value[0]}`}>{info.value[0]}</a> : "" }
                               {info.value != null && info.title == "E-mail" ? <a href={`mailto:${info.value[1]}`}>{info.value[1]}</a> : "" }
                               {info.value != null && info.title == "WebSite" ? <a href={`https://${info.value}`} target="_blank">{info.value}</a> : "" }
                               {info.value != null && info.title == "Fax" ? <a href={`tel:${info.value}`}>{info.value}</a> : "" }

                            </div>
                        </div>
                    </div>
                )
            })}
            {/* <SocialInfo social={["facebook" , "twitter" , "instagram" , "linkedin" , "github"]}/> */}
        </div>
    </div>  

    )
}

export default ContactInformation;