export const {
    REACT_APP_API_URL: API_URL,
    REACT_APP_API_URL_LIVE: LIVE_API_URL,
  } = process.env;
  export const TOKEN = "TOKEN";
  export const API =
    "http://104.248.201.83:5001/api/";
    export const AUTH =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwNDBlM2YxZDExMGQwMDAxNTMxZTRlOSIsInJvbGUiOiJzdXBlckFkbWluIiwiaWF0IjoxNjE0ODgxNjYwLCJleHAiOjE2MTc0NzM2NjB9.r4uqXMWHo6e58jC-wstKxQhQAgC-wzbTj5LLQRnNXOQ";
    export const S3 = "https://bp-ca-customers.s3.ca-central-1.amazonaws.com/";
