import produce from "immer";
import mergeById from "../../helpers/mergeById";

export const actions = {
  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  UPDATE_USER_SUCCESS: " UPDATE_USER_SUCCESS",
  UPDATE_USER: " UPDATE_USER",
  GET_USERS: "GET_USERS",
  SET_USERS_SEARCH_VALUE: "SET_USERS_SEARCH_VALUE",
  SET_USERS_IS_LOADING: "SET_USERS_IS_LOADING",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_SUCCESS_NO_MERGE: "GET_USERS_SUCCESS_NO_MERGE",
  GET_USERS_COUNT: "GET_USERS_COUNT",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  RESET_USERS: "RESET_USERS",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
};

const initialState = {
  users: [],
  isLoading: false,
  count: 0,
  searchValue: "",
};

export default produce((draft, action) => {
  const { type, payload } = action;
  if (!draft) {
    return initialState;
  }

  switch (type) {
    case actions.CREATE_USER_SUCCESS:
    case actions.GET_USER_SUCCESS: {
      const currentUser = draft.users;
      const user = payload;
      draft.users = mergeById(currentUser, user);
      break;
    }
    case actions.GET_USERS_SUCCESS_NO_MERGE: {
      return { ...draft, users: payload };
    }

    case actions.UPDATE_USER_SUCCESS: {
      const updatedUser = payload;
      const targetIndex = draft.users.findIndex(
        (user) => user._id === updatedUser._id
      );
      draft.users[targetIndex] = updatedUser;
      break;
    }

    case actions.SET_USERS_SEARCH_VALUE: {
      draft.users = [];
      draft.searchValue = payload;
      break;
    }

    case actions.GET_USERS_SUCCESS: {
      const currentUser = draft.users;
      const users = payload;
      draft.users = mergeById(currentUser, users);
      break;
    }

    case actions.SET_USERS_IS_LOADING: {
      draft.isLoading = payload;
      break;
    }
    case actions.GET_USERS_COUNT: {
      draft.count = payload;
      break;
    }
    case actions.RESET_USERS: {
      draft.users = [];
      break;
    }
    case actions.DELETE_USER_SUCCESS: {
      const userId = payload;
      const updatedusers = draft.users.filter((user) => user._id !== userId);
      draft.users = updatedusers;
      break;
    }
    default:
      break;
  }
});
