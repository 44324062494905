import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import api from "../../../helpers/api";
import { Button, Modal, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useToasts } from "react-toast-notifications";

const EventBookingRequests = () => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [eventRequests, setEventRequests] = useState({
        content: [],
        count: 0,
    });
    const { addToast } = useToasts();
    const [hasMore, setHasMore] = useState(true);
    const [isCompanyDetails, setICompanyDetails] = useState(false);
    const [offset, setOffset] = useState(0);
    const [size, setSize] = useState(30);
    const [actionType, setActionType] = useState("");
    const [selectedRequest, setSelectedRequest] = useState();

    const getEventRequests = async () => {
        try {
            const { data } = await api.post("/book-request", {
                offset,
                size,
                sort: "createdAt",
                dir: "desc",
                filterBy: [{ status: "PENDING" }],
            });

            // setEventRequests(data);

            setEventRequests((prev) => ({
                content: [...prev.content, ...data?.content],
                count: prev.count,
            }));

            if (
                data?.content?.length === 0 ||
                data?.content?.length >= data?.count
            ) {
                setHasMore(false);
            }

            setOffset((prev) => prev + 10);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getEventRequests();
    }, []);

    const handleRequestAction = async () => {
        try {
            await api.patch(`/book-request/${selectedRequest?._id}/review`, {
                status: actionType,
            });

            window.location.reload();
        } catch (error) {
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    return (
        <Sidebar>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <h3>Event Booking Requests</h3>
            </div>
            <InfiniteScroll
                dataLength={eventRequests?.content?.length}
                next={getEventRequests}
                hasMore={hasMore}
            >
                <Table
                    responsive
                    className="mt-5"
                    striped
                    bordered
                    hover
                    size="lg"
                >
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Event</th>
                            <th>Request Status</th>
                            <th>Event Status</th>
                            <th>Payment Attach</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventRequests.content?.map((req, i) => (
                            <tr key={req?._id}>
                                <td>{req?.user?.username}</td>
                                <td>{req?.event?.title}</td>
                                <td>{req?.status}</td>
                                <td>{req?.event?.status}</td>
                                <td>
                                    <a
                                        href={req?.verifyPaymentAttach}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link
                                    </a>
                                </td>
                                <td>
                                    <div className="d-flex gap-3">
                                        <Button
                                            // variant="primary"
                                            size="sm"
                                            onClick={() => {
                                                setActionType("CONFIRMED");
                                                setSelectedRequest(req);
                                                setIsConfirmModalOpen(true);
                                            }}
                                            disabled={
                                                req?.event?.status !==
                                                    "pre_started" ||
                                                req?.status !== "PENDING"
                                                    ? true
                                                    : false
                                            }
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => {
                                                setActionType("REFUSED");
                                                setSelectedRequest(req);
                                                setIsConfirmModalOpen(true);
                                            }}
                                            disabled={
                                                req?.event?.status !==
                                                    "pre_started" ||
                                                req?.status !== "PENDING"
                                                    ? true
                                                    : false
                                            }
                                        >
                                            Refuse
                                        </Button>
                                        {req?.user?.company && (
                                            <Button
                                                // variant="primary"
                                                size="sm"
                                                onClick={() => {
                                                    setSelectedRequest(req);
                                                    setICompanyDetails(true);
                                                }}
                                            >
                                                Company Details
                                            </Button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </InfiniteScroll>
            <Modal
                show={isConfirmModalOpen}
                onHide={() => setIsConfirmModalOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Request Action</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        background: "rgb(245, 245, 245)",
                    }}
                >
                    Are you sure you want to{" "}
                    {actionType === "CONFIRMED" ? "confirm" : "refuse"} this
                    request?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsConfirmModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant={
                            actionType === "CONFIRMED" ? "primary" : "danger"
                        }
                        onClick={handleRequestAction}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={isCompanyDetails}
                onHide={() => setICompanyDetails(false)}
                centered
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Company Details</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: "20px",
                        backgroundColor: "#f5f5f5",
                    }}
                >
                    <Table
                        responsive
                        className="mt-5"
                        striped
                        bordered
                        hover
                        size="lg"
                    >
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Position</th>
                                <th>Type</th>
                                <th>Company name</th>
                                <th>Company website</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{selectedRequest?.user?.title}</td>
                                <td>{selectedRequest?.user?.name}</td>
                                <td>{selectedRequest?.user?.email}</td>
                                <td>{selectedRequest?.user?.position}</td>
                                <td>{selectedRequest?.user?.type}</td>
                                <td>{selectedRequest?.user?.company?.name}</td>
                                <td>
                                    {selectedRequest?.user?.company?.website}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setICompanyDetails(false)}
                    >
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={() => {}}>
                        Create
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </Sidebar>
    );
};

export default EventBookingRequests;
