import React from 'react';

const MemberData = (props) => {
  const { data } = props;
  return (
    <div className='container bg-light text-dark p-5'>
      <div className='row'>
        <div className='col-6'>Name</div>
        <div className='col-6'>{data?.name}</div>
      </div>
      <div className='row'>
        <div className='col-6'>Position</div>
        <div className='col-6'>{data?.position}</div>
      </div>

      <div className='row'>
        <div className='col-12 my-3'>
          <u>Contact Information</u>
        </div>
      </div>

      {data?.mobile && (
        <div className='row'>
          <div className='col-6'>Phone</div>
          <div className='col-6'>{data?.businessPhone_1}</div>
        </div>
      )}
      {data?.businessFax_1 && (
        <div className='row'>
          <div className='col-6'>Fax</div>
          <div className='col-6'>{data?.businessFax_1}</div>
        </div>
      )}
      {data?.email && (
        <div className='row'>
          <div className='col-6'>Email</div>
          <div className='col-6'>{data?.email}</div>
        </div>
      )}
      {data?.company?.website && (
        <div className='row'>
          <div className='col-6'>Website</div>
          <div className='col-6'>{data?.company?.website}</div>
        </div>
      )}
      <div className='row'>
        <div className='col-12 my-3'>
          <u>Work Information</u>
        </div>
      </div>
      {data?.company?.name && (
        <div className='row'>
          <div className='col-6'>Company Name</div>
          <div className='col-6'>{data?.company?.name}</div>
        </div>
      )}
         {data?.sectors?.length !== 0 && (
        <div className='row my-5'>
          <div className='col-6'>Sectors</div>
          {data?.sectors?.map((sector) => {
            return (
              <>
                <div className='col-3'>{sector.secENm}</div> <br />
              </>
            );
          })}
          
        </div>
      )}
      {data?.company?.bio && (
        <div className='row'>
          <div className='col-6'>Company Bio</div>
          <div className='col-6'>{data?.company?.bio}</div>
        </div>
      )}
   
    </div>
  );
};

export default MemberData;
