import produce from "immer";
import mergeById from "../../helpers/mergeById";



export const actions = {
  GET_SUBASTIVITES : "GET_SUBASTIVITES",
  GET_SUBACTIVITES_SUCCESS : "GET_SUBACTIVITES_SUCCESS",
  GET_SUBACTIVITY: "GET_SUBACTIVITY",
  GET_SUBACTIVITY_SUCCESS : "GET_SUBACTIVITY_SUCCESS",
  SET_SUBACTIVITES_SEARCH_VALUE : "SET_SUBACTIVITES_SEARCH_VALUE",
  SET_SUBASTIVITES_IS_LOADING : "SET_SUBASTIVITES_IS_LOADING",
  GET_SUBACTIVITES_COUNT : "GET_SUBACTIVITES_COUNT",
  RESET_SUBACTIVITES : "RESET_SUBACTIVITES",
  GET_SUBACTIVITESHEADER_SUCCESS:"GET_SUBACTIVITESHEADER_SUCCESS",
  GET_SUBACTIVITESHEADER:"GET_SUBACTIVITESHEADER"
};

const initialState = {
  subactivites: [],
  subactivitesHeader:[],
  isLoading: false,
  count: 0,
  searchValue: "",
 
};


export default produce((draft, action) => {
  const {type, payload} = action;
  if (!draft) {
    return initialState;
  }

  switch (type) {
    case actions.SET_SUBACTIVITES_SEARCH_VALUE: {
      draft.subactivites = [];
      draft.searchValue = payload;
      break;
    }
    case actions.GET_SUBACTIVITES_SUCCESS: {
      const currentActivity = draft.subactivites;
      const subactivites = payload;
     // draft.sectors = sectors
      draft.subactivites =  subactivites;
      break;
    }
    case actions.GET_SUBACTIVITESHEADER_SUCCESS: {
      const subactivites = payload;
      draft.subactivitesHeader =  subactivites;
      break;
    }
    case actions.GET_SUBACTIVITY_SUCCESS: {
      const currentActivity = draft.subactivites;
      const subactivites = payload;
      draft.subactivites = mergeById(currentActivity, subactivites);
      break;
    }

   
    case actions.SET_SUBACTIVITES_IS_LOADING: {
      draft.isLoading = payload;
      break;
    }
    case actions.GET_SUBACTIVITES_COUNT: {
      draft.count = payload;
      break;
    }
    case actions.RESET_SUBACTIVITES: {
      draft.subactivites = [];
      break;
    }
    default:
      break;
  }
});
