import api from "../../helpers/api";

export const getusers = (options) => api.post("users/search", { ...options });

export const getUser = (userId) => api.get(`users/${userId}`);

export const deleteUser = (userId) => api.delete(`users/${userId}`);

export const postUser = (formData) => api.post("users", { ...formData });

export const updateUser = (userId, userFormData) =>
    api.put(`users/${userId}`, userFormData);
