import React from "react";
import P from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "../../helpers/cookies";
import {
    authTokenSelector,
    authCurrentUserTypeSelector,
    authIsLoadingSelector,
    authCurrentUserSelector,
} from "../../modules/auth/Selectors";

const ProtectedRoute = (props) => {
    const {
        component: Component,
        userType,
        requiredPermissions,
        authIsLoading,
        user,
        ...rest
    } = props;

    const token = getCookie("TOKEN");
    return (
        <Route
            {...rest}
            render={(componentProps) =>
                token ? (
                    <Component {...componentProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/events",
                            state: { from: componentProps.location },
                        }}
                    />
                )
            }
        />
    );
};

ProtectedRoute.propTypes = {
    component: P.func.isRequired,
    token: P.string,
    user: P.shape({}),
    authIsLoading: P.bool.isRequired,
};

ProtectedRoute.defaultProps = {
    token: null,
    user: null,
};

const mapStateToProps = (state) => {
    return {
        token: authTokenSelector(state),
        authIsLoading: authIsLoadingSelector(state),
    };
};

export default connect(mapStateToProps)(ProtectedRoute);
