import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import api from "../../../helpers/api";
import InputSelect from "../InputSelect";

const EventInfo = ({ eventStepOne, setEventStepOne, handleInputChange }) => {
    // const [sponsorsList, setSponsorsList] = useState({ content: [], count: 0 });
    // const [selectedSponsors, setSelectedSponsors] = useState([]);

    // useEffect(() => {
    //     if (selectedSponsors?.length) {
    //         setEventStepOne((prev) => ({
    //             ...prev,
    //             sponsors: selectedSponsors,
    //         }));
    //     }
    // }, [selectedSponsors]);

    // const getSponsors = async () => {
    //     try {
    //         const { data } = await api.post("/sponsors/search", {
    //             offset: 0,
    //             size: 20,
    //             sort: "createdAt",
    //             dir: "desc",
    //         });

    //         setSponsorsList(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // const handleSelectSponsors = (item) => {
    //     // Check if the item is already in the array
    //     const isItemSelected = selectedSponsors.includes(item);

    //     if (isItemSelected) {
    //         // If the item is in the array, remove it
    //         setSelectedSponsors((prevItems) =>
    //             prevItems.filter((prevItem) => prevItem !== item)
    //         );
    //     } else {
    //         // If the item is not in the array, push it
    //         setSelectedSponsors((prevItems) => [...prevItems, item]);
    //     }
    // };

    // useEffect(() => {
    //     getSponsors();
    // }, []);

    // console.log({ selectedSponsors });

    // useEffect(() => {
    //     if (eventStepOne?.sponsors?.length) {
    //         setSelectedSponsors(eventStepOne.sponsors);
    //     }
    // }, [eventStepOne.sponsors]);

    return (
        <div className="d-flex gap-5 flex-column">
            <div>
                <Form.Label>Capacity of Seats</Form.Label>
                <Form.Control
                    placeholder="Capacity of Seats"
                    aria-label="capacityOfSeats"
                    aria-describedby="capacityOfSeats"
                    type="number"
                    style={{ height: "45px" }}
                    name="numberOfSeats"
                    value={eventStepOne?.numberOfSeats}
                    onChange={handleInputChange}
                />
            </div>
            <div>
                <Form.Label>Select Country</Form.Label>
                <InputSelect
                    options={[
                        { label: "C1", value: "C1" },
                        { label: "C2", value: "C2" },
                        { label: "C3", value: "C3" },
                    ]}
                    isMulti
                    defaultValue={
                        eventStepOne?.country?.map((t) => ({
                            label: t,
                            value: t,
                        })) || []
                    }
                    onChange={(e) =>
                        setEventStepOne({
                            ...eventStepOne,
                            country: e?.map((c) => c?.value),
                        })
                    }
                />
            </div>
            <div>
                <Form.Label>Event Type</Form.Label>
                <InputSelect
                    options={[
                        { label: "T1", value: "T1" },
                        { label: "T2", value: "T2" },
                        { label: "T3", value: "T3" },
                    ]}
                    isMulti
                    defaultValue={
                        eventStepOne?.eventType?.map((t) => ({
                            label: t,
                            value: t,
                        })) || []
                    }
                    onChange={(e) =>
                        setEventStepOne({
                            ...eventStepOne,
                            eventType: e?.map((c) => c?.value),
                        })
                    }
                />
            </div>
            <div>
                <div
                    style={{
                        // width: "100%",
                        marginBottom: "10px",
                        backgroundColor: "none",
                    }}
                    // className="d-flex flex-column"
                >
                    {/* <Form.Label>Sponsors</Form.Label> */}
                    {/* <DropdownButton
                        id="dropdown-item-button"
                        title="Select Sponsors"
                        style={{
                            // width: "100%",
                            backgroundColor: "none",
                        }}
                    >
                        {sponsorsList?.content?.map((s) => (
                            <Dropdown.Item
                                as="button"
                                key={s?._id}
                                style={{
                                    backgroundColor: selectedSponsors.includes(
                                        s?._id
                                    )
                                        ? "rgb(10, 61, 128)"
                                        : "#fff",
                                    color: selectedSponsors.includes(s?._id)
                                        ? "#fff"
                                        : "#000",
                                    width: "100%",
                                }}
                                onClick={() => handleSelectSponsors(s?._id)}
                            >
                                {s?.name}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton> */}
                </div>
                {/* <div className="d-flex flex-column gap-2">
                    {sponsors.map((s, i) => (
                        <div
                            key={i}
                            className="d-flex align-items-center justify-content-between"
                            style={{ width: "100%" }}
                        >
                            <div
                                className="rounded p-3 d-flex align-items-center justify-content-between"
                                style={{
                                    width: "100%",
                                    background: "rgba(198, 208, 222, 1)",
                                }}
                            >
                                <span
                                    style={{
                                        color: "var(--primary-color)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {s}
                                </span>
                                <button
                                    // variant="danger"
                                    onClick={() => {
                                        const newSponsors = [...sponsors];
                                        newSponsors.splice(i, 1);
                                        setSponsors(newSponsors);
                                    }}
                                    style={{
                                        background: "var(--primary-color)",
                                        width: "20px",
                                        height: "20px",
                                        lineHeight: "20px",
                                        borderRadius: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#fff",
                                        outline: "none",
                                        border: "1px solid #fff",
                                        fontSize: "12px",
                                    }}
                                >
                                    x
                                </button>
                            </div>
                        </div>
                    ))}
                </div> */}
            </div>
        </div>
    );
};

export default EventInfo;
