import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Btn from '../../Buttons/Btn';
import Card from '../../Card/Card';
import MembersHeader from '../../Header/MembersHeader';
import AlphaList from '../../Pagination/AlphaList';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { membersPioneersSelector } from '../../../modules/members/Selectors';
import { getPioneersMembersAction } from '../../../modules/members/Actions';

const memberTypes = [
  { value: "", label: "Select" },
  { value: 'individual', label: 'Individual' },
  { value: 'corporate', label: 'Corporate' },
];


const Members = (props) => {
  const { members, getPioneersMembers, sectors } = props
  const [alpha, setAlpha] = useState('#');
  const [sector, setSector] = useState('');
  const [industry, setIndustry] = useState('');
  const [searchName, setSearchName] = useState('');
  const [activity, setActivity] = useState('')
  const [subActivity, setSubActivity] = useState('')

  const [headerData, setHeaderData] = useState({
    dir: false,
    memberType: memberTypes[0],
  });

  const parsed = queryString.parse(window.location.search, { parseFragmentIdentifier: false });


  useEffect(() => {
    if (parsed?.alpha) {
      setAlpha(parsed?.alpha);
    }
    if (parsed?.sector) setSector(parsed?.sector);
    if (parsed?.industry) setIndustry(parsed?.industry);
    if (parsed?.activity) setActivity(parsed?.activity);
    if (parsed?.subactivity) setSubActivity(parsed?.subactivity)
    if (parsed?.searchTerm) {
      if (parsed?.searchTerm == "") setSearchName("")
      setSearchName(parsed?.searchTerm);
    }
  }, [parsed]);

  useEffect(() => {


    const searchAlpha = alpha === '#' ? '' : alpha;
    let filterOptions = headerData.memberType.value != "" ? [{ type: headerData.memberType.value }] : []
    if (sector && sector != "undefined") filterOptions.push({ sectors: sector });
    if (industry && industry != "undefined") filterOptions.push({ industry: industry });
    if (activity && activity != "undefined") filterOptions.push({ activities: activity });
    if (subActivity && subActivity != "undefined") filterOptions.push({ subActivity: subActivity })
    if (headerData?.memberSubActivity) filterOptions.push({ subActivity: headerData?.memberSubActivity?.subacT_ID })
    if (headerData?.memberActivity) filterOptions.push({ activities: headerData?.memberActivity?.actId })
    // if (headerData?.memberSectors) filterOptions.push({ sectors : headerData?.memberSectors?.secId})


    let timeId = setTimeout(() => {

      getPioneersMembers({
        dir: headerData.dir ? 'asc' : "desc",
        filterBy: filterOptions,
        letter: parsed.letter == "" ? "" : parsed.letter,
      });
    }, 200);
    return () => clearTimeout(timeId);
  }, [headerData, alpha, industry, sector, searchName, getPioneersMembers, activity, subActivity]);


  return (
    <div className='section-block members--list'>
      <div className='container'>
        <div className='header--forList'>
          <AlphaList alpha={alpha} setAlpha={setAlpha} />
        </div>
        <div className='members--items row'>
          {!members ?
            <div className="spinner-border  m-auto mb-5" role="status">
              <span className="visually-hidden m-auto">Loading...</span>
            </div>
            :
            members?.map((member, index) => {
              return (
                <div className='col-12 col-md-6 col-lg-4 mb-5' key={index}>
                  <div className='member--item'>
                    <Card data={member} key={index}>
                      {/* <Btn label="Industry" dataType="sector"  /> */}
                    </Card>
                  </div>
                </div>
              );
            })}
        </div>

        <div className='d-flex justify-content-center mt-5' style={{ float: window.location.pathname !== '/pioneers' ? '' : 'right', cursor: 'pointer' }} >
          {window.location.pathname !== "/pioneers" ?
            <NavLink
              style={{ textDecoration: 'none' }}
              to={{
                pathname: `/pioneers`,
                search: `?letter=${parsed.letter}`,
              }}
            >
              <Btn dataType='button' label='Load More' animation searchTerm={searchName} alpha={alpha} headerData={headerData} getMembers={getPioneersMembers} members={members} />
            </NavLink>
            :
            <Btn dataType='button' label='Load More' animation searchTerm={searchName} alpha={alpha} headerData={headerData} getMembers={getPioneersMembers} members={members} />
          }

          {window.location.pathname == "/pioneers" ?
            "" :
            <NavLink
              to="/pioneers"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Btn dataType="button" label="View All Members" />
            </NavLink>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    members: membersPioneersSelector(state),
  };
};

const mapDispatchToProps = {
  getPioneersMembers: getPioneersMembersAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
