import React, { useEffect, useState } from "react";
import P from "prop-types";
import { connect } from "react-redux";

import {
    usersSelector,
    usersIsLoadingSelector,
    usersCountSelector,
} from "../../modules/user/Selectors";
import List from "../../components/Lists/List";
import ListItem from "../../components/Lists/ListItem";
import { deleteUserAction, getUsersAction } from "../../modules/user/Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { Form } from "react-bootstrap";

const UsersList = (props) => {
    const { users, getUsers, deleteUser, isLoading, usersCount } = props;
    const [term, setTerm] = useState("");
    const [size] = useState(15);

    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const timeId = setTimeout(() => {
            getUsers({ size, offset: 0, searchTerm: term });
        }, 500);
        return () => clearTimeout(timeId);
    }, [getUsers, term]);

    const fetchMoreData = () => {
        if (users.length >= usersCount) {
            setHasMore(false);
            return false;
        }
        getUsers({ offset: users.length, size, searchTerm: term });
    };

    const prCount = users.length <= usersCount ? users.length : usersCount;

    return (
        <div>
            <input
                value={term}
                onChange={(e) => setTerm(e.target.value)}
                class="form-control"
                type="text"
                placeholder="Search Users"
            />
            <List isLoading={isLoading} items={users}>
                <InfiniteScroll
                    dataLength={prCount}
                    next={fetchMoreData}
                    hasMore={hasMore}
                >
                    <div
                        className="mt-4 d-flex flex-column gap-4"
                        style={{ width: "100%" }}
                    >
                        <div
                            className="d-flex p-4 rounded-3 justify-content-between"
                            style={{ background: "rgba(0, 0, 0, .06)" }}
                        >
                            <b>Email</b>
                            <b>Actions</b>
                        </div>
                        {users.map((user) => {
                            return (
                                <ListItem
                                    item={user}
                                    editLink
                                    onDelete
                                    label={user.username}
                                    url="users"
                                />
                            );
                        })}
                    </div>
                </InfiniteScroll>
            </List>
        </div>
    );
};

UsersList.propTypes = {
    users: P.arrayOf(P.shape({ name: P.string, _id: P.string })).isRequired,
    getUsers: P.func.isRequired,
    deleteUser: P.func,
    isLoading: P.bool.isRequired,
};

UsersList.defaultProps = {
    deleteItem: null,
};

const mapStateToProps = (state) => ({
    usersCount: usersCountSelector(state),
    users: usersSelector(state),
    // searchValue: searchValueSelector(state),
    isLoading: usersIsLoadingSelector(state),
});

const mapDispatchToProps = {
    getUsers: getUsersAction,
    deleteUser: deleteUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
