import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CardSidebar from "./CardSidebar";

const Card = (props) => {
    const { data, news, children, withoutHover, slider } = props;
    const [isShown, setIsShown] = useState(false);

    function imageExists(image_url) {
        if (image_url) return true;
        else return false;
    }

    const photo = data?.photo?.split("\\")[2].split(".")[0];

    const avatar = imageExists(photo);
    return (
        <>
            <div
                className={`${news ? "thumbnail" : "avatar"} lazyload`}
                data-bg="assets/images/avatar.png"
            >
                <NavLink
                    to={{
                        pathname: `/member/${props.data._id}`,
                        state: props.data._id,
                    }}
                >
                    <img
                        className="avatar "
                        alt=""
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                        src={
                            avatar === false
                                ? "assets/images/avatar.png"
                                : `http://www.eba.org.eg/mem_photo/${photo}.jpg`
                        }
                        style={{
                            width: "100%",
                            transform:
                                isShown &&
                                window.location.toString().split("/")[3] !==
                                    "member"
                                    ? "scale(1.1)"
                                    : "scale(1)",
                        }}
                    />
                </NavLink>
            </div>
            <div className="content">
                {news ? (
                    <div className="fw-bolder">
                        <div
                            className="data excerpt-1 fw-bolder"
                            data-type="name"
                        >
                            {data?.name}
                        </div>
                        <div
                            className="title excerpt-2 fw-bolder"
                            data-type="role"
                        >
                            {data?.title}
                        </div>
                        <div
                            className="excerpt excerpt-2 fw-bolder"
                            data-type="company-name"
                        >
                            {data?.company?.name}
                        </div>
                    </div>
                ) : (
                    <>
                        {!withoutHover ? (
                            <div className="excerpt-2" data-type="name">
                                <NavLink
                                    style={{
                                        color: "black",
                                        textDecoration: "none",
                                    }}
                                    to={{
                                        pathname: `/member/${props.data._id}`,
                                        state: props?.data?._id,
                                    }}
                                >
                                    {data?.name}
                                </NavLink>
                            </div>
                        ) : (
                            <div
                                className="excerpt-2 fw-bolder"
                                data-type="name"
                            >
                                {" "}
                                {data?.name}
                            </div>
                        )}
                        <div className="excerpt-1 fw-bolder" data-type="role">
                            {data?.position}
                        </div>
                        <div
                            className="excerpt-1 fw-bolder"
                            data-type="company-name"
                        >
                            {data?.company?.name}
                        </div>
                    </>
                )}
                {children}
            </div>
        </>
    );
};

export default Card;
