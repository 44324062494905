import React from "react";

const ShadowHead = ({ header }) => {
    const headerArr = header.split(" ")
    return (
        <div className="title" data-shadowtext={header} style={{ marginBottom: '0', color: '#082244' }} >
            <h2 style={{ padding: '0' }}><strong>{headerArr[0]}</strong> {headerArr[1]}</h2>
        </div>
    )
}

export default ShadowHead;