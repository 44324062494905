import React from "react";
import Select from "react-select";

const InputSelect = ({ isMulti, options, defaultValue, onChange, name }) => {
    return (
        <Select
            defaultValue={defaultValue}
            isMulti={isMulti}
            name={name}
            options={options}
            onChange={onChange}
            classNamePrefix="select"
            value={defaultValue}
        />
    );
};

export default InputSelect;
