import { Form } from "formik";
import P from "prop-types";
import React from "react";

const FormGroup = (props) => {
  const { handleSubmit, heading, isLoading, children, disabled, deleteAd, adId, } = props;
  return (
    <Form className='form' onSubmit={handleSubmit}>
      <div className='body'>
        <div className='row'>
          <div className='col-12'>
            <div className=''>{children}</div>
          </div>

        </div>
        <div className='row '>
          <div className='col-4 mt-5'>
            <button
              type='submit'
              style={{ width: "100%" }}
              className={`btn btn-primary`}
            >
              {deleteAd ?
                "Edit" :
                "Submit"
              }
            </button>
          </div>
          {deleteAd && <div className="col-6 mt-5">

            <button
              className={`btn`}
              style={{ backgroundColor: "#e7f1ff", width: "100%" }}
              onClick={() => {
                deleteAd(adId)
              }}
            >
              Delete
            </button>


          </div>}
        </div>
      </div>




    </Form>

  );
};

FormGroup.propTypes = {
  handleSubmit: P.func.isRequired,
  heading: P.any.isRequired,
  isLoading: P.bool,
  disabled: P.bool,
  children: P.oneOfType([P.arrayOf(P.node), P.node]).isRequired,
};

FormGroup.defaultProps = {
  isLoading: false,
  disabled: false,
};

export default FormGroup;
