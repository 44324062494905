import React from "react";
import P from "prop-types";
import { Field } from "formik";

import FieldContainer from "../Misc/FieldContainer";

const TextInput = (props) => {
    const { name, label, type, placeholder, disabled } = props;

    return (
        <Field name={name} {...props}>
            {({ field, form }) => {
                const { errors, touched } = form;
                const isInvalid = errors[field.name] && touched[field.name];
                return (
                    <div className="form-group">
                        <label htmlFor={field.name} className="form-label">
                            {label}
                        </label>
                        <FieldContainer
                            errorMessage={isInvalid && errors[field.name]}
                        >
                            <input
                                style={{
                                    border: "1px solid #C4C4C4",
                                    padding: "8px",
                                    fontSize: "15px",
                                    width: "100%",
                                    borderRadius: "5px",
                                }}
                                className={` ${isInvalid ? "is-invalid" : ""}`}
                                type={type}
                                placeholder={placeholder}
                                {...field}
                                disabled={disabled}
                            />
                        </FieldContainer>
                    </div>
                );
            }}
        </Field>
    );
};

TextInput.propTypes = {
    name: P.string.isRequired,
    label: P.string.isRequired,
    type: P.string,
    placeholder: P.string,
    formTip: P.string,
    disabled: P.bool,
};

TextInput.defaultProps = {
    type: "text",
    placeholder: "",
    formTip: "",
    disabled: false,
};

export default TextInput;
