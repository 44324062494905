import { ErrorMessage } from "formik";
import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    username: Yup.string().required("please enter member username"),
    password: Yup.string(

    ).min(8 , "Password is too short - should be 8 chars minimum.")
    .required("please enter member password")
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/ , "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit"),
    role: Yup.string().oneOf(["superAdmin" , "client" , "member"] , "it must be superAdmin client or member").required("please select the member role") ,
    expireAt: Yup.string(),
  })
