import React from 'react'

const List = (props) =>{
   const {children , height , items } = props

   const style = {
      height : height ,
      overflow: "hidden"
   }
    return(
         <div className="content-inner" style={style}>
            <div className="row content-full">
                {children}
            </div>
          </div>
    )
}

export default List;