import produce from "immer";
import mergeById from "../../helpers/mergeById";



export const actions = {
  CREATE_FEEDBACK : "CREATE_FEEDBACK",
  CREATE_FEEDBACK_SUCCESS : "CREATE_FEEDBACK_SUCCESS",
  

};

const initialState = {
  feedback: [],
  Loading:[],
 
 
};

/* eslint consistent-return: 0 */
export default produce((draft, action) => {
  const {type, payload} = action;
  if (!draft) {
    return initialState;
  }

  
  switch (type) {
    case actions.CREATE_AD_SUCCESS:
  {
        const currentAd = draft.feedback;
        const feedback = payload;
        // draft.feedback = mergeById(currentAd, ad);
        break;
      }

    
    default:
      break;
  }
});
