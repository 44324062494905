import React, { useState } from "react";
import { Field } from "formik";
import styled from "styled-components";
import { AUTH, API, S3 } from "../../constants";
import api from "../../helpers/api";

const Upload = (props) => {
    const { name, label } = props;
    const [upload, setUpload] = useState(0);
    const [max, setMax] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [error, setError] = useState(null);

    // const data = {customerID: "67875867475467"};

    return (
        <div className="my-4">
            <Field name={name}>
                {({ field, form }) => {
                    const { setFieldValue } = form;
                    const { value } = field;
                    let fileName = value;

                    const handleUpload = async (file) => {
                        setError(null);
                        // if (file.type !== "image/png") {
                        //   setError("You can only upload Image ");
                        //   return;
                        // }

                        setFieldValue(field.name, "");

                        setUpload(file);
                        setMax(0);
                        const formData = new FormData();
                        formData.append("file", file);

                        setShowProgress(true);
                        try {
                            const data = await api
                                .post("/uploads", formData, {
                                    onUploadProgress: (ProgressEvent) => {
                                        console.log(
                                            `${ProgressEvent.loaded} / ${ProgressEvent.total} `
                                        );
                                        setMax(ProgressEvent.total);
                                        setUpload(ProgressEvent.loaded);
                                    },
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    },
                                })
                                .then((res) => {
                                    console.warn(res);
                                    setFieldValue(field.name, res.data.url);
                                });
                        } catch (error) {
                            setError(error.message);
                        } finally {
                            setShowProgress(false);
                        }
                    };

                    return (
                        <div className="form-group row">
                            <label
                                className="col-lg-3 col-form-label"
                                htmlFor={name}
                            >
                                {label}
                            </label>
                            <FieldContainer className="col-lg-12">
                                <input
                                    multiple={false}
                                    className="custom-file-input"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                        handleUpload(e.target.files[0])
                                    }
                                    placeholder={label}
                                />

                                <FileLabel className="custom-file-label">
                                    {fileName}
                                </FileLabel>
                                {error && (
                                    <TextError className="error">
                                        {error}
                                    </TextError>
                                )}
                                {showProgress && (
                                    <progress
                                        className="progress"
                                        max={max}
                                        value={upload}
                                    ></progress>
                                )}
                                {value && (
                                    <div className="file">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={value}
                                            className="file"
                                        ></a>{" "}
                                        <button
                                            className="btn btn-sm btn-label-danger"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFieldValue(field.name, "");
                                                setError(null);
                                            }}
                                        >
                                            X
                                        </button>
                                    </div>
                                )}
                            </FieldContainer>
                        </div>
                    );
                }}
            </Field>
        </div>
    );
};

const TextError = styled.span`
    color: red;
`;
const FieldContainer = styled.div`
    position: relative;
    padding-bottom: "15px";
`;

const FileLabel = styled.label`
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export default Upload;
