import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import Input from "../../../components/events/Input";
import api from "../../../helpers/api";
import { useToasts } from "react-toast-notifications";
import { IoMdCloudUpload } from "react-icons/io";
import FileInput from "../../../components/events/FileInput";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";

const UpdateProfilePage = () => {
    const { addToast } = useToasts();
    const [userInfo, setUserInfo] = useState({
        title: "",
        name: "",
        businessPhone_1: "",
        businessPhone_2: "",
        businessFax_1: "",
        businessFax_2: "",
        mobile: "",
        email: "",
        address: "",
        photo: "",
        position: "",
        type: "individual",
        company: {
            name: "",
            bio: "",
            email: "",
            website: "",
            address: "",
            phone_1: "",
            phone_2: "",
            phone_3: "",
            fax_1: "",
            fax_2: "",
            fax_3: "",
        },
        video: "",
        councils: "",
        committees: "",
    });
    const [isUpdateMe, setIsUpdateMe] = useState(false);

    const handleUserChange = (event) => {
        const { name, value } = event.target;
        // If the changed field is within the 'company' object
        if (name.startsWith("company.")) {
            // Extract the field name from 'name'
            const companyFieldName = name.split(".")[1];

            // Update the 'company' object within the state
            setUserInfo((prevState) => ({
                ...prevState,
                company: {
                    ...prevState.company,
                    [companyFieldName]: value,
                },
            }));
        } else {
            // If it's not within the 'company' object, update normally
            setUserInfo((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const updateUser = async () => {
        try {
            const { data } = await api.put(`/users/non-member/me`, {
                ...userInfo,
            });

            Cookies.set("user_info", data);

            window.location.reload();
        } catch (error) {
            console.error(error);
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const handleUpload = async (formData, property) => {
        try {
            const res = await api.post("/uploads", formData);

            console.log({ res });
            setUserInfo((prev) => ({ ...prev, photo: res?.data?.url }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileChange = async (event, property) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("file", file);

        await handleUpload(formData, property);
    };

    useEffect(() => {
        const userInfoCookie = Cookies.get("user_info");

        if (userInfoCookie) {
            const parsedUserInfo = JSON.parse(userInfoCookie);
            if (parsedUserInfo) {
                setUserInfo(parsedUserInfo.user || parsedUserInfo);
            }
        }
    }, []);

    return (
        <Sidebar>
            <h5>Update Profile</h5>
            <div className="d-flex flex-column gap-4 mt-5">
                <Input
                    value={userInfo?.title}
                    type="text"
                    label="Title"
                    name="title"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.name}
                    type="text"
                    label="Name"
                    name="name"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessPhone_1}
                    type="text"
                    label="Bussiness Phone 1"
                    name="businessPhone_1"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessPhone_2}
                    type="text"
                    label="Bussiness Phone 2"
                    name="businessPhone_2"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessFax_1}
                    type="text"
                    label="Bussiness Fax 1"
                    name="businessFax_1"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.businessFax_2}
                    type="text"
                    label="Bussiness Fax 2"
                    name="businessFax_2"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.mobile}
                    type="text"
                    label="Mobile"
                    name="mobile"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.email}
                    type="email"
                    label="Email"
                    name="email"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.address}
                    type="text"
                    label="Address"
                    name="address"
                    onChange={handleUserChange}
                />
                <FileInput
                    handleChange={handleFileChange}
                    title="User Photo"
                    icon={
                        <IoMdCloudUpload
                            color="rgba(156, 163, 175, 1)"
                            fontSize="40px"
                        />
                    }
                    description1="Click to upload or drag and drop"
                    description2="Max. File Size: 30MB"
                    imageSrc={userInfo?.photo || ""}
                />
                <Input
                    value={userInfo?.position}
                    type="text"
                    label="Position"
                    name="position"
                    onChange={handleUserChange}
                />
                <div className="d-flex flex-column gap-2">
                    <label>Type</label>
                    <select
                        value={userInfo?.type}
                        onChange={(e) =>
                            setUserInfo((prev) => ({
                                ...prev,
                                type: e.target.value,
                            }))
                        }
                        defaultValue="individual"
                    >
                        <option value="individual" selected>
                            Individual
                        </option>
                        <option value="corporate">Corporate</option>
                    </select>
                </div>
                <h6>Company Info</h6>
                <Input
                    value={userInfo?.company?.name}
                    type="text"
                    label="Company Name"
                    name="company.name"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.bio}
                    type="text"
                    label="Company Bio"
                    name="company.bio"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.email}
                    type="text"
                    label="Company Email"
                    name="company.email"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.website}
                    type="text"
                    label="Company Website"
                    name="company.website"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.address}
                    type="text"
                    label="Company Address"
                    name="company.address"
                    onChange={handleUserChange}
                />
                <Input
                    value={userInfo?.company?.phone_1}
                    type="text"
                    label="Company Phone"
                    name="company.phone_1"
                    onChange={handleUserChange}
                />
            </div>
            <Button
                onClick={updateUser}
                style={{ marginTop: "30px", width: "100%" }}
            >
                Update
            </Button>
        </Sidebar>
    );
};

export default UpdateProfilePage;
