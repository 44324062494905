import produce from "immer";
import mergeById from "../../helpers/mergeById";



export const actions = {
  CREATE_AD : "CREATE_AD",
  CREATE_AD_SUCCESS : "CREATE_AD_SUCCESS",
  UPDATE_AD_SUCCESS : " UPDATE_AD_SUCCESS",
  UPDATE_AD : " UPDATE_AD",
  GET_ADS : "GET_ADS",
  SET_ADS_SEARCH_VALUE : "SET_ADS_SEARCH_VALUE",
  SET_ADS_IS_LOADING : "SET_ADS_IS_LOADING",
  GET_ADS_SUCCESS : "GET_ADS_SUCCESS",
  GET_ADS_COUNT : "GET_ADS_COUNT",
  GET_AD : "GET_AD",
  GET_AD_SUCCESS : "GET_AD_SUCCESS",
  RESET_ADS : "RESET_ADS",
  DELETE_AD : "DELETE_AD",
  DELETE_AD_SUCCESS : "DELETE_AD_SUCCESS",
  ADS_VIEWERS:"ADS_VIEWERS",
  ADS_VIEWERS_SUCCESS:"ADS_VIEWERS_SUCCESS"

};

const initialState = {
  ads: [],
  membersLoad:[],
  isLoading: false,
  count: 0,
  searchValue: "",
  customAds:[]
 
};

/* eslint consistent-return: 0 */
export default produce((draft, action) => {
  const {type, payload} = action;
  if (!draft) {
    return initialState;
  }

  
  switch (type) {
      case actions.GET_AD_SUCCESS: {
       
        const currentAd = draft.ads;
        const ad = payload;
        draft.ads = mergeById(currentAd, ad);
        break;
      }

      case actions.CREATE_AD_SUCCESS: {
        const currentAd = draft.ads;
        const ad = payload;
        draft.ads = mergeById(currentAd, [ad]);
        break;
      }

      case actions.UPDATE_AD_SUCCESS: {
        const updatedAd = payload;
        const targetIndex = draft.ads.findIndex(
          (ad) => ad._id === updatedAd._id
        );
        draft.ads[targetIndex] = updatedAd;
        break;
      }

    case actions.SET_ADS_SEARCH_VALUE: {
      draft.ads = [];
      draft.searchValue = payload;
      break;
    }

    case actions.GET_ADS_SUCCESS: {
      const currentMember = draft.ads;
      const ads = payload;
      draft.ads= ads;
      draft.customAds = ads
      break;
    }

   

   
    case actions.SET_ADS_IS_LOADING: {
      draft.isLoading = payload;
      break;
    }
    case actions.GET_ADS_COUNT: {
      draft.count = payload;
      break;
    }
    case actions.RESET_ADS: {
      draft.members = [];
      break;
    }
    case actions.DELETE_AD_SUCCESS: {
      const adId = payload;
      const updatedAds = draft.ads.filter((ad) => ad._id !== adId);
      draft.ads = updatedAds;
      break;
    }

    case actions.ADS_VIEWERS_SUCCESS: {
    
      break;
    }
    default:
      break;
  }
});
