import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../../components/AdminSideBar/sidebar";
import { Button, Form } from "react-bootstrap";
import InputSelect from "../../../components/events/InputSelect";
import Input from "../../../components/events/Input";
import { IoMdCloudUpload } from "react-icons/io";
import FileInput from "../../../components/events/FileInput";
import api from "../../../helpers/api";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const CreateSponsor = () => {
    const { addToast } = useToasts();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const sponsorId = query.get("edit");
    const [selectedSponsor, setSelectedSponsor] = useState();
    const [sponsor, setSponsor] = useState({
        ticket: "",
        name: "",
        description: "",
        image: "",
        link: "",
    });
    const [selectedEventId, setSelectedEventId] = useState("");
    const [eventsList, setEventsList] = useState({ count: 0, content: [] });

    const getEvents = async () => {
        try {
            const { data } = await api.post("/events/search", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
            });

            setEventsList(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        const currentSponsor = JSON.parse(localStorage.getItem("sponsor"));

        if (sponsorId) {
            setSponsor(currentSponsor);
        }
    }, [sponsorId]);

    const handleSponsorAction = async () => {
        try {
            if (sponsorId) {
                await api.put(`/sponsors/${sponsorId}`, {
                    ...sponsor,
                    events: [
                        {
                            eventId: selectedEventId,
                            ticket: getSelectedEvent?.pricing?.find(
                                (p) => p?.type === sponsor?.ticket
                            ),
                        },
                    ],
                });
            } else {
                await api.post(`/sponsors`, {
                    ...sponsor,
                    events: [
                        {
                            eventId: selectedEventId,
                            ticket: getSelectedEvent?.pricing?.find(
                                (p) => p?.type === sponsor?.ticket
                            ),
                        },
                    ],
                });
            }

            window.location.pathname = "/dashboard/sponsors";
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setSponsor({
            ...sponsor,
            [name]: value,
        });
    };

    const handleUpload = async (formData) => {
        try {
            const res = await api.post("/uploads", formData);

            console.log({ res });
            setSponsor((prev) => ({ ...prev, image: res?.data?.url }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleCoverChange = async (event) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("file", file);

        await handleUpload(formData);
    };

    const getSelectedEvent = useMemo(() => {
        const e = eventsList?.content?.find((e) => e?._id === selectedEventId);

        return e;
    }, [eventsList?.content, selectedEventId]);

    console.log({ getSelectedEvent, sponsor });

    return (
        <Sidebar>
            <h3 className="mb-5">{sponsorId ? "Edit" : "Create"} Sponsor</h3>
            <div className="p-2">
                <div
                    className="d-flex flex-column gap-5"
                    style={{ width: "100%" }}
                >
                    <div className="d-flex flex-column gap-1">
                        <Form.Label style={{ fontSize: "18px" }}>
                            Select Event
                        </Form.Label>
                        <Form.Select
                            aria-label=""
                            size="lg"
                            style={{
                                height: "50px",
                                fontSize: "18px",
                            }}
                            name="country"
                            value={selectedEventId}
                            onChange={(e) => setSelectedEventId(e.target.value)}
                        >
                            <option>Open this select event</option>
                            {eventsList?.content?.map((event) => (
                                <option value={event?._id} key={event?._id}>
                                    {event?.title}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div>
                        <Form.Label>Event Tickets</Form.Label>
                        <InputSelect
                            options={getSelectedEvent?.pricing?.map((p) => {
                                return {
                                    label: `${p?.name} | Price: ${p?.value}`,
                                    value: p?.type,
                                };
                            })}
                            // isMulti
                            defaultValue={selectedSponsor?.ticket?.type}
                            onChange={(e) =>
                                setSponsor({
                                    ...sponsor,
                                    ticket: e.value,
                                })
                            }
                        />
                    </div>
                    <div className="d-flex gap-3" style={{ width: "100%" }}>
                        <Input
                            type="text"
                            id="name"
                            label="Sponsor Name"
                            placeholder="Sponsor Name"
                            name="name"
                            value={sponsor?.name}
                            onChange={handleInputChange}
                        />
                        <Input
                            type="text"
                            id="link"
                            label="Link"
                            placeholder="Link"
                            name="link"
                            value={sponsor?.link}
                            onChange={handleInputChange}
                        />
                    </div>
                    <Input
                        type="textarea"
                        id="description"
                        label="Description"
                        placeholder="Description"
                        name="description"
                        value={sponsor?.description}
                        onChange={handleInputChange}
                    />
                    <FileInput
                        handleChange={handleCoverChange}
                        title="Sponsor Logo"
                        icon={
                            <IoMdCloudUpload
                                color="rgba(156, 163, 175, 1)"
                                fontSize="40px"
                            />
                        }
                        description1="Click to upload or drag and drop"
                        description2="Max. File Size: 30MB"
                        imageSrc={sponsor?.image || ""}
                    />
                </div>
                <Button
                    className="mt-5"
                    variant="primary"
                    onClick={handleSponsorAction}
                >
                    {sponsorId ? "Edit Sponsor" : "Create Sponsor"}
                </Button>
            </div>
        </Sidebar>
    );
};

export default CreateSponsor;
