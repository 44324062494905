import List from "../../Lists/List";
import { useState } from "react";
import { Link } from "react-router-dom";
import ShadowHead from "../../Heads/ShadowHead";

const Categories = (props) => {
    const { sectors } = props;
    const [icon, setIcon] = useState("down");
    const [height, setHeight] = useState("60px");
    const [label, setLabel] = useState("Read more");

    const icons = [
        "fad fa-wheat",
        "fas fa-university",
        "fas fa-hard-hat",
        "fas fa-suitcase",
        "fas fa-book-open",
        "fas fa-charging-station",
        "fas fa-tree",
        "fas fa-industry",
        "fas fa-sim-card",
        "fas fa-sim-card",
        "fas fa-oil-can",
        "fas fa-trailer",
        "fas fa-hotel",
        "far fa-compass",
        "fas fa-plane-departure",
    ];
    const fetchMoreData = (e) => {
        if (height == "auto") {
            setHeight("60px");
            setIcon("down");
            setLabel("Read more");
        } else {
            setHeight("auto");
            setIcon("up");
            setLabel("Read less");
        }
    };

    return (
        <div className="categories my-5" style={{ marginBlock: "150px" }}>
            {/* <ShadowHead header="Sectors" /> */}
            <h2 style={{ color: "var(--primary-color)", marginBottom: "20px" }}>
                Sectors
            </h2>
            <div className="content m-0">
                {/* <List height={height} items={sectors}>
                    
                </List> */}
                <div className="d-flex flex-wrap gap-4">
                    {sectors.map((sector, index) => {
                        return (
                            <Link
                                key={index}
                                className="d-flex"
                                to={`/members?sector=${sector.secId}`}
                                // className="category--item"
                                style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                    background: "#fff",
                                    borderRadius: "3px",
                                    padding: "10px 20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <em
                                        className={`fad fa-${icons[index]}`}
                                        style={{
                                            fontSize: "22px",
                                            color: "var(--primary-color)",
                                            // marginInline: "10px",
                                        }}
                                    ></em>
                                    <p
                                        style={{
                                            margin: 0,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {sector.secENm}
                                    </p>
                                </div>
                            </Link>
                        );
                    })}
                </div>
                {/* <div className="read-more mb-5">
                    <button
                        type="button"
                        className="btn text-dark"
                        onClick={fetchMoreData}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <em
                                className={`fal fa-angle-${icon}`}
                                style={{
                                    fontSize: "25px",
                                    marginInline: "10px",
                                }}
                            ></em>
                            <p style={{ marginBottom: "0" }}> {label}</p>
                        </div>
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default Categories;
