import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Header from "../../components/Header/Header";
import SearchPage from "../../components/Search";
import api from "../../helpers/api";
import {
    Accordion,
    Badge,
    Button,
    Card,
    Form,
    FormControl,
    Modal,
    OverlayTrigger,
    Popover,
    Table,
} from "react-bootstrap";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import Input from "../../components/events/Input";
import Cookies from "js-cookie";
import { getCookie } from "../../helpers/cookies";

const PublicEvents = () => {
    const { addToast } = useToasts();
    const [events, setEvents] = useState({ content: [], count: 0 });
    const [pastEvents, setPastEvents] = useState({ content: [], count: 0 });
    const [sectors, setSectors] = useState({ content: [], count: 0 });
    const [sector, setSector] = useState("");
    const [verifyPaymentAttach, setVerifyPaymentAttach] = useState("");
    const [eventDetailsModal, setEventDetailsModal] = useState(false);
    const [isJoin, setIsJoin] = useState(false);
    const [isSchedule, setIsSchedule] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const [scheduleDate, setScheduleDate] = useState({
        startTime: "",
        endTime: "",
    });

    const getPublicEvents = async () => {
        try {
            const { data } = await api.post("/events/search-public", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
                filterBy: sector
                    ? [{ status: "pre_started" }, { "sectors._id": sector }]
                    : [{ status: "pre_started" }],
            });

            setEvents(data);
        } catch (error) {
            console.error(error);
        }
    };

    const getPastEvents = async () => {
        try {
            const { data } = await api.post("/events/search-public", {
                offset: 0,
                size: 50,
                sort: "createdAt",
                dir: "desc",
                filterBy: sector
                    ? [{ status: "finished" }, { "sectors._id": sector }]
                    : [{ status: "finished" }],
            });

            setPastEvents(data);
        } catch (error) {
            console.error(error);
        }
    };

    const getSectors = async () => {
        try {
            const { data } = await api.post("/sectors/search", {
                offset: 0,
                size: 50,
            });

            setSectors(data);
        } catch (error) {
            console.log({ error });
        }
    };

    const joinEvent = async (eventId) => {
        try {
            const { data } = await api.post(`/book-request/${eventId}`, {
                verifyPaymentAttach,
            });

            addToast("Your request has been sent. wait for confirmation", {
                id: "success",
                appearance: "success",
                autoDismiss: true,
            });
            setIsJoin(false);
        } catch (error) {
            console.log({ error });
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const scheduleAMeeting = async () => {
        try {
            const { data } = await api.post(`/member-schedules`, {
                eventId: selectedEvent?._id,
                startTime: scheduleDate?.startTime,
                endTime: scheduleDate?.endTime,
            });

            addToast(
                "Your request has been sent. wait for confirmation email",
                {
                    id: "success",
                    appearance: "success",
                    autoDismiss: true,
                }
            );
            setIsSchedule(false);
        } catch (error) {
            console.log({ error });
            addToast("Something went wrong", {
                id: "error",
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    useEffect(() => {
        getPublicEvents();
        getPastEvents();
    }, [sector]);

    useEffect(() => {
        if (Cookies.get("userID")) {
            getSectors();
        }
    }, []);

    console.log({ events, sectors });

    return (
        <Layout>
            <Header>
                <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column">
                        <h1 style={{ fontWeight: "700" }}>
                            EBA{" "}
                            <span style={{ fontWeight: "300" }}>Events</span>{" "}
                        </h1>
                        <h1 style={{ fontWeight: "700" }}>Directory </h1>
                    </div>
                    <p style={{ color: "#333", lineHeight: "1.8" }}>
                        Plan your next adventure with our curated selection of
                        events.
                    </p>
                </div>
            </Header>
            <div className="container">
                <div className="mt-5">
                    <h3>Upcoming Events</h3>
                </div>
                <div className="mt-5 d-flex align-items-center gap-3">
                    {!Cookies.get("userID") ? null : (
                        <Form.Group className="w-100">
                            <Form.Label>Sector</Form.Label>
                            <Form.Select
                                style={{ fontSize: "20px" }}
                                size="lg"
                                value={sector}
                                onChange={(e) => setSector(e.target.value)}
                            >
                                <option value="">All</option>
                                {sectors?.content?.map((s) => (
                                    <option key={s?._id} value={s?._id}>
                                        {s?.secENm}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    )}
                </div>
                <div className="mt-5 d-flex flex-column gap-4">
                    {events?.content?.map((event) => (
                        <div
                            // href={event?._id}
                            key={event?._id}
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                // setSelectedEvent(event);
                                // setEventDetailsModal(true);
                                window.location.pathname = `/event-details/${event?._id}`;
                            }}
                        >
                            <div
                                className="w-100 p-5"
                                style={{
                                    background: "#fff",
                                    position: "relative",
                                }}
                            >
                                <div className="d-flex flex-column gap-2">
                                    <h2>{event?.title}</h2>
                                    <h6>
                                        {moment(event?.startTime).format("lll")}
                                    </h6>
                                </div>
                                <div className="mt-5 d-flex flex-column gap-4">
                                    <h6 style={{ textDecoration: "underline" }}>
                                        Sponsored By
                                    </h6>
                                    <div className="d-flex flex-column gap-3">
                                        {event?.sponsors?.map((s, i) => (
                                            <div
                                                key={s}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    window.open(s?.link);
                                                }}
                                                className="d-flex align-items-center gap-3"
                                            >
                                                <div>
                                                    <img
                                                        src={s?.image}
                                                        alt={s?.name}
                                                        style={{
                                                            objectFit: "cover",
                                                            width: "50px",
                                                            height: "50px",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h6>{s?.name}</h6>
                                                    <p style={{ margin: 0 }}>
                                                        {s?.description}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* {Cookies.get("userRole") ===
                                "superAdmin" ? null : (
                                    <div
                                        style={{
                                            marginTop: "40px",
                                        }}
                                    >
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setSelectedEvent(event);
                                                setIsJoin(true);
                                            }}
                                        >
                                            Join
                                        </Button>
                                    </div>
                                )} */}
                            </div>
                            <div className="w-100">
                                <img
                                    src={event?.cover}
                                    alt={event?.title}
                                    style={{
                                        width: "100%",
                                        height: "500px",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="container">
                <div className="mt-5">
                    <h3>Recent Events</h3>
                </div>
                <div className="mt-5 d-flex flex-column gap-4">
                    {pastEvents?.content?.map((event) => (
                        <div
                            // href={event?._id}
                            key={event?._id}
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                // setSelectedEvent(event);
                                // setEventDetailsModal(true);
                                window.location.pathname = `/event-details/${event?._id}`;
                            }}
                        >
                            <div
                                className="w-100 p-5"
                                style={{
                                    background: "#fff",
                                    position: "relative",
                                }}
                            >
                                <div className="d-flex flex-column gap-2">
                                    <h2>{event?.title}</h2>
                                    <h6>
                                        {moment(event?.startTime).format("lll")}
                                    </h6>
                                </div>
                                <div className="mt-5 d-flex flex-column gap-4">
                                    <h6 style={{ textDecoration: "underline" }}>
                                        Sponsored By
                                    </h6>
                                    <div className="d-flex flex-column gap-3">
                                        {event?.sponsors?.map((s, i) => (
                                            <div
                                                key={s}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    window.open(s?.link);
                                                }}
                                                className="d-flex align-items-center gap-3"
                                            >
                                                <div>
                                                    <img
                                                        src={s?.image}
                                                        alt={s?.name}
                                                        style={{
                                                            objectFit: "cover",
                                                            width: "50px",
                                                            height: "50px",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h6>{s?.name}</h6>
                                                    <p style={{ margin: 0 }}>
                                                        {s?.description}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="w-100">
                                <img
                                    src={event?.cover}
                                    alt={event?.title}
                                    style={{
                                        width: "100%",
                                        height: "500px",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal show={isJoin} onHide={() => setIsJoin(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Join The Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Verify Payment Attach</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Verify Payment Attach"
                                value={verifyPaymentAttach}
                                onChange={(e) =>
                                    setVerifyPaymentAttach(e.target.value)
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setIsJoin(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => joinEvent(selectedEvent?._id)}
                        disabled={!verifyPaymentAttach}
                    >
                        Join
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={eventDetailsModal}
                fullscreen
                onHide={() => setEventDetailsModal(false)}
            >
                <Modal.Body
                    style={{ padding: "0 0 50px 0", background: "#f2f2f2" }}
                >
                    <div className="w-100">
                        <img
                            src={selectedEvent?.cover}
                            alt={selectedEvent?.title}
                            style={{
                                height: "350px",
                                width: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </div>
                    <div className="container">
                        <div className="mt-5 d-flex gap-5">
                            <div style={{ flex: 0.7 }}>
                                <div>
                                    <h1>{selectedEvent?.title}</h1>
                                    <p>{selectedEvent?.description}</p>
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-between p-4 my-4"
                                    style={{
                                        background: "#fff",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: "600",
                                        }}
                                    >
                                        Start Event Time
                                    </span>
                                    <span
                                        style={{
                                            color: "var(--primary-color)",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {moment(
                                            selectedEvent?.startTime
                                        ).format("llll")}
                                    </span>
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-between p-4 my-4"
                                    style={{
                                        background: "#fff",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: "600",
                                        }}
                                    >
                                        End Event Time
                                    </span>
                                    <span
                                        style={{
                                            color: "var(--primary-color)",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {moment(selectedEvent?.endTime).format(
                                            "llll"
                                        )}
                                    </span>
                                </div>

                                <div className="d-flex flex-column gap-3">
                                    {selectedEvent?.eventSessions?.map(
                                        (session, i) => (
                                            <div
                                                key={session?._id}
                                                className="p-4"
                                                style={{
                                                    background: "#fff",
                                                    borderRadius: "4px",
                                                }}
                                            >
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <Badge bg="primary">
                                                            {i + 1}
                                                        </Badge>
                                                        <h5>{session?.name}</h5>
                                                    </div>
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            window.open(
                                                                session?.speakerPresentation
                                                            )
                                                        }
                                                    >
                                                        Presentation
                                                    </Button>
                                                </div>
                                                <div className="mt-4 d-flex align-items-center gap-2">
                                                    <span
                                                        style={{
                                                            color:
                                                                "var(--primary-color)",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        {moment(
                                                            session?.startTime
                                                        ).format("HH:mm a")}
                                                    </span>
                                                    <span>to</span>
                                                    <span
                                                        style={{
                                                            color:
                                                                "var(--primary-color)",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        {moment(
                                                            session?.endTime
                                                        ).format("HH:mm a")}
                                                    </span>
                                                </div>
                                                <div className="mt-4">
                                                    <p>
                                                        {session?.description}
                                                    </p>
                                                </div>
                                                <div className="mt-4 d-flex flex-column gap-3">
                                                    <h6>The Presenter</h6>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <img
                                                            src={
                                                                session?.speakerPhoto
                                                            }
                                                            alt={
                                                                session?.speakerName
                                                            }
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius:
                                                                    "50%",
                                                            }}
                                                        />
                                                        <h6>
                                                            {
                                                                session?.speakerName
                                                            }
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <Accordion defaultActiveKey="0">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>
                                                                <h3>
                                                                    Break Times
                                                                </h3>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Table
                                                                    striped
                                                                    bordered
                                                                    hover
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Name
                                                                            </th>
                                                                            <th>
                                                                                Start
                                                                            </th>
                                                                            <th>
                                                                                End
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {session?.breaktime?.map(
                                                                            (
                                                                                breaktime
                                                                            ) => (
                                                                                <tr
                                                                                    key={
                                                                                        breaktime?._id
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        {
                                                                                            breaktime?.name
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {moment(
                                                                                            breaktime?.startBreakTime
                                                                                        ).format(
                                                                                            "lll"
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {moment(
                                                                                            breaktime?.endBreakTime
                                                                                        ).format(
                                                                                            "lll"
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>
                                                                <h3>
                                                                    B2B Meetings
                                                                </h3>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Table
                                                                    striped
                                                                    bordered
                                                                    hover
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Name
                                                                            </th>
                                                                            <th>
                                                                                Start
                                                                            </th>
                                                                            <th>
                                                                                End
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {session?.b2bMeetings?.map(
                                                                            (
                                                                                meeting
                                                                            ) => (
                                                                                <tr
                                                                                    key={
                                                                                        meeting?._id
                                                                                    }
                                                                                >
                                                                                    <td>
                                                                                        {
                                                                                            meeting?.name
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {moment(
                                                                                            meeting?.startMeetingTime
                                                                                        ).format(
                                                                                            "lll"
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {moment(
                                                                                            meeting?.endMeetingTime
                                                                                        ).format(
                                                                                            "lll"
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <div style={{ flex: 0.3 }}>
                                <Button onClick={() => setIsSchedule(true)}>
                                    Schedule a Meeting
                                </Button>
                                <Modal
                                    show={isSchedule}
                                    onHide={() => setIsSchedule(false)}
                                    centered
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Schedule a Meeting
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                        style={{
                                            padding: "20px",
                                            backgroundColor: "#f5f5f5",
                                        }}
                                    >
                                        <div className="d-flex flex-column gap-5">
                                            <Input
                                                type="datetime-local"
                                                id="startTime"
                                                label="Start Time"
                                                name="startTime"
                                                value={scheduleDate.startTime}
                                                onChange={(e) =>
                                                    setScheduleDate({
                                                        ...scheduleDate,
                                                        startTime:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            <Input
                                                type="datetime-local"
                                                id="endTime"
                                                label="End Time"
                                                name="endTime"
                                                value={scheduleDate.endTime}
                                                onChange={(e) =>
                                                    setScheduleDate({
                                                        ...scheduleDate,
                                                        endTime: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={() => setIsSchedule(false)}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={scheduleAMeeting}
                                        >
                                            Create
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Card
                                    style={{
                                        padding: "10px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Card.Body>
                                        <Card.Title
                                            style={{ fontSize: "20px" }}
                                        >
                                            We will talk about
                                        </Card.Title>
                                        <div className="mt-4 d-flex flex-wrap gap-3">
                                            {selectedEvent?.sectors?.map(
                                                (sec) => (
                                                    <Badge
                                                        key={sec?._id}
                                                        bg="primary"
                                                    >
                                                        {sec?.secENm}
                                                    </Badge>
                                                )
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card
                                    style={{
                                        padding: "10px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Card.Body>
                                        <Card.Title
                                            style={{ fontSize: "20px" }}
                                        >
                                            Country
                                        </Card.Title>
                                        <Badge bg="primary">
                                            {selectedEvent?.country}
                                        </Badge>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setEventDetailsModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
};

export default PublicEvents;
