const beforeSubmit = (values, ...args) => {
    for (let i = 0; i < args.length; i++) {
      const [fieldName, value] = args[i];
  
      if (values[fieldName] === undefined || values[fieldName] === "") {
        continue;
      }
  
      if (Array.isArray(values[fieldName]) && values[fieldName].length === 0) {
        continue;
      }
  
      // the value is an array
      if (Array.isArray(values[fieldName])) {
        const changedArray = values[fieldName].map((item) => item[value]);
        values[fieldName] = changedArray;
      } else {
        // the value is an object
        values[fieldName] = values[fieldName][value];
      }
    }
    return values;
  };
  
  export default beforeSubmit;
  