import {createSelector} from "reselect";

const membersStateSelector = (state) => state.members;
const selectById = (state, sectorId) => sectorId;



export const membersSelector = createSelector(
  [membersStateSelector],
  (members) => members.members
);
export const membersSearchSelector = createSelector(
  [membersStateSelector],
  (members) => members.members
);

export const memberSelector = createSelector(
  [membersStateSelector],
  (members)=>members.member
)

export const membersPioneersSelector = createSelector(
  [membersStateSelector],
  (members) => members.pioneers
);

// export const membersLoadSelector = createSelector(
//   [membersStateSelector],
//   (members) => members.membersLoad
// );

export const membersCountSelector = createSelector(
  [membersStateSelector],
  (members) => members.count
);
export const membersSearchCountSelector = createSelector(
  [membersStateSelector],
  (members) => members.countSearch
);

export const membersIsLoadingSelector = createSelector(
  [membersStateSelector],
  (members) => members.isLoading
);

export const searchValueSelector = createSelector(
  [membersStateSelector],
  (members) => members.searchValue
);

export const memberByIdSelector = createSelector(
 
  [membersSelector, selectById],
  (members, _id) => {
    const member = members.find(member => _id === member._id);
    if (!member) {
      return null;
    }
    const {company} = member;
    if (company) {
      return {
        ...member,
        company: company 
      };
    }
    return member;
  }
);

export const membersAsNameAndIdSelector = createSelector(
  [membersSelector],
  (members) =>
   members.map(({_id , name}) =>{ 
     return {
       name , _id
     }

   }).flat()
)