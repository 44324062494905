import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";

const Header = ({ children }) => {
    return (
        <div className="hero--header home">
            <div className="background-container"></div>
            <div className="container">
                <NavBar />
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default Header;
