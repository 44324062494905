import React, { useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { Field } from "formik";
import { Link, Redirect } from "react-router-dom";

const SelectInput = (props) => {
    const {
        getItems,
        options,
        name: fieldName,
        isLoading,
        disabled,
        label,
        isMulti,
        getLabel,
        optionLabel,
    } = props;

    const [searchValue, setSearchValue] = useState("");
    const [value, setValue] = useState("");

    const defaultLabel = getLabel ? getLabel : "name";
    const [searchTimeout, setSearchTimeout] = useState(null);

    useEffect(() => {
        if (searchValue !== "") {
            clearTimeout(searchTimeout);
            setSearchTimeout(
                setTimeout(() => {
                    getItems({ searchTerm: searchValue, size: 30 });
                }, 300)
            );
        }
    }, [getItems, searchValue]);

    const handleInputChange = (inputValue, action) => {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setSearchValue({ inputValue });
        }
    };
    return (
        <Field name={fieldName} className="form-control form-select">
            {({ form, field }) => (
                <div className="form-group row">
                    {!!label && (
                        <label
                            className="col-lg-3 col-form-label"
                            htmlFor={fieldName}
                        >
                            {label} :
                        </label>
                    )}

                    <FieldContainer
                        className={`${!!label ? "col-lg-9" : "col-lg-12"}`}
                    >
                        <Select
                            {...field}
                            isLoading={isLoading}
                            isMulti={isMulti}
                            isDisabled={disabled}
                            onChange={(changedValue) => {
                                if (changedValue === null && isMulti) {
                                    form.setFieldValue(field.name, []);

                                    return;
                                }

                                // single
                                if (!isMulti) {
                                    return form.setFieldValue(
                                        fieldName,
                                        changedValue
                                    );
                                }
                            }}
                            inputValue={searchValue}
                            defaultInputValue={searchValue}
                            placeholder="Search..."
                            options={options}
                            styles={{
                                menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: 999,
                                    color: "black",
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "white",
                                    color: "gray",
                                    outline: "none",
                                    border: "0",
                                }),
                                input: (provided, state) => ({
                                    ...provided,
                                    color: "black",
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "black",
                                }),
                            }}
                            onInputChange={(inputValue, action) => {
                                if (
                                    action.action !== "input-blur" &&
                                    action.action !== "menu-close"
                                ) {
                                    setSearchValue(inputValue);
                                    form.setFieldValue(
                                        fieldName,
                                        inputValue,
                                        "test"
                                    );
                                }

                                return inputValue;
                            }}
                            onFocus={() => {
                                form.setFieldTouched(fieldName, true);
                            }}
                            onBlur={() => {
                                if (!field.value) {
                                    form.setFieldError(fieldName, "Required");
                                }
                            }}
                            getOptionLabel={
                                optionLabel
                                    ? optionLabel
                                    : (option) => option[defaultLabel]
                            }
                            getOptionValue={(option) => option._id}
                            defaultValue={field.value}
                        />
                    </FieldContainer>
                </div>
            )}
        </Field>
    );
};

const FieldContainer = styled.div`
    position: relative;
    padding-bottom: "15px";
`;

export default SelectInput;
