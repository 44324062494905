import React from "react";

const Input = ({
    value,
    onChange,
    id,
    label,
    type,
    placeholder,
    textareaHeight = "200px",
    name,
    disabled,
    min,
}) => {
    if (type === "textarea") {
        return (
            <div className="position-relative">
                <label
                    for={id}
                    className="position-absolute"
                    style={{
                        top: "-13px",
                        marginLeft: "30px",
                        backgroundColor: "#f5f5f5",
                        padding: "0 10px",
                        fontWeight: "normal",
                    }}
                >
                    {label}
                </label>
                <textarea
                    name={name}
                    id={id}
                    type={type || "text"}
                    placeholder={placeholder || label}
                    onChange={onChange}
                    value={value}
                    style={{
                        paddingLeft: "20px",
                        paddingTop: "10px",
                        height: textareaHeight,
                        width: "100%",
                        background: "inherit",
                        borderRadius: "14px",
                        border: "1px solid rgba(104, 104, 104, 1)",
                        color: "rgba(104, 104, 104, 1)",
                    }}
                />
            </div>
        );
    }

    return (
        <div className="position-relative" style={{ width: "100%" }}>
            <label
                for={id}
                className="position-absolute"
                style={{
                    top: "-13px",
                    marginLeft: "30px",
                    backgroundColor: "#f5f5f5",
                    padding: "0 10px",
                    fontWeight: "normal",
                }}
            >
                {label}
            </label>
            <input
                name={name}
                id={id}
                min={min}
                type={type || "text"}
                placeholder={placeholder || label}
                onChange={onChange}
                value={value}
                disabled={disabled}
                style={{
                    padding: "0 20px",
                    height: "48px",
                    width: "100%",
                    background: "inherit",
                    borderRadius: "14px",
                    border: "1px solid rgba(104, 104, 104, 1)",
                    color: "rgba(104, 104, 104, 1)",
                }}
            />
        </div>
    );
};

export default Input;
