import React from "react";
import P from "prop-types";
import {Field} from "formik";
import styled from "styled-components";
import ReactSelect from "react-select";

import FieldContainer from "../Misc/FieldContainer";

const Select = (props) => {
  const {
    name,
    label,
    options,
    isLoading,
    hideLabel,
    noPadding,
    noMargin,
    isDisabled,
  } = props;
  return (
    <Field name={name} {...props}>
      {({field, form}) => {
        const {errors, touched} = form;
        const errorMessage = errors[field.name];
        const isInvalid = errorMessage && touched[field.name];
        return (
          <FormGroup className='form-group row' noMargin={noMargin}>
            {!hideLabel && (
              <label htmlFor={field.name} className='col-lg-3 col form-label'>
                {label}
              </label>
            )}
            <FieldContainer
              isFull={hideLabel}
              noPadding={noPadding}
              errorMessage={isInvalid && errorMessage}
            >
              <ReactSelect
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: "none",
                    border: "1px solid #ebedf2",
                  }),
                }}
                options={options}
                {...field}
                value={
                  props.value !== "emptyStockLocation" && options.length
                    ? options.find((o) => o.value === field.value) || {
                        label: "Select...",
                        value: "",
                      }
                    : {
                        label: "Select...",
                        value: "",
                      }
                }
                onChange={({value, label, info}) => {
                  form.setFieldValue(name, value);
                  
              
                }}
                noOptionsMessage={({inputValue}) => {
                  if (field.value) {
                    form.setFieldValue(name, "");
                  }
                  if (props.noOptionsMessage) {
                    props.noOptionsMessage(inputValue);
                  }
                  return "No options";
                }}
                isLoading={isLoading}
                isDisabled={isDisabled}
              />
            </FieldContainer>
          </FormGroup>
        );
      }}
    </Field>
  );
};

const FormGroup = styled.div`
  ${(props) =>
    props.noMargin
      ? `
    margin-bottom: 0 !important;
  `
      : ""}
`;

Select.propTypes = {
  name: P.string.isRequired,
  label: P.string,
  isLoading: P.bool,
  isDisabled: P.bool,
  hideLabel: P.bool,
  noPadding: P.bool,
  noMargin: P.bool,
  value: P.string,
};

Select.defaultProps = {
  label: undefined,
  isLoading: false,
  isDisabled: false,
  hideLabel: false,
  noPadding: false,
  noMargin: false,
  value: undefined,
};

export default Select;
