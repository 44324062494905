/* eslint-disable object-curly-newline */
import { Field, Formik } from "formik";
import P from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import TextInput from "../../components/Form/inputs/TextInput";

import FormGroup from "../../components/Form/FormGroup";
import Select from "../../components/Form/inputs/SelectInput";

import membersSchema from "./adsSchema";
import { Redirect } from "react-router";
import { useState } from "react";
import beforeSubmit from "../../helpers/beforeSubmit";
import moment from "moment";

import {
    createAdAction,
    getAdAction,
    getAdsAction,
    setAdsIsLoadingAction,
    updateAdAction,
} from "../../modules/ads/Actions";
import {
    getMembersAction,
    getMembersSearchAction,
} from "../../modules/members/Actions";
import {
    adByIdSelector,
    adsSelector,
    customAdsSelector,
} from "../../modules/ads/Selectors";
import { membersSelector } from "../../modules/members/Selectors";
import Upload from "./upload";
import { DatePickerField } from "../../components/Form/inputs/DatePicker";
import SelectInput from "../../components/Form/inputs/selsect";
import { membersSearchAsNameAndIdSelector } from "../../modules/search/Selectors";
import { Form } from "formik";
import addSchema from "./adsSchema";
import { Badge, Form as BForm } from "react-bootstrap";
const AdsForm = (props) => {
    // items
    const {
        createMember,
        getMember,
        updateMember,
        memberId,
        member,
        adId,
        deleteAd,
        data,
        mappedMembers,
        getMemberssearch,
    } = props;

    const [value, setValue] = useState("");
    const [errorsData, setErrorsData] = useState("");

    const [urlField, setUrlField] = useState("image");
    const [input, setInput] = useState(<></>);

    useEffect(() => {
        if (data?.asset != "") setUrlField("image");
        else setUrlField("video");
    }, [data]);

    const AdLocation = [
        { value: "home", label: "Home" },
        { value: "search", label: "Search" },
        { value: "login", label: "Login" },
        { value: "industry", label: "Industry", name: "industry" },
        { value: "member", label: "Member" },
        { value: "pioneers", label: "Pioneers" },
        { value: "advanced search", label: "Advanced Search" },
    ];

    const AdBlock = [
        { value: "Select..", label: "Select..." },
        { value: "HOME_BLOCK_1", label: "HOME_BLOCK_1", name: "home" },
        { value: "HOME_BLOCK_2", label: "HOME_BLOCK_2", name: "home" },
        { value: "HOME_BLOCK_3", label: "HOME_BLOCK_3", name: "home" },
        { value: "HOME_BLOCK_4", label: "HOME_BLOCK_4", name: "home" },
        { value: "SEARCH_BLOCK_1", label: "SEARCH_BLOCK_1", name: "search" },
        { value: "SEARCH_BLOCK_2", label: "SEARCH_BLOCK_2", name: "search" },
        { value: "SEARCH_BLOCK_3", label: "SEARCH_BLOCK_3", name: "search" },
        { value: "LOGIN_BLOCK_1", label: "LOGIN_BLOCK_1", name: "login" },
        { value: "LOGIN_BLOCK_2", label: "LOGIN_BLOCK_2", name: "login" },
        { value: "LOGIN_BLOCK_3", label: "LOGIN_BLOCK_3", name: "login" },
        { value: "LOGIN_BLOCK_4", label: "LOGIN_BLOCK_4", name: "login" },
        {
            value: "INDUSTRY_BLOCK_1",
            label: "INDUSTRY_BLOCK_1",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_2",
            label: "INDUSTRY_BLOCK_2",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_3",
            label: "INDUSTRY_BLOCK_3",
            name: "industry",
        },
        {
            value: "INDUSTRY_BLOCK_4",
            label: "INDUSTRY_BLOCK_4",
            name: "industry",
        },
        { value: "MEMBER_BLOCK_1", label: "MEMBER_BLOCK_1", name: "member" },
        { value: "MEMBER_BLOCK_2", label: "MEMBER_BLOCK_2", name: "member" },
        { value: "MEMBER_BLOCK_3", label: "MEMBER_BLOCK_3", name: "member" },
        { value: "MEMBER_BLOCK_4", label: "MEMBER_BLOCK_4", name: "member" },
        {
            value: "PIONEERS_BLOCK_1",
            label: "PIONEERS_BLOCK_1",
            name: "pioneers",
        },
        {
            value: "PIONEERS_BLOCK_2",
            label: "PIONEERS_BLOCK_2",
            name: "pioneers",
        },
        {
            value: "PIONEERS_BLOCK_3",
            label: "PIONEERS_BLOCK_3",
            name: "pioneers",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_1",
            label: "ADVANCED_SEARCH_BLOCK_1",
            name: "advanced search",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_2",
            label: "ADVANCED_SEARCH_BLOCK_2",
            name: "advanced search",
        },
        {
            value: "ADVANCED_SEARCH_BLOCK_3",
            label: "ADVANCED_SEARCH_BLOCK_3",
            name: "advanced search",
        },
    ];

    useEffect(() => {
        if (urlField == "video")
            setInput(<TextInput name="targetUrl" label="Video Ad URL:" />);
        else if (urlField === "image")
            setInput(<TextInput name="asset" label="Image Ad URL:" />);
        else if (urlField === "upload") setInput(<Upload name="asset" />);
    }, [urlField, data]);

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div
                style={{
                    background: "white",
                    width: "100%",
                    padding: "30px",
                    marginBottom: "20px",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.08)",
                    borderRadius: "10px",
                    position: "relative",
                }}
            >
                <Formik
                    enableReinitialize
                    initialValues={
                        data
                            ? {
                                  ...data,
                                  startDate: data?.startDate?.split("T")[0],
                                  endDate: data?.endDate?.split("T")[0],
                              }
                            : membersSchema().cast()
                    }
                    onSubmit={(values, errors) => {
                        const payload = {
                            ...values,
                            locationId: data?.locationId,
                            startDate: moment(values.startDate).toISOString(),
                            endDate: moment(values.endDate).toISOString(),
                        };
                        const changedValues = beforeSubmit(payload, ["_id"]);
                        if (adId) {
                            updateMember(adId, changedValues);
                        } else {
                            createMember({
                                ...changedValues,
                                startDate: moment(
                                    values.startDate
                                ).toISOString(),
                                endDate: moment(values.endDate).toISOString(),
                            });
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        values,
                        handleBlur,
                        handleChange,
                        errors,
                        setValues,
                    }) => {
                        return (
                            <Form className="form" onSubmit={handleSubmit}>
                                <div>{errorsData}</div>
                                <Badge
                                    pill
                                    bg="secondary"
                                    style={{
                                        position: "absolute",
                                        top: "5%",
                                        right: "3%",
                                    }}
                                >
                                    Viewers: {data?.views ? data?.views : "0"}
                                </Badge>
                                {/* <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{ width: "100%" }}>
                                        <Select
                                            name="location"
                                            label="Location:"
                                            options={AdLocation}
                                            isLoading={false}
                                            value={setValue(values.location)}
                                        />
                                    </div>
                                </div> */}
                                <div className="mt-5">
                                    {/* <select
                                        name="blockId"
                                        label="Block:"
                                        className="col-12 p-2"
                                        placeholder="Select..."
                                        value={values.blockId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ display: "block" }}
                                    >
                                        <option value=""> Select...</option>
                                        {AdBlock.map((data, index) => {
                                            if (data.name == value)
                                                return (
                                                    <option
                                                        key={index}
                                                        value={data.value}
                                                    >
                                                        {" "}
                                                        {data.value}
                                                    </option>
                                                );
                                        })}
                                    </select> */}
                                    <div className="row mt-4">
                                        <div className="col-4">
                                            <input
                                                type="radio"
                                                name="urlField"
                                                value="video"
                                                onChange={() =>
                                                    setUrlField("video")
                                                }
                                                checked={urlField === "video"}
                                            />{" "}
                                            <label> Video </label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                type="radio"
                                                name="urlField"
                                                value="image"
                                                onChange={() =>
                                                    setUrlField("image")
                                                }
                                                checked={urlField === "image"}
                                            />{" "}
                                            <label> Image </label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                type="radio"
                                                name="urlField"
                                                value="upload"
                                                onChange={() =>
                                                    setUrlField("upload")
                                                }
                                                checked={urlField === "upload"}
                                            />{" "}
                                            <label> Upload Image </label>
                                        </div>
                                    </div>
                                    <div className="mt-5">{input}</div>
                                    <TextInput
                                        name="addLink"
                                        label="Advertisement Link:"
                                    />
                                    <div className="d-flex gap-3 flex-wrap">
                                        <BForm.Group
                                            controlId="startDate"
                                            style={{ flex: 1 }}
                                        >
                                            <BForm.Label>
                                                Enter Start Date
                                            </BForm.Label>
                                            <BForm.Control
                                                type="date"
                                                name="startDate"
                                                placeholder="Enter Start Date"
                                            />
                                        </BForm.Group>
                                        <BForm.Group
                                            controlId="endDate"
                                            style={{ flex: 1 }}
                                        >
                                            <BForm.Label>
                                                Enter End Date
                                            </BForm.Label>
                                            <BForm.Control
                                                type="date"
                                                name="endDate"
                                                placeholder="Enter Start Date"
                                            />
                                        </BForm.Group>
                                    </div>
                                    <button
                                        type="submit"
                                        style={{
                                            width: "100%",
                                            marginTop: "30px",
                                        }}
                                        className={`btn btn-primary  w-100 mb-3`}
                                    >
                                        {deleteAd ? "Edit" : "Submit"}
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                {deleteAd && (
                    <button
                        className="btn btn-secondary"
                        style={{
                            width: "100%",
                        }}
                        onClick={() => {
                            deleteAd(adId);
                            setValue("asset", "");
                            setValue("targetUrl", "");
                            setValue("addLink", "");
                        }}
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const memberId =
        props.match && props.match.params ? props.match.params.id : "";
    return {
        memberId,
        member: adByIdSelector(state, memberId),
        membersIsLoading: setAdsIsLoadingAction(state),
        members: membersSelector(state),
        ads: adsSelector(state),
        customAds: customAdsSelector(state),
        mappedMembers: membersSearchAsNameAndIdSelector(state),
    };
};

const mapDispatchToProps = {
    createMember: createAdAction,
    getMember: getAdAction,
    updateMember: updateAdAction,
    getMembers: getMembersAction,
    getAds: getAdsAction,
    getMemberssearch: getMembersSearchAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsForm);
