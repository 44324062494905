import { actions } from "./Reducer";

export const createAdAction = (formData) => {
  console.log("click");

  return {
    type: actions.CREATE_AD,
    payload: formData,
  };
};

export const createAdSuccessAction = (ad) => ({
  type: actions.CREATE_AD_SUCCESS,
  payload: ad,
});

export const getAdAction = (id) => ({
  type: actions.GET_AD,
  payload: id,
});

export const getAdSuccessAction = (ad) => ({
  type: actions.GET_AD_SUCCESS,
  payload: ad,
});

export const updateAdAction = (adId, adFormData) => ({
  type: actions.UPDATE_AD,
  payload: {
    adId,
    adFormData,
  },
});

export const updateAdSuccessAction = (ad) => ({
  type: actions.UPDATE_AD_SUCCESS,
  payload: ad,
});

export const getAdsAction = (payload) => ({
  type: actions.GET_ADS,
  payload,
});

export const getAdsSuccessAction = (ads) => ({
  type: actions.GET_ADS_SUCCESS,
  payload: ads,
});

export const addViewersAction = (id) => ({
  type: actions.ADS_VIEWERS,
  payload: id,
});

export const addViewersSuccessAction = () => ({
  type: actions.ADS_VIEWERS_SUCCESS,
});

export const setSearchValueAction = (value) => ({
  type: actions.SET_ADS_SEARCH_VALUE,
  payload: value,
});

export const setAdsIsLoadingAction = (isLoading) => ({
  type: actions.SET_ADS_IS_LOADING,
  payload: isLoading,
});

export const getAdsCountAction = (count) => ({
  type: actions.GET_ADS_COUNT,
  payload: count,
});

export const resetAdsAction = () => ({
  type: actions.RESET_ADS,
});

export const deleteAdAction = (id) => ({
  type: actions.DELETE_AD,
  payload: id,
});

export const deleteAdSuccessAction = (adId) => ({
  type: actions.DELETE_AD_SUCCESS,
  payload: adId,
});
