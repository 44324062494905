import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AdsForm from "./adsForm";
import { deleteAdAction, getAdsAction } from "../../modules/ads/Actions";
import { combineReducers } from "redux";
import Sidebar from "../../components/AdminSideBar/sidebar";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Col, Row } from "react-bootstrap";

const AdsList = (props) => {
    const {
        ads,
        selectedBlock,
        deleteAd,
        getAds,
        location,
        locationId,
    } = props;

    const [blockId, setBlockId] = useState("");

    const includesAds = (arr, adValue) => {
        let found = false;
        for (let i = 0; i < arr.length; i++) {
            if (ads[i].blockId == adValue) {
                found = true;
                break;
            }
        }
        return found;
    };
    let intersection = selectedBlock.filter((x) => includesAds(ads, x.label));

    let difference = selectedBlock.filter((x) => !includesAds(ads, x.label));

    useEffect(() => {
        getAds({ filterBy: [{ blockId: blockId }] });
    }, [blockId]);
    const handelChangeLocation = (e) => {
        setBlockId(e);
    };
    const parsed = queryString.parse(window.location.search);
    return (
        <Row>
            <Col xs={12} md={2}>
                <div className="d-flex flex-column gap-3 flex-wrap align-item-right">
                    {selectedBlock.map((adBlock) => {
                        return (
                            <Link
                                to={{
                                    pathname: window.location.pathname,
                                    search: `?block=${adBlock?.label}&location=${parsed?.location}`,
                                }}
                                style={{
                                    textDecoration: "none",
                                    flex: 1,
                                }}
                            >
                                <div
                                    onClick={() =>
                                        handelChangeLocation(adBlock?.value)
                                    }
                                    style={{
                                        border: "1px solid #80808026",
                                        background:
                                            parsed?.block === adBlock?.label
                                                ? "#0A3D80"
                                                : "white",
                                        // width: "180px",
                                        height: "55px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "10px",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        textDecoration: "none",
                                        color:
                                            parsed?.block === adBlock?.label
                                                ? "white"
                                                : "#0A3D80",
                                        cursor: "pointer",
                                    }}
                                >
                                    {adBlock.label}
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </Col>
            <Col xs={12} md={10}>
                {blockId ? (
                    ads?.length === 0 ? (
                        <AdsForm
                            data={{
                                location: location,
                                blockId: blockId,
                                addLink: "",
                                targetUrl: "",
                                asset: "",
                            }}
                        />
                    ) : (
                        <AdsForm
                            data={ads[0]}
                            deleteAd={deleteAd}
                            adId={ads[0]?._id}
                        />
                    )
                ) : null}
            </Col>
        </Row>
    );
};

const mapDispatchToProps = {
    deleteAd: deleteAdAction,
    getAds: getAdsAction,
};

export default connect(null, mapDispatchToProps)(AdsList);
