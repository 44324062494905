import React from "react";
import P from "prop-types";
import styled from "styled-components";

import TextError from "./TextError";

const FieldContainer = ({
  children,
  errorMessage,
  formTip,
  isFull,
  noPadding,
}) => (
  <Container
    className="col-lg-12 " 
    noPadding={noPadding}
  >
    {children}
    <TextError errorMessage={errorMessage} />
    {formTip && <span className='form-text text-muted'>{formTip}</span>}
  </Container>
);

const Container = styled.div`
  position: relative;
  padding-bottom: ${(props) => (props.noPadding ? 0 : "15px")};
`;

FieldContainer.propTypes = {
  children: P.oneOfType([P.arrayOf(P.node), P.node]).isRequired,
  errorMessage: P.string,
  formTip: P.string,
  isFull: P.bool,
  noPadding: P.bool,
};

FieldContainer.defaultProps = {
  errorMessage: "",
  formTip: "",
  isFull: false,
  noPadding: false,
};

export default FieldContainer;
