import { actions } from "./Reducer";

export const newSuccessNotificationAction = (message) => ({
    type: actions.NEW_SUCCESS_NOTIFICATION,
    payload: {
        message,
        dateTimeAsId: Date.now(),
    },
});

export const removeSuccessNotificationAction = (dateTimeAsId) => ({
    type: actions.REMOVE_SUCCESS_NOTIFICATION,
    payload: dateTimeAsId,
});

export const newErrorNotificationAction = (message) => ({
    type: actions.NEW_ERROR_NOTIFICATION,
    payload: {
        message,
        dateTimeAsId: Date.now(),
    },
});

/**
 * Helper Action Creator, this decides which action creator to return from an error
 */
export const errorNotificationFromError = (error) => {
    if (error.response) {
        console.log(error);
        const { response } = error;
        const { status, statusText, data } = response;
        switch (status) {
            case 400: {
                const errorMessage =
                    data && data.message ? data.message : "Unhandled 400";
                return newErrorNotificationAction(errorMessage);
            }
            case 401: {
                const errorMessage =
                    statusText ||
                    "Server responded with 401 unauthorized. Please re-login.";
                return newErrorNotificationAction(errorMessage);
            }
            case 403: {
                const errorMessage = data.message;
                return newErrorNotificationAction(
                    `403 ${errorMessage}: Can't access ${response.config.url}`
                );
            }
            case 422: {
                const { message, errors } = response.data;
                return newErrorNotificationAction(
                    `${message}: ${Object.keys(errors).join(", ")}`
                );
            }
            default:
                return newErrorNotificationAction(
                    `Unhandled error status code: ${status}`
                );
        }
    } else {
        console.log(error);
        return newErrorNotificationAction(
            "Could not connect to server or developer error, see console."
        );
    }
};

export const removeErrorNotificationAction = (dateTimeAsId) => ({
    type: actions.REMOVE_ERROR_NOTIFICATION,
    payload: dateTimeAsId,
});
