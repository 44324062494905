import React from "react";
import Home from "../../pages/home/home";
import MembersList from "../../pages/members";
import Profile from "../../pages/profile/profile";
import ProtectedRoute from "../Auth/ProtectedRouter";
import Help from "../../pages/help/help";
import pioneers from "../../pages/pioneers";
import Members from "../../pages/AddUsers/index";
import Ads from "../../pages/AddAds/index";
import Dashboard from "../../pages/dashboard/dashboard";
import MemberAds from "../../pages/AddAds/memberAds";
import Events from "../../pages/events/events";
import EventSessions from "../../pages/events/EventSessions";
import EventCreate from "../../pages/events/create";
import PublicEvents from "../../pages/public-events";
import SponsorsPage from "../../pages/dashboard/sponsors";
import EventBookingRequests from "../../pages/dashboard/event-book-requests";
import SchedulesPage from "../../pages/dashboard/member-schedules";
import ConfirmSchedule from "../../pages/confirm-schedule";
import SingleEvent from "../../pages/public-events/single-event";
import { Route } from "react-router-dom";
import EventB2B from "../../pages/events/EventB2B";
import EventBreaktimes from "../../pages/events/EventBreaktimes";
import PendingRequests from "../../pages/dashboard/pending-requests";
import UpdateProfilePage from "../../pages/dashboard/update-profile";
import DelegationsPage from "../../pages/dashboard/delegations";
import CreateB2B from "../../pages/events/CreateB2B";
import CreateBreaktime from "../../pages/events/CreateBreaktime";
import CreateSponsor from "../../pages/dashboard/sponsors/CreateSponsor";
import CreateDelegation from "../../pages/dashboard/delegations/CreateDelegation";
import BookedTickets from "../../pages/dashboard/booked-tickets";
import TicketInvitation from "../../pages/ticket-invitation";

const UserLayout = () => {
    return (
        <>
            <ProtectedRoute
                exact
                path="/dashboard/advertisments/member/:id"
                component={MemberAds}
            ></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/member/:id"
                component={Profile}
            ></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/members"
                component={MembersList}
            ></ProtectedRoute>
            {/* <ProtectedRoute exact path="/news/:id" component={NewsDetails}></ProtectedRoute> */}
            {/* <ProtectedRoute path="/news" component={NewsList}></ProtectedRoute> */}
            <ProtectedRoute
                path="/pioneers"
                component={pioneers}
            ></ProtectedRoute>
            <ProtectedRoute path="/contactus" component={Help}></ProtectedRoute>
            <ProtectedRoute path="/home" component={Home}></ProtectedRoute>
            <ProtectedRoute exact path="/" component={Home}></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/dashboard/events"
                component={Events}
            ></ProtectedRoute>
            <Route
                exact
                path="/event-details/:eventId"
                component={SingleEvent}
            ></Route>
            <ProtectedRoute
                path="/dashboard/sponsors"
                component={SponsorsPage}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/create-sponsor"
                component={CreateSponsor}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/schedules"
                component={SchedulesPage}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/pending-requests"
                component={PendingRequests}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/confirm-schedule/:scheduleId"
                component={ConfirmSchedule}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/event-booking-requests"
                component={EventBookingRequests}
            ></ProtectedRoute>
            <Route path="/events" component={PublicEvents}></Route>
            <ProtectedRoute
                path="/dashboard/events-create"
                component={EventCreate}
            ></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/dashboard/sessions"
                component={EventSessions}
            ></ProtectedRoute>

            <ProtectedRoute
                exact
                path="/dashboard/event-b2b"
                component={EventB2B}
            ></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/dashboard/create-b2b"
                component={CreateB2B}
            ></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/dashboard/event-breaktimes"
                component={EventBreaktimes}
            ></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/dashboard/create-breaktimes"
                component={CreateBreaktime}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/users"
                component={Members}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/update-profile"
                component={UpdateProfilePage}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/delegations"
                component={DelegationsPage}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/create-delegation"
                component={CreateDelegation}
            ></ProtectedRoute>
            <ProtectedRoute
                path="/dashboard/booked-tickets"
                component={BookedTickets}
            ></ProtectedRoute>
            <Route
                path="/ticket-invitation/:id"
                component={TicketInvitation}
            ></Route>

            <ProtectedRoute
                exact
                path="/dashboard/advertisments"
                component={Ads}
            ></ProtectedRoute>

            <ProtectedRoute
                exact
                path="/dashboard"
                component={Dashboard}
            ></ProtectedRoute>
            {/* <ProtectedRoute path="*" component={ErrorPage}></ProtectedRoute> */}
        </>
    );
};

export default UserLayout;
