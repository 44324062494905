import {createSelector} from "reselect";

const adsStateSelector = (state) => state.ads;
const selectById = (state, adId) => adId;

export const adsSelector = createSelector(
  [adsStateSelector],
  (ads) => ads.ads
);

export const customAdsSelector = createSelector(
  [adsStateSelector],
  (ads) => ads.customAds
);

export const adsLoadSelector = createSelector(
  [adsStateSelector],
  (ads) => ads.adsLoad
);

export const adsCountSelector = createSelector(
  [adsStateSelector],
  (ads) => ads.count
);

export const adsIsLoadingSelector = createSelector(
  [adsStateSelector],
  (ads) => ads.isLoading
);

export const searchValueSelector = createSelector(
  [adsStateSelector],
  (ads) => ads.searchValue
);

export const adViewersSelector = createSelector(
  [adsStateSelector],
  (ads) => ads.views
);


export const adByIdSelector = createSelector(
 
  [adsSelector, selectById],
  (ads, _id) => {
    const ad = ads.find(ad => _id === ad._id);
    if (!ad) {
      return null;
    }
   
    return ad;
  }
);