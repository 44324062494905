import React, { useEffect, useState } from "react";
import SearchPage from "../../components/Search/index";
import Categories from "../../components/Combine/Categories/index";
import Advertsment from "../../components/Advartsment/Advartsment";
import NewMembersSlider from "../../components/Combine/NewMembersSlider/index";
import Header from "../../components/Header/Header";
import Layout from "../../components/Layout/Layout";
import { connect } from "react-redux";
import { getMembersAction } from "../../modules/members/Actions";
import {
    membersIsLoadingSelector,
    membersPioneersSelector,
    membersSelector,
} from "../../modules/members/Selectors";
import MembersList from "../../components/Combine/Members/MembersList";
import { getSectorsAction } from "../../modules/sectors/Actions";
import {
    sectorsIsLoadingSelector,
    sectorsSelector,
} from "../../modules/sectors/Selector";
import { adsLoadSelector } from "../../modules/ads/Selectors";
import { getAdsAction } from "../../modules/ads/Actions";

function Home(props) {
    const {
        members,
        getMembers,
        getSectors,
        sectors,
        isLoading,
        pioneers,
        getAds,
    } = props;
    const [scrollY, setScrollY] = useState();
    const [opacity, setOpacity] = useState(0);
    const [memberComponents, setMemberComponents] = useState(<></>);

    useEffect(() => {
        getAds({ filterBy: [{ location: "home" }] });
        getSectors();
    }, []);

    const handleScroll = (event) => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (scrollY > 1000) {
            //arbitrary amount
            setMemberComponents(
                <>
                    <MembersList
                        members={members}
                        getMembers={props.getMembers}
                        sectors={sectors}
                        isLoading={isLoading}
                    />
                    <div style={{ marginTop: "60px" }}>
                        <Advertsment location="HOME_BLOCK_3" />
                    </div>
                    <div style={{ marginTop: "60px" }}>
                        <Advertsment location="HOME_BLOCK_4" />
                    </div>
                </>
            );
            setOpacity("1");
        } else setOpacity("0");
    }, [scrollY]);

    return (
        <Layout>
            <div>
                <Header>
                    <SearchPage />
                    <Categories sectors={sectors} />
                </Header>
                <div style={{ marginTop: "60px" }}>
                    <Advertsment location="HOME_BLOCK_1" />
                </div>
                <NewMembersSlider />
                <div style={{ marginTop: "60px" }}>
                    <Advertsment location="HOME_BLOCK_2" />
                </div>
                <br />
                <div
                    style={{
                        opacity: opacity,
                        transition: "ease-in-out",
                        transitionDuration: "1s",
                        transitionProperty: "opacity",
                    }}
                >
                    {memberComponents}
                </div>
            </div>
        </Layout>
    );
}
const mapStateToProps = (state) => {
    return {
        members: membersSelector(state),
        pioneers: membersPioneersSelector(state),
        sectors: sectorsSelector(state),
        isLoading: adsLoadSelector(state),
    };
};
const mapDispatchToProps = {
    getMembers: getMembersAction,
    getSectors: getSectorsAction,
    getAds: getAdsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
